/* eslint camelcase:  off */
export interface IMerchantAccount {
  uuid?: string;
  display_name: string;
  primary: boolean;
  currency: string;
  transaction_limit: number;
  bank_name?: string;
  bank_city?: string;
  bank_region?: string;
  bank_address_line_1?: string;
  bank_address_line_2?: string;
  bank_address_line_3?: string;
  bank_postcode?: string;
  bank_country_code?: string;

  name_on_account?: string;
  iban_code?: string;
  account_number?: string;
  swift_code?: string;
  bic_code?: string;
  branch_code?: string;
  sort_code?: string;
  aba_number?: string;

  beneficiary_city?: string;
  beneficiary_region?: string;
  beneficiary_address_line_1?: string;
  beneficiary_address_line_2?: string;
  beneficiary_address_line_3?: string;
  beneficiary_postcode?: string;
  beneficiary_country_code?: string;

  bank_data_filled: boolean;
  beneficiary_data_filled: boolean;
  note?: string;
  created_at?: string;
  updated_at?: string;
  type?: string,
  deposit_address?: string
}

export const MerchantAccountState: IMerchantAccount = {
  display_name: '',
  primary: false,
  currency: '',
  transaction_limit: 0,
  bank_name: '',
  bank_city: '',
  bank_region: '',
  bank_address_line_1: '',
  bank_address_line_2: '',
  bank_address_line_3: '',
  bank_postcode: '',
  bank_country_code: '',

  name_on_account: '',
  iban_code: '',
  account_number: '',
  swift_code: '',
  bic_code: '',
  branch_code: '',
  sort_code: '',
  aba_number: '',

  beneficiary_city: '',
  beneficiary_region: '',
  beneficiary_address_line_1: '',
  beneficiary_address_line_2: '',
  beneficiary_address_line_3: '',
  beneficiary_postcode: '',
  beneficiary_country_code: '',

  bank_data_filled: false,
  beneficiary_data_filled: false,
  note: '',
  created_at: '',
  updated_at: '',
  type: 'fiat',
  deposit_address: '',
};

export interface IMerchantAccountIds {
  merchantUUID: string;
  merchantAccountUUID: string;
}

export interface IMerchantAccountUpdate {
  form: IMerchantAccount;
  Ids: IMerchantAccountIds;
}

export interface IMerchantAccountAdd {
  form: IMerchantAccount;
  merchantUUID: string;
}
