/* eslint-disable no-nested-ternary */
import { Box, Grid, makeStyles } from '@material-ui/core';
import intl from 'react-intl-universal';
import React from 'react';
import { CharData } from './ChartData';

interface Props {
  count: number;
  total: number;
  average: number;
  currentMonthTotalAmount: number;
  previousMonthTotalAmount: number;
  currentMonthAvg: number;
  previousMonthAvg: number;
  widgetPrev: number;
  widgetCurrent: number;
}

const useStyles = makeStyles(() => ({
  chip: {
    // backgroundColor: '#36a66c',
    color: '#fff',
    paddingLeft: '5px',
    paddingRight: '5px',
    fontSize: '12px',
    fontWeight: 'bold',
  },
  main: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  sectionRow: {
    margin: '10px',
    padding: '10px 20px',
    borderRadius: '10px',
    boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
    backgroundColor: 'rgba(0, 0, 0, 0.05)',
  },
  sectionRowLight: {
    margin: '9px 10px',
    position: 'relative',
    backgroundColor: 'rgba(0, 0, 0, 0.05)',
  },
}));
const WidgetTransaction = ({
  count,
  total,
  average,
  currentMonthTotalAmount,
  previousMonthTotalAmount,
  currentMonthAvg,
  previousMonthAvg,
  widgetPrev,
  widgetCurrent,
}: Props) => {
  const classes = useStyles();
  return (
    <div className={classes.main}>
      <Grid
        container
      >
        <Grid
          item
          md={4}
          xs={12}
        >
          <div>
            <Box
              display="flex"
              justifyContent="flex-end"
              style={{ paddingRight: '35px', marginBottom: '-12px' }}
            >
              <span className={classes.chip} style={{ backgroundColor: total === 0 ? 'gray' : total < 0 ? 'red' : '#36a66c' }}>
                {total}
                %
              </span>
            </Box>
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              justifyContent="space-around"
            >
              <h4>{intl.get('TOTAL_AMOUNT')}</h4>
              <h4
                style={{
                  border: '1px solid #959595', borderRadius: '3px', color: '#4031f3', opacity: 0.6, minWidth: '90px',
                }}
              >
                {previousMonthTotalAmount?.toFixed(2)}
              </h4>
              <h4 style={{
                color: '#4031f3', border: '1px solid #959595', borderRadius: '3px', minWidth: '90px',
              }}
              >
                {currentMonthTotalAmount?.toFixed(2)}
              </h4>
            </Box>
            <div className={[classes.sectionRow, classes.sectionRowLight].join(' ')}>
              <CharData color="#4031f3" />
            </div>
          </div>
        </Grid>
        <Grid
          item
          md={4}
          xs={12}
        >
          <Box
            display="flex"
            justifyContent="flex-end"
            style={{ paddingRight: '35px', marginBottom: '-12px' }}
          >
            <span className={classes.chip} style={{ backgroundColor: count === 0 ? 'gray' : count < 0 ? 'red' : '#36a66c' }}>
              {count}
              %
            </span>
          </Box>
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-around"
          >
            <h4>{intl.get('COUNT_BY_MONTH')}</h4>
            <h4 style={{
              border: '1px solid #959595', borderRadius: '3px', color: '#870a15', opacity: 0.6, minWidth: '90px',
            }}
            >
              {widgetPrev?.toFixed(2)}
            </h4>
            <h4 style={{
              color: '#870a15', border: '1px solid #959595', borderRadius: '3px', minWidth: '90px',
            }}
            >
              {widgetCurrent?.toFixed(2)}
            </h4>
          </Box>
          <div className={[classes.sectionRow, classes.sectionRowLight].join(' ')}>
            <CharData color="#870a15" />
          </div>
        </Grid>
        <Grid
          item
          md={4}
          xs={12}
        >
          <Box
            display="flex"
            justifyContent="flex-end"
            style={{ paddingRight: '35px', marginBottom: '-12px' }}
          >
            <span className={classes.chip} style={{ backgroundColor: average === 0 ? 'gray' : average < 0 ? 'red' : '#36a66c' }}>
              {average}
              {' '}
              %
            </span>
          </Box>
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-around"
          >
            <h4>{intl.get('AVERAGE_BY_MONTH')}</h4>
            <h4
              style={{
                border: '1px solid #959595', borderRadius: '3px', color: '#2677a6', opacity: 0.6, minWidth: '90px',
              }}
            >
              {previousMonthAvg?.toFixed(2)}
            </h4>
            <h4 style={{
              color: '#2677a6', border: '1px solid #959595', borderRadius: '3px', minWidth: '90px',
            }}
            >
              {currentMonthAvg?.toFixed(2)}
            </h4>
          </Box>
          <div className={[classes.sectionRow, classes.sectionRowLight].join(' ')}>
            <CharData color="#2677a6" />
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default WidgetTransaction;
