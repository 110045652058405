import { API } from '../config';
import { PayoutFilter } from '../types/dashboard.types';
import { AddPayoutAccount, IMerchantBillingPayload, PayoutAccountIds } from '../types/merchant-billing.types';

const getMerchantBillingList = (filters?: PayoutFilter) => {
  let queryParams = `${filters?.status ? `&status=${filters.status}` : ''}`;
  if (filters?.merchantUUID) {
    queryParams += `&merchant_uuid=${filters.merchantUUID}`;
  }
  if (filters?.currency) {
    queryParams += `&currency=${filters.currency}`;
  }
  if (filters?.selectedDates?.from && filters?.selectedDates?.to) {
    queryParams += `&from=${filters?.selectedDates.from}&to=${filters?.selectedDates.to}`;
  }
  if (filters?.paidOn) {
    queryParams += `&paid_on=${filters?.paidOn}`;
  }
  return API
    .get(`/admin/merchants/payouts?with=merchant,accounts${queryParams}`);
};

const getPayoutsExport = (filters?: PayoutFilter) => {
  let queryParams = '?';
  if (filters?.selectedDates?.from && filters?.selectedDates?.to) {
    queryParams += `from=${filters?.selectedDates.from}&to=${filters?.selectedDates.to}`;
    if (filters?.status) {
      queryParams += `&status=${filters.status}`;
    }
    if (filters?.merchantUUID) {
      queryParams += `&merchant_uuid=${filters.merchantUUID}`;
    }
    if (filters?.currency) {
      queryParams += `&currency=${filters.currency}`;
    }
    if (filters?.paidOn) {
      queryParams += `&paid_on=${filters?.paidOn}`;
    }
  }
  return API
    .get(`/admin/exports/payouts${queryParams}`);
};

const getMerchantBillingById = (uuid: string) => API.get(`/admin/merchants/payouts/${uuid}?with=merchant,accounts`);

const updateMerchantBillingStatus = (body: any) => API.post(`/admin/merchants/payouts/${body.id}`, body.form);

const addMerchantBilling = (body: IMerchantBillingPayload) => API.post('/admin/merchants/payouts', body);

const getMerchantAccountOptions = (merchantUUID: string) => API.get(`/admin/options/merchants/${merchantUUID}/accounts`);

const getPayoutTransactionList = (merchantBillUUID: string) => API.get(`/admin/merchants/payouts/${merchantBillUUID}/transactions`);

const addPayoutAccount = (body: AddPayoutAccount) => API.post(`/admin/merchants/payouts/${body.merchantBillUUID}/accounts`, body.form);

const deletePayoutAccount = (payload: PayoutAccountIds) => API
  .delete(`/admin/merchants/payouts/${payload.merchantBillUUID}/accounts/${payload.payoutAccountUUID}`);

export const merchantBillingService = {
  getMerchantBillingList,
  getMerchantBillingById,
  addMerchantBilling,
  updateMerchantBillingStatus,
  getMerchantAccountOptions,
  getPayoutTransactionList,
  addPayoutAccount,
  deletePayoutAccount,
  getPayoutsExport,
};
