import React from 'react';
import { Button } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => createStyles({
  button: {
    background: '#870a15',
    color: 'white',
    fontSize: '10px',
    fontWeight: 'bold',
    margin: '10px',
    padding: '7px 60px',
  },

}));

const StatusButton = ({ status, updateStatus }: any) => {
  const classes = useStyles();

  let text = '';
  let color = '';

  if (status === 'Pending') {
    text = 'Approved';
    color = 'green';
  } else if (status === 'Approved') {
    text = 'InProgress';
    color = 'blue';
  } else if (status === 'InProgress') {
    text = 'Complete';
    color = 'green';
  }

  return (
    <div>
      <Button
        className={classes.button}
        style={{ backgroundColor: `${color}` }}
        variant="contained"
        onClick={() => updateStatus(text)}
      >
        {text}
      </Button>
    </div>
  );
};

export default StatusButton;
