import React from 'react';
import {
  ThemeProvider,
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Box from '@material-ui/core/Box';
import {
  makeStyles,
} from '@material-ui/core/styles';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import { customDatePicker } from '../../../assets/styles/date-picker';
import { Dates } from '../../../types/common.types';
import CommonDate from '../../../components/CustomDate';

const useStyles = makeStyles(() => ({
  blueColor: {
    color: '#3d7df7 !important',
  },
  yearDate: {
    border: '1px solid #959595',
    borderRadius: '11px',
    color: '#959595',
    paddingLeft: '8px',
    paddingRight: '8px',
  },
  monthsContainer: {
    backgroundImage: 'linear-gradient(to right, #ffffff 2%, #ebeaea 52%, #ffffff 96%)',
    margin: '10px',
    padding: '10px',
  },
  monthLabel: {
    margin: '0 24px',
    color: 'rgba(44, 51, 61, 0.6)',
    cursor: 'pointer',
  },
}));
const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

interface Props {
  getSelectedDates: (event: Dates) => void;
  SelectedPrevDates: (event: Dates) => void;
}

export const TopSection: React.FC<Props> = ({ getSelectedDates, SelectedPrevDates }: Props) => {
  const classes = useStyles();
  const firstYear = 2020;
  const [year, setYear] = React.useState(new Date().getFullYear());
  const [month, setMonth] = React.useState(new Date().getMonth());
  const date = new Date();
  const firstDate = new Date(date.getFullYear(), month + 1, 1).getDate();
  const lastDate = new Date(date.getFullYear(), month + 1, 0).getDate();
  const firstDay = `0${firstDate} / ${month + 1} / ${year}`;
  const lastDays = `${lastDate} / ${month + 1} / ${year}`;

  const dateFormat = (monthIndex: number, nextYear?: number | null) => {
    const lastDay = (new Date(year, monthIndex + 1, 0)).getDate();
    const selectedDate = {
      from: (`${year}-${(monthIndex + 1).toString().length === 1 ? `0${monthIndex + 1}` : (monthIndex + 1)}-01`),
      to: (`${year}-${(monthIndex + 1).toString().length === 1 ? `0${monthIndex + 1}` : (monthIndex + 1)}-${lastDay}`),
    };
    const PrevDate = {
      from: (`${year}-${(monthIndex - 0).toString().length === 1 ? `0${monthIndex - 0}` : (monthIndex - 0)}-01`),
      to: (`${year}-${(monthIndex - 0).toString().length === 1 ? `0${monthIndex - 0}` : (monthIndex - 0)}-${lastDay}`),
    };
    setMonth(monthIndex);
    getSelectedDates(selectedDate);
    SelectedPrevDates(PrevDate);
  };
  return (
    <>
      <div>
        <div style={{ fontSize: '12px', fontWeight: 'bold' }}><CommonDate month={months[month]} year={year} /></div>
        <hr />
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="center"
          alignItems="center"
        >
          <div className={classes.yearDate}>{firstDay}</div>
          <ThemeProvider theme={customDatePicker}>
            <IconButton
              onClick={() => {
                dateFormat((month - 1) > 11
                  ? (month - 1) - 12 : (month - 1) < 0 ? 12 - Math.abs(month - 1) : month - 1);
                ((month - 1) < 0 ? setYear(year - 1) : setYear(year));
              }}
              disabled={firstYear === year}
            >
              <KeyboardArrowLeftIcon fontSize="large" />
            </IconButton>
            <h1 className={classes.blueColor}>{year}</h1>
            <IconButton onClick={() => {
              dateFormat((month + 1) > 11
                ? (month + 1) - 12 : month + 1, (month + 1) > 11 ? year + 1 : null);
              ((month + 1) > 11 ? setYear(year + 1) : setYear(year));
            }}
            >
              <KeyboardArrowRightIcon fontSize="large" />
            </IconButton>
          </ThemeProvider>
          <div className={classes.yearDate}>{lastDays}</div>
        </Box>
      </div>
      <div className={classes.monthsContainer}>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="center"
          alignItems="center"
        >
          <h3
            onClick={() => {
              dateFormat((month - 2) > 11
                ? (month - 2) - 12 : (month - 2) < 0 ? 12 - Math.abs(month - 2) : month - 2);
              ((month - 2) < 0 ? setYear(year - 1) : setYear(year));
            }}
            className={classes.monthLabel}
          >
            {months[(month - 2) > 11
              ? (month - 2) - 12 : (month - 2) < 0 ? 12 - Math.abs(month - 2) : month - 2]}
          </h3>
          <h3
            onClick={() => {
              dateFormat((month - 1) > 11
                ? (month - 1) - 12 : (month - 1) < 0 ? 12 - Math.abs(month - 1) : month - 1);
              ((month - 1) < 0 ? setYear(year - 1) : setYear(year));
            }}
            className={classes.monthLabel}
          >
            {months[(month - 1) > 11
              ? (month - 1) - 12 : (month - 1) < 0 ? 12 - Math.abs(month - 1) : month - 1]}
          </h3>
          <h3
            onClick={() => dateFormat((month))}
            className={[classes.monthLabel, classes.blueColor].join(' ')}
          >
            {months[month]}
          </h3>
          <h3
            onClick={() => {
              dateFormat((month + 1) > 11
                ? (month + 1) - 12 : month + 1, (month + 1) > 11 ? year + 1 : null);
              ((month + 1) > 11 ? setYear(year + 1) : setYear(year));
            }}
            className={classes.monthLabel}
          >
            {months[(month + 1) > 11
              ? (month + 1) - 12 : month + 1]}
          </h3>
          <h3
            onClick={() => {
              dateFormat((month + 2) > 11
                ? (month + 2) - 12 : month + 2, (month + 2) > 11 ? year + 1 : null);
              ((month + 2) > 11 ? setYear(year + 1) : setYear(year));
            }}
            className={classes.monthLabel}
          >
            {months[(month + 2) > 11
              ? (month + 2) - 12 : month + 2]}
          </h3>
        </Box>
      </div>
    </>
  );
};
