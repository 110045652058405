import React from 'react';
import intl from 'react-intl-universal';
import {
  Card,
  CardHeader,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  makeStyles,
  Paper,
  IconButton,
  Collapse,
  Box,
  Typography,
  TablePagination,
} from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { BillingPayment } from '../../../types/merchant-billing.types';

interface BillingPaymentTypes {
  billingPayments: BillingPayment[];
  merchantPanel: boolean;
}

const useStyles = makeStyles({
  root: {
    marginTop: '10px',
  },
  table: {
    minWidth: 650,
  },
});

export const BillingPaymentList = ({ billingPayments, merchantPanel }: BillingPaymentTypes) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [expandedTransactionId, setExpandedTransactionId] = React.useState<string | undefined>('');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  return (
    <Card className={classes.root}>
      <CardHeader
        subheader={intl.get('PAYOUT_TRANSACTIONS')}
      />
      <TableContainer component={Paper}>
        <Table stickyHeader className={classes.table} aria-label="Billing transaction list">
          <TableHead>
            <TableRow>
              <TableCell>#</TableCell>
              {!merchantPanel
                ? (
                  <>
                    <TableCell align="center">{intl.get('TRANSACTION_TOTAL')}</TableCell>
                    <TableCell align="center">{intl.get('PSP_COST')}</TableCell>
                    <TableCell align="center">{intl.get('MERCHANT_MARGIN')}</TableCell>
                    <TableCell align="center">{intl.get('SERVICE_FEE')}</TableCell>
                    <TableCell align="center">{intl.get('REMAINING_AMOUNT')}</TableCell>
                    <TableCell align="center">{intl.get('CURRENCY')}</TableCell>
                    <TableCell align="center">{intl.get('CREATED_AT')}</TableCell>
                  </>
                )
                : (
                  <>
                    <TableCell align="center">{intl.get('AMOUNT')}</TableCell>
                    <TableCell align="center">{intl.get('SERVICE_FEE')}</TableCell>
                    <TableCell align="center">{intl.get('REMAINING_AMOUNT')}</TableCell>
                    <TableCell align="center">{intl.get('CURRENCY')}</TableCell>
                  </>
                )}
              <TableCell align="center">{intl.get('ACTIONS')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {billingPayments
              && billingPayments.length
              && billingPayments.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row: BillingPayment, i: number) => (
                <>
                  <TableRow key={row.uuid}>
                    <TableCell>
                      {i + 1}
                    </TableCell>
                    {!merchantPanel
                      ? (
                        <>
                          <TableCell align="center">
                            {row?.transaction_total}
                          </TableCell>
                          <TableCell align="center">{row.psp_margin_amount}</TableCell>
                          <TableCell align="center">{row.merchant_margin_amount}</TableCell>
                          <TableCell align="center">{row?.service_fee}</TableCell>
                          <TableCell align="center">{row.remaining_amount}</TableCell>
                          <TableCell align="center">{row.currency}</TableCell>
                          <TableCell align="center">
                            {row.created_at && `${new Date(row.created_at).toLocaleDateString()} ${new Date(row.created_at).toLocaleTimeString()}`}
                          </TableCell>
                          <TableCell align="center">
                            <IconButton aria-label="expand row" size="small" onClick={() => { setOpen(!open); setExpandedTransactionId(row.uuid); }}>
                              {open && expandedTransactionId === row.uuid ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                            </IconButton>
                          </TableCell>
                        </>
                      ) : (
                        <>
                          <TableCell align="center">{row?.amount}</TableCell>
                          <TableCell align="center">{row?.service_fee}</TableCell>
                          <TableCell align="center">{row.remaining_amount}</TableCell>
                          <TableCell align="center">{row.currency}</TableCell>
                          <TableCell align="center">
                            <IconButton aria-label="expand row" size="small" onClick={() => { setOpen(!open); setExpandedTransactionId(row.uuid); }}>
                              {open && expandedTransactionId === row.uuid ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                            </IconButton>
                          </TableCell>
                        </>
                      )}
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={10}>
                      <Collapse in={open && expandedTransactionId === row.uuid} timeout="auto" unmountOnExit>
                        <Box margin={1}>
                          <Typography variant="h6" gutterBottom component="div">
                            {intl.get('DETAILS')}
                          </Typography>
                          {!merchantPanel
                            ? (
                              <>
                                <TableContainer component={Paper}>
                                  <Table stickyHeader className={classes.table} aria-label="Billing transaction">
                                    <TableHead>
                                      <TableRow>
                                        <TableCell>#</TableCell>
                                        <TableCell align="center">{intl.get('PSP_COST')}</TableCell>
                                        <TableCell align="center">{intl.get('PSP_MARGIN_RATE')}</TableCell>
                                        <TableCell align="center">{intl.get('MERCHANT_MARGIN_RATE')}</TableCell>
                                        <TableCell align="center">{intl.get('SERVICE_FEE')}</TableCell>
                                        <TableCell align="center">{intl.get('REMAINING_AMOUNT')}</TableCell>
                                        <TableCell align="center">{intl.get('FX_RATE')}</TableCell>
                                        <TableCell align="center">{intl.get('CREATED_AT')}</TableCell>
                                      </TableRow>
                                    </TableHead>
                                    <TableBody>
                                      <TableRow key={row.uuid}>
                                        <TableCell>
                                          {i + 1}
                                        </TableCell>
                                        <TableCell align="center">{row.psp_cost}</TableCell>
                                        <TableCell align="center">{row.psp_margin_rate}</TableCell>
                                        <TableCell align="center">{row.merchant_margin_rate}</TableCell>
                                        <TableCell align="center">{row.service_fee}</TableCell>
                                        <TableCell align="center">{row.remaining_amount}</TableCell>
                                        <TableCell align="center">{row.fx_rate}</TableCell>
                                        <TableCell align="center">{new Date(row.created_at).toLocaleDateString()}</TableCell>
                                      </TableRow>
                                    </TableBody>
                                  </Table>
                                </TableContainer>
                              </>
                            )
                            : null}
                        </Box>
                      </Collapse>
                    </TableCell>
                  </TableRow>
                </>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      {billingPayments && billingPayments.length
        ? (
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={billingPayments.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        )
        : null}
    </Card>
  );
};
