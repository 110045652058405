import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import intl from 'react-intl-universal';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { KYCActions } from '../../../store/actions/Merchant';

interface IProps {
  openDialog: boolean, closeApproveDialog: () => void;
  kyc: any;
  merchantUUID: string;
}

export const ApproveKyc: React.FC<IProps> = ({
  openDialog,
  closeApproveDialog,
  kyc,
  merchantUUID,
}: IProps): React.ReactElement => {
  const [open, setOpen] = React.useState(openDialog);
  const dispatch = useDispatch();
  useEffect(() => {
    setOpen(openDialog);
  }, [openDialog]);

  const approveKyc = () => {
    dispatch(KYCActions.approveKyc({ merchantUUID, merchantKycUUID: kyc.uuid }));
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={closeApproveDialog}
        aria-labelledby="kyc-approve-title"
        aria-describedby="kyc-approve-desc"
      >
        <DialogTitle id="kyc-approve-title">{intl.get('APPROVE_KYC')}</DialogTitle>
        <DialogContent>
          <DialogContentText id="kyc-approve-desc">
            {intl.get('APPROVE_KYC_DIALOG_DESCRIPTION')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeApproveDialog} color="primary">
            {intl.get('CANCEL')}
          </Button>
          <Button onClick={approveKyc} color="primary" autoFocus>
            {intl.get('APPROVE')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
