import { Dispatch } from 'redux';
import { AxiosError } from 'axios';
import { merchantAccountConstants } from '../constants';
import { merchantAccountService } from '../../services';
import { merchantPanelAccountService } from '../../services/Merchant';

import {
  IMerchantAccount, IMerchantAccountAdd, IMerchantAccountIds, IMerchantAccountUpdate,
} from '../../types/merchant-account.types';
import { Pagination } from '../../types/common.types';

const getMerchantAccountList = (merchantUUID: string, paginationData: Pagination, merchantPanel: boolean) => {
  const request = () => ({ type: merchantAccountConstants.GET_MERCHANT_ACCOUNT_LIST_REQUEST });
  const success = (allMerchantAccountList: IMerchantAccount[], pagination: Pagination) => (
    { type: merchantAccountConstants.GET_MERCHANT_ACCOUNT_LIST_SUCCESS, allMerchantAccountList, pagination }
  );
  const failure = (error: any) => ({ type: merchantAccountConstants.GET_MERCHANT_ACCOUNT_LIST_FAILURE, error });
  return (dispatch: Dispatch) => {
    dispatch(request());
    if (!merchantPanel) {
      merchantAccountService.getMerchantAccountList(merchantUUID, paginationData)
        .then(
          (res) => {
            dispatch(success(res.data, res.data));
          },
        ).catch((error) => {
          dispatch(failure(error.message));
        });
    } else {
      merchantPanelAccountService.getMerchantAccountList(paginationData)
        .then(
          (res) => {
            dispatch(success(res.data.data, res.data));
          },
        ).catch((error) => {
          dispatch(failure(error.message));
        });
    }
  };
};

const getMerchantAccountById = (ids: IMerchantAccountIds) => {
  const request = () => ({ type: merchantAccountConstants.GET_MERCHANT_ACCOUNT_BY_ID_REQUEST });
  const success = (payload: IMerchantAccount) => (
    { type: merchantAccountConstants.GET_MERCHANT_ACCOUNT_BY_ID_SUCCESS, payload }
  );
  const failure = (error: any) => ({ type: merchantAccountConstants.GET_MERCHANT_ACCOUNT_BY_ID_FAILURE, error });
  return (dispatch: Dispatch) => {
    dispatch(request());
    merchantAccountService.getMerchantAccountById(ids)
      .then(
        (res) => {
          dispatch(success(res.data));
        },
      ).catch((error) => {
        dispatch(failure(error.message));
      });
  };
};

const addMerchantAccount = (body: IMerchantAccountAdd, merchantPanel: boolean) => {
  const request = () => ({ type: merchantAccountConstants.ADD_MERCHANT_ACCOUNT_REQUEST });
  const success = (payload: IMerchantAccount[]) => ({ type: merchantAccountConstants.ADD_MERCHANT_ACCOUNT_SUCCESS, payload });
  const failure = (errors: any) => ({ type: merchantAccountConstants.ADD_MERCHANT_ACCOUNT_FAILURE, errors });
  return (dispatch: Dispatch) => {
    dispatch(request());
    if (!merchantPanel) {
      merchantAccountService.addMerchantAccount(body)
        .then(
          (res) => {
            dispatch(success(res.data));
          },
        ).catch((error: AxiosError) => dispatch(failure(error.response?.data?.errors)));
    } else {
      merchantPanelAccountService.addMerchantAccount(body)
        .then(
          (res) => {
            dispatch(success(res.data));
          },
        ).catch((error: AxiosError) => dispatch(failure(error.response?.data?.errors)));
    }
  };
};

const updateMerchantAccount = (body: IMerchantAccountUpdate, merchantPanel: boolean) => {
  const request = () => ({ type: merchantAccountConstants.UPDATE_MERCHANT_ACCOUNT_REQUEST });
  const success = (payload: IMerchantAccount[]) => ({ type: merchantAccountConstants.UPDATE_MERCHANT_ACCOUNT_SUCCESS, payload });
  const failure = (error: any) => ({ type: merchantAccountConstants.UPDATE_MERCHANT_ACCOUNT_FAILURE, error });
  return (dispatch: Dispatch) => {
    dispatch(request());
    if (!merchantPanel) {
      merchantAccountService.updateMerchantAccount(body)
        .then(
          (res) => {
            dispatch(success(res.data));
          },
        ).catch((error: AxiosError) => dispatch(failure(error.response?.data?.errors || [])));
    } else {
      merchantPanelAccountService.updateMerchantAccount(body)
        .then(
          (res) => {
            dispatch(success(res.data));
          },
        ).catch((error: AxiosError) => dispatch(failure(error.response?.data?.errors || [])));
    }
  };
};

const deleteMerchantAccount = (payload: IMerchantAccountIds, merchantPanel: boolean) => {
  const request = () => ({ type: merchantAccountConstants.DELETE_MERCHANT_ACCOUNT_REQUEST });
  const success = (uuid: string) => (
    {
      type: merchantAccountConstants.DELETE_MERCHANT_ACCOUNT_SUCCESS, uuid,
    }
  );
  const failure = (error: any) => ({ type: merchantAccountConstants.DELETE_MERCHANT_ACCOUNT_FAILURE, error });
  return (dispatch: Dispatch) => {
    dispatch(request());
    if (!merchantPanel) {
      merchantAccountService.deleteMerchantAccount(payload)
        .then(
          (res) => {
            if (res.status === 204) {
              dispatch(success(payload.merchantAccountUUID));
            } else {
              dispatch(failure('Something went wrong'));
            }
          },
        );
    } else {
      merchantPanelAccountService.deleteMerchantAccount(payload)
        .then(
          (res) => {
            if (res.status === 204) {
              dispatch(success(payload.merchantAccountUUID));
            } else {
              dispatch(failure('Something went wrong'));
            }
          },
        );
    }
  };
};

export const merchantAccountActions = {
  getMerchantAccountList,
  getMerchantAccountById,
  addMerchantAccount,
  updateMerchantAccount,
  deleteMerchantAccount,
};
