import { IAdminTransaction } from '../../types/admin-transaction.types';
import { Option, Pagination } from '../../types/common.types';
import { ExportData } from '../../types/merchant-billing.types';

interface Types {
  GET_ADMIN_TRANSACTION_LIST_REQUEST: string;
  GET_ADMIN_TRANSACTION_LIST_SUCCESS: string;
  GET_ADMIN_TRANSACTION_LIST_FAILURE: string;

  GET_ADMIN_TRANSACTION_BY_ID_REQUEST: string;
  GET_ADMIN_TRANSACTION_BY_ID_SUCCESS: string;
  GET_ADMIN_TRANSACTION_BY_ID_FAILURE: string;

  GET_PAYMENT_PROVIDER_OPTIONS_REQUEST: string;
  GET_PAYMENT_PROVIDER_OPTIONS_SUCCESS: string;
  GET_PAYMENT_PROVIDER_OPTIONS_FAILURE: string;

  GET_PAYMENT_METHOD_OPTIONS_REQUEST: string;
  GET_PAYMENT_METHOD_OPTIONS_SUCCESS: string;
  GET_PAYMENT_METHOD_OPTIONS_FAILURE: string;

  GET_TRANSACTIONS_EXPORT_REQUEST: string;
  GET_TRANSACTIONS_EXPORT_SUCCESS: string;
  GET_TRANSACTIONS_EXPORT_FAILURE: string;

  RESET_EXPORT_DATA_STATE: string;

}

export const adminTransactionConstants: Types = {

  GET_ADMIN_TRANSACTION_LIST_REQUEST: 'GET_ADMIN_TRANSACTION_LIST_REQUEST',
  GET_ADMIN_TRANSACTION_LIST_SUCCESS: 'GET_ADMIN_TRANSACTION_LIST_SUCCESS',
  GET_ADMIN_TRANSACTION_LIST_FAILURE: 'GET_ADMIN_TRANSACTION_LIST_FAILURE',

  GET_ADMIN_TRANSACTION_BY_ID_REQUEST: 'GET_ADMIN_TRANSACTION_BY_ID_REQUEST',
  GET_ADMIN_TRANSACTION_BY_ID_SUCCESS: 'GET_ADMIN_TRANSACTION_BY_ID_SUCCESS',
  GET_ADMIN_TRANSACTION_BY_ID_FAILURE: 'GET_ADMIN_TRANSACTION_BY_ID_FAILURE',

  GET_PAYMENT_PROVIDER_OPTIONS_REQUEST: 'GET_PAYMENT_PROVIDER_OPTIONS_REQUEST',
  GET_PAYMENT_PROVIDER_OPTIONS_SUCCESS: 'GET_PAYMENT_PROVIDER_OPTIONS_SUCCESS',
  GET_PAYMENT_PROVIDER_OPTIONS_FAILURE: 'GET_PAYMENT_PROVIDER_OPTIONS_FAILURE',

  GET_PAYMENT_METHOD_OPTIONS_REQUEST: 'GET_PAYMENT_METHOD_OPTIONS_REQUEST',
  GET_PAYMENT_METHOD_OPTIONS_SUCCESS: 'GET_PAYMENT_METHOD_OPTIONS_SUCCESS',
  GET_PAYMENT_METHOD_OPTIONS_FAILURE: 'GET_PAYMENT_METHOD_OPTIONS_FAILURE',

  GET_TRANSACTIONS_EXPORT_REQUEST: 'GET_TRANSACTIONS_EXPORT_REQUEST',
  GET_TRANSACTIONS_EXPORT_SUCCESS: 'GET_TRANSACTIONS_EXPORT_SUCCESS',
  GET_TRANSACTIONS_EXPORT_FAILURE: 'GET_TRANSACTIONS_EXPORT_FAILURE',

  RESET_EXPORT_DATA_STATE: 'RESET_EXPORT_DATA_STATE',
};

interface LoadGetAdminTransactionListAction {
  type: typeof adminTransactionConstants.GET_ADMIN_TRANSACTION_LIST_REQUEST;
  allAdminTransactionList: IAdminTransaction[];
  payload: IAdminTransaction;
  uuid: string;
  providerOptions: Option[];
  paymentMethodOptions: Option[];
  exports: ExportData;
  pagination:Pagination;
}

interface LoadedGetAdminTransactionListAction {
  type: typeof adminTransactionConstants.GET_ADMIN_TRANSACTION_LIST_SUCCESS;
  allAdminTransactionList: IAdminTransaction[];
  payload: IAdminTransaction;
  uuid: string;
  providerOptions: Option[];
  paymentMethodOptions: Option[];
  exports: ExportData;
  pagination:Pagination;
}

interface FailedGetAdminTransactionListAction {
  type: typeof adminTransactionConstants.GET_ADMIN_TRANSACTION_LIST_FAILURE;
  allAdminTransactionList: IAdminTransaction[];
  payload: IAdminTransaction;
  uuid: string;
  providerOptions: Option[];
  paymentMethodOptions: Option[];
  exports: ExportData;
  pagination:Pagination;
}

interface LoadGetAdminTransactionByIdAction {
  type: typeof adminTransactionConstants.GET_ADMIN_TRANSACTION_BY_ID_REQUEST;
  allAdminTransactionList: IAdminTransaction[];
  payload: IAdminTransaction;
  uuid: string;
  providerOptions: Option[];
  paymentMethodOptions: Option[];
  exports: ExportData;
  pagination:Pagination;
}

interface LoadedGetAdminTransactionByIdAction {
  type: typeof adminTransactionConstants.GET_ADMIN_TRANSACTION_BY_ID_SUCCESS;
  allAdminTransactionList: IAdminTransaction[];
  payload: IAdminTransaction;
  uuid: string;
  providerOptions: Option[];
  paymentMethodOptions: Option[];
  exports: ExportData;
  pagination:Pagination;
}

interface FailedGetAdminTransactionByIdAction {
  type: typeof adminTransactionConstants.GET_ADMIN_TRANSACTION_BY_ID_FAILURE;
  allAdminTransactionList: IAdminTransaction[];
  payload: IAdminTransaction;
  uuid: string;
  providerOptions: Option[];
  paymentMethodOptions: Option[];
  exports: ExportData;
  pagination:Pagination;
}

interface LoadGetPaymentProviderOptionsAction {
  type: typeof adminTransactionConstants.GET_PAYMENT_PROVIDER_OPTIONS_REQUEST;
  allAdminTransactionList: IAdminTransaction[];
  payload: IAdminTransaction;
  uuid: string;
  providerOptions: Option[];
  paymentMethodOptions: Option[];
  exports: ExportData;
  pagination:Pagination;
}

interface LoadedGetPaymentProviderOptionsAction {
  type: typeof adminTransactionConstants.GET_PAYMENT_PROVIDER_OPTIONS_SUCCESS;
  allAdminTransactionList: IAdminTransaction[];
  payload: IAdminTransaction;
  uuid: string;
  providerOptions: Option[];
  paymentMethodOptions: Option[];
  exports: ExportData;
  pagination:Pagination;
}

interface FailedGetPaymentProviderOptionsAction {
  type: typeof adminTransactionConstants.GET_PAYMENT_PROVIDER_OPTIONS_FAILURE;
  allAdminTransactionList: IAdminTransaction[];
  payload: IAdminTransaction;
  uuid: string;
  providerOptions: Option[];
  paymentMethodOptions: Option[];
  exports: ExportData;
  pagination:Pagination;
}

interface LoadGetPaymentMethodOptionsAction {
  type: typeof adminTransactionConstants.GET_PAYMENT_METHOD_OPTIONS_REQUEST;
  allAdminTransactionList: IAdminTransaction[];
  payload: IAdminTransaction;
  uuid: string;
  providerOptions: Option[];
  paymentMethodOptions: Option[];
  exports: ExportData;
  pagination:Pagination;
}

interface LoadedGetPaymentMethodOptionsAction {
  type: typeof adminTransactionConstants.GET_PAYMENT_METHOD_OPTIONS_SUCCESS;
  allAdminTransactionList: IAdminTransaction[];
  payload: IAdminTransaction;
  uuid: string;
  providerOptions: Option[];
  paymentMethodOptions: Option[];
  exports: ExportData;
  pagination:Pagination;
}

interface FailedGetPaymentMethodOptionsAction {
  type: typeof adminTransactionConstants.GET_PAYMENT_METHOD_OPTIONS_FAILURE;
  allAdminTransactionList: IAdminTransaction[];
  payload: IAdminTransaction;
  uuid: string;
  providerOptions: Option[];
  paymentMethodOptions: Option[];
  exports: ExportData;
  pagination:Pagination;
}

interface LoadGetTransactionsExportAction {
  type: typeof adminTransactionConstants.GET_TRANSACTIONS_EXPORT_REQUEST;
  allAdminTransactionList: IAdminTransaction[];
  payload: IAdminTransaction;
  uuid: string;
  providerOptions: Option[];
  paymentMethodOptions: Option[];
  exports: ExportData;
  pagination:Pagination;
}

interface LoadedGetTransactionsExportAction {
  type: typeof adminTransactionConstants.GET_TRANSACTIONS_EXPORT_SUCCESS;
  allAdminTransactionList: IAdminTransaction[];
  payload: IAdminTransaction;
  uuid: string;
  providerOptions: Option[];
  paymentMethodOptions: Option[];
  exports: ExportData;
  pagination:Pagination;
}

interface FailedTransactionsExportAction {
  type: typeof adminTransactionConstants.GET_TRANSACTIONS_EXPORT_FAILURE;
  allAdminTransactionList: IAdminTransaction[];
  payload: IAdminTransaction;
  uuid: string;
  providerOptions: Option[];
  paymentMethodOptions: Option[];
  exports: ExportData;
  pagination:Pagination;
}

export type AdminTransactionActionTypes
  = LoadGetAdminTransactionListAction
  | LoadedGetAdminTransactionListAction
  | FailedGetAdminTransactionListAction

  | LoadGetAdminTransactionByIdAction
  | LoadedGetAdminTransactionByIdAction
  | FailedGetAdminTransactionByIdAction

  | LoadGetPaymentProviderOptionsAction
  | LoadedGetPaymentProviderOptionsAction
  | FailedGetPaymentProviderOptionsAction

  | LoadGetPaymentMethodOptionsAction
  | LoadedGetPaymentMethodOptionsAction
  | FailedGetPaymentMethodOptionsAction

  | LoadGetTransactionsExportAction
  | LoadedGetTransactionsExportAction
  | FailedTransactionsExportAction;
