import React from 'react';
import intl from 'react-intl-universal';
import {
  Switch,
  Route,
  NavLink,
} from 'react-router-dom';
import Divider from '@material-ui/core/Divider';
import Avatar from '@material-ui/core/Avatar';
import { makeStyles } from '@material-ui/core/styles';
import { Merchant } from '../MerchantPanel/Merchant';
import { BankAccounts } from '../MerchantPanel/BankAccounts/BankAccounts';
import { MerchantTransactions } from '../MerchantPanel/Transactions/Transactions';
import { Billings } from '../MerchantPanel/Billings';
import { BillDetails } from '../AdminPanel/MerchantBillings';
import { Docs } from '../MerchantPanel/Docs/Docs';
import { TwoFAAuthentication } from '../MerchantPanel/Merchant/twoFA';

import { RequesToPayBill } from '../MerchantPanel/Billings/RequestToPay';
import { MerchantDashboard } from '../MerchantPanel/Dashboard/Dashboard';
import { history } from '../../helpers';
import ImagePlaceholder from '../../assets/icons/avatar.jpg';
import HomeIcon from '../../assets/icons/Home.png';
import BankAccountIcon from '../../assets/icons/Bank_Account.svg';
import TransactionIcon from '../../assets/icons/Transaction.png';
import PayoutIcon from '../../assets/icons/Payout.svg';
import PayoutActiveIcon from '../../assets/icons/Payout_1.png';
import DocIcon from '../../assets/icons/Doc.svg';
import SettingIcon from '../../assets/icons/Setting.svg';
import SettlementIcon from '../../assets/icons/settlement.png';
import SettlementAcvtiveIcon from '../../assets/icons/settlement_Active.png';
import { MerchantSettlementReport } from '../MerchantPanel/SettlementReports/SettlementReports';

interface Props {
  path: string;
}

interface MenuProps extends Props {
  name: string;
}

const useStyles = makeStyles(() => ({
  root: {
    marginTop: '30%',
    '@global': {
      '*::-webkit-scrollbar': {
        width: '0',
        background: 'transparent',
      },
    },
  },

  linkStyle: {
    textDecoration: 'none',
    color: '#FFF',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingBottom: '30px',
    marginTop: '10px',
    position: 'relative',
  },
  iconStyle: {
    margin: '8px',
  },
  activeLink: {
    background: 'rgba(84, 97, 114, 0.4)',
    width: '95%',
  },
  lineStyle: {
    border: 'solid 5px #3485f7',
    width: '0',
    position: 'absolute',
    right: '-6px',
    height: '88px',
    marginTop: '30px',
    borderRadius: '5px',
  },
  user: {
    color: '#FFF',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    margin: '5px',
    background: 'rgba(84, 97, 114, 0.4)',
    borderRadius: '5px',
    width: '90%',
    padding: '10px 0',
  },
}));

export const MerchantMenuItems: React.FC<MenuProps> = ({ path, name }: MenuProps) => {
  const classes = useStyles();
  return (
    <>
      <div className={classes.root}>
        <div className="left-menu">
          <NavLink to={path} className={classes.linkStyle} activeClassName={classes.activeLink} exact>
            <img src={HomeIcon} alt="" width="32" className={classes.iconStyle} />
            <span>{intl.get('HOME')}</span>
            {path === history.location.pathname ? <span className={classes.lineStyle} /> : null}
          </NavLink>
          <NavLink to={`${path}/bank-accounts`} className={classes.linkStyle} activeClassName={classes.activeLink} exact>
            <img src={BankAccountIcon} alt="" width="32" className={classes.iconStyle} />
            <span>{intl.get('BANK_ACCOUNTS')}</span>
            {`${path}/bank-accounts` === `${history.location.pathname}` ? <span className={classes.lineStyle} /> : null}
          </NavLink>
          <NavLink to={`${path}/transactions`} className={classes.linkStyle} activeClassName={classes.activeLink} exact>
            <img src={TransactionIcon} alt="" width="32" className={classes.iconStyle} />
            <span>{intl.get('TRANSACTIONS')}</span>
            {`${path}/transactions` === `${history.location.pathname}` ? <span className={classes.lineStyle} /> : null}
          </NavLink>
          <NavLink to={`${path}/payouts`} className={classes.linkStyle} activeClassName={classes.activeLink}>
            {history.location.pathname.includes('payouts')
              ? <img src={PayoutActiveIcon} alt="" width="32" className={classes.iconStyle} />
              : <img src={PayoutIcon} alt="" width="32" className={classes.iconStyle} />}
            <span>{intl.get('PAYOUTS')}</span>
            {history.location.pathname.includes('payouts') ? <span className={classes.lineStyle} /> : null}
          </NavLink>
          <NavLink to={`${path}/settlements`} className={classes.linkStyle} activeClassName={classes.activeLink} exact>
            {`${path}/settlements` === `${history.location.pathname}` ? <img src={SettlementAcvtiveIcon} alt="" width="32" className={classes.iconStyle} />
              : <img src={SettlementIcon} alt="" width="32" className={classes.iconStyle} />}
            <span>{intl.get('SETTLEMENTS')}</span>
            {`${path}/settlements` === `${history.location.pathname}` ? <span className={classes.lineStyle} /> : null}
          </NavLink>
          <NavLink to={`${path}/docs`} className={classes.linkStyle} activeClassName={classes.activeLink} exact>
            <img src={DocIcon} alt="" width="32" className={classes.iconStyle} />
            <span>{intl.get('DOCS')}</span>
            {`${path}/docs` === `${history.location.pathname}` ? <span className={classes.lineStyle} /> : null}
          </NavLink>
          <div>
            <Divider style={{ backgroundColor: '#ffffff', opacity: '0.4', margin: '0 20px 0 8px' }} />
            <NavLink to={`${path}/settings`} className={classes.linkStyle} activeClassName={classes.activeLink} exact style={{ marginTop: '50px' }}>
              <img src={SettingIcon} alt="" width="32" className={classes.iconStyle} />
              <span>{intl.get('SETTINGS')}</span>
              {`${path}/settings` === `${history.location.pathname}` ? <span className={classes.lineStyle} /> : null}
            </NavLink>
          </div>
          <div className={classes.user}>
            <NavLink to={`${path}/twoFA`} className={classes.linkStyle} activeClassName={classes.activeLink} exact>
              <Avatar alt="Merchant image" src={ImagePlaceholder} style={{ margin: '5px', width: '32px', height: '32px' }} />
              <span>{name}</span>
            </NavLink>
          </div>
        </div>
      </div>
    </>
  );
};

export const MerchantRoutes: React.FC<Props> = ({ path }: Props) => (
  <>
    <Switch>
      <Route exact path={path}>
        <MerchantDashboard />
      </Route>
      <Route exact path={`${path}/bank-accounts`}>
        <BankAccounts />
      </Route>
      <Route exact path={`${path}/transactions`}>
        <MerchantTransactions />
      </Route>
      <Route exact path={`${path}/settlements`}>
        <MerchantSettlementReport />
      </Route>
      <Route exact path={`${path}/payouts`}>
        <Billings />
      </Route>
      <Route exact path={`${path}/payouts/:billingId`}>
        <BillDetails />
      </Route>
      <Route exact path={`${path}/payouts/request-payment/:billingId`}>
        <RequesToPayBill />
      </Route>
      <Route exact path={`${path}/docs`}>
        <Docs />
      </Route>
      <Route exact path={`${path}/twoFA`}>
        <TwoFAAuthentication />
      </Route>
      <Route exact path={`${path}/settings`}>
        <Merchant />
      </Route>
    </Switch>
  </>
);
