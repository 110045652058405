import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/reducers';
import Toolbar from './Toolbar';
import MerchantList from './MerchantList';
import { IMerchant } from '../../../types/merchant.types';
import PageHeader from '../../../components/StyledComponents/PageHeader';

export const Merchants = () => {
  const merchantReducer = useSelector((state: RootState) => state.merchantReducer);
  const merchantList: IMerchant[] = merchantReducer.allMerchantList || [];
  const [paginationPar, setPaginationPar] = React.useState({ limit: 10, current_page: 1 });
  return (
    <>
      <PageHeader
        label="MERCHANTS"
        componentToPassDown={<Toolbar pagination={paginationPar} />}
      />
      <MerchantList merchants={merchantList} changePaginationOptions={(e) => { setPaginationPar(e); }} />
    </>
  );
};
