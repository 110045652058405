import React from 'react';
import intl from 'react-intl-universal';
import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  Paper,
  IconButton,
  Collapse,
  TablePagination,
  ThemeProvider,
  Box,
  Divider,
  Grid,
} from '@material-ui/core';
import {
  makeStyles, Theme, createStyles,
} from '@material-ui/core/styles';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import LinearProgress from '@material-ui/core/LinearProgress';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/reducers';
import { IAdminTransaction } from '../../../types/admin-transaction.types';
import { tableTheme } from '../../../assets/styles/table-style';
import { StyledTableHeadCell } from '../../../components/StyledComponents';
import { BooleanLabel } from '../../../components';
import { paginationTheme } from '../../../components/StyledComponents/paginateTheme';

interface IAdminTransactionTypes {
  adminTransactions: IAdminTransaction[],
  merchantPanel: boolean;
  changePaginationOptions: (e: any) => void;
}

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    marginTop: '10px',
  },
  table: {
    minWidth: 650,
  },
  expandIcon: {
    backgroundColor: '#ffffff',
    borderRadius: '5px',
  },
  tableBodyCell: {
    fontSize: '12px',
  },
  expandedPanel: {
    [theme.breakpoints.up('sm')]: {
      width: '850px',
      right: '23px',
      padding: '40px',
    },
    right: 0,
    padding: '20px',
    backgroundColor: '#2c333d',
    position: 'absolute',
    zIndex: 9999,
    borderRadius: '8px',
    fontSize: '12px',
  },
  divider: {
    background: '#fff',
    margin: '10px auto',
  },
  detailPanelSection: {
    padding: theme.spacing(2),
  },
  detailPanelInput: {
    padding: '5px',
    backgroundColor: '#3d4651',
    minWidth: '80px',
    textAlign: 'center',
  },
  detailPanelInputError: {
    padding: '5px',
    backgroundColor: 'red',
    minWidth: '80px',
    textAlign: 'center',
  },
  detailPanelCell: {
    marginBottom: '20px',
  },
  colorPrimary: {
    backgroundColor: '#3485f7',
  },
  barColorPrimary: {
    backgroundColor: '#3485f7',
  },
}));

export default ({ adminTransactions, merchantPanel, changePaginationOptions }: IAdminTransactionTypes) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [expandedTransactionId, setExpandedTransactionId] = React.useState<string | undefined>('');
  const transactionReducer = useSelector((state: RootState) => state.adminTransactionReducer);
  const { total } = transactionReducer.pagination;
  const [pagination, setPagination] = React.useState({
    limit: 10,
    current_page: 1,
  });
  const handleChangePage = (event: unknown, newPage: number) => {
    setPagination({
      ...pagination,
      current_page: ++newPage,
    });
    changePaginationOptions({ ...pagination, current_page: newPage });
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPagination({
      ...pagination,
      limit: +event.target.value,
      current_page: 1,
    });
    changePaginationOptions({ ...pagination, limit: +event.target.value });
  };
  const { loading } = transactionReducer;
  return (
    <Card className={classes.root}>
      <TableContainer component={Paper}>
        <ThemeProvider theme={tableTheme}>
          {loading ? <LinearProgress classes={{ colorPrimary: classes.colorPrimary, barColorPrimary: classes.barColorPrimary }} /> : null}
          <Table size="small" className={classes.table} aria-label="Admin transaction list">
            <TableHead>
              <TableRow>
                {!merchantPanel
                  ? (
                    <>
                      <TableCell><StyledTableHeadCell>{intl.get('TRANSACTION_NUMBER')}</StyledTableHeadCell></TableCell>
                      <TableCell><StyledTableHeadCell>{intl.get('MERCHANT')}</StyledTableHeadCell></TableCell>
                      <TableCell><StyledTableHeadCell>{intl.get('PROVIDER')}</StyledTableHeadCell></TableCell>
                      <TableCell><StyledTableHeadCell>{intl.get('PAYMENT_METHOD')}</StyledTableHeadCell></TableCell>
                    </>
                  ) : <TableCell><StyledTableHeadCell>{intl.get('ORDER_NUMBER')}</StyledTableHeadCell></TableCell>}
                <TableCell><StyledTableHeadCell>{intl.get('AMOUNT')}</StyledTableHeadCell></TableCell>
                <TableCell><StyledTableHeadCell>{intl.get('CURRENCY')}</StyledTableHeadCell></TableCell>
                <TableCell><StyledTableHeadCell>{intl.get('SUCCESS')}</StyledTableHeadCell></TableCell>
                <TableCell><StyledTableHeadCell>{intl.get('CREATED_AT')}</StyledTableHeadCell></TableCell>
                <TableCell><StyledTableHeadCell>{intl.get('ACTIONS')}</StyledTableHeadCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {adminTransactions
                && adminTransactions.map((row: IAdminTransaction) => (
                  <>
                    <TableRow key={row.uuid} style={{ position: 'relative' }}>
                      {!merchantPanel
                        ? (
                          <>
                            <TableCell className={classes.tableBodyCell}>{row.transaction_no}</TableCell>
                            <TableCell className={classes.tableBodyCell}>{row?.merchant?.company_name}</TableCell>
                            <TableCell className={classes.tableBodyCell}>{row.psp_code}</TableCell>
                            <TableCell className={classes.tableBodyCell}>{row.payment_method || 'N/A'}</TableCell>
                          </>
                        ) : <TableCell className={classes.tableBodyCell}>{row.order_no}</TableCell>}
                      <TableCell className={classes.tableBodyCell}>{Number(row?.amount)?.toFixed(4)}</TableCell>
                      <TableCell className={classes.tableBodyCell}>{row.currency}</TableCell>
                      <TableCell className={classes.tableBodyCell}><BooleanLabel label={row?.success} /></TableCell>
                      <TableCell className={classes.tableBodyCell}>
                        {row.created_at && `${new Date(row.created_at).toLocaleDateString()} ${new Date(row.created_at).toLocaleTimeString()}`}
                      </TableCell>
                      <TableCell align="center">
                        <IconButton
                          className={classes.expandIcon}
                          aria-label="expand row"
                          size="small"
                          onClick={() => { setOpen(!open); setExpandedTransactionId(row.uuid); }}
                        >
                          {open && expandedTransactionId === row.uuid ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>
                      </TableCell>
                    </TableRow>
                    {open && expandedTransactionId === row.uuid
                      ? (
                        <TableRow>
                          <TableCell
                            style={{
                              paddingBottom: 0, paddingTop: 0, backgroundColor: '#2c333d', color: '#fff',
                            }}
                            colSpan={12}
                          >
                            <Collapse in={open && expandedTransactionId === row.uuid} timeout="auto" unmountOnExit>
                              <div className={classes.expandedPanel}>
                                <Box
                                  display="flex"
                                  justifyContent="space-around"
                                  flexDirection="row"
                                  flexWrap="wrap"
                                >
                                  <Grid
                                    item
                                    md={5}
                                  >
                                    <div className={classes.detailPanelSection}>
                                      <div className={classes.detailPanelCell}>
                                        <Box
                                          display="flex"
                                          flexDirection="row"
                                          justifyContent="space-between"
                                          alignItems="center"
                                        >
                                          <span>{intl.get('ORDER_NUMBER')}</span>
                                          <div className={classes.detailPanelInput}>{row.order_no}</div>
                                        </Box>
                                        <Divider classes={{ root: classes.divider }} />
                                      </div>
                                    </div>
                                    <div className={classes.detailPanelSection}>
                                      <div className={classes.detailPanelCell}>
                                        <Box
                                          display="flex"
                                          flexDirection="row"
                                          justifyContent="space-between"
                                          alignItems="center"
                                        >
                                          <span>{intl.get('BASE_CURRENCY')}</span>
                                          <div className={classes.detailPanelInput}>{row.base_currency}</div>
                                        </Box>
                                        <Divider classes={{ root: classes.divider }} />
                                      </div>
                                    </div>
                                    <div className={classes.detailPanelSection}>
                                      <div className={classes.detailPanelCell}>
                                        <Box
                                          display="flex"
                                          flexDirection="row"
                                          justifyContent="space-between"
                                          alignItems="center"
                                        >
                                          <span>{intl.get('BASE_AMOUNT')}</span>
                                          <div className={classes.detailPanelInput}>{Number(row?.amount)?.toFixed(4)}</div>
                                        </Box>
                                        <Divider classes={{ root: classes.divider }} />
                                      </div>
                                    </div>
                                  </Grid>
                                  <Grid
                                    item
                                    md={5}
                                  >
                                    <div className={classes.detailPanelSection}>
                                      <div className={classes.detailPanelCell}>
                                        <Box
                                          display="flex"
                                          flexDirection="row"
                                          justifyContent="space-between"
                                          alignItems="center"
                                        >
                                          <span>{intl.get('EXCHANGE_RATE')}</span>
                                          <div className={classes.detailPanelInput}>{row?.exchange_rate}</div>
                                        </Box>
                                        <Divider classes={{ root: classes.divider }} />
                                      </div>
                                    </div>
                                    <div className={classes.detailPanelSection}>
                                      <div className={classes.detailPanelCell}>
                                        <Box
                                          display="flex"
                                          flexDirection="row"
                                          justifyContent="space-between"
                                          alignItems="center"
                                        >
                                          <span>{intl.get('MARGIN_RATE')}</span>
                                          <div className={classes.detailPanelInput}>{row?.merchant?.margin_rate || 'N/A'}</div>
                                        </Box>
                                        <Divider classes={{ root: classes.divider }} />
                                      </div>
                                    </div>
                                    <div className={classes.detailPanelSection}>
                                      <div className={classes.detailPanelCell}>
                                        <Box
                                          display="flex"
                                          flexDirection="row"
                                          justifyContent="space-between"
                                          alignItems="center"
                                        >
                                          <span>{intl.get('MARGIN_TYPE')}</span>
                                          <div className={classes.detailPanelInput}>{row?.merchant?.margin_type || 'N/A'}</div>
                                        </Box>
                                        <Divider classes={{ root: classes.divider }} />
                                      </div>
                                    </div>
                                  </Grid>
                                  {
                                    row && row.error ? (
                                      <Grid
                                        item
                                        md={11}
                                      >
                                        <div className={classes.detailPanelSection}>
                                          <div className={classes.detailPanelCell}>
                                            <Box
                                              display="flex"
                                              flexDirection="row"
                                              justifyContent="space-between"
                                              alignItems="center"
                                            >
                                              <span>Error</span>
                                              <div className={classes.detailPanelInputError}>{row.error}</div>
                                            </Box>
                                            <Divider classes={{ root: classes.divider }} />
                                          </div>
                                        </div>
                                      </Grid>
                                    ) : null
                                  }
                                  <Grid
                                    item
                                    md={11}
                                  >
                                    <div style={{ paddingRight: '20px' }}>
                                      <Box
                                        display="flex"
                                        flexDirection="row"
                                        justifyContent="flex-end"
                                      >
                                        <span>{`${intl.get('UPDATED_AT')} ${new Date(row.created_at).toLocaleDateString()}`}</span>
                                      </Box>
                                    </div>

                                  </Grid>
                                </Box>
                              </div>
                            </Collapse>
                          </TableCell>
                        </TableRow>
                      ) : null}
                  </>
                ))}
            </TableBody>
          </Table>
        </ThemeProvider>
      </TableContainer>
      {
        total && adminTransactions && adminTransactions.length
          ? (
            <ThemeProvider theme={paginationTheme}>
              <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={total}
                rowsPerPage={pagination.limit}
                page={pagination.current_page - 1}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </ThemeProvider>
          )
          : null
      }
    </Card>
  );
};
