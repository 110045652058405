import React, { useState } from 'react';
import intl from 'react-intl-universal';
import {
  TextField,
  InputAdornment,
  IconButton,
  Box,
} from '@material-ui/core';
import PasswordStrengthBar from 'react-password-strength-bar';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Visibility from '@material-ui/icons/Visibility';
import crossIcon from '../assets/icons/cross.jpg';
import yesIcon from '../assets/icons/yes.png';

export const NewPasswordInput = ({
  width, onChange, value, isLowerCharacter, isUperCharacter, isNumber, isSpecialCharacter, length,
}: any) => {
  const [showPassword, setShowPassword] = useState(false);
  const handleClick = () => {
    setShowPassword((prev) => !prev);
  };
  return (
    <div>
      <div style={{ marginBottom: '30px' }}>
        <h4>Your password needs to be:</h4>
        <Box style={{ display: 'flex', flexDirection: 'row', margin: '5px' }}>
          {' '}
          <img alt="password" style={{ height: '20px', width: '20px', marginRight: '10px' }} src={length ? yesIcon : crossIcon} />
          {' '}
          <span>{intl.get('PASSWORD_LENGTH_MESSAGE')}</span>
        </Box>
        <Box style={{ display: 'flex', flexDirection: 'row', margin: '5px' }}>
          {' '}
          <img
            alt="password"
            style={{ height: '20px', width: '20px', marginRight: '10px' }}
            src={isLowerCharacter && isUperCharacter ? yesIcon : crossIcon}
          />
          <span>{intl.get('MIXTURE_OF_UPPER_AND_LOWER')}</span>
        </Box>
        {/* <Box style={{ display: 'flex', flexDirection: 'row', margin: '5px' }}>
          {' '}
          <img alt="password" style={{ height: '20px', width: '20px', marginRight: '10px' }} src={isUperCharacter ? yesIcon : crossIcon} />
          <span>{intl.get('UPER_CHARACTER_MESSAGE')}</span>
        </Box> */}
        <Box style={{ display: 'flex', flexDirection: 'row', margin: '5px' }}>
          {' '}
          <img alt="password" style={{ height: '20px', width: '20px', marginRight: '10px' }} src={isNumber ? yesIcon : crossIcon} />
          {' '}
          <span>{intl.get('ONE_NUMBER')}</span>
        </Box>
        <Box style={{ display: 'flex', flexDirection: 'row', margin: '5px' }}>
          {' '}
          <img alt="password" style={{ height: '20px', width: '20px', marginRight: '10px' }} src={isSpecialCharacter ? yesIcon : crossIcon} />
          {' '}
          <span>{intl.get('SPECIAL_CHARACTER_MESSAGE')}</span>
        </Box>
      </div>
      <TextField
        InputProps={{
          endAdornment:
            <InputAdornment position="end">
              <IconButton
                edge="end"
                onClick={handleClick}
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>,

        }}
        onChange={(e) => onChange(e.target.value)}
        value={value}
        placeholder={intl.get('NEW_PASSWORD')}
        variant="outlined"
        size="small"
        style={{ backgroundColor: '#f4f4f4', marginBottom: '10px', marginTop: '10px', width }}
        type={showPassword ? 'text' : 'password'}
      />
      <div>
        <div style={{ fontSize: '15px', fontWeight: 'bold', marginBottom: '10px', marginTop: '10px' }}>Password Strength</div>
        <PasswordStrengthBar
          style={{ width }}
          shortScoreWord="Weak"
          scoreWords={['Weak', 'Meduim', 'Strong', 'Very Strong']}
          password={value}
          minLength={12}
        />
      </div>

    </div>
  );
};
