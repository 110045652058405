import { Dispatch } from 'redux';
import { AxiosError } from 'axios';
import { adminTransactionConstants } from '../constants';
import { adminTransactionService } from '../../services';
import { transactionService } from '../../services/Merchant/transaction.service';
import { IAdminTransaction, TransactionFilter } from '../../types/admin-transaction.types';
import { Option, Pagination } from '../../types/common.types';
import { ExportData } from '../../types/merchant-billing.types';

const getAdminTransactionList = (merchantPanel: boolean, paginationData: Pagination, filters?: TransactionFilter) => {
  const request = () => ({ type: adminTransactionConstants.GET_ADMIN_TRANSACTION_LIST_REQUEST });
  const success = (allAdminTransactionList: IAdminTransaction[], pagination: Pagination) => (
    { type: adminTransactionConstants.GET_ADMIN_TRANSACTION_LIST_SUCCESS, allAdminTransactionList, pagination }
  );
  const failure = (error: any) => ({ type: adminTransactionConstants.GET_ADMIN_TRANSACTION_LIST_FAILURE, error });
  return (dispatch: Dispatch) => {
    dispatch(request());
    if (!merchantPanel) {
      adminTransactionService.getAdminTransactionList(paginationData, filters)
        .then(
          (res) => {
            dispatch(success(res.data.data, res.data));
          },
        ).catch((error: AxiosError) => dispatch(failure(error.response?.data?.errors || [])));
    } else {
      transactionService.getTransactionList(paginationData, filters)
        .then(
          (res) => {
            dispatch(success(res.data.data, res.data));
          },
        ).catch((error: AxiosError) => dispatch(failure(error.response?.data?.errors || [])));
    }
  };
};

const getProviderOptions = (merchantPanel: boolean) => {
  const request = () => ({ type: adminTransactionConstants.GET_PAYMENT_PROVIDER_OPTIONS_REQUEST });
  const success = (providerOptions: Option[]) => (
    { type: adminTransactionConstants.GET_PAYMENT_PROVIDER_OPTIONS_SUCCESS, providerOptions }
  );
  const failure = (error: any) => ({ type: adminTransactionConstants.GET_PAYMENT_PROVIDER_OPTIONS_FAILURE, error });
  return (dispatch: Dispatch) => {
    dispatch(request());
    if (!merchantPanel) {
      adminTransactionService.getPaymentProviderOptions()
        .then(
          (res) => {
            dispatch(success(res.data));
          },
        ).catch((error: AxiosError) => dispatch(failure(error.response?.data?.errors || [])));
    }
  };
};

const getPaymentMethodOptions = (merchantPanel: boolean) => {
  const request = () => ({ type: adminTransactionConstants.GET_PAYMENT_METHOD_OPTIONS_REQUEST });
  const success = (paymentMethodOptions: Option[]) => (
    { type: adminTransactionConstants.GET_PAYMENT_METHOD_OPTIONS_SUCCESS, paymentMethodOptions }
  );
  const failure = (error: any) => ({ type: adminTransactionConstants.GET_PAYMENT_METHOD_OPTIONS_FAILURE, error });
  return (dispatch: Dispatch) => {
    dispatch(request());
    if (!merchantPanel) {
      adminTransactionService.getPaymentMethodOptions()
        .then(
          (res) => {
            dispatch(success(res.data));
          },
        ).catch((error: AxiosError) => dispatch(failure(error.response?.data?.errors || [])));
    }
  };
};

const getAdminTransactionById = (uuid: string, merchantPanel: boolean) => {
  const request = () => ({ type: adminTransactionConstants.GET_ADMIN_TRANSACTION_BY_ID_REQUEST });
  const success = (payload: IAdminTransaction) => (
    { type: adminTransactionConstants.GET_ADMIN_TRANSACTION_BY_ID_SUCCESS, payload }
  );
  const failure = (error: any) => ({ type: adminTransactionConstants.GET_ADMIN_TRANSACTION_BY_ID_FAILURE, error });
  return (dispatch: Dispatch) => {
    dispatch(request());
    if (!merchantPanel) {
      adminTransactionService.getAdminTransactionById(uuid)
        .then(
          (res) => {
            dispatch(success(res.data));
          },
        ).catch((error: AxiosError) => dispatch(failure(error.response?.data?.errors || [])));
    } else {
      transactionService.getTransactionById(uuid)
        .then(
          (res) => {
            dispatch(success(res.data));
          },
        ).catch((error: AxiosError) => dispatch(failure(error.response?.data?.errors || [])));
    }
  };
};

const getTransactionExport = (merchantPanel: boolean, filters?: TransactionFilter) => {
  const request = () => ({ type: adminTransactionConstants.GET_TRANSACTIONS_EXPORT_REQUEST });
  const success = (exports: ExportData) => (
    { type: adminTransactionConstants.GET_TRANSACTIONS_EXPORT_SUCCESS, exports }
  );
  const failure = (error: any) => ({ type: adminTransactionConstants.GET_TRANSACTIONS_EXPORT_FAILURE, error });
  return (dispatch: Dispatch) => {
    dispatch(request());

    if (!merchantPanel) {
      adminTransactionService.getTransactionExport(filters)
        .then(
          (res) => {
            dispatch(success({ filename: res.data.filename }));
          },
        ).catch((error: AxiosError) => dispatch(failure(error.response?.data?.errors || [])));
    } else {
      transactionService.getTransactionExport(filters)
        .then(
          (res) => {
            dispatch(success({ filename: res.data.filename }));
          },
        ).catch((error: AxiosError) => dispatch(failure(error.response?.data?.errors || [])));
    }
  };
};

const resetTransactionsExportState = () => {
  const success = () => (
    { type: adminTransactionConstants.RESET_EXPORT_DATA_STATE }
  );
  return (dispatch: Dispatch) => {
    dispatch(success());
  };
};

export const adminTransactionActions = {
  getAdminTransactionList,
  getAdminTransactionById,
  getProviderOptions,
  getPaymentMethodOptions,
  getTransactionExport,
  resetTransactionsExportState,
};
