import React, { useEffect } from 'react';
import intl from 'react-intl-universal';
import {
  Box,
} from '@material-ui/core';
import {
  makeStyles, Theme,
} from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { WidgetError } from '../types/dashboard.types';
import { RootState } from '../store/reducers';
import { dashboardActions } from '../store/actions';
import { utils } from '../helpers/utils';

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    color: 'indianred',
    fontSize: '14px',
    margin: 0,
  },
  sectionRow: {
    padding: '10px 20px 70px 20px',
    borderRadius: '10px',
    boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
    backgroundColor: '#040532',
  },
  sectionRowLight: {
    margin: '12px 10px',
    position: 'relative',
    backgroundColor: 'rgba(0, 0, 0, 0.05)',
  },
  iconStyle: {
    color: '#880a15',
  },
  redColor: {
    color: '#880a15',
  },
  rowStyle: {
    marginTop: theme.spacing(1),
  },
  progressContainer: {
    padding: '10px 20px',
    borderRadius: '16px',
    boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
    backgroundColor: '#ffffff',
  },
}));

export const ErrorSummary = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const dashboardReducer = useSelector((state: RootState) => state.dashboardReducer);
  const ErrorWidgetsData: WidgetError[] = dashboardReducer.WidgetsError || [];
  const merchantPanel = utils.userType() === 'merchant';
  useEffect(() => {
    dispatch(dashboardActions.getWidgetErrors(merchantPanel));
  }, [dispatch, merchantPanel]);

  return (
    <>
      <div>
        <div className={[classes.sectionRow, classes.sectionRowLight].join(' ')}>
          {ErrorWidgetsData.length ? ErrorWidgetsData?.map((item: WidgetError) => (
            <div>
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
                className={classes.rowStyle}
              >
                <h3 className={classes.title}>{item.error}</h3>
                <h4 className={classes.redColor} style={{ margin: '8px' }}>
                  <span>{item.total}</span>
                  <span style={{ marginLeft: '15px' }} />
                </h4>
              </Box>
            </div>
          ))
            : (
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <h3 className={classes.title}>{intl.get('NO_ERROR_FOUND')}</h3>
              </Box>
            )}
        </div>
      </div>
    </>
  );
};
