import React from 'react';
import intl from 'react-intl-universal';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import {
  Box,
  Card,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  TableContainer,
  Paper,
  TablePagination,
  IconButton,
} from '@material-ui/core';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { IMerchantAccount } from '../../../types/merchant-account.types';
import { AccountOptions } from '../../../types/merchant-billing.types';
import { ConfirmationBox } from '../../../components/ConfirmationBox';

interface IMerchantAccountTypes {
  accounts: IMerchantAccount[] | undefined;
  accountOptions: AccountOptions[];
  addPayoutAccount: (uuid: string) => void;
  deletePayoutAccount: (uuid: string | undefined) => void;
  merchantPanel: boolean;
  billStatus: string | undefined;
}

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    marginTop: theme.spacing(2),
    borderRadius: '15px',
  },
  tableHeadCell: {
    fontSize: '12px',
    fontWeight: 700,
  },
  tableBodyCell: {
    fontSize: '12px',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

export const BankAccount = ({
  accounts, accountOptions, addPayoutAccount, deletePayoutAccount, merchantPanel, billStatus,
}: IMerchantAccountTypes) => {
  const classes = useStyles();
  const [payoutOptions, setPayoutOptions] = React.useState('');
  const [confirmation, setConfirmation] = React.useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const uuid = event.target.value as string;
    setPayoutOptions(uuid);
    addPayoutAccount(uuid);
  };

  const openConfirmationBox = () => {
    setConfirmation(true);
  };

  const closeConfirmationBox = () => {
    setConfirmation(false);
  };
  return (
    <Card className={classes.root}>
      <TableContainer component={Paper}>
        <Box m={2} display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
          <h3 style={{ textAlign: 'left', margin: '20px' }}>{intl.get('BANK_ACCOUNT')}</h3>
          <FormControl className={classes.formControl}>
            <InputLabel id="demo-simple-select-helper-label">{intl.get('ADD_BANK_ACCOUNT')}</InputLabel>
            <Select
              labelId="demo-simple-select-helper-label"
              id="demo-simple-select-helper"
              value={payoutOptions}
              onChange={handleChange}
            >
              {accountOptions?.map((option: AccountOptions) => (
                <MenuItem value={option.value} key={option.value} disabled={!merchantPanel ? (!!((billStatus !== 'pending' && billStatus !== 'requested'))) : (billStatus !== 'pending')}>{option.label}</MenuItem>
              ))}
            </Select>
            <FormHelperText>{intl.get('SELECT_BANK')}</FormHelperText>
          </FormControl>
        </Box>
        <Table aria-label="account list">
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableHeadCell}>{intl.get('DISPLAY_NAME')}</TableCell>
              <TableCell className={classes.tableHeadCell}>{intl.get('BANK_NAME')}</TableCell>
              <TableCell className={classes.tableHeadCell}>{intl.get('ACCOUNT_NUMBER')}</TableCell>
              <TableCell className={classes.tableHeadCell}>{intl.get('NAME_ON_ACCOUNT')}</TableCell>
              <TableCell className={classes.tableHeadCell}>{intl.get('CURRENCY')}</TableCell>
              <TableCell className={classes.tableHeadCell}>{intl.get('CRYPTO_ADDRESS')}</TableCell>
              <TableCell className={classes.tableHeadCell}>{intl.get('ACTIONS')}</TableCell>
              {/* <TableCell className={classes.tableHeadCell}>{intl.get('COUNTRY')}</TableCell> */}
              {/* <TableCell className={classes.tableHeadCell} />
              <TableCell className={classes.tableHeadCell} />
              <TableCell className={classes.tableHeadCell} />
              <TableCell className={classes.tableHeadCell} /> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {accounts && accounts.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row: IMerchantAccount) => (
              <TableRow key={row.uuid}>
                <TableCell className={classes.tableBodyCell}>{row?.bank_name}</TableCell>
                <TableCell className={classes.tableBodyCell}>{row?.bank_name}</TableCell>
                <TableCell className={classes.tableBodyCell}>{row?.account_number}</TableCell>
                <TableCell className={classes.tableBodyCell}>{row?.name_on_account}</TableCell>
                <TableCell className={classes.tableBodyCell}>{row?.currency}</TableCell>
                <TableCell className={classes.tableBodyCell}>{row?.deposit_address}</TableCell>
                {/* <TableCell className={classes.tableBodyCell}>{row?.bank_country_code}</TableCell> */}
                <TableCell className={classes.tableHeadCell}>
                  <IconButton disabled={!merchantPanel ? (!!((billStatus !== 'pending' && billStatus !== 'requested'))) : (billStatus !== 'pending')}>
                    <DeleteOutlineIcon style={{ cursor: 'pointer', fill: !merchantPanel ? ((billStatus !== 'pending' && billStatus !== 'requested') ? '' : '#3485f7') : (billStatus !== 'pending' ? '' : '#3485f7') }} onClick={() => openConfirmationBox()} />
                  </IconButton>
                </TableCell>
                <ConfirmationBox openDialog={confirmation} closeConfirmationBox={closeConfirmationBox} submitConfirmationBox={() => deletePayoutAccount(row.uuid)} />
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {
        accounts && accounts.length
          ? (
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, 50]}
              component="div"
              count={accounts.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          )
          : null
      }
    </Card>
  );
};
