import React from 'react';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';

interface Props {
  label: boolean | string | number | undefined;
}

export const BooleanLabel = ({ label }: Props) => (
  <div>
    {label === 'yes' || label === true
      ? <CheckIcon style={{ color: 'green' }} />
      : <CloseIcon />}
  </div>
);
