import { combineReducers } from 'redux';
/**
 * all reducers
 */
import { authentication } from './auth.reducer';
import { userReducer } from './user.reducer';
import { merchantReducer } from './merchant.reducer';
import { paymentProviderReducer } from './payment-provider.reducer';
import { merchantAccountReducer } from './merchant-account.reducer';
import { adminTransactionReducer } from './admin-transaction.reducer';
import { merchantBillingReducer } from './merchant-billing.reducer';

import { merchantPanelReducer } from './Merchant/merchant.reducer';
import { KYCReducer } from './Merchant/file.reducer';

import { dashboardReducer } from './dashboard.reducer';
import { adminSettlementReducer } from './admin-settlement.reducer';

export const rootReducer = combineReducers({
  authentication,
  userReducer,
  merchantReducer,
  paymentProviderReducer,
  merchantAccountReducer,
  adminTransactionReducer,
  merchantBillingReducer,
  merchantPanelReducer,
  KYCReducer,
  dashboardReducer,
  adminSettlementReducer,
});

export type RootState = ReturnType<typeof rootReducer>
