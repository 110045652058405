import React, { useState, useEffect } from 'react';
import intl from 'react-intl-universal';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
} from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import ControlPointIcon from '@material-ui/icons/ControlPoint';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { paymentProviderActions, adminTransactionActions } from '../../../store/actions';
import { RootState } from '../../../store/reducers';
import { DisplaySnackbar, Dropdown, DropdownProviderOptions } from '../../index';
import { IPaymentProvider, PaymentProviderCode, PaymentProviderInitialForm } from '../../../types/payment-provider.types';

export const PaymentProviderForm:
  React.FC<{ openDialog: boolean, closePaymentProviderForm: () => void, selectedPaymentProvider: IPaymentProvider | null }> = (
    { openDialog, closePaymentProviderForm, selectedPaymentProvider },
  ) => {
    const dispatch = useDispatch();
    const [values, setValues] = useState({ ...PaymentProviderInitialForm, margin_type: 'percent' });
    const [open, setOpen] = React.useState(openDialog);
    const [properties, setProperties] = React.useState<any>({ key: '', value: '' });

    const paymentProviderState = useSelector((state: RootState) => state.paymentProviderReducer);
    const { pspConfigOptions, pspCodeOptions } = paymentProviderState;
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setValues({
        ...values,
        [(event.target as HTMLInputElement).name]: (event.target as HTMLInputElement).value,
      });
    };

    const handleProperties = (event: React.ChangeEvent<HTMLInputElement>) => {
      setProperties({
        ...properties,
        [(event.target as HTMLInputElement).name]: (event.target as HTMLInputElement).value,
      });
    };
    const handleUpdateParamsValue = (event: React.ChangeEvent<HTMLInputElement>, key: string) => {
      setValues({ ...values, parameters: { ...values.parameters, [key]: (event.target as HTMLInputElement).value } });
    };

    const handleCodeOption = (event: React.ChangeEvent<{ value: unknown }>) => {
      const code = event.target.value as string;
      const parameters = pspConfigOptions[code];
      const updatedParams: any = {};
      if (parameters?.length) {
        parameters.forEach((param: string) => {
          updatedParams[param] = '';
        });
        setValues({ ...values, parameters: updatedParams, code });
      } else {
        setValues({ ...values, code });
      }
    };

    const onSubmit = () => {
      if (selectedPaymentProvider) {
        const forUpdate = {
          name: values.name,
          code: values.code,
          margin_rate: values.margin_rate,
          margin_type: values.margin_type,
          parameters: values.parameters,
          descriptor: values.descriptor,
          day_limit: values.day_limit,
          backup_psp_id: values.backup_psp_id,
          card_type: values.card_type,
        };
        dispatch(paymentProviderActions.updatePaymentProvider({ form: forUpdate, id: values.id }));
      } else {
        dispatch(paymentProviderActions.addPaymentProvider({ ...values, parameters: values.parameters }));
      }
    };

    /**
     * This will run when paymentProvider reducer updates
     */
    useEffect(() => {
      if (paymentProviderState.isUpdated) {
        /**
         * Reset form only if a new paymentProvider added
         */
        if (!selectedPaymentProvider) {
          setValues({ ...PaymentProviderInitialForm, margin_type: 'percent' });
        }
      }
    }, [paymentProviderState, selectedPaymentProvider]);

    /**
     * This will run when we open/close paymentProvider form dialog
     */
    useEffect(() => {
      setOpen(openDialog);
      dispatch(paymentProviderActions.getPaymentProviderCode());
      dispatch(paymentProviderActions.getPspOptions());
      dispatch(adminTransactionActions.getProviderOptions(false));
    }, [openDialog, dispatch]);

    /**
     * This will check if paymentProvider is being adding or editing
     */
    useEffect(() => {
      if (selectedPaymentProvider) {
        setValues(selectedPaymentProvider);
      }
    }, [selectedPaymentProvider]);

    const addOptions = (key: string, value: string) => {
      const obj = { [key]: value };
      setValues({ ...values, parameters: { ...obj, ...values.parameters } });
      setProperties({ key: '', value: '' });
    };

    const remove = (keyName: string) => {
      delete values.parameters[keyName];
      setValues({ ...values });
    };

    return (
      <div>
        {paymentProviderState && paymentProviderState.updateError
          ? paymentProviderState.errorMessages && Object.values(paymentProviderState.errorMessages).length
          && Object.values(paymentProviderState.errorMessages).map(
            (error: any) => error?.length && error.map(
              (msg: string) => <DisplaySnackbar type="error" message={msg} />,
            ),
          ) : null}
        {paymentProviderState && paymentProviderState.isUpdated
          ? <DisplaySnackbar type="success" message={intl.get('UPDATED_SUCCESSFULLY') || 'Updated successfully'} /> : null}
        <div>
          <Dialog fullWidth maxWidth="md" open={open} onClose={closePaymentProviderForm} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">{`${selectedPaymentProvider?.updated_at ? intl.get('EDIT') || 'Edit' : intl.get('ADD') || 'Add'} ${intl.get('PAYMENT') || 'Payment'} ${intl.get('PROVIDER') || 'Provider'} `}</DialogTitle>
            <DialogContent>
              <form
                autoComplete="off"
                noValidate
              >
                <Card>
                  <CardContent>
                    <Grid
                      container
                      spacing={3}
                    >
                      <Grid
                        item
                        md={6}
                        xs={12}
                      >
                        <TextField
                          fullWidth
                          label={intl.get('NAME')}
                          name="name"
                          onChange={handleChange}
                          required
                          value={values.name}
                          variant="outlined"
                          style={{ paddingTop: '10px' }}
                        />
                      </Grid>
                      <Grid
                        item
                        md={6}
                        xs={12}
                      >
                        <TextField
                          style={{ width: '100%', paddingTop: '10px' }}
                          id="outlined-select-currency"
                          select
                          label={intl.get('CODE')}
                          value={values.code}
                          onChange={handleCodeOption}
                          variant="outlined"
                        >
                          {pspCodeOptions.map((row: PaymentProviderCode) => (
                            <MenuItem key={row.id} value={row.label}>
                              {row.label}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                      <Grid
                        item
                        md={6}
                        xs={12}
                      >
                        <Dropdown
                          list={['percent', 'flat']}
                          defaultValue={values.margin_type}
                          i18nTag="MARGIN_TYPE"
                          label="Margin Type"
                          onChange={(e: string) => setValues({ ...values, margin_type: e })}
                        />
                      </Grid>
                      <Grid
                        item
                        md={6}
                        xs={12}
                      >
                        <TextField
                          fullWidth
                          label={intl.get('MARGIN_RATE')}
                          name="margin_rate"
                          onChange={handleChange}
                          required
                          value={values.margin_rate}
                          variant="outlined"
                          style={{ paddingTop: '10px' }}
                        />
                      </Grid>
                      <Grid
                        item
                        md={6}
                        xs={12}
                      >
                        <TextField
                          size="small"
                          fullWidth
                          label={intl.get('DESCRIPTOR')}
                          name="descriptor"
                          onChange={handleChange}
                          value={values.descriptor}
                          variant="outlined"
                          style={{ paddingTop: '10px' }}
                        />
                      </Grid>
                      <Grid
                        item
                        md={6}
                        xs={12}
                      >
                        <TextField
                          size="small"
                          fullWidth
                          label={intl.get('DAY_LIMIT')}
                          name="day_limit"
                          onChange={handleChange}
                          value={values.day_limit}
                          variant="outlined"
                          style={{ paddingTop: '10px' }}
                        />
                      </Grid>
                      <Grid
                        item
                        md={6}
                        xs={12}
                      >
                        <DropdownProviderOptions
                          i18nTag="BACKUP_PSP_ID"
                          defaultValue={values.backup_psp_id}
                          onChange={(e: string) => setValues({ ...values, backup_psp_id: e })}
                        />
                      </Grid>
                      <Grid
                        item
                        md={6}
                        xs={12}
                      >
                        <TextField
                          style={{ width: '100%', paddingTop: '10px' }}
                          id="outlined-select-currency"
                          select
                          label={intl.get('CARD_TYPE')}
                          value={values.card_type}
                          onChange={(e) => setValues({ ...values, card_type: e.target.value as string })}
                          variant="outlined"
                        >
                          {
                            ['all', 'visa', 'mc'].map((value: string) => <MenuItem value={value}>{value}</MenuItem>)
                          }
                        </TextField>
                      </Grid>
                      <Grid
                        item
                        md={12}
                        xs={12}
                      >
                        <h3>{intl.get('PARAMETERS')}</h3>
                      </Grid>

                      <>
                        <Grid
                          item
                          md={5}
                          xs={12}
                        >
                          <TextField
                            size="small"
                            label={intl.get('KEY') || 'Key'}
                            fullWidth
                            name="key"
                            value={properties.key}
                            onChange={handleProperties}
                            required
                            variant="outlined"
                          />
                        </Grid>
                        <Grid
                          item
                          md={5}
                          xs={12}
                        >
                          <TextField
                            size="small"
                            label={intl.get('VALUE') || 'Value'}
                            fullWidth
                            name="value"
                            value={properties.value}
                            onChange={handleProperties}
                            required
                            variant="outlined"
                          />
                        </Grid>

                      </>
                      <ControlPointIcon
                        style={{
                          color: '#fff', margin: '14px 0', background: '#3485f7', borderRadius: '5px',
                        }}
                        onClick={() => addOptions(properties.key, properties.value)}
                      />
                      {values.parameters && Object.entries(values.parameters).map(([key], index) => (
                        <>
                          <Grid
                            item
                            md={5}
                            xs={12}
                          >
                            <TextField
                              size="small"
                              label={intl.get('KEY') || 'Key'}
                              fullWidth
                              name="key"
                              disabled
                              value={Object.keys(values.parameters)[index]}
                              required
                              variant="outlined"
                            />
                          </Grid>
                          <Grid
                            item
                            md={5}
                            xs={12}
                          >
                            <TextField
                              size="small"
                              label={intl.get('VALUE') || 'Value'}
                              fullWidth
                              name="value"
                              onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleUpdateParamsValue(event, Object.keys(values.parameters)[index])}
                              value={values.parameters[key]}
                              required
                              variant="outlined"
                            />
                          </Grid>
                          <HighlightOffIcon
                            style={{
                              color: '#3485f7', cursor: 'pointer', fontSize: '30px', marginTop: '10px',
                            }}
                            onClick={() => remove(key)}
                          />
                        </>
                      ))}
                    </Grid>
                  </CardContent>
                </Card>
              </form>
            </DialogContent>
            <DialogActions>
              <Grid
                item
              >
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => onSubmit()}
                >
                  {intl.get('SAVE') || 'Save'}
                </Button>
                <Button
                  color="default"
                  variant="contained"
                  style={{ marginLeft: '20px' }}
                  onClick={closePaymentProviderForm}
                >
                  {intl.get('CLOSE') || 'Close'}
                </Button>
              </Grid>
            </DialogActions>
          </Dialog>
        </div>
      </div>
    );
  };
