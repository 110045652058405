import { Dispatch } from 'redux';
import { AxiosError } from 'axios';
import {
  SettlementReport, ISettlements, SettlementFilter, Refunds,
} from '../../types/settlement.types';
import { adminSettlementConstants } from '../constants/settlement.constants';
import { adminSettlementService } from '../../services/admin-settlement.service';
import { Pagination, DownloadFile } from '../../types/common.types';
import { merchantSettlementService } from '../../services/merchant-settlement.service';
import { ExportData } from '../../types/merchant-billing.types';

const getSettlementList = (merchantPanel: boolean, paginationData: Pagination, filters?: SettlementFilter) => {
  const request = () => ({ type: adminSettlementConstants.GET_ADMIN_SETTLEMENT_LIST_REQUEST });
  const success = (allSettlementList: SettlementReport[], pagination: Pagination) => (
    { type: adminSettlementConstants.GET_ADMIN_SETTLEMENT_LIST_SUCCESS, allSettlementList, pagination }
  );
  const failure = (error: any) => ({ type: adminSettlementConstants.GET_ADMIN_SETTLEMENT_LIST_FAILURE, error });
  return (dispatch: Dispatch) => {
    dispatch(request());
    if (!merchantPanel) {
      adminSettlementService.getSettlementList(paginationData, filters)
        .then(
          (res) => {
            dispatch(success(res.data.data, res.data));
          },
        ).catch((error: AxiosError) => dispatch(failure(error.response?.data?.errors || [])));
    } else {
      merchantSettlementService.getSettlementList(paginationData)
        .then(
          (res) => {
            dispatch(success(res.data, res));
          },
        ).catch((error: AxiosError) => dispatch(failure(error.response?.data?.errors || [])));
    }
  };
};

const fileDownload = (settlementUUID: any) => {
  const request = () => ({ type: adminSettlementConstants.GET_ADMIN_SETTLEMENT_LIST_DONWLOAD_REQUEST });
  const success = (downloadFile: DownloadFile) => (
    { type: adminSettlementConstants.GET_ADMIN_SETTLEMENT_LIST_DONWLOAD_SUCCESS, downloadFile }
  );
  const failure = (error: any) => ({ type: adminSettlementConstants.GET_ADMIN_SETTLEMENT_LIST_DONWLOAD_FAILURE, error });
  return (dispatch: Dispatch) => {
    dispatch(request());
    adminSettlementService.downloadFile(settlementUUID)
      .then(
        (res) => {
          dispatch(success(res.data));
        },
      ).catch((error) => {
        dispatch(failure(error.message));
      });
  };
};

const addSettlement = (body: ISettlements) => {
  const request = () => ({ type: adminSettlementConstants.ADD_SETTLEMENT_REQUEST });
  const success = (payload: ISettlements[]) => ({ type: adminSettlementConstants.ADD_SETTLEMENT_SUCCESS, payload });
  const failure = (errors: any) => ({ type: adminSettlementConstants.ADD_SETTLEMENT_FAILURE, errors });
  return (dispatch: Dispatch) => {
    dispatch(request());
    adminSettlementService.addSettlement(body)
      .then(
        (res) => {
          dispatch(success(res.data));
        },
      ).catch((error: AxiosError) => dispatch(failure(error.response?.data?.errors)));
  };
};

const updateSettlement = (body: any, uuid: any) => {
  const request = () => ({ type: adminSettlementConstants.UPDATE_SETTLEMENT_REQUEST });
  const success = (updatePayload: SettlementReport) => ({ type: adminSettlementConstants.UPDATE_SETTLEMENT_SUCCESS, updatePayload });
  const failure = (error: any) => ({ type: adminSettlementConstants.UPDATE_SETTLEMENT_FAILURE, error });
  return (dispatch: Dispatch) => {
    dispatch(request());
    adminSettlementService.updateSettlement(body, uuid)
      .then(
        (res) => {
          dispatch(success(res.data));
        },
      ).catch((error) => {
        dispatch(failure(error.message));
      });
  };
};

const settlementDetails = (merchantPanel: boolean, settlementUUID: string) => {
  const request = () => ({ type: adminSettlementConstants.SETTLEMENT_FOR_EDIT_REQUEST });
  const success = (settlementForEdit: SettlementReport) => ({ type: adminSettlementConstants.SETTLEMENT_FOR_EDIT_SUCCESS, settlementForEdit });
  const failure = (error: any) => ({ type: adminSettlementConstants.SETTLEMENT_FOR_EDIT_FAILURE, error });
  return (dispatch: Dispatch) => {
    dispatch(request());
    if (!merchantPanel) {
      adminSettlementService.settlementDetails(settlementUUID)
        .then(
          (res) => {
            dispatch(success(res.data));
          },
        ).catch((error) => {
          dispatch(failure(error.message));
        });
    } else {
      merchantSettlementService.settlementDetails(settlementUUID)
        .then(
          (res) => {
            dispatch(success(res.data));
          },
        ).catch((error) => {
          dispatch(failure(error.message));
        });
    }
  };
};

const getSettlementRefundList = (paginationData: Pagination, filters?: SettlementFilter) => {
  const request = () => ({ type: adminSettlementConstants.GET_SETTLEMENT_REFUND_LIST_REQUEST });
  const success = (refundSettlementList: SettlementReport[], pagination: Pagination) => (
    { type: adminSettlementConstants.GET_SETTLEMENT_REFUND_LIST_SUCCESS, refundSettlementList, pagination }
  );
  const failure = (error: any) => ({ type: adminSettlementConstants.GET_SETTLEMENT_REFUND_LIST_FAILURE, error });
  return (dispatch: Dispatch) => {
    dispatch(request());
    adminSettlementService.getSettlementRefund(paginationData, filters)
      .then(
        (res) => {
          dispatch(success(res.data.data, res.data));
        },
      ).catch((error) => {
        dispatch(failure(error.message));
      });
  };
};

const addRefund = (body: Refunds) => {
  const request = () => ({ type: adminSettlementConstants.ADD_REFUND_REQUEST });
  const success = (addRefund: Refunds[]) => ({ type: adminSettlementConstants.ADD_REFUND_SUCCESS, addRefund });
  const failure = (errors: any) => ({ type: adminSettlementConstants.ADD_REFUND_FAILURE, errors });
  return (dispatch: Dispatch) => {
    dispatch(request());
    adminSettlementService.addRefund(body)
      .then(
        (res) => {
          dispatch(success(res.data));
        },
      ).catch((error: AxiosError) => dispatch(failure(error.response?.data?.errors || [])));
  };
};

const getRefundExport = (filters?: SettlementFilter) => {
  const request = () => ({ type: adminSettlementConstants.GET_REFUNDS_EXPORT_REQUEST });
  const success = (exports: ExportData) => (
    { type: adminSettlementConstants.GET_REFUNDS_EXPORT_SUCCESS, exports }
  );
  const failure = (error: any) => ({ type: adminSettlementConstants.GET_REFUNDS_EXPORT_FAILURE, error });
  return (dispatch: Dispatch) => {
    dispatch(request());
    adminSettlementService.getRefundExport(filters)
      .then(
        (res) => {
          dispatch(success({ filename: res.data.filename }));
        },
      ).catch((error: AxiosError) => dispatch(failure(error.response?.data?.errors || [])));
  };
};

const resetState = () => {
  const request = () => ({ type: adminSettlementConstants.RESET_STATE });

  return (dispatch: Dispatch) => {
    dispatch(request());
  };
};

export const adminSettlementActions = {
  getSettlementList,
  addSettlement,
  fileDownload,
  updateSettlement,
  resetState,
  settlementDetails,
  getSettlementRefundList,
  addRefund,
  getRefundExport,
};
