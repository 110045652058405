import { Dispatch } from 'redux';
import { AxiosError } from 'axios';
import { paymentProviderConstants } from '../constants';
import { paymentProviderService } from '../../services';
import { IPaymentProvider, PaymentProviderCode } from '../../types/payment-provider.types';
import { Pagination } from '../../types/common.types';

const getPaymentProviderList = (paginationData: Pagination) => {
  const request = () => ({ type: paymentProviderConstants.GET_PAYMENT_PROVIDER_LIST_REQUEST });
  const success = (allPaymentProviderList: IPaymentProvider[], pagination: Pagination) => (
    { type: paymentProviderConstants.GET_PAYMENT_PROVIDER_LIST_SUCCESS, allPaymentProviderList, pagination }
  );
  const failure = (error: any) => ({ type: paymentProviderConstants.GET_PAYMENT_PROVIDER_LIST_FAILURE, error });
  return (dispatch: Dispatch) => {
    dispatch(request());
    paymentProviderService.getPaymentProviderList(paginationData)
      .then(
        (res) => {
          dispatch(success(res.data.data, res.data));
        },
      ).catch((error: AxiosError) => dispatch(failure(error.response?.data?.errors || [])));
  };
};

const getPaymentProviderCode = () => {
  const request = () => ({ type: paymentProviderConstants.PAYMENT_PROVIDER_CODE_REQUEST });
  const success = (pspCodeOptions: PaymentProviderCode[]) => (
    { type: paymentProviderConstants.PAYMENT_PROVIDER_CODE_SUCCESS, pspCodeOptions }
  );
  const failure = (error: any) => ({ type: paymentProviderConstants.PAYMENT_PROVIDER_CODE_FAILURE, error });
  return (dispatch: Dispatch) => {
    dispatch(request());
    paymentProviderService.getPaymentProviderCode()
      .then(
        (res) => {
          dispatch(success(res.data));
        },
      ).catch((error: AxiosError) => dispatch(failure(error.response?.data?.errors || [])));
  };
};

const getPaymentProviderById = (id: number) => {
  const request = () => ({ type: paymentProviderConstants.GET_PAYMENT_PROVIDER_BY_ID_REQUEST });
  const success = (payload: IPaymentProvider) => (
    { type: paymentProviderConstants.GET_PAYMENT_PROVIDER_BY_ID_SUCCESS, payload }
  );
  const failure = (error: any) => ({ type: paymentProviderConstants.GET_PAYMENT_PROVIDER_BY_ID_FAILURE, error });
  return (dispatch: Dispatch) => {
    dispatch(request());
    paymentProviderService.getPaymentProviderById(id)
      .then(
        (res) => {
          dispatch(success(res.data));
        },
      )
      .catch((error: AxiosError) => dispatch(failure(error.response?.data?.errors || [])));
  };
};

const addPaymentProvider = (body: IPaymentProvider) => {
  const request = () => ({ type: paymentProviderConstants.ADD_PAYMENT_PROVIDER_REQUEST });
  const success = (payload: IPaymentProvider[]) => ({ type: paymentProviderConstants.ADD_PAYMENT_PROVIDER_SUCCESS, payload });
  const failure = (errors: any) => ({ type: paymentProviderConstants.ADD_PAYMENT_PROVIDER_FAILURE, errors });
  return (dispatch: Dispatch) => {
    dispatch(request());
    paymentProviderService.addPaymentProvider(body)
      .then(
        (res) => {
          dispatch(success(res.data));
        },
      ).catch((error: AxiosError) => dispatch(failure(error.response?.data?.errors || [])));
  };
};

const updatePaymentProvider = (body: any) => {
  const request = () => ({ type: paymentProviderConstants.UPDATE_PAYMENT_PROVIDER_REQUEST });
  const success = (payload: IPaymentProvider[]) => ({ type: paymentProviderConstants.UPDATE_PAYMENT_PROVIDER_SUCCESS, payload });
  const failure = (errors: any) => ({ type: paymentProviderConstants.UPDATE_PAYMENT_PROVIDER_FAILURE, errors });
  return (dispatch: Dispatch) => {
    dispatch(request());
    paymentProviderService.updatePaymentProvider(body)
      .then(
        (res) => {
          dispatch(success(res.data));
        },
      ).catch((error: AxiosError) => dispatch(failure(error.response?.data?.errors || [])));
  };
};

const deletePaymentProvider = (currentId: number) => {
  const request = () => ({ type: paymentProviderConstants.DELETE_PAYMENT_PROVIDER_REQUEST });
  const success = (id: number) => (
    {
      type: paymentProviderConstants.DELETE_PAYMENT_PROVIDER_SUCCESS, id,
    }
  );
  const failure = (error: any) => ({ type: paymentProviderConstants.DELETE_PAYMENT_PROVIDER_FAILURE, error });
  return (dispatch: Dispatch) => {
    dispatch(request());
    paymentProviderService.deletePaymentProvider(currentId)
      .then(
        () => {
          dispatch(success(currentId));
        },
      ).catch((error: AxiosError) => dispatch(failure(error.response?.data?.errors || [])));
  };
};

const getPspOptions = () => {
  const request = () => ({ type: paymentProviderConstants.PAYMENT_PROVIDER_OPTIONS_REQUEST });
  const success = (pspConfigOptions: any) => (
    { type: paymentProviderConstants.PAYMENT_PROVIDER_OPTIONS_SUCCESS, pspConfigOptions }
  );
  const failure = (error: any) => ({ type: paymentProviderConstants.PAYMENT_PROVIDER_OPTIONS_FAILURE, error });
  return (dispatch: Dispatch) => {
    dispatch(request());
    paymentProviderService.getPspOptions()
      .then(
        (res) => {
          dispatch(success(res.data));
        },
      ).catch((error: AxiosError) => dispatch(failure(error.response?.data?.errors || [])));
  };
};

export const paymentProviderActions = {
  getPaymentProviderList,
  getPaymentProviderById,
  addPaymentProvider,
  updatePaymentProvider,
  deletePaymentProvider,
  getPaymentProviderCode,
  getPspOptions,
};
