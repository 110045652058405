import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/reducers';
import Toolbar from './Toolbar';
import TransactionList from './TransactionList';
import { IAdminTransaction } from '../../../types/admin-transaction.types';
import PageHeader from '../../../components/StyledComponents/PageHeader';

interface Types {
  merchantPanel: boolean;
}

export const Transactions = ({ merchantPanel }: Types) => {
  const transactionReducer = useSelector((state: RootState) => state.adminTransactionReducer);
  const transactionList: IAdminTransaction[] = transactionReducer.allAdminTransactionList || [];
  const [paginationPar, setPaginationPar] = React.useState({ limit: 10, current_page: 1 });
  return (
    <>
      {' '}
      <PageHeader
        label="TRANSACTIONS"
        componentToPassDown={<Toolbar merchantPanel={merchantPanel} pagination={paginationPar} />}
      />
      <TransactionList adminTransactions={transactionList} merchantPanel={merchantPanel} changePaginationOptions={(e) => { setPaginationPar(e); }} />
    </>
  );
};
