import { KYC } from '../../../types/file.types';

interface Types {

  FILE_UPLOAD_REQUEST: string;
  FILE_UPLOAD_SUCCESS: string;
  FILE_UPLOAD_FAILURE: string;

  FILE_LIST_REQUEST: string;
  FILE_LIST_SUCCESS: string;
  FILE_LIST_FAILURE: string;

  APPROVE_KYC_REQUEST: string;
  APPROVE_KYC_SUCCESS: string;
  APPROVE_KYC_FAILURE: string;

  DELETE_KYC_REQUEST: string;
  DELETE_KYC_SUCCESS: string;
  DELETE_KYC_FAILURE: string;

  VIEW_KYC_REQUEST: string;
  VIEW_KYC_SUCCESS: string;
  VIEW_KYC_FAILURE: string;

  RESET_KYC_STATE:string

}

export const fileConstants: Types = {

  FILE_UPLOAD_REQUEST: 'FILE_UPLOAD_REQUEST',
  FILE_UPLOAD_SUCCESS: 'FILE_UPLOAD_SUCCESS',
  FILE_UPLOAD_FAILURE: 'FILE_UPLOAD_FAILURE',

  FILE_LIST_REQUEST: 'FILE_LIST_REQUEST',
  FILE_LIST_SUCCESS: 'FILE_LIST_SUCCESS',
  FILE_LIST_FAILURE: 'FILE_LIST_FAILURE',

  APPROVE_KYC_REQUEST: 'APPROVE_KYC_REQUEST',
  APPROVE_KYC_SUCCESS: 'APPROVE_KYC_SUCCESS',
  APPROVE_KYC_FAILURE: 'APPROVE_KYC_FAILURE',

  DELETE_KYC_REQUEST: 'DELETE_KYC_REQUEST',
  DELETE_KYC_SUCCESS: 'DELETE_KYC_SUCCESS',
  DELETE_KYC_FAILURE: 'DELETE_KYC_FAILURE',

  VIEW_KYC_REQUEST: 'VIEW_KYC_REQUEST',
  VIEW_KYC_SUCCESS: 'VIEW_KYC_SUCCESS',
  VIEW_KYC_FAILURE: 'VIEW_KYC_FAILURE',

  RESET_KYC_STATE: 'RESET_KYC_STATE',

};

interface LoadUploadFileAction {
  type: typeof fileConstants.FILE_UPLOAD_REQUEST;
  file: File;
  files: KYC[];
  kyc: KYC;
}

interface LoadedUploadFileAction {
  type: typeof fileConstants.FILE_UPLOAD_SUCCESS;
  file: File;
  files: KYC[];
  kyc: KYC;
}

interface FailedUploadFileAction {
  type: typeof fileConstants.FILE_UPLOAD_FAILURE;
  file: File;
  files: KYC[];
  kyc: KYC;
}

interface LoadFileListAction {
  type: typeof fileConstants.FILE_LIST_REQUEST;
  file: File;
  files: KYC[];
  kyc: KYC;
}

interface LoadedFileListAction {
  type: typeof fileConstants.FILE_LIST_SUCCESS;
  file: File;
  files: KYC[];
  kyc: KYC;
}

interface FailedFileListAction {
  type: typeof fileConstants.FILE_LIST_FAILURE;
  file: File;
  files: KYC[];
  kyc: KYC;
}

interface LoadApproveKycAction {
  type: typeof fileConstants.APPROVE_KYC_REQUEST;
  file: File;
  files: KYC[];
  kyc: KYC;
}

interface LoadedApproveKycAction {
  type: typeof fileConstants.APPROVE_KYC_SUCCESS;
  file: File;
  files: KYC[];
  kyc: KYC;
}

interface FailedApproveKycAction {
  type: typeof fileConstants.APPROVE_KYC_FAILURE;
  file: File;
  files: KYC[];
  kyc: KYC;
}

interface LoadDeleteKycAction {
  type: typeof fileConstants.DELETE_KYC_REQUEST;
  file: File;
  files: KYC[];
  kyc: KYC;
}

interface LoadedDeleteKycAction {
  type: typeof fileConstants.DELETE_KYC_SUCCESS;
  file: File;
  files: KYC[];
  kyc: KYC;
}

interface FailedDeleteKycAction {
  type: typeof fileConstants.DELETE_KYC_FAILURE;
  file: File;
  files: KYC[];
  kyc: KYC;
}

export type FileActionTypes
  = LoadUploadFileAction
  | LoadedUploadFileAction
  | FailedUploadFileAction

  | LoadFileListAction
  | LoadedFileListAction
  | FailedFileListAction

  | LoadApproveKycAction
  | LoadedApproveKycAction
  | FailedApproveKycAction

  | LoadDeleteKycAction
  | LoadedDeleteKycAction
  | FailedDeleteKycAction;
