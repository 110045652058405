import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  useParams,
} from 'react-router-dom';
import { RootState } from '../../../store/reducers';
import Toolbar from './Toolbar';
import MerchantAccountList from './MerchantAccountList';
import { IMerchantAccount } from '../../../types/merchant-account.types';
import { merchantAccountActions } from '../../../store/actions';
import PageCommon from '../../../components/StyledComponents/PageCommon';

type RouteParams = {
  merchantId: string;
}
interface Types {
  merchantPanel: boolean;
}

export const MerchantAccounts = ({ merchantPanel }: Types) => {
  const dispatch = useDispatch();
  const params = useParams<RouteParams>();
  const [paginationPar, setPaginationPar] = React.useState({ limit: 10, current_page: 1 });
  useEffect(() => {
    dispatch(merchantAccountActions.getMerchantAccountList(params.merchantId, paginationPar, merchantPanel));
  }, [dispatch, params, merchantPanel, paginationPar]);
  const merchantAccountReducer = useSelector((state: RootState) => state.merchantAccountReducer);
  const merchantAccountList: IMerchantAccount[] = merchantAccountReducer.allMerchantAccountList || [];
  return (
    <>
      <PageCommon
        label="ACCOUNT_DETAILS"
        componentToPassDown={<Toolbar merchantUUID={params.merchantId} merchantPanel={merchantPanel} />}
      />
      <MerchantAccountList merchantAccounts={merchantAccountList} merchantUUID={params.merchantId} merchantPanel={merchantPanel} changePaginationOptions={(e) => setPaginationPar(e)} />
    </>
  );
};
