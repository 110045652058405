import { createMuiTheme } from '@material-ui/core/styles';

export const paginationTheme = createMuiTheme({
  overrides: {
    MuiTablePagination: {
      select: {
        paddingLeft: '8px !important',
        paddingRight: '24px !important',
      },
    },
  },
});
