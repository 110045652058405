import React, { useState, useEffect } from 'react';
import intl from 'react-intl-universal';
import { useSelector, useDispatch } from 'react-redux';
import {
  Card,
  Box,
  Grid,
  Button,
  Radio,
  RadioGroup,
  FormControlLabel,
} from '@material-ui/core';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { RootState } from '../../../store/reducers';
import { merchantActions } from '../../../store/actions';
import { MerchantPsp, MerchantPspInitialState } from '../../../types/merchant.types';
import { Option } from '../../../types/common.types';
import { DropdownProviderOptions } from '../../index';

interface IProps {
  closeMerchantAccountForm: () => void;
  selectedMerchantPsp: MerchantPsp | null;
  merchantUUID: string;
}

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    color: '#fff',
  },
  expandedPanel: {
    right: 0,
    padding: '20px',
    backgroundColor: '#2c333d',
    position: 'absolute',
    zIndex: 9999,
    borderRadius: '8px',
    fontSize: '12px',
  },
  divider: {
    background: '#fff',
    margin: '5px auto',
  },
  detailPanelSection: {
    padding: theme.spacing(2),
  },
  inputContainer: {
    margin: '10px',
  },
  detailPanelInput: {
    all: 'unset',
    padding: '5px',
    margin: '5px',
    backgroundColor: '#3d4651 !important',
  },
  fullWidth: {
    width: '100%',
  },
  formControl: {
    margin: theme.spacing(1),
    width: '55%',
  },
}));

export const PspForm: React.FC<IProps> = ({
  closeMerchantAccountForm,
  selectedMerchantPsp,
  merchantUUID,
}: IProps): React.ReactElement => {
  const classes = useStyles();
  const [form, setForm] = useState({ ...MerchantPspInitialState, merchant_uuid: merchantUUID });

  const dispatch = useDispatch();
  const onSubmit = () => {
    if (selectedMerchantPsp) {
      dispatch(merchantActions.updateMerchantPsp(form, selectedMerchantPsp.id));
    } else {
      dispatch(merchantActions.addMerchantPsp(form));
    }
  };
  const merchantReducer = useSelector((state: RootState) => state.merchantReducer);
  const transactionReducer = useSelector((state: RootState) => state.adminTransactionReducer);
  const paymentMethodOptions: Option[] = transactionReducer.paymentMethodOptions || [];

  /**
      * This will run when merchantAccount reducer updates
      */
  useEffect(() => {
    if (merchantReducer.isUpdated) {
      closeMerchantAccountForm();
      dispatch(merchantActions.getPspByMerchantUUID(merchantUUID));
      if (!selectedMerchantPsp) {
        setForm({ ...MerchantPspInitialState });
      }
    }
  }, [merchantReducer, selectedMerchantPsp, dispatch, merchantUUID, closeMerchantAccountForm]);

  /**
    * This will check if merchantAccount is being adding or editing
    */
  useEffect(() => {
    if (selectedMerchantPsp) {
      const merchantUuid = selectedMerchantPsp.merchant && selectedMerchantPsp?.merchant?.uuid;
      setForm({
        merchant_uuid: merchantUuid || merchantUUID,
        payment_provider_id: selectedMerchantPsp.payment_provider_id,
        payment_method: selectedMerchantPsp.payment_method,
        active: selectedMerchantPsp.active,
        ratio: selectedMerchantPsp.ratio,
      });
    }
  }, [selectedMerchantPsp, merchantUUID]);

  const changePaymentMethod = (event: React.ChangeEvent<{ value: unknown }>) => {
    const selectedValue = event.target.value as string;
    setForm({ ...form, payment_method: selectedValue });
  };
  return (
    <Card className={classes.root}>
      <div className={classes.expandedPanel}>
        <Box
          display="flex"
          justifyContent="space-around"
          flexDirection="row"
          flexWrap="wrap"
        >
          <Grid
            item
            md={5}
          >
            <div className={classes.inputContainer}>
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="flex-start"
                alignItems="center"
              >
                <DropdownProviderOptions
                  i18nTag="PAYMENT_PROVIDERS"
                  defaultValue={form.payment_provider_id}
                  onChange={(e: string) => setForm({ ...form, payment_provider_id: e })}
                />
              </Box>
            </div>
          </Grid>
          <Grid
            item
            md={5}
          >
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="flex-start"
              alignItems="center"
            >
              <span style={{ color: '#fff', marginRight: '10px' }}>{intl.get('PAYMENT_METHODS')}</span>
              <FormControl className={classes.formControl}>
                <Select
                  value={form.payment_method}
                  onChange={changePaymentMethod}
                  style={{ color: '#fff' }}
                >
                  {
                    paymentMethodOptions && paymentMethodOptions.length && paymentMethodOptions.map((option: Option) => (
                      <MenuItem value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </Box>
          </Grid>

          <Grid
            item
            md={5}
          >
            <div className={classes.inputContainer}>
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="flex-start"
                alignItems="center"
              >
                <span style={{ marginRight: '10px' }}>{intl.get('RATIO')}</span>
                <input
                  type="number"
                  className={classes.detailPanelInput}
                  onChange={(e) => setForm({ ...form, ratio: Number(e.target.value) })}
                  value={form.ratio}
                />
              </Box>
            </div>
          </Grid>

          <Grid
            item
            md={5}
          >
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="flex-start"
              alignItems="center"
            >
              <span style={{ marginRight: '10px' }}>{intl.get('ACTIVE')}</span>
              <RadioGroup
                aria-label="primary"
                name="primary"
                value={form.active}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setForm(
                    { ...form, active: e.target.value === 'yes' },
                  );
                }}
                style={{ flexDirection: 'row' }}
              >
                <FormControlLabel value="yes" checked={form.active} control={<Radio />} label={intl.get('YES') || 'Yes'} />
                <FormControlLabel value="no" checked={!form.active} control={<Radio />} label={intl.get('NO') || 'No'} />
              </RadioGroup>
            </Box>
          </Grid>

          <Grid
            item
            md={12}
          >
            <Box
              display="flex"
              flexDirection="row"
              flexWrap="wrap"
              justifyContent="center"
              alignItems="center"
            >
              <Grid
                item
              >
                <Button
                  color="default"
                  variant="contained"
                  onClick={() => onSubmit()}
                  size="small"
                >
                  {intl.get('SAVE')}
                </Button>
                <Button
                  color="default"
                  variant="contained"
                  style={{ marginLeft: '20px' }}
                  onClick={closeMerchantAccountForm}
                  size="small"
                >
                  {intl.get('CLOSE')}
                </Button>
              </Grid>
            </Box>
          </Grid>
        </Box>
      </div>
    </Card>
  );
};
