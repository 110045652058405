import React, { useEffect } from 'react';
import intl from 'react-intl-universal';
import {
  MenuItem,
  TextField,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { Option } from '../../types/common.types';
import { adminTransactionActions } from '../../store/actions';
import { RootState } from '../../store/reducers';

export const DropdownProviderOptions = ({
  onChange, defaultValue, i18nTag,
}: any) => {
  const [selectedValue, setSelectedValue] = React.useState(defaultValue);
  // const classes = useStyles();
  const dispatch = useDispatch();
  const transactionReducer = useSelector((state: RootState) => state.adminTransactionReducer);
  const providerOptions: Option[] = transactionReducer.providerOptions || [];

  useEffect(() => {
    dispatch(adminTransactionActions.getProviderOptions(false));
  }, [dispatch]);

  return (
    <TextField
      style={{ width: '100%', paddingTop: '10px' }}
      id="outlined-select-currency"
      select
      label={intl.get(i18nTag)}
      value={selectedValue}
      onChange={(e) => { onChange(e.target.value); setSelectedValue(e.target.value); }}
      variant="outlined"
    >
      {
        providerOptions && providerOptions.length && providerOptions.map((option: Option) => (
          <MenuItem value={option?.value} key={option.value}>
            {option.label}
          </MenuItem>
        ))
      }
    </TextField>
  );
};
