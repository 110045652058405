/* eslint-disable camelcase */
import React, { useEffect } from 'react';
import intl from 'react-intl-universal';
import {
  makeStyles, createStyles,
} from '@material-ui/core/styles';
import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  ThemeProvider,
  TablePagination,
  TableContainer,
  Paper,
  LinearProgress,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import DownloadImage from '../../../assets/icons/Download_1.png';
import Download_Hover from '../../../assets/icons/Download_hover.png';
import Mail from '../../../assets/icons/Mail.png';
import Mail_Hover from '../../../assets/icons/Mail_hover.png';
import Edit from '../../../assets/icons/Edit.png';
import Edit_Hover from '../../../assets/icons/Edit_hover.png';
import { SettlementReport } from '../../../types/settlement.types';
import { RootState } from '../../../store/reducers';
import { adminSettlementActions } from '../../../store/actions/admin-settlement.actions';
import Badges from '../../../components/Badges';
import { tableTheme } from '../../../assets/styles/table-style';
import { history } from '../../../helpers';
import { paginationTheme } from '../../../components/StyledComponents/paginateTheme';
import { StyledTableHeadCell } from '../../../components';

interface Props {
  settlement: SettlementReport[];
  changePaginationOptions: (e: any) => void;
  merchantPanel: boolean;
}

const useStyles = makeStyles(() => createStyles({
  root: {
    margin: '10px auto',
  },
  table: {
    minWidth: 650,
  },
  tableHeadCell: {
    fontSize: '13px',
    fontWeight: 'bold',
    lineHeight: '1.2',
    letterSpacing: '0.9',
    textAlign: 'left',
    color: '#232931',
  },
  tableBodyCell: {
    color: '#232931',
    fontSize: '11px',
    paddingTop: '8px',
    paddingBottom: '8px',
  },
  tableBodyIcon: {
    cursor: 'pointer',
  },
  EditIcon: {
    color: 'black',
    fontSize: '18px',
    cursor: 'pointer',
    '&:hover': {
      color: 'blue',
    },
  },
  mailIcon: {
    color: '#3f51b5',
    fontSize: '19px',
  },
  colorPrimary: {
    backgroundColor: 'rgba(0, 0, 0, 0.87)',
  },
  barColorPrimary: {
    backgroundColor: '#6639e4',
  },
}));

export default ({ settlement, changePaginationOptions, merchantPanel }: Props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const settlemetState = useSelector((state: RootState) => state.adminSettlementReducer);
  const { total } = settlemetState.pagination;
  const [pagination, setPagination] = React.useState({
    limit: 10,
    current_page: 1,
  });
  const handleChangePage = (event: unknown, newPage: number) => {
    setPagination({
      ...pagination,
      current_page: ++newPage,
    });
    changePaginationOptions({ ...pagination, current_page: newPage });
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPagination({
      ...pagination,
      limit: +event.target.value,
      current_page: 1,
    });
    changePaginationOptions({ ...pagination, limit: +event.target.value });
  };

  const Download = (settlementUUID: any) => {
    dispatch(adminSettlementActions.fileDownload(settlementUUID));
  };

  useEffect(() => {
    if (settlemetState?.downloadFile?.url) {
      window.open(
        settlemetState.downloadFile.url,
        '_blank',
      );
      dispatch(adminSettlementActions.resetState());
    }
  }, [dispatch, settlemetState]);
  const { loading } = settlemetState;
  return (
    <Card className={classes.root}>
      <TableContainer component={Paper}>
        <ThemeProvider theme={tableTheme}>
          {loading ? <LinearProgress classes={{ colorPrimary: classes.colorPrimary, barColorPrimary: classes.barColorPrimary }} /> : null}
          <Table size="small" className={classes.table} aria-label="settlement Report list">
            <TableHead>
              <TableRow>
                <>
                  {!merchantPanel
                    ? <TableCell><StyledTableHeadCell>{intl.get('MERCHANT')}</StyledTableHeadCell></TableCell> : null}
                  <TableCell><StyledTableHeadCell>{intl.get('BATCH_NO')}</StyledTableHeadCell></TableCell>
                  {!merchantPanel
                    ? <TableCell><StyledTableHeadCell>{intl.get('PROVIDER')}</StyledTableHeadCell></TableCell> : null}
                  <TableCell><StyledTableHeadCell>{intl.get('CURRENCY')}</StyledTableHeadCell></TableCell>
                  <TableCell><StyledTableHeadCell>{intl.get('SETTLEMENT_FINAL_AMOUNT')}</StyledTableHeadCell></TableCell>
                  <TableCell><StyledTableHeadCell>{intl.get('DATE & TIME')}</StyledTableHeadCell></TableCell>
                  <TableCell><StyledTableHeadCell>{intl.get('STATUS')}</StyledTableHeadCell></TableCell>
                  {!merchantPanel
                    ? (
                      <>
                        <TableCell> </TableCell>
                        <TableCell> </TableCell>
                        <TableCell> </TableCell>

                      </>
                    )
                    : null}
                </>
              </TableRow>
            </TableHead>
            <TableBody>
              {settlement && settlement.map((row) => (
                <TableRow style={{ position: 'relative' }}>
                  <>
                    {!merchantPanel
                      ? (
                        <TableCell className={classes.tableBodyCell}>
                          {row?.merchant?.company_name}
                        </TableCell>
                      ) : null}
                    <TableCell className={classes.tableBodyCell}>{row.filename}</TableCell>
                    {!merchantPanel
                      ? (
                        <TableCell className={classes.tableBodyCell}>
                          {row?.payment_provider?.code}
                        </TableCell>
                      ) : null}
                    <TableCell className={classes.tableBodyCell}>{row.transactions_currency}</TableCell>
                    <TableCell className={classes.tableBodyCell}>
                      {row?.settlement_final_amount?.toFixed(2)}
                    </TableCell>
                    <TableCell className={classes.tableBodyCell}>
                      {row.created_at && `${new Date(row.created_at).toLocaleDateString()} ${new Date(row.created_at).toLocaleTimeString()}`}
                    </TableCell>
                    <TableCell className={classes.tableBodyCell}><Badges status={row?.status} /></TableCell>
                    {!merchantPanel
                      ? (
                        <>
                          <TableCell className={classes.tableBodyCell}>
                            <img
                              className={classes.tableBodyIcon}
                              src={Mail}
                              onMouseOver={e => (e.currentTarget.src = `${Mail_Hover}`)}
                              onMouseOut={e => (e.currentTarget.src = `${Mail}`)}
                              width="15"
                              height="15"
                              alt="MailImage"
                            />
                          </TableCell>
                          <TableCell className={classes.tableBodyCell}>
                            <img
                              className={classes.tableBodyIcon}
                              src={Edit}
                              onMouseOver={e => (e.currentTarget.src = `${Edit_Hover}`)}
                              onMouseOut={e => (e.currentTarget.src = `${Edit}`)}
                              width="15"
                              height="15"
                              alt="EditImage"
                              onClick={() => history.push(`/dashboard/settlements/EditForm/${row.uuid}`)}
                            />
                          </TableCell>
                        </>
                      )
                      : null}
                    <TableCell className={classes.tableBodyCell}>
                      <img
                        className={classes.tableBodyIcon}
                        src={DownloadImage}
                        onMouseOver={e => (e.currentTarget.src = `${Download_Hover}`)}
                        onMouseOut={e => (e.currentTarget.src = `${DownloadImage}`)}
                        width="15"
                        height="15"
                        alt="downloadImage"
                        onClick={() => Download(row.uuid)}
                      />
                    </TableCell>
                  </>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </ThemeProvider>
      </TableContainer>
      {
        total && settlement && settlement.length
          ? (
            <ThemeProvider theme={paginationTheme}>
              <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={total}
                rowsPerPage={pagination.limit}
                page={pagination.current_page - 1}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </ThemeProvider>
          )
          : null
      }
    </Card>
  );
};
