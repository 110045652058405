import { IMerchant, MerchantCurrencyBalances } from '../../../types/merchant.types';

interface Types {

  GET_MERCHANT_REQUEST: string;
  GET_MERCHANT_SUCCESS: string;
  GET_MERCHANT_FAILURE: string;

  UPDATE_MERCHANT_REQUEST: string;
  UPDATE_MERCHANT_SUCCESS: string;
  UPDATE_MERCHANT_FAILURE: string;

  GET_MERCHANT_BALANCES_REQUEST: string;
  GET_MERCHANT_BALANCES_SUCCESS: string;
  GET_MERCHANT_BALANCES_FAILURE: string;

}

export const merchantConstants: Types = {

  GET_MERCHANT_REQUEST: 'GET_MERCHANT_REQUEST',
  GET_MERCHANT_SUCCESS: 'GET_MERCHANT_SUCCESS',
  GET_MERCHANT_FAILURE: 'GET_MERCHANT_FAILURE',

  UPDATE_MERCHANT_REQUEST: 'UPDATE_MERCHANT_REQUEST',
  UPDATE_MERCHANT_SUCCESS: 'UPDATE_MERCHANT_SUCCESS',
  UPDATE_MERCHANT_FAILURE: 'UPDATE_MERCHANT_FAILURE',

  GET_MERCHANT_BALANCES_REQUEST: 'GET_MERCHANT_BALANCES_REQUEST',
  GET_MERCHANT_BALANCES_SUCCESS: 'GET_MERCHANT_BALANCES_SUCCESS',
  GET_MERCHANT_BALANCES_FAILURE: 'GET_MERCHANT_BALANCES_FAILURE',
};

interface LoadGetMerchantAction {
  type: typeof merchantConstants.GET_MERCHANT_REQUEST;
  merchant: IMerchant;
  errors: any;
  merchantBalances:MerchantCurrencyBalances[]
}

interface LoadedGetMerchantAction {
  type: typeof merchantConstants.GET_MERCHANT_SUCCESS;
  merchant: IMerchant;
  errors: any;
  merchantBalances:MerchantCurrencyBalances[]
}

interface FailedGetMerchantAction {
  type: typeof merchantConstants.GET_MERCHANT_FAILURE;
  merchant: IMerchant;
  errors: any;
  merchantBalances:MerchantCurrencyBalances[]
}

interface LoadUpdateMerchantAction {
  type: typeof merchantConstants.UPDATE_MERCHANT_REQUEST;
  merchant: IMerchant;
  errors: any;
  merchantBalances:MerchantCurrencyBalances[]
}

interface LoadedUpdateMerchantAction {
  type: typeof merchantConstants.UPDATE_MERCHANT_SUCCESS;
  merchant: IMerchant;
  errors: any;
  merchantBalances:MerchantCurrencyBalances[]
}

interface FailedUpdateMerchantAction {
  type: typeof merchantConstants.UPDATE_MERCHANT_FAILURE;
  merchant: IMerchant;
  errors: any;
  merchantBalances:MerchantCurrencyBalances[]
}

interface LoadGetMerchantBalancesAction {
  type: typeof merchantConstants.GET_MERCHANT_BALANCES_REQUEST;
  merchant: IMerchant;
  errors: any;
  merchantBalances:MerchantCurrencyBalances[]
}

interface LoadedGetMerchantBalancesAction {
  type: typeof merchantConstants.GET_MERCHANT_BALANCES_SUCCESS;
  merchant: IMerchant;
  errors: any;
  merchantBalances:MerchantCurrencyBalances[]
}

interface FailedGetMerchantBalancesAction {
  type: typeof merchantConstants.GET_MERCHANT_BALANCES_FAILURE;
  merchant: IMerchant;
  errors: any;
  merchantBalances:MerchantCurrencyBalances[]
}

export type MerchantActionTypes
  = LoadGetMerchantAction
  | LoadedGetMerchantAction
  | FailedGetMerchantAction

  | LoadUpdateMerchantAction
  | LoadedUpdateMerchantAction
  | FailedUpdateMerchantAction

  | LoadGetMerchantBalancesAction
  | LoadedGetMerchantBalancesAction
  | FailedGetMerchantBalancesAction;
