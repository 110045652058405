import React, { useEffect } from 'react';
import intl from 'react-intl-universal';
import 'date-fns';
import {
  Box,
  IconButton,
  Button,
  ThemeProvider,
  Card,
  CardContent,
} from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import FilterListIcon from '@material-ui/icons/FilterList';
import CloseIcon from '@material-ui/icons/Close';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  DatePicker,
} from '@material-ui/pickers';
import { useDispatch, useSelector } from 'react-redux';
import { customDatePicker } from '../../../assets/styles/date-picker';
import { PayoutForm, SearchInput } from '../../../components';
import ExportIcon from '../../../assets/icons/Export.svg';
import { Dates } from '../../../types/common.types';
import { PayoutFilter } from '../../../types/dashboard.types';
import { merchantBillingActions } from '../../../store/actions';
import { RootState } from '../../../store/reducers';

import { MerchantDropDown } from '../../../components/MerchantDropDown';
import { CurrencyDropDown } from '../../../components/Currency';
import { StatusDropDown } from '../../../components/StatusDropdown';

const useStyles = makeStyles((theme: Theme) => ({
  iconStyle: {
    fill: '#3485f7',
  },
  dateLabel: {
    fontWeight: 'bold',
    margin: '10px',
    marginTop: '15px',
  },
  clearFilters: {
    color: '#3981f7',
    fontSize: '12px',
    cursor: 'pointer',
    paddingBottom: '12px',
    marginLeft: '10px',
  },
  filterContainer: {
    borderRadius: '5px',
    marginBottom: '10px',
    position: 'relative',
  },
  MuiInputBaseinput: {
    width: '91px',
  },
  export: {
    padding: '1px 9px',
    marginTop: '9px',
    marginLeft: '20px',
  },
}));
export interface Props {
  merchantPanel: boolean;
}

const initialFilters: PayoutFilter = {
  status: '', merchantUUID: '', currency: '', paidOn: '', selectedDates: { from: '', to: '' },
};

export default ({ merchantPanel }: Props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [selectedDates, setSelectedDates] = React.useState<Dates>({ from: '', to: '' });
  const [selectedFilters, setSelectedFilters] = React.useState<PayoutFilter>(initialFilters);
  const [clearFilter, setClearFilter] = React.useState(false);
  const now = new Date(Date.now());
  const [selectedFromDate, setSelectedFromDate] = React.useState<Date | null>(
    new Date(now.getFullYear(), now.getMonth(), 1),
  );
  const [selectedToDate, setSelectedToDate] = React.useState<Date | null>(
    new Date(now.getFullYear(), now.getMonth(), (new Date(now.getFullYear(), now.getMonth(), 0)).getDate()),
  );
  const [selectedPaidOnDate, setSelectedPaidOnDate] = React.useState<Date | null>(
    new Date(),
  );
  const [showFilters, setShowFilters] = React.useState(false);

  const [displayMerchantPayoutForm, setdisplayMerchantPayoutForm] = React.useState(false);

  const openPayoutForm = () => {
    setdisplayMerchantPayoutForm(true);
  };

  const closePayoutForm = () => {
    setdisplayMerchantPayoutForm(false);
  };

  const dateFormat = (date: Date | null) => {
    const year = date?.getFullYear();
    const month = `${(date && (date?.getMonth() + 1).toString().length === 1 ? `0${date?.getMonth() + 1}` : (date && date?.getMonth() + 1))}`;
    const day = `${date?.getDate().toString().length === 1 ? `0${date?.getDate()}` : date?.getDate()}`;
    return `${year}-${month}-${day}`;
  };

  const handleFromDateChange = (date: Date | null) => {
    setSelectedFromDate(date);
    const dates = { to: selectedDates.to, from: dateFormat(date) };
    setSelectedDates(dates);
    setSelectedFilters({ ...selectedFilters, selectedDates: dates });
  };

  const handlePaidOnDateChange = (date: Date | null) => {
    setSelectedPaidOnDate(date);
    setSelectedFilters({ ...selectedFilters, paidOn: dateFormat(date) });
  };

  const handleToDateChange = (date: Date | null) => {
    setSelectedToDate(date);
    const dates = { to: dateFormat(date), from: selectedDates.from };
    setSelectedDates(dates);
    setSelectedFilters({ ...selectedFilters, selectedDates: dates });
  };

  useEffect(() => {
    dispatch(merchantBillingActions.getMerchantBillingList(merchantPanel, selectedFilters));
    setClearFilter(false);
  }, [dispatch, merchantPanel, selectedFilters]);

  const clearFilters = () => {
    setSelectedFilters(initialFilters);
    setSelectedDates({ from: '', to: '' });
    setSelectedFromDate(new Date());
    setSelectedToDate(new Date());
    setSelectedPaidOnDate(new Date());
    setClearFilter(true);
  };

  const exportData = () => {
    if (selectedFilters.selectedDates?.from && selectedFilters.selectedDates.to) {
      dispatch(merchantBillingActions.getPayoutsExport(merchantPanel, selectedFilters));
    } else {
      const dates = { to: dateFormat(selectedToDate), from: dateFormat(selectedFromDate) };
      dispatch(merchantBillingActions.getPayoutsExport(merchantPanel, { ...selectedFilters, selectedDates: dates }));
    }
  };

  const merchantBillingReducer = useSelector((state: RootState) => state.merchantBillingReducer);
  useEffect(() => {
    if (merchantBillingReducer?.exports?.filename) {
      window.open(merchantBillingReducer?.exports?.filename, '_blank');
      dispatch(merchantBillingActions.resetPayoutsExportState());
    }
    if (merchantBillingReducer?.addForm) {
      setTimeout(() => {
        dispatch(merchantBillingActions.getMerchantBillingList(merchantPanel, selectedFilters));
      }, 1000);
      setdisplayMerchantPayoutForm(false);
    }
  }, [merchantBillingReducer, dispatch, merchantPanel, selectedFilters]);

  return (
    <div>
      <Box>
        <Card>
          <CardContent>
            {
              showFilters === false ? (
                <Grid container wrap="wrap" justify="space-between" style={{ justifyContent: 'flex-end' }}>
                  <Box>
                    <IconButton onClick={() => setShowFilters(!showFilters)}>
                      <FilterListIcon className={classes.iconStyle} />
                    </IconButton>
                    <span>
                      {intl.get('FILTER')}
                    </span>
                  </Box>
                </Grid>
              )
                : null
            }
            {showFilters ? (
              <Grid className={classes.filterContainer} container spacing={1} wrap="wrap" justify="space-between">
                <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
                  <Box m={1} display="flex" flexDirection="row" flexWrap="wrap" alignItems="flex-end" justifyContent="flex-start">
                    {!merchantPanel
                      ? (
                        <div>
                          <MerchantDropDown
                            i18nTag="MERCHANT"
                            minWidth={180}
                            defaultValue={selectedFilters.merchantUUID}
                            onChange={(e: string) => setSelectedFilters({ ...selectedFilters, merchantUUID: e })}
                            clearFilter={clearFilter}
                            margin="5px 10px"
                          />
                        </div>
                      ) : null}
                    <div>
                      <StatusDropDown
                        i18nTag="STATUS"
                        minWidth={180}
                        defaultValue={selectedFilters && selectedFilters.status}
                        onChange={(e: string) => setSelectedFilters({ ...selectedFilters, status: e })}
                        margin="5px 10px"
                        clearFilter={clearFilter}
                      />
                    </div>
                    <div>
                      <CurrencyDropDown
                        i18nTag="CURRENCY"
                        minWidth={180}
                        defaultValue={selectedFilters && selectedFilters.currency}
                        onChange={(e: string) => setSelectedFilters({ ...selectedFilters, currency: e })}
                        margin="5px 10px"
                        clearFilter={clearFilter}
                      />
                    </div>
                    <ThemeProvider theme={customDatePicker}>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <Box
                          display="flex"
                          flexDirection="row"
                          justifyContent="flex-start"
                          alignItems="center"
                        >
                          <span className={classes.dateLabel}>{intl.get('PAID_ON')}</span>
                          <DatePicker
                            disableToolbar
                            inputVariant="outlined"
                            format="MM/dd/yyyy"
                            margin="normal"
                            id="date-from"
                            value={selectedPaidOnDate}
                            className={classes.MuiInputBaseinput}
                            onChange={handlePaidOnDateChange}
                            style={{ marginRight: '10px' }}
                          />
                        </Box>
                      </MuiPickersUtilsProvider>
                    </ThemeProvider>
                    <span
                      className={classes.clearFilters}
                      onClick={() => { clearFilters(); setSelectedFilters({ status: '', merchantUUID: '' }); }}
                      aria-hidden="true"
                    >
                      {intl.get('CLEAR_ALL_FILTERS')}
                    </span>
                  </Box>
                  <IconButton
                    onClick={() => setShowFilters(!showFilters)}
                    style={{
                      position: 'absolute',
                      top: '-5px',
                      right: '-5px',
                    }}
                  >
                    <CloseIcon className={classes.iconStyle} />
                  </IconButton>
                </Box>
              </Grid>
            ) : null}
            <div style={{
              display: 'flex', flexDirection: 'row', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'space-between',
            }}
            >
              <Box
                display="flex"
                flexWrap="wrap"
                justifyContent="flex-start"
                alignItems="center"
              >
                <ThemeProvider theme={customDatePicker}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Box
                      display="flex"
                      flexDirection="row"
                      justifyContent="flex-start"
                      alignItems="center"
                    >
                      <span className={classes.dateLabel}>{intl.get('FROM')}</span>
                      <DatePicker
                        disableToolbar
                        inputVariant="outlined"
                        format="MM/dd/yyyy"
                        margin="normal"
                        id="date-from"
                        className={classes.MuiInputBaseinput}
                        value={selectedFromDate}
                        onChange={handleFromDateChange}
                        style={{ marginRight: '10px' }}
                      />
                      <span className={classes.dateLabel}>{intl.get('TO')}</span>
                      <DatePicker
                        disableToolbar
                        inputVariant="outlined"
                        format="MM/dd/yyyy"
                        margin="normal"
                        id="date-to"
                        className={classes.MuiInputBaseinput}
                        value={selectedToDate}
                        onChange={handleToDateChange}
                      />
                    </Box>
                  </MuiPickersUtilsProvider>
                </ThemeProvider>
                <div>
                  <Button
                    className={classes.export}
                    onClick={exportData}
                    variant="outlined"
                    size="small"
                    style={{ display: 'flex', alignItems: 'flex-end', borderRadius: '5px' }}
                  >
                    {intl.get('EXPORT')}
                    <img src={ExportIcon} alt="" width="25" />
                  </Button>
                </div>
                <div style={{ marginTop: '9px', marginLeft: '20px' }}>
                  <SearchInput width="150px" onChange={() => { console.log('hello world'); }} />
                </div>
              </Box>
              {
                merchantPanel ? (
                  <Box
                    display="flex"
                    justifyContent="flex-end"
                    alignItems="center"
                    style={{ marginTop: '10px' }}
                  >
                    <Button
                      style={{ background: '#3981f7', color: 'white' }}
                      variant="contained"
                      onClick={openPayoutForm}
                    >
                      {intl.get('REQUEST_PAYOUTS')}
                    </Button>
                  </Box>
                ) : null
              }
            </div>
          </CardContent>
          <PayoutForm openDialog={displayMerchantPayoutForm} closePayoutForm={closePayoutForm} />
        </Card>
      </Box>
    </div>
  );
};
