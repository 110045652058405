import { IMerchant } from './merchant.types';
import { IPaymentProvider } from './payment-provider.types';
import { Dates } from './common.types';
import { IAdminTransaction } from './admin-transaction.types';

/* eslint camelcase:  off */
export interface SettlementReport {
  id?: number;
  uuid?: string;
  merchant_id?: number;
  merchant?: IMerchant;
  batch_no?: string;
  date?: string;
  time?: string;
  bank_account_no?: number;
  payment_provider_id?: number;
  filename?: string;
  transactions_currency: string;
  payment_provider?: IPaymentProvider;
  transactions_amount: number;
  transactions_margin: number;
  transactions_handling_fee: number;
  refund_amount: number;
  refund_margin: number;
  refund_currency: string;
  refund_handling_fee: number;
  refund_chargeback_fee: number;
  refund_fee: number;
  settlement_currency: string;
  settlement_amount: number;
  settlement_rate_cost: number;
  settlement_rate_type: number;
  settlement_cost: number;
  settlement_tid: string;
  settlement_final_amount?: number;
  status: string;
  remark: string;
  code?: any;
  created_by_user?: number;
  updated_by_user?: number;
  completed_by_user?: number;
  settlement_start_date?: string;
  settlement_end_date?: string;
  settlement_complete_date?: string;
  deleted_at?: string;
  created_at?: string;
  updated_at?: string;
  transaction?: IAdminTransaction;
}

export const updateSettlementForm: SettlementReport = {
  transactions_currency: '',
  transactions_amount: 0,
  transactions_margin: 0,
  transactions_handling_fee: 0,
  refund_amount: 0,
  refund_margin: 0,
  refund_handling_fee: 0,
  refund_chargeback_fee: 0,
  refund_currency: '',
  refund_fee: 0,
  settlement_currency: '',
  settlement_amount: 0,
  settlement_rate_cost: 0,
  settlement_rate_type: 0,
  settlement_cost: 0,
  settlement_tid: '',
  status: '',
  remark: '',
};

export interface ISettlements {
  file: any;
  remark: string;
  merchant_uuid: string;
  payment_provider_id?: number;
}

export const settlementFrom: ISettlements = {
  file: '',
  remark: '',
  merchant_uuid: '',
  payment_provider_id: undefined,
};
export interface SettlementFilter {
  merchantUUID?: string;
  selectedDates?: Dates;
  settlement_currency?: string;
  status?: string;
  payment_provider_id?: string;
}

export interface Refunds {
  merchant_uuid: string;
  currency: string;
  amount: string;
  status: string | unknown;
  reason: string | unknown;
  transactionNo: string;
}

export const refundForm: Refunds = {
  merchant_uuid: '',
  currency: '',
  amount: '',
  status: 'Pending',
  reason: '10',
  transactionNo: '',
};
