import { Pagination } from '../../types/common.types';
import { IMerchantAccount } from '../../types/merchant-account.types';

interface Types {
  GET_MERCHANT_ACCOUNT_LIST_REQUEST: string
  GET_MERCHANT_ACCOUNT_LIST_SUCCESS: string
  GET_MERCHANT_ACCOUNT_LIST_FAILURE: string

  GET_MERCHANT_ACCOUNT_BY_ID_REQUEST: string
  GET_MERCHANT_ACCOUNT_BY_ID_SUCCESS: string
  GET_MERCHANT_ACCOUNT_BY_ID_FAILURE: string

  ADD_MERCHANT_ACCOUNT_REQUEST: string
  ADD_MERCHANT_ACCOUNT_SUCCESS: string
  ADD_MERCHANT_ACCOUNT_FAILURE: string

  UPDATE_MERCHANT_ACCOUNT_REQUEST: string
  UPDATE_MERCHANT_ACCOUNT_SUCCESS: string
  UPDATE_MERCHANT_ACCOUNT_FAILURE: string

  DELETE_MERCHANT_ACCOUNT_REQUEST: string
  DELETE_MERCHANT_ACCOUNT_SUCCESS: string
  DELETE_MERCHANT_ACCOUNT_FAILURE: string

}

export const merchantAccountConstants: Types = {

  GET_MERCHANT_ACCOUNT_LIST_REQUEST: 'GET_MERCHANT_ACCOUNT_LIST_REQUEST',
  GET_MERCHANT_ACCOUNT_LIST_SUCCESS: 'GET_MERCHANT_ACCOUNT_LIST_SUCCESS',
  GET_MERCHANT_ACCOUNT_LIST_FAILURE: 'GET_MERCHANT_ACCOUNT_LIST_FAILURE',

  GET_MERCHANT_ACCOUNT_BY_ID_REQUEST: 'GET_MERCHANT_ACCOUNT_BY_ID_REQUEST',
  GET_MERCHANT_ACCOUNT_BY_ID_SUCCESS: 'GET_MERCHANT_ACCOUNT_BY_ID_SUCCESS',
  GET_MERCHANT_ACCOUNT_BY_ID_FAILURE: 'GET_MERCHANT_ACCOUNT_BY_ID_FAILURE',

  ADD_MERCHANT_ACCOUNT_REQUEST: 'ADD_MERCHANT_ACCOUNT_REQUEST',
  ADD_MERCHANT_ACCOUNT_SUCCESS: 'ADD_MERCHANT_ACCOUNT_SUCCESS',
  ADD_MERCHANT_ACCOUNT_FAILURE: 'ADD_MERCHANT_ACCOUNT_FAILURE',

  UPDATE_MERCHANT_ACCOUNT_REQUEST: 'UPDATE_MERCHANT_ACCOUNT_REQUEST',
  UPDATE_MERCHANT_ACCOUNT_SUCCESS: 'UPDATE_MERCHANT_ACCOUNT_SUCCESS',
  UPDATE_MERCHANT_ACCOUNT_FAILURE: 'UPDATE_MERCHANT_ACCOUNT_FAILURE',

  DELETE_MERCHANT_ACCOUNT_REQUEST: 'DELETE_MERCHANT_ACCOUNT_REQUEST',
  DELETE_MERCHANT_ACCOUNT_SUCCESS: 'DELETE_MERCHANT_ACCOUNT_SUCCESS',
  DELETE_MERCHANT_ACCOUNT_FAILURE: 'DELETE_MERCHANT_ACCOUNT_FAILURE',

};

interface LoadGetMerchantAccountListAction {
  type: typeof merchantAccountConstants.GET_MERCHANT_ACCOUNT_LIST_REQUEST
  allMerchantAccountList: IMerchantAccount[]
  payload: IMerchantAccount
  uuid: string
  errors?: any;
  pagination: Pagination;
}

interface LoadedGetMerchantAccountListAction {
  type: typeof merchantAccountConstants.GET_MERCHANT_ACCOUNT_LIST_SUCCESS
  allMerchantAccountList: IMerchantAccount[]
  payload: IMerchantAccount
  uuid: string
  errors?: any;
  pagination: Pagination;
}

interface FailedGetMerchantAccountListAction {
  type: typeof merchantAccountConstants.GET_MERCHANT_ACCOUNT_LIST_FAILURE
  allMerchantAccountList: IMerchantAccount[]
  payload: IMerchantAccount
  uuid: string
  errors?: any;
  pagination: Pagination;
}

interface LoadGetMerchantAccountByIdAction {
  type: typeof merchantAccountConstants.GET_MERCHANT_ACCOUNT_BY_ID_REQUEST
  allMerchantAccountList: IMerchantAccount[]
  payload: IMerchantAccount
  uuid: string
  errors?: any;
  pagination: Pagination;
}

interface LoadedGetMerchantAccountByIdAction {
  type: typeof merchantAccountConstants.GET_MERCHANT_ACCOUNT_BY_ID_SUCCESS
  allMerchantAccountList: IMerchantAccount[]
  payload: IMerchantAccount
  uuid: string
  errors?: any;
  pagination: Pagination;
}

interface FailedGetMerchantAccountByIdAction {
  type: typeof merchantAccountConstants.GET_MERCHANT_ACCOUNT_BY_ID_FAILURE
  allMerchantAccountList: IMerchantAccount[]
  payload: IMerchantAccount
  uuid: string
  errors?: any;
  pagination: Pagination;
}

interface LoadAddMerchantAccountAction {
  type: typeof merchantAccountConstants.ADD_MERCHANT_ACCOUNT_REQUEST
  allMerchantAccountList: IMerchantAccount[]
  payload: IMerchantAccount
  uuid: string
  errors?: any;
  pagination: Pagination;
}

interface LoadedAddMerchantAccountAction {
  type: typeof merchantAccountConstants.ADD_MERCHANT_ACCOUNT_SUCCESS
  allMerchantAccountList: IMerchantAccount[]
  payload: IMerchantAccount
  uuid: string
  errors?: any;
  pagination: Pagination;
}

interface FailedAddMerchantAccountAction {
  type: typeof merchantAccountConstants.ADD_MERCHANT_ACCOUNT_FAILURE
  allMerchantAccountList: IMerchantAccount[]
  payload: IMerchantAccount
  uuid: string
  errors?: any;
  pagination: Pagination;
}

interface LoadUpdateMerchantAccountAction {
  type: typeof merchantAccountConstants.UPDATE_MERCHANT_ACCOUNT_REQUEST
  allMerchantAccountList: IMerchantAccount[]
  payload: IMerchantAccount
  uuid: string
  errors?: any;
  pagination: Pagination;
}

interface LoadedUpdateMerchantAccountAction {
  type: typeof merchantAccountConstants.UPDATE_MERCHANT_ACCOUNT_SUCCESS
  allMerchantAccountList: IMerchantAccount[]
  payload: IMerchantAccount
  uuid: string
  errors?: any;
  pagination: Pagination;
}

interface FailedUpdateMerchantAccountAction {
  type: typeof merchantAccountConstants.UPDATE_MERCHANT_ACCOUNT_FAILURE
  allMerchantAccountList: IMerchantAccount[]
  payload: IMerchantAccount
  uuid: string
  errors?: any;
  pagination: Pagination;
}

interface LoadDeleteMerchantAccountAction {
  type: typeof merchantAccountConstants.DELETE_MERCHANT_ACCOUNT_REQUEST
  allMerchantAccountList: IMerchantAccount[]
  payload: IMerchantAccount
  uuid: string
  errors?: any;
  pagination: Pagination;
}

interface LoadedDeleteMerchantAccountAction {
  type: typeof merchantAccountConstants.DELETE_MERCHANT_ACCOUNT_SUCCESS
  allMerchantAccountList: IMerchantAccount[]
  payload: IMerchantAccount
  uuid: string
  errors?: any;
  pagination: Pagination;
}

interface FailedDeleteMerchantAccountAction {
  type: typeof merchantAccountConstants.DELETE_MERCHANT_ACCOUNT_FAILURE
  allMerchantAccountList: IMerchantAccount[]
  payload: IMerchantAccount
  uuid: string
  errors?: any;
  pagination: Pagination;
}

export type MerchantAccountActionTypes
  = LoadGetMerchantAccountListAction
  | LoadedGetMerchantAccountListAction
  | FailedGetMerchantAccountListAction

  | LoadGetMerchantAccountByIdAction
  | LoadedGetMerchantAccountByIdAction
  | FailedGetMerchantAccountByIdAction

  | LoadAddMerchantAccountAction
  | LoadedAddMerchantAccountAction
  | FailedAddMerchantAccountAction

  | LoadUpdateMerchantAccountAction
  | LoadedUpdateMerchantAccountAction
  | FailedUpdateMerchantAccountAction

  | LoadDeleteMerchantAccountAction
  | LoadedDeleteMerchantAccountAction
  | FailedDeleteMerchantAccountAction;
