import React from 'react';
import { Transactions } from '../../AdminPanel/Transactions/Transactions';

export const MerchantTransactions = () => {
  const isMerchantPanel = true;
  return (
    <>
      <Transactions merchantPanel={isMerchantPanel} />
    </>
  );
};
