import React from 'react';
import intl from 'react-intl-universal';
import {
  TextField,
  InputAdornment,
  SvgIcon,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';

export const SearchInput = ({ value, width, onChange }: any) => {
  const [selectedValue, setSelectedValue] = React.useState(value);
  return (
    <div>
      <TextField
        style={{ width }}
        value={selectedValue}
        onChange={(e) => {
          onChange(e.target.value); setSelectedValue(e.target.value);
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SvgIcon
                fontSize="small"
                color="action"
              >
                <SearchIcon style={{ fill: '#3485f7' }} />
              </SvgIcon>
            </InputAdornment>
          ),
        }}
        placeholder={intl.get('SEARCH')}
        variant="outlined"
        size="small"
      />
    </div>
  );
};
