import React, { useEffect } from 'react';
import intl from 'react-intl-universal';
import {
  Box,
  Button,
  TextField,
  Grid,
} from '@material-ui/core';
import ArrowBackSharpIcon from '@material-ui/icons/ArrowBackSharp';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import { StyledSectionHeading } from '../../../components/StyledComponents/SectionHeading';
import page from '../../../assets/icons/pages.png';
import { RootState } from '../../../store/reducers';
import { settlementFrom } from '../../../types/settlement.types';
import { adminSettlementActions } from '../../../store/actions/admin-settlement.actions';
import { DisplaySnackbar } from '../../../components/Snackbar';
import { history } from '../../../helpers/index';
import { ProviderDropDown } from '../../../components/ProviderDropDown';
import { MerchantDropDown } from '../../../components/MerchantDropDown';

interface Types {
  merchantPanel: boolean;
}
const useStyles = makeStyles(() => createStyles({
  formControl: {
    minWidth: 250,
    width: '100%',
  },
  formInput: {
    minWidth: '350px',
    margin: '20px 0',
  },
  buttonRight: {
    background: '#3f51b5',
    color: 'white',
  },
  circularProgress: {
    color: '#3f51b5',
  },
  back: {
    marginRight: '30px',
    '&:hover': {
      color: '#3f51b5',
      cursor: 'pointer',
    },
  },
}));

const SettlementReportsForm = ({ merchantPanel }: Types) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const fileRef: React.RefObject<HTMLInputElement> = React.createRef();
  const [values, setValues] = React.useState({ ...settlementFrom });
  const [fileName, SetFileName] = React.useState('');
  const settlementReducer = useSelector((state: RootState) => state.adminSettlementReducer);
  const { loading } = settlementReducer;

  const onSubmit = () => {
    dispatch(adminSettlementActions.addSettlement({ ...values }));
  };

  const toBase64 = (file: any) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

  const handleUpload = async (file: any) => {
    const fileBase64 = await toBase64(file);
    setValues({ ...values, file: fileBase64 });
  };

  const onFileChange = (event: any) => {
    const file: File = event && event.target && event?.target?.files[0];
    SetFileName(file.name);
    handleUpload(file);
  };
  const backChange = () => {
    history.push('/dashboard/settlements');
  };
  useEffect(() => {
    if (settlementReducer.addForm) {
      setTimeout(() => {
        history.push('/dashboard/settlements');
      }, 3000);
    }
  }, [settlementReducer.addForm]);

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
        <Box className={classes.back}>
          <ArrowBackSharpIcon onClick={backChange} />
        </Box>
        <Box>
          <StyledSectionHeading>{intl.get('ADD_SETTLEMENT')}</StyledSectionHeading>
        </Box>
      </div>
      <div style={{ height: '100px' }}>
        {
          loading ? (
            <span style={{
              display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative',
            }}
            >
              <h3 style={{ marginRight: '30px' }}>{intl.get('FILE_UPLOAD_IN_PROGRESS')}</h3>
              <CircularProgress className={classes.circularProgress} />
            </span>
          ) : null
        }
      </div>
      <form
        autoComplete="off"
        noValidate
      >
        <Grid
          container
          spacing={2}
        >
          <Grid
            item
            sm={6}
            md={6}
            xs={12}
          >
            <MerchantDropDown
              i18nTag="MERCHANT"
              variant="outlined"
              width="100%"
              defaultValue={values && values.merchant_uuid}
              onChange={(e: string) => setValues({ ...values, merchant_uuid: e })}
            />
          </Grid>
          <Grid
            item
            sm={6}
            md={6}
            xs={12}
          >
            <ProviderDropDown
              i18nTag="PROVIDER"
              variant="outlined"
              width="100%"
              defaultValue={values && values.payment_provider_id}
              onChange={(e: number) => setValues({ ...values, payment_provider_id: e })}
              merchantPanel={merchantPanel}
            />
          </Grid>
          <Grid
            item
            sm={6}
            md={6}
            xs={12}
          >
            <TextField
              className={classes.formControl}
              placeholder={intl.get('BATCH_NO')}
              variant="outlined"
            // onChange={onFileChange}
            />
          </Grid>
          <Grid
            item
            sm={6}
            md={6}
            xs={12}
          >
            <TextField
              className={classes.formControl}
              placeholder={intl.get('REMARK')}
              variant="outlined"
              value={values.remark}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => setValues(
                { ...values, remark: (event.target as HTMLInputElement).value },
              )}
            />
          </Grid>
          <Grid
            item
            sm={6}
            md={6}
            xs={12}
            style={{ display: 'flex' }}
          >
            <input hidden type="file" onChange={onFileChange} ref={fileRef} />
            <Button
              className={classes.buttonRight}
              style={{ width: '80%' }}
              onClick={() => fileRef && fileRef.current && fileRef.current.click()}
            >
              {intl.get('UPLOAD_SETTLEMENT_FILE')}
            </Button>
            <span style={{ display: 'flex' }}>
              {' '}
              <img src={page} alt="" width="40px" />
            </span>
            <p>
              {fileName}
            </p>
          </Grid>
          <Grid
            item
            sm={6}
            md={6}
            xs={12}
          >
            <Button
              className={classes.buttonRight}
              style={{ width: '100%' }}
              variant="contained"
              onClick={onSubmit}
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </form>
      {settlementReducer && settlementReducer.isError
        ? settlementReducer.errorMessages && Object.values(settlementReducer.errorMessages).length
        && Object.values(settlementReducer.errorMessages).map(
          (error: any) => error?.length && error.map(
            (msg: string) => <DisplaySnackbar type="error" message={msg} />,
          ),
        ) : null}
      {
        settlementReducer && settlementReducer.addForm
          ? <DisplaySnackbar type="success" message={intl.get('ADDED_SUCCESSFULLY')} /> : null
      }
    </div>
  );
};
export default SettlementReportsForm;
