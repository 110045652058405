import React, { useEffect } from 'react';
import intl from 'react-intl-universal';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useDispatch, useSelector } from 'react-redux';
import { authActions } from '../../store/actions';
import { Copyright, DisplaySnackbar } from '../../components';
import { history, utils } from '../../helpers';
import { RootState } from '../../store/reducers';
import UserIcon from '../../assets/icons/User_Icon.png';
import './style.css';
import PasswordResusable from '../../components/PasswordResusable';

const useStyles = makeStyles((theme) => ({
  overrides: {
    MuiInput: {
      root: {
        background: 'black',
      },
    },
  },
  input: {
    '&:-webkit-autofill': {
      WebkitTextFillColor: 'white !important',
      borderWidth: '1px !important',
      borderColor: '#282454 !important',
    },
  },
  notchedOutline: {
    borderWidth: '1px',
    borderColor: '#282454 !important',
  },
  textfield: {
    border: '1px solid white',
    borderRadius: '3px',
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(5),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  welcome: {
    margin: '0',
    fontFamily: 'PlayfairDisplay',
    fontSize: '42px',
    fontWeight: 'bold',
    color: '#1c4268',
  },
  normalLable: {
    color: '#3485f7',
    fontFamily: 'PlayfairDisplay',
    fontSize: '18px',
    fontWeight: 'bold',
  },
  formLabel: {
    color: '#1c4268',
    fontSize: '15px',
    fontWeight: 'bold',
  },
  blueLable: {
    color: '#3485f7',
    fontSize: '15px',
    fontWeight: 'bold',
    marginLeft: '10px',
  },
}));

export default () => {
  const loginState = useSelector((state: RootState) => state.authentication);
  if (utils.isAuth() && !loginState.newPasswordUpdated) {
    history.push('/dashboard');
  }

  const classes = useStyles();
  const dispatch = useDispatch();
  const [resetPassword, setResetPassword] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [twoFAToken, setTwoFAToken] = React.useState(false);
  const [FAToken, setFAToken] = React.useState('');
  // const [isValidate, setIsValidate] = React.useState(false);
  const [values, setValues] = React.useState({
    email: '',
    password: '',
    inputValue: null,
    newPassword: '',
  });

  const handleClose = () => {
    setOpen(false);
  };

  const resetPasswordRequest = () => {
    dispatch(authActions.updatePassword(values.newPassword));
  };

  const validate = () => {
    dispatch(authActions.validate2FA({ two_fa_token: FAToken, two_fa_code: values.inputValue }));
  };

  const onSubmit = () => {
    if (!resetPassword) {
      dispatch(authActions.login(values.email, values.password));
    } else {
      dispatch(authActions.resetPasswordLink(values.email));
    }
  };

  useEffect(() => {
    if (loginState.loggedInUser && loginState.loggedInUser.FAToken) {
      setTwoFAToken(true);
      setFAToken(loginState.loggedInUser.FAToken);
      dispatch(authActions.resetState());
    }
    if (loginState && loginState.resetPassword) {
      setOpen(true);
      dispatch(authActions.resetState());
    }
    if (loginState && loginState.newPasswordUpdated) {
      setTimeout(() => {
        if (open) {
          setOpen(false);
          dispatch(authActions.resetState());
          history.push('/dashboard');
        }
      }, 3000);
    }
  }, [loginState, dispatch, open]);

  if (window.location.pathname.includes('login')) {
    document.body.style.backgroundColor = '#131128';
  } else {
    document.body.style.backgroundColor = '#fafafa';
  }

  return (
    <Container component="main" maxWidth="xs" style={{ marginTop: '7%', marginBottom: '5%' }} className="login-container">
      <CssBaseline />
      <h1 className="welcomeHeading">
        {intl.get('PLEASE_LOGIN')}
      </h1>
      <div className={classes.paper}>
        <Avatar className={classes.avatar} alt="icon" src={UserIcon} />
        <div className="smallHeading">{intl.get('SIGN_IN')}</div>
        {!twoFAToken ? (
          <form className={classes.form} noValidate>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <div className="inputLabel">{intl.get('EMAIL')}</div>
            </Box>
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              autoFocus
              required
              id="email"
              name="email"
              autoComplete="email"
              style={{ marginBottom: '20px' }}
              className="inputStyle"
              InputProps={{
                classes: {
                  input: classes.input,
                  notchedOutline: classes.notchedOutline,
                },
                style: {
                  color: '#fff',
                },
              }}
              onChange={(e) => setValues({
                email: e.target.value, password: values.password, inputValue: values.inputValue, newPassword: values.newPassword,
              })}
              onKeyDown={(e) => { if (e.key === 'Enter') { onSubmit(); } }}
            />
            {
              !resetPassword
                ? (
                  <>
                    <Box
                      display="flex"
                      flexDirection="row"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <div className="inputLabel">{intl.get('PASSWORD')}</div>
                      <div className={classes.blueLable} onClick={() => setResetPassword(true)} aria-hidden="true" style={{ cursor: 'pointer' }}>
                        {intl.get('FORGOT_PASSWORD')}
                        ?
                      </div>
                    </Box>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      name="password"
                      type="password"
                      id="password"
                      autoComplete="current-password"
                      className="inputStyle"
                      InputProps={{
                        classes: {
                          input: classes.input,
                          notchedOutline: classes.notchedOutline,
                        },
                        style: {
                          color: '#fff',
                        },
                      }}
                      onChange={(e) => setValues({
                        email: values.email, password: e.target.value, inputValue: values.inputValue, newPassword: values.newPassword,
                      })}
                      onKeyDown={(e) => { if (e.key === 'Enter') { onSubmit(); } }}
                    />

                    <Box
                      display="flex"
                      flexDirection="row"
                      justifyContent="space-between"
                    >
                      <FormControlLabel
                        control={(
                          <Checkbox
                            style={{
                              color: '#fff',
                            }}
                            value="remember"
                            color="secondary"
                          />
                        )}
                        label={<Typography className="inputLabel">{intl.get('KEEP_ME_LOGIN')}</Typography>}
                      />
                    </Box>
                  </>
                )
                : null
            }

            <button
              type="button"
              className="loginBtn"
              style={{ cursor: 'pointer' }}
              onClick={() => onSubmit()}
            >
              {!resetPassword ? intl.get('LOGIN') : intl.get('SEND_RESET_PASSWORD_LINK')}
            </button>

            <Grid container>
              <Grid item xs>
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                  style={{ marginTop: '40px' }}

                >
                  <div className="smallHeading">
                    {intl.get('DONT_HAVE_ACCOUNT')}
                  </div>

                  <Link href={`${process.env.REACT_APP_BASE_URL}/register`} variant="body2" className={classes.blueLable} style={{ cursor: 'pointer' }}>
                    {intl.get('SIGNUP')}
                  </Link>
                </Box>
              </Grid>
            </Grid>

            {resetPassword
              ? (
                <Grid container>
                  <Grid item xs>
                    <Box
                      display="flex"
                      flexDirection="row"
                      justifyContent="space-between"
                      style={{ marginTop: '40px' }}

                    >
                      <div className="smallHeading">
                        {intl.get('ALREADY_HAVE_ACCOUNT')}
                      </div>
                      <div onClick={() => setResetPassword(false)} className={classes.blueLable} aria-hidden="true" style={{ cursor: 'pointer' }}>
                        {intl.get('LOGIN')}
                      </div>
                    </Box>
                  </Grid>
                </Grid>
              ) : null}
          </form>
        )
          : (
            <Box>
              <div>
                <TextField
                  size="small"
                  onChange={(e: any) => setValues({
                    email: values.email, password: values.password, inputValue: e.target.value, newPassword: values.newPassword,
                  })}
                  defaultValue={values.inputValue}
                  placeholder={intl.get('ENTER_6_DIGITS_CODE')}
                  InputProps={{
                    style: {
                      color: '#fff',
                    },
                  }}
                  variant="outlined"
                  className={classes.textfield}
                />
              </div>
              <div>
                <Button
                  onClick={() => { validate(); }}
                  style={{
                    padding: '5px 60px 5px 60px', margin: '10px', background: '#f5f5f5', color: '#3f51b5', border: '1px solid #3f51b5',
                  }}
                  variant="contained"
                >
                  {intl.get('VALIDATE')}
                </Button>
              </div>
            </Box>
          )}
      </div>

      <div>
        <Dialog
          open={open}
          onClose={handleClose}
          disableBackdropClick
          maxWidth="sm"
          fullWidth
        >
          <DialogTitle>{intl.get('RESET_PASSWORD')}</DialogTitle>
          <DialogContent>
            <PasswordResusable changePassword={(e) => {
              setValues({ ...values, newPassword: e });
            }}
            />
            {/* <NewPasswordInput
              width="200px"
              value={values.newPassword}
              isSpecialCharacter={isSpecialCharacter}
              isUperCharacter={isUperCharacter}
              isLowerCharacter={isLowerCharacter}
              isNumber={isNumber}
              length={length}
              onChange={(e: string) => setPassValue(e)}
            />
            <div style={{ marginTop: '15px' }}>
              <Button
                variant="contained"
                size="small"
                style={{ background: '#f5f5f5', color: '#880a15', border: '1px solid #880a15' }}
                onClick={generatePass}
              >
                {intl.get('GENERATE_PASSWORD')}
              </Button>
            </div> */}

          </DialogContent>
          <DialogActions>

            <Button onClick={resetPasswordRequest} color="primary" autoFocus>
              {intl.get('CHANGE_PASSWORD')}
            </Button>
          </DialogActions>
        </Dialog>
      </div>

      <Box mt={8}>
        <Copyright />
      </Box>
      {
        loginState.isError && !loginState.faError
          ? <DisplaySnackbar type="error" message={intl.get('EMAIL_OR_PASSWORD_NOT_CORRECT')} /> : null
      }
      {loginState && !loginState.isError && loginState.faError
        ? loginState.errorMessages && Object.values(loginState.errorMessages).length
        && Object.values(loginState.errorMessages).map(
          (error: any) => error.length && error?.map(
            (msg: string) => <DisplaySnackbar type="error" message={msg} />,
          ),
        ) : null}

      {loginState && loginState.faError && !Object.values(loginState.errorMessages).length
        ? <DisplaySnackbar type="error" message={intl.get('INVALID_CODE')} /> : null}
      {
        loginState.resetLinkSent
          ? <DisplaySnackbar type="info" message={intl.get('RESET_PASSWORD_LINK_SENT')} /> : null
      }
      {
        loginState.newPasswordUpdated
          ? <DisplaySnackbar type="success" message={intl.get('PASSWORD_UPDATED')} /> : null
      }
    </Container>
  );
};
