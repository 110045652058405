import React, { useEffect } from 'react';
import intl from 'react-intl-universal';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Box } from '@material-ui/core';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import { makeStyles } from '@material-ui/core/styles';
import { RootState } from '../../../store/reducers';
import { authActions } from '../../../store/actions/auth.actions';
import { DisplaySnackbar } from '../../../components';
import Toolbar from './Toolbar';

const useStyles = makeStyles(() => ({
  linkStyle: {
    margin: '70px 0px 0px 0px',
    padding: '20px',
    border: '1px solid #3f51b5',
  },
}));

export const TwoFAAuthentication = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [state, setState] = React.useState({
    twoFA: false,
    inputValue: null,
    twoFAData: { secret: '', message: '', qrImage: '' },
  });
  const [message, setMessage] = React.useState('');
  useEffect(() => {
    dispatch(authActions.request2FA());
  }, [dispatch]);

  const loginState = useSelector((reducer: RootState) => reducer.authentication);
  useEffect(() => {
    if (loginState?.twoFAData?.secret && !state?.twoFAData?.secret) {
      setState({ ...state, twoFAData: loginState.twoFAData });
      dispatch(authActions.resetState());
    }
    if (loginState?.twoFAData?.message === '2FA already enabled.') {
      setState({ ...state, twoFAData: loginState.twoFAData, twoFA: true });
      dispatch(authActions.resetState());
    }
    if (loginState.isError || loginState.twoFAActivated) {
      setTimeout(() => {
        dispatch(authActions.resetState());
        setMessage('Something went wrong or Too Many request');
      }, 2000);
    }
  }, [loginState, dispatch, state]);

  const activate = () => {
    dispatch(authActions.activate2FA(state.inputValue));
  };
  return (
    <>
      <Toolbar />
      <div>
        {state.twoFAData.qrImage
          ? (
            <Box className={classes.linkStyle} display="inline-block" flexDirection="column" alignItems="center">
              <img width={200} src={state.twoFAData.qrImage} alt="QR" />
              <div style={{ marginTop: '20px' }}>{state.twoFAData.secret}</div>

              <Box>
                <div>
                  <OutlinedInput
                    style={{ margin: '40px 0px 30px 0px' }}
                    onChange={(e: any) => setState({ ...state, inputValue: e.target.value })}
                    defaultValue={state.inputValue}
                    placeholder={intl.get('ENTER_6_DIGITS_CODE')}
                  />
                </div>
                <Button
                  onClick={() => { activate(); }}
                  style={{
                    padding: '5px 75px 5px 75px', margin: '20px', background: '#f5f5f5', color: '#3f51b5', border: '1px solid #3f51b5',
                  }}
                  variant="contained"
                >
                  {intl.get('ACTIVATE')}
                </Button>
              </Box>
            </Box>
          ) : null}
      </div>
      {state?.twoFAData?.message === '2FA already enabled.' ? <div>{state?.twoFAData?.message}</div> : <div>{message}</div>}
      {loginState && !loginState.isError && loginState.faError
        ? loginState.errorMessages && Object.values(loginState.errorMessages).length
        && Object.values(loginState.errorMessages).map(
          (error: any) => error?.length && error?.map(
            (msg: string) => <DisplaySnackbar type="error" message={msg} />,
          ),
        ) : null}

      {loginState && loginState.faError && !Object.values(loginState.errorMessages).length
        ? <DisplaySnackbar type="error" message={intl.get('INVALID_CODE')} /> : null}
      {loginState && loginState.twoFAActivated
        ? <DisplaySnackbar type="success" message={intl.get('UPDATED_SUCCESSFULLY')} /> : null}
    </>
  );
};
