import { Dispatch } from 'redux';
import { AxiosError } from 'axios';
import { merchantConstants } from '../constants';
import { merchantService } from '../../services';
import { IMerchant, MerchantFilter, MerchantPsp } from '../../types/merchant.types';
import { Pagination } from '../../types/common.types';
import { AccountOptions } from '../../types/merchant-billing.types';

const getMerchantList = (paginationData?: Pagination, selectedFilters?: MerchantFilter) => {
  const request = () => ({ type: merchantConstants.GET_MERCHANT_LIST_REQUEST });
  const success = (allMerchantList: IMerchant[], pagination: Pagination) => (
    { type: merchantConstants.GET_MERCHANT_LIST_SUCCESS, allMerchantList, pagination }
  );
  const failure = (error: any) => ({ type: merchantConstants.GET_MERCHANT_LIST_FAILURE, error });
  return (dispatch: Dispatch) => {
    dispatch(request());
    merchantService.getMerchantList(paginationData, selectedFilters)
      .then(
        (res) => {
          dispatch(success(res.data.data, res.data));
        },
      ).catch((error) => {
        dispatch(failure(error.message));
      });
  };
};

const getMerchantById = (uuid: string) => {
  const request = () => ({ type: merchantConstants.GET_MERCHANT_BY_ID_REQUEST });
  const success = (payload: IMerchant) => (
    { type: merchantConstants.GET_MERCHANT_BY_ID_SUCCESS, payload }
  );
  const failure = (error: any) => ({ type: merchantConstants.GET_MERCHANT_BY_ID_FAILURE, error });
  return (dispatch: Dispatch) => {
    dispatch(request());
    merchantService.getMerchantById(uuid)
      .then(
        (res) => {
          dispatch(success(res.data));
        },
      ).catch((error: AxiosError) => dispatch(failure(error.response?.data?.errors || [])));
  };
};

const addMerchant = (body: IMerchant) => {
  const request = () => ({ type: merchantConstants.ADD_MERCHANT_REQUEST });
  const success = (payload: IMerchant[]) => ({ type: merchantConstants.ADD_MERCHANT_SUCCESS, payload });
  const failure = (errors: any) => ({ type: merchantConstants.ADD_MERCHANT_FAILURE, errors });
  return (dispatch: Dispatch) => {
    dispatch(request());
    merchantService.addMerchant(body)
      .then(
        (res) => {
          dispatch(success(res.data));
        },
      ).catch((error: AxiosError) => dispatch(failure(error.response?.data?.errors || [])));
  };
};

const updateMerchant = (body: any) => {
  const request = () => ({ type: merchantConstants.UPDATE_MERCHANT_REQUEST });
  const success = (payload: IMerchant[]) => ({ type: merchantConstants.UPDATE_MERCHANT_SUCCESS, payload });
  const failure = (errors: any) => ({ type: merchantConstants.UPDATE_MERCHANT_FAILURE, errors });
  return (dispatch: Dispatch) => {
    dispatch(request());
    merchantService.updateMerchant(body)
      .then(
        (res) => {
          dispatch(success(res.data));
        },
      ).catch((error: AxiosError) => dispatch(failure(error.response?.data?.errors || [])));
  };
};

const deleteMerchant = (selectedUUID: string) => {
  const request = () => ({ type: merchantConstants.DELETE_MERCHANT_REQUEST });
  const success = (uuid: string) => (
    {
      type: merchantConstants.DELETE_MERCHANT_SUCCESS, uuid,
    }
  );
  const failure = (error: any) => ({ type: merchantConstants.DELETE_MERCHANT_FAILURE, error });
  return (dispatch: Dispatch) => {
    dispatch(request());
    merchantService.deleteMerchant(selectedUUID)
      .then(
        () => {
          dispatch(success(selectedUUID));
        },
      ).catch((error: AxiosError) => dispatch(failure(error.response?.data?.errors || [])));
  };
};

const getMerchantPspList = () => {
  const request = () => ({ type: merchantConstants.GET_MERCHANT_PSP_LIST_REQUEST });
  const success = (merchantPspList: MerchantPsp[]) => (
    { type: merchantConstants.GET_MERCHANT_PSP_LIST_SUCCESS, merchantPspList }
  );
  const failure = (error: any) => ({ type: merchantConstants.GET_MERCHANT_PSP_LIST_FAILURE, error });
  return (dispatch: Dispatch) => {
    dispatch(request());
    merchantService.getMerchantPspList()
      .then(
        (res) => {
          dispatch(success(res.data.data));
        },
      ).catch((error: AxiosError) => dispatch(failure(error.response?.data?.errors || [])));
  };
};

const getMerchantPspById = (id: number) => {
  const request = () => ({ type: merchantConstants.GET_MERCHANT_PSP_BY_ID_REQUEST });
  const success = (merchantPspDetails: MerchantPsp) => (
    { type: merchantConstants.GET_MERCHANT_PSP_BY_ID_SUCCESS, merchantPspDetails }
  );
  const failure = (error: any) => ({ type: merchantConstants.GET_MERCHANT_PSP_BY_ID_FAILURE, error });
  return (dispatch: Dispatch) => {
    dispatch(request());
    merchantService.getMerchantPspById(id)
      .then(
        (res) => {
          dispatch(success(res.data));
        },
      ).catch((error: AxiosError) => dispatch(failure(error.response?.data?.errors || [])));
  };
};

const addMerchantPsp = (body: MerchantPsp) => {
  const request = () => ({ type: merchantConstants.ADD_MERCHANT_PSP_REQUEST });
  const success = (merchantPspDetails: MerchantPsp) => ({ type: merchantConstants.ADD_MERCHANT_PSP_SUCCESS, merchantPspDetails });
  const failure = (errors: any) => ({ type: merchantConstants.ADD_MERCHANT_PSP_FAILURE, errors });
  return (dispatch: Dispatch) => {
    dispatch(request());
    merchantService.addMerchantPsp(body)
      .then(
        (res) => {
          dispatch(success(res.data));
        },
      ).catch((error: AxiosError) => dispatch(failure(error.response?.data?.errors || [])));
  };
};

const updateMerchantPsp = (body: MerchantPsp, id: number | undefined) => {
  const request = () => ({ type: merchantConstants.UPDATE_MERCHANT_PSP_REQUEST });
  const success = (merchantPspDetails: MerchantPsp) => ({ type: merchantConstants.UPDATE_MERCHANT_PSP_SUCCESS, merchantPspDetails });
  const failure = (errors: any) => ({ type: merchantConstants.UPDATE_MERCHANT_PSP_FAILURE, errors });
  return (dispatch: Dispatch) => {
    dispatch(request());
    merchantService.updateMerchantPsp(body, id)
      .then(
        (res) => {
          dispatch(success(res.data));
        },
      ).catch((error: AxiosError) => dispatch(failure(error.response?.data?.errors || [])));
  };
};

const deleteMerchantPsp = (selectedId: number) => {
  const request = () => ({ type: merchantConstants.DELETE_MERCHANT_PSP_REQUEST });
  const success = (selectedPspId: number) => (
    {
      type: merchantConstants.DELETE_MERCHANT_PSP_SUCCESS, selectedPspId,
    }
  );
  const failure = (error: any) => ({ type: merchantConstants.DELETE_MERCHANT_PSP_FAILURE, error });
  return (dispatch: Dispatch) => {
    dispatch(request());
    merchantService.deleteMerchantPsp(selectedId)
      .then(
        () => {
          dispatch(success(selectedId));
        },
      ).catch((error: AxiosError) => dispatch(failure(error.response?.data?.errors || [])));
  };
};

const getPspByMerchantUUID = (uuid: string) => {
  const request = () => ({ type: merchantConstants.GET_PSP_BY_MERCHANT_UUID_REQUEST });
  const success = (merchantPspList: MerchantPsp[]) => (
    { type: merchantConstants.GET_PSP_BY_MERCHANT_UUID_SUCCESS, merchantPspList }
  );
  const failure = (error: any) => ({ type: merchantConstants.GET_PSP_BY_MERCHANT_UUID_FAILURE, error });
  return (dispatch: Dispatch) => {
    dispatch(request());
    merchantService.getPspByMerchantUUID(uuid)
      .then(
        (res) => {
          dispatch(success(res.data.data));
        },
      ).catch((error) => {
        dispatch(failure(error.message));
      });
  };
};

const merchantOptions = () => {
  const request = () => ({ type: merchantConstants.MERCHANTS_PROVIDER_REQUEST });
  const success = (merchantOptions: AccountOptions[]) => (
    { type: merchantConstants.MERCHANTS_PROVIDER_SUCCESS, merchantOptions }
  );
  const failure = (error: any) => ({ type: merchantConstants.MERCHANTS_PROVIDER_FAILURE, error });
  return (dispatch: Dispatch) => {
    dispatch(request());
    merchantService.merchantOptions()
      .then(
        (res) => {
          dispatch(success(res.data));
        },
      ).catch((error) => {
        dispatch(failure(error.message));
      });
  };
};

export const merchantActions = {
  getMerchantList,
  getMerchantById,
  addMerchant,
  updateMerchant,
  deleteMerchant,
  getMerchantPspList,
  getMerchantPspById,
  addMerchantPsp,
  updateMerchantPsp,
  deleteMerchantPsp,
  getPspByMerchantUUID,
  merchantOptions,
};
