import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  Paper,
  // IconButton,
  // Collapse,
  // Box,
  // Divider,
  // Grid,
} from '@material-ui/core';
import {
  makeStyles, Theme, createStyles,
} from '@material-ui/core/styles';
import intl from 'react-intl-universal';
// import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
// import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { RootState } from '../../../store/reducers';
import { IAdminTransaction } from '../../../types/admin-transaction.types';
import { BooleanLabel } from '../../../components';
import { adminTransactionActions } from '../../../store/actions';

interface Props {
  merchantPanel: boolean;
}
const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    marginTop: '10px',
  },
  main: {
    marginTop: '15px',
  },
  table: {
    backgroundColor: '#EDEDED',
  },
  tableHead: {
    fontSize: '12px',
    fontWeight: 'bold',
    color: '#0f0e3b',
    borderBottom: '1px solid #707070',
  },
  tableBody: {
    fontSize: '11px',
    color: '#0f0e3b',
    borderBottom: '1px solid #707070',
  },
  EditIcon: {
    color: 'black',
    cursor: 'pointer',
    '&:hover': {
      color: 'blue',
    },
  },
  deleteIcon: {
    color: 'black',
    cursor: 'pointer',
    '&:hover': {
      color: 'red',
    },
  },
  expandIcon: {
    backgroundColor: '#ffffff',
    borderRadius: '5px',
  },
  tableBodyCell: {
    fontSize: '12px',
  },
  expandedPanel: {
    [theme.breakpoints.up('sm')]: {
      width: '850px',
      right: '23px',
      padding: '40px',
    },
    right: 0,
    padding: '20px',
    backgroundColor: '#2c333d',
    position: 'absolute',
    zIndex: 9999,
    borderRadius: '8px',
    fontSize: '12px',
  },
  divider: {
    background: '#fff',
    margin: '10px auto',
  },
  detailPanelSection: {
    padding: theme.spacing(2),
  },
  detailPanelInput: {
    padding: '5px',
    backgroundColor: '#3d4651',
    minWidth: '80px',
    textAlign: 'center',
  },
  detailPanelInputError: {
    padding: '5px',
    backgroundColor: 'red',
    minWidth: '80px',
    textAlign: 'center',
  },
  detailPanelCell: {
    marginBottom: '20px',
  },
}));

export default ({ merchantPanel }: Props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const transactionState = useSelector((state: RootState) => state.adminTransactionReducer);
  const transactionList: IAdminTransaction[] = transactionState.allAdminTransactionList || [];
  // const [open, setOpen] = React.useState(false);
  // const [expandedTransactionId, setExpandedTransactionId] = React.useState<string | undefined>('');
  const [paginationPar] = React.useState({ limit: 10, current_page: 1 });

  useEffect(() => {
    dispatch(adminTransactionActions.getAdminTransactionList(merchantPanel, paginationPar));
  }, [dispatch, merchantPanel, paginationPar]);

  return (
    <div className={classes.main}>
      <TableContainer
        component={Paper}
        style={{ display: 'flex' }}
        className={classes.table}
      >
        <Table size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableHead}>{intl.get('ORDER_NUMBER')}</TableCell>
              <TableCell align="center" className={classes.tableHead}>{intl.get('AMOUNT')}</TableCell>
              <TableCell align="center" className={classes.tableHead}>{intl.get('CURRENCY')}</TableCell>
              <TableCell align="center" className={classes.tableHead}>{intl.get('SUCCESS')}</TableCell>
              <TableCell align="center" className={classes.tableHead}>{intl.get('CREATED_AT')}</TableCell>
              {/* <TableCell align="center" className={classes.tableHead}>{intl.get('ACTIONS')}</TableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {transactionList
              && transactionList.map((row: IAdminTransaction) => (
                <>
                  <TableRow key={row.uuid} style={{ position: 'relative' }}>
                    <TableCell>{row.order_no}</TableCell>
                    <TableCell>{Number(row?.amount)?.toFixed(4)}</TableCell>
                    <TableCell>{row.currency}</TableCell>
                    <TableCell><BooleanLabel label={row?.success} /></TableCell>
                    <TableCell>
                      {row.created_at && `${new Date(row.created_at).toLocaleDateString()} ${new Date(row.created_at).toLocaleTimeString()}`}
                    </TableCell>
                    {/* <TableCell align="center">
                      <IconButton
                        className={classes.expandIcon}
                        aria-label="expand row"
                        size="small"
                        onClick={() => { setOpen(!open); setExpandedTransactionId(row.uuid); }}
                      >
                        {open && expandedTransactionId === row.uuid ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                      </IconButton>
                    </TableCell> */}
                  </TableRow>
                  {/* {open && expandedTransactionId === row.uuid
                    ? (
                      <TableRow>
                        <TableCell
                          style={{
                            paddingBottom: 0, paddingTop: 0, backgroundColor: '#2c333d', color: '#fff',
                          }}
                          colSpan={12}
                        >
                          <Collapse in={open && expandedTransactionId === row.uuid} timeout="auto" unmountOnExit>
                            <div className={classes.expandedPanel}>
                              <Box
                                display="flex"
                                justifyContent="space-around"
                                flexDirection="row"
                                flexWrap="wrap"
                              >
                                <Grid
                                  item
                                  md={5}
                                >
                                  <div className={classes.detailPanelSection}>
                                    <div className={classes.detailPanelCell}>
                                      <Box
                                        display="flex"
                                        flexDirection="row"
                                        justifyContent="space-between"
                                        alignItems="center"
                                      >
                                        <span>{intl.get('ORDER_NUMBER')}</span>
                                        <div className={classes.detailPanelInput}>{row.order_no}</div>
                                      </Box>
                                      <Divider classes={{ root: classes.divider }} />
                                    </div>
                                  </div>
                                  <div className={classes.detailPanelSection}>
                                    <div className={classes.detailPanelCell}>
                                      <Box
                                        display="flex"
                                        flexDirection="row"
                                        justifyContent="space-between"
                                        alignItems="center"
                                      >
                                        <span>{intl.get('BASE_CURRENCY')}</span>
                                        <div className={classes.detailPanelInput}>{row.base_currency}</div>
                                      </Box>
                                      <Divider classes={{ root: classes.divider }} />
                                    </div>
                                  </div>
                                  <div className={classes.detailPanelSection}>
                                    <div className={classes.detailPanelCell}>
                                      <Box
                                        display="flex"
                                        flexDirection="row"
                                        justifyContent="space-between"
                                        alignItems="center"
                                      >
                                        <span>{intl.get('BASE_AMOUNT')}</span>
                                        <div className={classes.detailPanelInput}>{Number(row?.amount)?.toFixed(4)}</div>
                                      </Box>
                                      <Divider classes={{ root: classes.divider }} />
                                    </div>
                                  </div>
                                </Grid>
                                <Grid
                                  item
                                  md={5}
                                >
                                  <div className={classes.detailPanelSection}>
                                    <div className={classes.detailPanelCell}>
                                      <Box
                                        display="flex"
                                        flexDirection="row"
                                        justifyContent="space-between"
                                        alignItems="center"
                                      >
                                        <span>{intl.get('EXCHANGE_RATE')}</span>
                                        <div className={classes.detailPanelInput}>{row?.exchange_rate}</div>
                                      </Box>
                                      <Divider classes={{ root: classes.divider }} />
                                    </div>
                                  </div>
                                  <div className={classes.detailPanelSection}>
                                    <div className={classes.detailPanelCell}>
                                      <Box
                                        display="flex"
                                        flexDirection="row"
                                        justifyContent="space-between"
                                        alignItems="center"
                                      >
                                        <span>{intl.get('MARGIN_RATE')}</span>
                                        <div className={classes.detailPanelInput}>{row?.merchant?.margin_rate || 'N/A'}</div>
                                      </Box>
                                      <Divider classes={{ root: classes.divider }} />
                                    </div>
                                  </div>
                                  <div className={classes.detailPanelSection}>
                                    <div className={classes.detailPanelCell}>
                                      <Box
                                        display="flex"
                                        flexDirection="row"
                                        justifyContent="space-between"
                                        alignItems="center"
                                      >
                                        <span>{intl.get('MARGIN_TYPE')}</span>
                                        <div className={classes.detailPanelInput}>{row?.merchant?.margin_type || 'N/A'}</div>
                                      </Box>
                                      <Divider classes={{ root: classes.divider }} />
                                    </div>
                                  </div>
                                </Grid>
                                {
                                  row && row.error ? (
                                    <Grid
                                      item
                                      md={11}
                                    >
                                      <div className={classes.detailPanelSection}>
                                        <div className={classes.detailPanelCell}>
                                          <Box
                                            display="flex"
                                            flexDirection="row"
                                            justifyContent="space-between"
                                            alignItems="center"
                                          >
                                            <span>Error</span>
                                            <div className={classes.detailPanelInputError}>{row.error}</div>
                                          </Box>
                                          <Divider classes={{ root: classes.divider }} />
                                        </div>
                                      </div>
                                    </Grid>
                                  ) : null
                                }
                                <Grid
                                  item
                                  md={11}
                                >
                                  <div style={{ paddingRight: '20px' }}>
                                    <Box
                                      display="flex"
                                      flexDirection="row"
                                      justifyContent="flex-end"
                                    >
                                      <span>{`${intl.get('UPDATED_AT')} ${new Date(row.created_at).toLocaleDateString()}`}</span>
                                    </Box>
                                  </div>

                                </Grid>
                              </Box>
                            </div>
                          </Collapse>
                        </TableCell>
                      </TableRow>
                    ) : null} */}
                </>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};
