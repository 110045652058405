import { MerchantAccountActionTypes, merchantAccountConstants } from '../constants';
import { IMerchantAccount, MerchantAccountState } from '../../types/merchant-account.types';
import { Pagination } from '../../types/common.types';

export interface IState {
  loading: boolean
  isError: boolean
  allMerchantAccountList: IMerchantAccount[]
  merchantAccountDetails: IMerchantAccount
  updateError: boolean,
  pagination: Pagination;
  isUpdated: boolean,
  isDeleted: boolean,
  errorMessages: string[];
}

const initialState: IState = {
  loading: false,
  isError: false,
  allMerchantAccountList: [],
  updateError: false,
  pagination: { limit: 10, current_page: 1 },
  isUpdated: false,
  isDeleted: false,
  merchantAccountDetails: MerchantAccountState,
  errorMessages: [],
};

export const merchantAccountReducer = (state = initialState, action: MerchantAccountActionTypes): IState => {
  switch (action.type) {
    case merchantAccountConstants.GET_MERCHANT_ACCOUNT_LIST_REQUEST:
      return {
        ...state, loading: true, isError: false, isUpdated: false, isDeleted: false, errorMessages: [],
      };
    case merchantAccountConstants.GET_MERCHANT_ACCOUNT_LIST_SUCCESS:
      return {
        ...state, loading: false, isError: false, allMerchantAccountList: action.allMerchantAccountList, pagination: action.pagination,
      };
    case merchantAccountConstants.GET_MERCHANT_ACCOUNT_LIST_FAILURE:
      return {
        ...state, loading: false, isError: true,
      };

    case merchantAccountConstants.GET_MERCHANT_ACCOUNT_BY_ID_REQUEST:
      return {
        ...state, loading: true, isError: false, errorMessages: [],
      };
    case merchantAccountConstants.GET_MERCHANT_ACCOUNT_BY_ID_SUCCESS:
      return {
        ...state, loading: false, isError: false, merchantAccountDetails: action.payload,
      };
    case merchantAccountConstants.GET_MERCHANT_ACCOUNT_BY_ID_FAILURE:
      return {
        ...state, loading: false, isError: true,
      };

    case merchantAccountConstants.ADD_MERCHANT_ACCOUNT_REQUEST:
      return {
        ...state, loading: true, updateError: false, isUpdated: false, errorMessages: [],
      };
    case merchantAccountConstants.ADD_MERCHANT_ACCOUNT_SUCCESS:
      return {
        ...state,
        loading: false,
        updateError: false,
        isUpdated: true,
        allMerchantAccountList: [...state.allMerchantAccountList, action.payload],
      };
    case merchantAccountConstants.ADD_MERCHANT_ACCOUNT_FAILURE:
      return {
        ...state, loading: false, updateError: true, errorMessages: action.errors,
      };

    case merchantAccountConstants.UPDATE_MERCHANT_ACCOUNT_REQUEST:
      return {
        ...state, loading: true, updateError: false, isUpdated: false, errorMessages: [],
      };
    case merchantAccountConstants.UPDATE_MERCHANT_ACCOUNT_SUCCESS: {
      const merchantAccountIndex = state.allMerchantAccountList.findIndex((merchantAccount) => merchantAccount.uuid === action.payload.uuid);
      const updatedList = [...state.allMerchantAccountList];
      updatedList[merchantAccountIndex] = action.payload;
      return {
        ...state,
        loading: false,
        updateError: false,
        isUpdated: true,
        allMerchantAccountList: updatedList,
      };
    }
    case merchantAccountConstants.UPDATE_MERCHANT_ACCOUNT_FAILURE:
      return {
        ...state, loading: false, updateError: true, errorMessages: action.errors,
      };

    case merchantAccountConstants.DELETE_MERCHANT_ACCOUNT_REQUEST:
      return {
        ...state, loading: true, isError: false, isDeleted: false, errorMessages: [],
      };
    case merchantAccountConstants.DELETE_MERCHANT_ACCOUNT_SUCCESS:
      return {
        ...state,
        loading: false,
        isError: false,
        isDeleted: true,
        allMerchantAccountList: state.allMerchantAccountList.filter((item) => item.uuid !== action.uuid),
      };
    case merchantAccountConstants.DELETE_MERCHANT_ACCOUNT_FAILURE:
      return {
        ...state, loading: false, isError: true,
      };

    default:
      return state;
  }
};
