import { Dates } from './common.types';

/* eslint camelcase:  off */
export interface PayoutData {
  total_payouts?: number;
  total_payouts_amount: number;
  unpaid_payouts?: number;
  unpaid_payouts_amount: number;
  total_transactions?: number;
  total_transactions_amount: number;
}

export const PayoutDataState: PayoutData = {
  total_payouts: 0,
  total_payouts_amount: 0,
  unpaid_payouts: 0,
  unpaid_payouts_amount: 0,
  total_transactions: 0,
  total_transactions_amount: 0,
};

export interface ProviderBalance {
  code: string;
  count: number;
  balance: number;
}

export interface PaymentMethod {
  payment_method: string,
  total: number,
  amount: number,
}

export interface DailyTransaction {
  date: string,
  total_success: number,
  total_failed: number,
  amount_success: number,
  amount_failed: number,
}

export interface PayoutFilter {
  status?: string;
  merchantUUID?: string;
  selectedDates?: Dates;
  currency?: string;
  paidOn?: string;
}

interface Amount {
  currency: string
  amount: number
  merchantName?: string
}

interface Average {
  currency: string
  amount: number
}
export interface WidgetTransactions {
  transactions: number;
  successful: number;
  amounts: Amount[];
  averages: Average[]
}

export const TransactionDataState: WidgetTransactions = {
  transactions: 0,
  successful: 0,
  amounts: [],
  averages: [],

};
export interface WidgetError {
  error: string
  total: number
}

export interface Merchant {
  uuid: string
  name?: string
}

export interface MerchantBalances {
  merchant: Merchant,
  currency: string,
  amount: number,
}

export interface Balances {
  currency: string;
  amount: number;
  reserved: number;
}
