import React from 'react';
import intl from 'react-intl-universal';
import {
  Grid,
} from '@material-ui/core';
import { StyledSectionHeading } from '../../../components';

export const Toolbar: React.FC = () => {
  return (
    <div>
      <Grid container spacing={1} wrap="wrap" justify="space-between">
        <StyledSectionHeading style={{ fontSize: '28px' }}>{intl.get('DASHBOARD')}</StyledSectionHeading>
      </Grid>
    </div>
  );
};
