import React, { useState, useEffect } from 'react';
import intl from 'react-intl-universal';
import { useSelector, useDispatch } from 'react-redux';
import {
  // Button,
  // Grid,
  Dialog,
  // DialogActions,
  DialogContent,
} from '@material-ui/core';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { adminTransactionActions } from '../../../store/actions';
import { RootState } from '../../../store/reducers';
import { DisplaySnackbar } from '../../index';
import { MerchantPsp } from '../../../types/merchant.types';
import { PspForm } from '../index';

interface IProps {
  openDialog: boolean, closeMerchantAccountForm: () => void;
  selectedMerchantPsp: MerchantPsp | null;
  merchantUUID: string;
  merchantPanel: boolean;
}

const useStyles = makeStyles((theme: Theme) => createStyles({
  dialogContainer: {
    [theme.breakpoints.up('md')]: {
      marginLeft: '165px',
    },
    marginTop: '100px',
  },
  button: {
    margin: theme.spacing(1),
  },
  backBtn: {
    borderRadius: '5px',
    position: 'absolute',
    top: '-80px',
    left: 0,
    height: '20px',
  },
}));

export const MerchantPspForm: React.FC<IProps> = ({
  openDialog,
  closeMerchantAccountForm,
  selectedMerchantPsp,
  merchantUUID,
  merchantPanel,
}: IProps): React.ReactElement => {
  const classes = useStyles();
  // const [form, setForm] = useState(MerchantPspInitialState);
  const [open, setOpen] = useState(openDialog);
  // const [submitForm, setSubmitForm] = useState(false);
  // const [selectMerchantAccountUUID, setSelectMerchantAccountUUID] = useState<string>('');

  const dispatch = useDispatch();
  // const onSubmit = () => {
  //   // setSubmitForm(true);
  //   if (selectedMerchantPsp) {
  //     // const forUpdate = { Ids: { merchantUUID, merchantAccountUUID: selectMerchantAccountUUID }, form };
  //     // dispatch(merchantAccountActions.updateMerchantAccount(forUpdate, merchantPanel));
  //   } else {
  //     // dispatch(merchantAccountActions.addMerchantAccount({ merchantUUID, form }, merchantPanel));
  //   }
  // };
  const merchantReducer = useSelector((state: RootState) => state.merchantReducer);

  /**
     * This will run when merchantAccount reducer updates
     */
  useEffect(() => {
    if (merchantReducer.isUpdated) {
      setTimeout(() => {
        setOpen(false);
      }, 2000);
    }
  }, [merchantReducer]);

  /**
     * This will run when we open/close merchantAccount form dialog
     */
  useEffect(() => {
    setOpen(openDialog);
  }, [openDialog]);

  /**
     * This will check if merchantAccount is being adding or editing
     */
  useEffect(() => {
    dispatch(adminTransactionActions.getPaymentMethodOptions(merchantPanel));
    dispatch(adminTransactionActions.getProviderOptions(merchantPanel));
    // if (selectedMerchantPsp) {
    //   // setSelectMerchantAccountUUID(selectedMerchantPsp.uuid || '');
    //   setForm({ ...selectedMerchantPsp });
    // }
  }, [dispatch, selectedMerchantPsp, merchantPanel]);

  return (
    <div>
      {merchantReducer && merchantReducer.updateError
        ? merchantReducer.errorMessages && Object.values(merchantReducer.errorMessages).length
        && Object.values(merchantReducer.errorMessages).map(
          (error: any) => error?.length && error.map(
            (msg: string) => <DisplaySnackbar type="error" message={msg} />,
          ),
        ) : null}
      {merchantReducer && merchantReducer.isUpdated
        ? <DisplaySnackbar type="success" message={intl.get('UPDATED_SUCCESSFULLY')} /> : null}
      <div>
        <Dialog
          fullWidth
          maxWidth="sm"
          open={open}
          onClose={closeMerchantAccountForm}
          aria-labelledby="form-dialog-title"
          className={classes.dialogContainer}
          PaperProps={{
            style: {
              backgroundColor: '#2c333d',
              boxShadow: 'none',
              overflow: 'inherit',
            },
          }}
        >
          <DialogContent>
            <PspForm
              closeMerchantAccountForm={closeMerchantAccountForm}
              selectedMerchantPsp={selectedMerchantPsp}
              merchantUUID={merchantUUID}
            />
          </DialogContent>
          {/* <DialogActions>
            <Grid
              item
            >
              <Button
                size="small"
                color="default"
                variant="contained"
                onClick={() => onSubmit()}
              >
                {intl.get('SAVE')}
              </Button>
              <Button
                size="small"
                color="default"
                variant="contained"
                style={{ marginLeft: '20px' }}
                onClick={closeMerchantAccountForm}
              >
                {intl.get('CLOSE')}
              </Button>
            </Grid>
          </DialogActions> */}
        </Dialog>
      </div>
    </div>
  );
};
