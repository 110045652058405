import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import intl from 'react-intl-universal';
import './App.css';
import {
  Router, Switch, Route, Redirect,
} from 'react-router-dom';
import LoginPage from './modules/Login/LoginPage';
import ResetPassword from './modules/Login/ResetPassword';
import { authActions } from './store/actions';
import Layout from './modules/Layout/Layout';
import { PrivateRoute } from './components';
import { history, utils } from './helpers';

const App = () => {
  const dispatch = useDispatch();
  if (!utils.isAuth()) {
    dispatch(authActions.logout());
  }
  const [selectedLanguage, setSelectedLanguage] = useState('');

  useEffect(() => {
    /**
     * Load selected lang strings
     */
    const lang = localStorage.getItem('selectedLocale') || 'en';
    const resetPasswordToken = localStorage.getItem('resetPasswordToken') || null;
    const visitedReset = localStorage.getItem('visitedReset') || null;
    if (resetPasswordToken && !visitedReset) {
      history.push('/reset-password');
    }
    import(`./locales/${lang}.json`)
      .then((res) => {
        intl.init({
          currentLocale: lang,
          locales: {
            [lang]: res.default,
          },
        });
        setSelectedLanguage(lang);
      });
  }, [selectedLanguage]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const lang = event.target.checked ? 'zh' : 'en';
    localStorage.setItem('selectedLocale', lang);
    setSelectedLanguage(lang);
    /**
     * Reload the app to load the i18n content in the whole app
     */
    window.location.reload();
    // window.location.search = `?lang=${lang}`;
  };

  return (
    <div className="App">
      <div>
        <Router history={history}>
          <Switch>
            <Route path="/login" component={LoginPage} />
            <Route exact path="/reset-password" component={ResetPassword} />
            <PrivateRoute path="/dashboard" component={Layout} />
            <Redirect from="*" to="/dashboard" />
          </Switch>
        </Router>
      </div>
      <div className="lang-switch-container">
        <label className="switch" htmlFor="togBtn">
          <input type="checkbox" id="togBtn" checked={selectedLanguage === 'zh'} onChange={handleChange} />
          <div className="slider round" />
        </label>
      </div>
    </div>
  );
};

export default App;
