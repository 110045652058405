import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import intl from 'react-intl-universal';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import {
  Card,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  ThemeProvider,
  TablePagination,
  Collapse,
} from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import DeleteIcon from '@material-ui/icons/Delete';
import { merchantAccountActions } from '../../../store/actions';
import { RootState } from '../../../store/reducers';
import { DisplaySnackbar, MerchantAccountForm, MerchantAccountEdit } from '../../../components';
import { IMerchantAccount, MerchantAccountState } from '../../../types/merchant-account.types';
import { tableTheme } from '../../../assets/styles/table-style';
import { ConfirmationBox } from '../../../components/ConfirmationBox';

interface IMerchantAccountTypes {
  merchantAccounts: IMerchantAccount[];
  merchantUUID: string;
  merchantPanel: boolean;
  changePaginationOptions: (e: any) => void;
}

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    margin: '10px auto',
  },
  table: {
    minWidth: 650,
  },
  tableHeadCell: {
    fontSize: '12px',
    fontWeight: 700,
  },
  tableBodyCell: {
    color: '#232931',
    fontSize: '12px',
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  expandIcon: {
    backgroundColor: '#ffffff',
    borderRadius: '5px',
  },
  iconStyle: {
    fill: '#3485f7',
  },
  checkBox: {
    backgroundColor: '#fff',
    border: 'none',
  },
}));

export default ({
  merchantAccounts, merchantUUID, merchantPanel, changePaginationOptions,
}: IMerchantAccountTypes) => {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);
  const [expandedAccountId, setExpandedAccountId] = React.useState<string | undefined>('');
  const [displayMerchantAccountForm, setdisplayMerchantAccountForm] = React.useState(false);
  const [confirmation, setConfirmation] = React.useState(false);
  const [selectedMerchantAccount, setSelectedMerchantAccount] = React.useState(MerchantAccountState);
  const merchantReducerState = useSelector((state: RootState) => state.merchantAccountReducer);
  const { total } = merchantReducerState.pagination;
  const [pagination, setPagination] = React.useState({
    limit: 10,
    current_page: 1,
  });
  const handleChangePage = (event: unknown, newPage: number) => {
    setPagination({
      ...pagination,
      current_page: ++newPage,
    });
    changePaginationOptions({ ...pagination, current_page: newPage });
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPagination({
      ...pagination,
      limit: +event.target.value,
      current_page: 1,
    });
    changePaginationOptions({ ...pagination, limit: +event.target.value });
  };
  const handleExpandClick = (merchantAccount: IMerchantAccount) => {
    setExpanded(!expanded);
    setExpandedAccountId(merchantAccount.uuid);
    setSelectedMerchantAccount(merchantAccount);
  };
  const dispatch = useDispatch();
  const merchantAccountState = useSelector((state: RootState) => state.merchantAccountReducer);

  const onDelete = (uuid: any) => {
    dispatch(merchantAccountActions.deleteMerchantAccount({ merchantUUID, merchantAccountUUID: uuid }, merchantPanel));
  };

  const closeMerchantAccountForm = () => {
    setdisplayMerchantAccountForm(false);
    setExpanded(false);
    setExpandedAccountId('');
    setSelectedMerchantAccount(MerchantAccountState);
  };

  const openConfirmationBox = () => {
    setConfirmation(true);
  };

  const closeConfirmationBox = () => {
    setConfirmation(false);
  };
  return (
    <>
      <Card className={classes.root}>
        <TableContainer component={Paper}>
          <ThemeProvider theme={tableTheme}>
            <Table className={classes.table} aria-label="billing list">
              <TableHead>
                <TableRow>
                  <TableCell />
                  <TableCell className={classes.tableHeadCell}>{intl.get('DISPLAY_NAME')}</TableCell>
                  <TableCell className={classes.tableHeadCell}>{intl.get('BANK_NAME')}</TableCell>
                  <TableCell className={classes.tableHeadCell}>{intl.get('ACCOUNT_NUMBER')}</TableCell>
                  <TableCell className={classes.tableHeadCell}>{intl.get('NAME_ON_ACCOUNT')}</TableCell>
                  <TableCell className={classes.tableHeadCell}>{intl.get('TRANSACTION_LIMIT')}</TableCell>
                  <TableCell className={classes.tableHeadCell}>{intl.get('CURRENCY')}</TableCell>
                  {/* <TableCell className={classes.tableHeadCell}>{intl.get('COUNTRY')}</TableCell> */}
                  <TableCell className={classes.tableHeadCell} />
                </TableRow>
              </TableHead>
              <TableBody>
                {merchantAccounts
                  && merchantAccounts.map((row: IMerchantAccount) => (
                    <>
                      <TableRow key={row.uuid}>
                        <TableCell />
                        <TableCell className={classes.tableBodyCell}>{row.display_name}</TableCell>
                        <TableCell className={classes.tableBodyCell}>{row.bank_name}</TableCell>
                        <TableCell className={classes.tableBodyCell}>{row.account_number}</TableCell>
                        <TableCell className={classes.tableBodyCell}>{row.name_on_account}</TableCell>
                        <TableCell className={classes.tableBodyCell}>{row.transaction_limit}</TableCell>
                        <TableCell className={classes.tableBodyCell}>{row.currency}</TableCell>
                        {/* <TableCell className={classes.tableBodyCell}>{row.bank_country_code}</TableCell> */}
                        <TableCell align="center" style={{ padding: 0 }}>
                          <IconButton onClick={() => openConfirmationBox()} aria-label="delete">
                            <DeleteIcon className={classes.iconStyle} />
                          </IconButton>
                          <IconButton
                            className={classes.expandIcon}
                            aria-label="expand row"
                            size="small"
                            onClick={() => handleExpandClick(row)}
                          >
                            {expanded && row.uuid === expandedAccountId ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                          </IconButton>
                        </TableCell>
                      </TableRow>
                      {expanded && row.uuid === expandedAccountId
                        ? (
                          <TableRow>
                            <TableCell
                              style={{
                                paddingBottom: 0, paddingTop: 0, backgroundColor: '#2c333d', color: '#fff', borderRadius: '10px',
                              }}
                              colSpan={12}
                            >
                              <Collapse in={expanded && row.uuid === expandedAccountId} timeout="auto" unmountOnExit>
                                <MerchantAccountEdit
                                  closeMerchantAccountForm={closeMerchantAccountForm}
                                  selectedMerchantAccount={selectedMerchantAccount}
                                  merchantUUID={merchantUUID}
                                  merchantPanel={merchantPanel}
                                />
                              </Collapse>
                            </TableCell>
                          </TableRow>
                        ) : null}
                      <ConfirmationBox openDialog={confirmation} closeConfirmationBox={closeConfirmationBox} submitConfirmationBox={() => onDelete(row.uuid)} />
                    </>
                  ))}
              </TableBody>
            </Table>
          </ThemeProvider>
        </TableContainer>
        {total && merchantAccounts && merchantAccounts.length
          ? (
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={total}
              rowsPerPage={pagination.limit}
              page={pagination.current_page - 1}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          )
          : null}
      </Card>

      <MerchantAccountForm
        openDialog={displayMerchantAccountForm}
        closeMerchantAccountForm={closeMerchantAccountForm}
        selectedMerchantAccount={selectedMerchantAccount}
        merchantUUID={merchantUUID}
        merchantPanel={merchantPanel}
      />
      {merchantAccountState && merchantAccountState.isDeleted
        ? <DisplaySnackbar type="success" message={intl.get('DELETED_SUCCESSFULLY') || 'Deleted successfully'} /> : null}
    </>
  );
};
