import { MerchantBillingActionTypes, merchantBillingConstants } from '../constants';
import {
  BillingPayment, IMerchantBilling, IMerchantBillingState, AccountOptions, PayoutTransaction, ExportData,
} from '../../types/merchant-billing.types';

export interface IState {
  loading: boolean;
  isError: boolean;
  merchantBillingList: IMerchantBilling[];
  merchantBillingDetails: IMerchantBilling;
  updateError: boolean;
  isUpdated: boolean;
  isDeleted: boolean,
  billingPayments?: BillingPayment[];
  accountOptions: AccountOptions[];
  payoutTransactions: PayoutTransaction[];
  exports: ExportData;
  addForm: boolean;
  errorMessages: string[]
}

const initialState: IState = {
  loading: false,
  isError: false,
  merchantBillingList: [],
  updateError: false,
  isUpdated: false,
  merchantBillingDetails: IMerchantBillingState,
  accountOptions: [],
  payoutTransactions: [],
  isDeleted: false,
  exports: { filename: '' },
  addForm: false,
  errorMessages: [],
};

export const merchantBillingReducer = (state = initialState, action: MerchantBillingActionTypes): IState => {
  switch (action.type) {
    case merchantBillingConstants.GET_MERCHANT_BILLING_LIST_REQUEST:
      return {
        ...state, loading: true, addForm: false, isError: false, isUpdated: false, isDeleted: false,
      };
    case merchantBillingConstants.GET_MERCHANT_BILLING_LIST_SUCCESS:
      return {
        ...state, loading: false, isError: false, merchantBillingList: action.merchantBillingList,
      };
    case merchantBillingConstants.GET_MERCHANT_BILLING_LIST_FAILURE:
      return {
        ...state, loading: false, isError: true,
      };

    case merchantBillingConstants.GET_PAYOUTS_EXPORT_REQUEST:
      return {
        ...state, loading: true, addForm: false, isError: false, isUpdated: false, isDeleted: false,
      };
    case merchantBillingConstants.GET_PAYOUTS_EXPORT_SUCCESS:
      return {
        ...state, loading: false, isError: false, exports: action.exports,
      };
    case merchantBillingConstants.GET_PAYOUTS_EXPORT_FAILURE:
      return {
        ...state, loading: false, isError: true,
      };

    case merchantBillingConstants.RESET_EXPORT_DATA_STATE:
      return {
        ...state, loading: false, addForm: false, isError: false, isUpdated: false, isDeleted: false, exports: { filename: '' },
      };

    case merchantBillingConstants.GET_MERCHANT_BILLING_BY_ID_REQUEST:
      return {
        ...state, loading: true, addForm: false, isError: false, isDeleted: false,
      };
    case merchantBillingConstants.GET_MERCHANT_BILLING_BY_ID_SUCCESS:
      return {
        ...state, loading: false, isError: false, merchantBillingDetails: action.payload,
      };
    case merchantBillingConstants.GET_MERCHANT_BILLING_BY_ID_FAILURE:
      return {
        ...state, loading: false, isError: true,
      };

    case merchantBillingConstants.ADD_MERCHANT_BILLING_REQUEST:
      return {
        ...state, loading: true, addForm: false, updateError: false, isUpdated: false,
      };
    case merchantBillingConstants.ADD_MERCHANT_BILLING_SUCCESS:
      return {
        ...state,
        loading: false,
        updateError: false,
        isUpdated: true,
      };
    case merchantBillingConstants.ADD_MERCHANT_BILLING_FAILURE:
      return {
        ...state, loading: false, updateError: true,
      };

    case merchantBillingConstants.UPDATE_MERCHANT_BILLING_REQUEST:
      return {
        ...state, loading: true, addForm: false, updateError: false, isUpdated: false,
      };
    case merchantBillingConstants.UPDATE_MERCHANT_BILLING_SUCCESS: {
      const merchantBillingIndex = state.merchantBillingList.findIndex((merchantBilling) => merchantBilling.uuid === action.payload.uuid);
      const updatedList = [...state.merchantBillingList];
      updatedList[merchantBillingIndex] = action.payload;
      return {
        ...state,
        loading: false,
        updateError: false,
        isUpdated: true,
        merchantBillingList: updatedList,
      };
    }
    case merchantBillingConstants.UPDATE_MERCHANT_BILLING_FAILURE:
      return {
        ...state, loading: false, updateError: true,
      };

    case merchantBillingConstants.GET_MERCHANT_ACCOUNT_OPTIONS_REQUEST:
      return {
        ...state, loading: true, addForm: false, isError: false, isUpdated: false,
      };
    case merchantBillingConstants.GET_MERCHANT_ACCOUNT_OPTIONS_SUCCESS:
      return {
        ...state, loading: false, isError: false, accountOptions: action.accountOptions,
      };
    case merchantBillingConstants.GET_MERCHANT_ACCOUNT_OPTIONS_FAILURE:
      return {
        ...state, loading: false, isError: true,
      };

    case merchantBillingConstants.GET_PAYOUT_TRANSACTION_LIST_REQUEST:
      return {
        ...state, loading: true, addForm: false, isError: false, isUpdated: false,
      };
    case merchantBillingConstants.GET_PAYOUT_TRANSACTION_LIST_SUCCESS:
      return {
        ...state, loading: false, isError: false, payoutTransactions: action.payoutTransactions,
      };
    case merchantBillingConstants.GET_PAYOUT_TRANSACTION_LIST_FAILURE:
      return {
        ...state, loading: false, isError: true,
      };

    case merchantBillingConstants.ADD_PAYOUT_ACCOUNT_REQUEST:
      return {
        ...state, loading: true, addForm: false, updateError: false, isUpdated: false,
      };
    case merchantBillingConstants.ADD_PAYOUT_ACCOUNT_SUCCESS:
      return {
        ...state,
        loading: false,
        updateError: false,
        isUpdated: true,
        addForm: true,
      };
    case merchantBillingConstants.ADD_PAYOUT_ACCOUNT_FAILURE:
      return {
        ...state, loading: false, updateError: true,
      };

    case merchantBillingConstants.DELETE_PAYOUT_ACCOUNT_REQUEST:
      return {
        ...state, loading: true, addForm: false, isDeleted: false, isUpdated: false, isError: false,
      };
    case merchantBillingConstants.DELETE_PAYOUT_ACCOUNT_SUCCESS: {
      const updatedAccounts = state.merchantBillingDetails?.accounts?.filter((account) => account.uuid !== action.uuid);
      return {
        ...state,
        loading: false,
        isDeleted: true,
        merchantBillingDetails: { ...state.merchantBillingDetails, accounts: updatedAccounts },
      };
    }
    case merchantBillingConstants.DELETE_PAYOUT_ACCOUNT_FAILURE:
      return {
        ...state, loading: false, isError: true,
      };

    case merchantBillingConstants.ADD_PAYOUTS_ACCOUNT_REQUEST:
      return {
        ...state, loading: true, addForm: false, isError: false, errorMessages: [],
      };
    case merchantBillingConstants.ADD_PAYOUTS_ACCOUNT_SUCCESS:
      return {
        ...state,
        loading: false,
        isError: false,
        addForm: true,
        errorMessages: [],
        merchantBillingDetails: action.payload,
      };
    case merchantBillingConstants.ADD_PAYOUTS_ACCOUNT_FAILURE:
      return {
        ...state, loading: false, isError: true, addForm: false, errorMessages: action.errors,
      };

    default:
      return state;
  }
};
