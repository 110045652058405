import jwtDecode from 'jwt-decode';

const isAuth = () => {
  const auth = JSON.parse(localStorage.getItem('auth') || '{}');
  const decoded: any = auth?.access_token && jwtDecode(auth.access_token);
  const now = new Date();
  if (decoded && now.getTime() < decoded.exp * 1000) {
    return true;
  }
  return false;
};

const userType = () => {
  const auth = JSON.parse(localStorage.getItem('auth') || '{}');
  return auth?.role;
};

export const utils = {
  isAuth,
  userType,
};
