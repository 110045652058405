import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
// eslint-disable-next-line no-unused-vars
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';

const Alert = (props: AlertProps) => <MuiAlert elevation={6} variant="filled" {...props} />;

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));

interface ISnackbar {
  type: 'success' | 'info' | 'warning' | 'error',
  message: string
}

export const DisplaySnackbar = (props: ISnackbar) => {
  const classes = useStyles();
  const { type, message } = props;
  const [open, setOpen] = React.useState(true);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className={classes.root}>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={type}>{message}</Alert>
      </Snackbar>
    </div>
  );
};
