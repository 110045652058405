import React from 'react';
import { useSelector } from 'react-redux';
import PageHeader from '../../../components/StyledComponents/PageHeader';
import { RootState } from '../../../store/reducers';
import { SettlementReport } from '../../../types/settlement.types';
import Toolbar from './Toolbar';
import TransactionReportsList from './TransactionReportsList';

interface Types {
  merchantPanel: boolean;
}

const TransactionReports = ({ merchantPanel }: Types) => {
  const [paginationPar, setPaginationPar] = React.useState({ limit: 10, current_page: 1 });
  const settlementReducer = useSelector((state: RootState) => state.adminSettlementReducer);
  const settlementList: SettlementReport[] = settlementReducer.allSettlementList || [];
  return (
    <div>
      <PageHeader
        label="SETTLEMENTS"
        componentToPassDown={<Toolbar merchantPanel={merchantPanel} pagination={paginationPar} />}
      />
      <TransactionReportsList merchantPanel={merchantPanel} settlement={settlementList} changePaginationOptions={(e) => setPaginationPar(e)} />
    </div>
  );
};

export default TransactionReports;
