import { API } from '../../config';

const getMerchant = () => API.get('/merchant/merchants');

const updateMerchant = (body: any) => API.post('/merchant/merchants', body.form);

const merchantCurrency = () => API.get('/merchant/options/balances');

export const merchantService = {
  getMerchant,
  updateMerchant,
  merchantCurrency,
};
