import React, { useEffect } from 'react';
import intl from 'react-intl-universal';
import {
  Box,
  Button,
  TextField,
} from '@material-ui/core';
import {
  useParams,
} from 'react-router-dom';
import ArrowBackSharpIcon from '@material-ui/icons/ArrowBackSharp';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { StyledSectionHeading } from '../../../components/StyledComponents/SectionHeading';
import { updateSettlementForm } from '../../../types/settlement.types';
import { RootState } from '../../../store/reducers';
import { adminSettlementActions } from '../../../store/actions/admin-settlement.actions';
import { DisplaySnackbar } from '../../../components/Snackbar';
import Badges from '../../../components/Badges';
import StatusButton from '../../../components/StatusButton';
import { history } from '../../../helpers';

const useStyles = makeStyles(() => createStyles({
  formControl: {
    minWidth: '350px',
    margin: '20px 0',
  },
  formInput: {
    minWidth: '350px',
    margin: '20px 0',
  },
  buttonRight: {
    background: '#3f51b5',
    color: 'white',
    margin: '5px 0',
    minWidth: '350px',
  },
  button: {
    background: '#3f51b5',
    color: 'white',
    fontSize: '10px',
    fontWeight: 'bold',
    margin: '10px',
    padding: '7px 60px',
  },
  back: {
    marginRight: '30px',
    '&:hover': {
      color: '#3f51b5',
      cursor: 'pointer',
    },
  },
}));

type RouteParams = {
  settlementUUID: string;
}
interface Types {
  merchantPanel: boolean;
}
export const EditSettlementReportForm = ({ merchantPanel }: Types) => {
  const classes = useStyles();
  const params = useParams<RouteParams>();
  const dispatch = useDispatch();
  const [values, setValues] = React.useState({ ...updateSettlementForm });
  const settlementReducer = useSelector((state: RootState) => state.adminSettlementReducer);

  useEffect(() => {
    if (values && !values.id) {
      setValues({ ...settlementReducer.settlementForEdit });
    }
  }, [dispatch, values, settlementReducer.settlementForEdit]);

  useEffect(() => {
    dispatch(adminSettlementActions.settlementDetails(merchantPanel, `${params.settlementUUID}`));
  }, [params.settlementUUID, dispatch, merchantPanel]);

  const submit = (status: string) => {
    dispatch(adminSettlementActions.updateSettlement({ ...values, status }, settlementReducer.settlementForEdit.uuid));
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValues({
      ...values,
      [(event.target as HTMLInputElement).name]: (event.target as HTMLInputElement).value,
    });
  };
  const backChange = () => {
    history.push('/dashboard/settlements');
  };
  useEffect(() => {
    if (settlementReducer.updateForm) {
      setTimeout(() => {
        history.push('/dashboard/settlements');
      }, 1000);
    }
  }, [settlementReducer.updateForm]);

  return (
    <div>
      {settlementReducer && settlementReducer.updateError
        ? <DisplaySnackbar type="error" message={intl.get('SOMETHING_WENT_WRONG') || 'Something went wrong'} /> : null}
      {
        settlementReducer && settlementReducer.updateForm
          ? <DisplaySnackbar type="success" message={intl.get('UPDATED_SUCCESSFULLY') || 'Updated successfully'} /> : null
      }
      <Box display="flex" alignItems="center">
        <Box className={classes.back}>
          <ArrowBackSharpIcon onClick={backChange} />
        </Box>
        <StyledSectionHeading>{intl.get('SETTLEMENT')}</StyledSectionHeading>
        {settlementReducer.settlementForEdit?.filename && settlementReducer.settlementForEdit?.status ? (
          <span style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <h3 style={{ marginLeft: '20px' }}>
              (
              {settlementReducer.settlementForEdit?.filename}
              )
            </h3>
            <Badges style={{ marginRight: '30px' }} status={settlementReducer.settlementForEdit?.status} />
          </span>
        ) : null}
      </Box>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <div style={{ width: '65%' }}>
          <div>
            <h3 style={{ textAlign: 'left' }}>{intl.get('TRANSACTIONS')}</h3>
            <div
              style={{
                display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', alignItems: 'center',
              }}
            >
              <Box>
                <TextField
                  className={classes.formInput}
                  placeholder={intl.get('TRANSACTION_CURRENCY')}
                  id="outlined-multiline-flexible"
                  label="TRANSACTION CURRENCY"
                  variant="outlined"
                  size="small"
                  value={values.transactions_currency}
                  onChange={handleChange}
                  name="transactions_currency"
                />
              </Box>
              <Box>
                <TextField
                  className={classes.formInput}
                  placeholder={intl.get('TRANSACTION_AMOUNT')}
                  id="outlined-multiline-flexible"
                  label="TRANSACTION AMOUNT"
                  variant="outlined"
                  size="small"
                  onChange={handleChange}
                  value={values.transactions_amount}
                  name="transactions_amount"
                />
              </Box>
            </div>
            <div
              style={{
                display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', alignItems: 'center',
              }}
            >
              <Box>
                <TextField
                  className={classes.formInput}
                  placeholder={intl.get('TRANSACTION_MARGIN')}
                  id="outlined-multiline-flexible"
                  label="TRANSACTION MARGIN"
                  variant="outlined"
                  size="small"
                  value={values.transactions_margin}
                  onChange={handleChange}
                  name="transactions_margin"
                />
              </Box>
              <Box>
                <TextField
                  className={classes.formInput}
                  placeholder={intl.get('TRANSACTION_HANDLING_FEE')}
                  id="outlined-multiline-flexible"
                  label="TRANSACTION HANDLING FEE"
                  variant="outlined"
                  size="small"
                  value={values.transactions_handling_fee}
                  onChange={handleChange}
                  name="transactions_handling_fee"
                />
              </Box>
            </div>
          </div>
          <div>
            <h3 style={{ textAlign: 'left' }}>{intl.get('REFUNDS')}</h3>
            <div
              style={{
                display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', alignItems: 'center',
              }}
            >
              <Box>
                <TextField
                  className={classes.formInput}
                  placeholder={intl.get('REFUND_CURRENCY')}
                  id="outlined-multiline-flexible"
                  label="REFUND CURRENCY"
                  variant="outlined"
                  size="small"
                  value={values.refund_currency}
                  onChange={handleChange}
                  name="refund_currency"
                />
              </Box>
              <Box>
                <TextField
                  className={classes.formInput}
                  placeholder={intl.get('REFUND_AMOUNT')}
                  id="outlined-multiline-flexible"
                  label="REFUND AMOUNT"
                  variant="outlined"
                  size="small"
                  value={values.refund_amount}
                  onChange={handleChange}
                  name="refund_amount"
                />
              </Box>
            </div>
            <div
              style={{
                display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', alignItems: 'center',
              }}
            >
              <Box>
                <TextField
                  className={classes.formInput}
                  placeholder={intl.get('REFUND_MARGIN')}
                  id="outlined-multiline-flexible"
                  label="REFUND MARGIN"
                  variant="outlined"
                  size="small"
                  value={values.refund_margin}
                  onChange={handleChange}
                  name="refund_margin"
                />
              </Box>
              <Box>
                <TextField
                  className={classes.formInput}
                  placeholder={intl.get('REFUND_HANDLING_FEE')}
                  id="outlined-multiline-flexible"
                  label="REFUND HANDLING FEE"
                  variant="outlined"
                  size="small"
                  value={values.refund_handling_fee}
                  onChange={handleChange}
                  name="refund_handling_fee"
                />
              </Box>
            </div>
            <div
              style={{
                display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', alignItems: 'center',
              }}
            >
              <Box>
                <TextField
                  className={classes.formInput}
                  placeholder={intl.get('REFUND_CHARGEBACK_FEE')}
                  variant="outlined"
                  id="outlined-multiline-flexible"
                  label="REFUND CHARGE BACK FEE"
                  size="small"
                  value={values.refund_chargeback_fee}
                  onChange={handleChange}
                  name="refund_chargeback_fee"
                />
              </Box>
              <Box>
                <TextField
                  className={classes.formInput}
                  placeholder={intl.get('REFUND_FEE')}
                  id="outlined-multiline-flexible"
                  label="REFUND FEE"
                  variant="outlined"
                  size="small"
                  value={values.refund_fee}
                  onChange={handleChange}
                  name="refund_fee"
                />
              </Box>
            </div>
          </div>
          <div>
            <h3 style={{ textAlign: 'left' }}>{intl.get('SETTLEMENT')}</h3>
            <div
              style={{
                display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', alignItems: 'center',
              }}
            >
              <Box>
                <TextField
                  className={classes.formInput}
                  placeholder={intl.get('SETTLEMENT_CURRENCY')}
                  id="outlined-multiline-flexible"
                  label="SETTLEMENT CURRENCY"
                  variant="outlined"
                  size="small"
                  value={values.settlement_currency}
                  onChange={handleChange}
                  name="settlement_currency"
                />
              </Box>
              <Box>
                <TextField
                  className={classes.formInput}
                  placeholder={intl.get('SETTLEMENT_AMOUNT')}
                  id="outlined-multiline-flexible"
                  label="SETTLEMENT AMOUNT"
                  variant="outlined"
                  size="small"
                  value={values.settlement_amount}
                  onChange={handleChange}
                  name="settlement_amount"
                />
              </Box>
            </div>
            <div
              style={{
                display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', alignItems: 'center',
              }}
            >
              <Box>
                <TextField
                  className={classes.formInput}
                  placeholder={intl.get('SETTLEMENT_RATE_COST')}
                  id="outlined-multiline-flexible"
                  label="SETTLEMENT RATE COST"
                  variant="outlined"
                  size="small"
                  name="settlement_rate_cost"
                  value={values.settlement_rate_cost}
                  onChange={handleChange}
                />
              </Box>
              <Box>
                <TextField
                  className={classes.formInput}
                  placeholder={intl.get('SETTLEMENT_RATE_TYPE')}
                  id="outlined-multiline-flexible"
                  label="SETTLEMENT RATE TYPE"
                  variant="outlined"
                  size="small"
                  value={values.settlement_rate_type}
                  onChange={handleChange}
                  name="settlement_rate_type"
                />
              </Box>
            </div>
            <div
              style={{
                display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', alignItems: 'center',
              }}
            >
              <Box>
                <TextField
                  className={classes.formInput}
                  placeholder={intl.get('SETTLEMENT_COST')}
                  id="outlined-multiline-flexible"
                  label="SETTLEMENT COST"
                  variant="outlined"
                  size="small"
                  value={values.settlement_cost}
                  onChange={handleChange}
                  name="settlement_cost"
                />
              </Box>
              <Box>
                <TextField
                  className={classes.formInput}
                  placeholder={intl.get('SETTLEMENT_TID')}
                  id="outlined-multiline-flexible"
                  label="SETTLEMENT TID"
                  variant="outlined"
                  size="small"
                  value={values.settlement_tid}
                  onChange={handleChange}
                  name="settlement_tid"
                />
              </Box>
            </div>
            <div>
              <Box>
                <TextField
                  className={classes.formInput}
                  placeholder={intl.get('REMARK')}
                  id="outlined-multiline-flexible"
                  label="REMARK"
                  variant="outlined"
                  fullWidth
                  size="small"
                  value={values.remark}
                  onChange={handleChange}
                  name="remark"
                />
              </Box>
            </div>
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button
              className={classes.buttonRight}
              variant="contained"
              onClick={() => submit('Cancelled')}
            >
              {intl.get('CANCEL')}
            </Button>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
              <div>
                <Button
                  className={classes.button}
                  style={{ background: 'orangered' }}
                  variant="contained"
                  onClick={() => submit(values.status)}
                >
                  {intl.get('EDIT')}
                </Button>
              </div>
              <div>
                <div>
                  <StatusButton status={values.status} updateStatus={(status: string) => submit(status)} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
