import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/reducers';
import MerchantBillingList from './MerchantBillingList';
import { IMerchantBilling } from '../../../types/merchant-billing.types';
import Toolbar from './Toolbar';
import PageHeader from '../../../components/StyledComponents/PageHeader';

interface Types {
  merchantPanel: boolean;
}

export const MerchantBillings = ({ merchantPanel }: Types) => {
  const merchantBillingReducer = useSelector((state: RootState) => state.merchantBillingReducer);
  const merchantBillingList: IMerchantBilling[] = merchantBillingReducer.merchantBillingList || [];
  const { loading } = merchantBillingReducer;

  return (
    <>
      <PageHeader
        label="PAYOUTS"
        componentToPassDown={<Toolbar merchantPanel={merchantPanel} />}
      />
      <MerchantBillingList merchantBillings={merchantBillingList} merchantPanel={merchantPanel} loading={loading} />
    </>
  );
};
