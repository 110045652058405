import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import intl from 'react-intl-universal';
import {
  useParams,
} from 'react-router-dom';
import {
  Box,
  Button,
  Card,
  CardHeader,
  CardActions,
  CardContent,
  Divider,
  Typography,
  Grid,
  makeStyles,
} from '@material-ui/core';
import ArrowBackSharpIcon from '@material-ui/icons/ArrowBackSharp';
import { RootState } from '../../../store/reducers';
import { IPaymentProvider, IPaymentProviderState } from '../../../types/payment-provider.types';
import { paymentProviderActions } from '../../../store/actions';
import { PaymentProviderForm } from '../../../components';
import { history } from '../../../helpers';

type RouteParams = {
  id: string;
}

const useStyles = makeStyles({
  back: {
    display: 'flex',
    '&:hover': {
      color: '#3485f7',
      cursor: 'pointer',
    },
  },
});

export const PaymentProviderDetail: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const params = useParams<RouteParams>();
  const [paymentProvider, setPaymentProvider] = useState<IPaymentProvider>(IPaymentProviderState);
  const [displayPaymentProviderForm, setdisplayPaymentProviderForm] = React.useState(false);

  useEffect(() => {
    dispatch(paymentProviderActions.getPaymentProviderById(Number(params.id)));
  }, [dispatch, params]);

  const paymentProviderReducer = useSelector((state: RootState) => state.paymentProviderReducer);

  useEffect(() => {
    if (paymentProviderReducer.paymentProviderDetails) {
      setPaymentProvider(paymentProviderReducer.paymentProviderDetails);
    }
  }, [paymentProviderReducer.paymentProviderDetails]);

  useEffect(() => {
    if (paymentProviderReducer.isUpdated) {
      dispatch(paymentProviderActions.getPaymentProviderById(Number(params.id)));
    }
  }, [paymentProviderReducer.isUpdated, dispatch, params]);

  const openPaymentProviderForm = () => {
    setdisplayPaymentProviderForm(true);
  };

  const closePaymentProviderForm = () => {
    setdisplayPaymentProviderForm(false);
  };

  const backChange = () => {
    setTimeout(() => {
      history.push('/dashboard/payment-providers');
    }, 100);
  };

  /**
   * This will run when data added/edited
   */
  React.useEffect(() => {
    if (paymentProviderReducer.isUpdated) {
      setdisplayPaymentProviderForm(false);
    }
  }, [paymentProviderReducer.isUpdated]);

  return (
    <>
      <div style={{
        display: 'flex', justifyContent: 'flex-start', alignItems: 'center', paddingBottom: '5px',
      }}
      >
        <Box className={classes.back}>
          <ArrowBackSharpIcon onClick={backChange} />
        </Box>
      </div>
      <Card>
        <CardHeader
          title={paymentProvider.name}
          subheader={`${intl.get('COMPANY') || 'Company'} ${intl.get('CODE') || 'Code'}: ${paymentProvider.code}`}
        />
        <CardContent>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              md={6}
              sm={6}
              xs={10}
            >
              <Box
                alignItems="start"
                display="flex"
                flexDirection="column"
              >
                <Typography
                  color="textSecondary"
                  variant="body1"
                >
                  {`${intl.get('MARGIN_RATE')} : ${paymentProvider.margin_rate}`}
                </Typography>
                <Typography
                  color="textSecondary"
                  variant="body1"
                >
                  {`${intl.get('CARD_TYPE')} : ${paymentProvider.card_type}`}
                </Typography>
                <Typography
                  color="textSecondary"
                  variant="body1"
                >
                  {`${intl.get('MARGIN_TYPE')} : ${paymentProvider.margin_type}`}
                </Typography>
                <Typography
                  color="textSecondary"
                  variant="body1"
                >
                  {`${intl.get('CODE')} : ${paymentProvider.code}`}
                </Typography>
                <Typography
                  color="textSecondary"
                  variant="body1"
                >
                  {`${intl.get('DESCRIPTOR')} : ${paymentProvider.descriptor}`}
                </Typography>
                <Typography
                  color="textSecondary"
                  variant="body1"
                >
                  {`${intl.get('CREATED_AT')} : ${paymentProvider.created_at && new Date(paymentProvider.created_at).toLocaleDateString()}`}
                </Typography>
                <Typography
                  color="textSecondary"
                  variant="body1"
                >
                  {`${intl.get('DAY_LIMIT')} : ${paymentProvider.day_limit}`}
                </Typography>
                <Typography
                  color="textSecondary"
                  variant="body1"
                >
                  {`${intl.get('DAY_USAGE')} : ${paymentProvider.day_usage}`}
                </Typography>
                <Typography
                  color="textSecondary"
                  variant="body1"
                >
                  {`${intl.get('NAME')} : ${paymentProvider.name}`}
                </Typography>
                <Typography
                  color="textSecondary"
                  variant="body1"
                >
                  {`${intl.get('BACKUP_PSP_ID')} : ${paymentProvider.backup_psp_id}`}
                </Typography>
                <Typography
                  color="textSecondary"
                  variant="body1"
                >
                  {`${intl.get('REAL_DAY_LIMIT')} : ${paymentProvider.real_day_limit}`}
                </Typography>
                <Typography
                  color="textSecondary"
                  variant="body1"
                >
                  {`${intl.get('TYPE')} : ${paymentProvider.type}`}
                </Typography>
              </Box>
            </Grid>
            <Grid
              item
              md={6}
              sm={6}
              xs={2}
            >
              <Box
                display="flex"
                alignItems="flex-end"
                flexDirection="column"
              >
                <Typography
                  color="textSecondary"
                  variant="body1"
                >
                  {
                    paymentProvider.parameters && Object.entries(paymentProvider.parameters).map(([key]) => (
                      <li style={{ listStyle: 'none', fontSize: '20px' }}>
                        <span>{key}</span>
                        :
                        <span>******</span>
                      </li>
                    ))
                  }
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <CardActions>
          <Button
            color="primary"
            fullWidth
            variant="text"
            onClick={() => openPaymentProviderForm()}
          >
            {intl.get('EDIT') || 'Edit'}
          </Button>
        </CardActions>
      </Card>
      <PaymentProviderForm
        openDialog={displayPaymentProviderForm}
        closePaymentProviderForm={closePaymentProviderForm}
        selectedPaymentProvider={paymentProvider}
      />
    </>
  );
};
