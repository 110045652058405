import React, { useEffect } from 'react';
import intl from 'react-intl-universal';
import 'date-fns';
import {
  Box,
  Button,
  createStyles,
  makeStyles,
  ThemeProvider,
  IconButton,
  Card,
  CardContent,
} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { Link } from 'react-router-dom';
import {
  MuiPickersUtilsProvider,
  DatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { useSelector, useDispatch } from 'react-redux';
import CloseIcon from '@material-ui/icons/Close';
import FilterListIcon from '@material-ui/icons/FilterList';
import { SearchInput } from '../../../components/SearchInput';
import { Dates, Pagination } from '../../../types/common.types';
import { SettlementFilter } from '../../../types/settlement.types';
import { customDatePicker } from '../../../assets/styles/date-picker';
import ExportIcon from '../../../assets/icons/Export.svg';
import { RootState } from '../../../store/reducers';
import { adminSettlementActions } from '../../../store/actions/admin-settlement.actions';
import { ProviderDropDown } from '../../../components/ProviderDropDown';
import { MerchantDropDown } from '../../../components/MerchantDropDown';
import { CurrencyDropDown } from '../../../components/Currency';
import { StatusDropDown } from '../../../components/StatusDropdown';

const useStyles = makeStyles(() => createStyles({
  topSection: {
    background: '#f4f4f4',
    border: '#c7c7c7',
    marginBottom: '12px',
  },
  button: {
    background: '#fff',
    color: '#3485f7',
    margin: '5px',
    borderRadius: '20px',
    fontSize: '10px',
    fontWeight: 'bold',
    border: '1px solid #c7c7c7',
  },
  input: {
    backgroundColor: '#f4f4f4',
    margin: '10px',
  },
  mainButton: {
    margin: '10px',
  },
  leftSection: {
    marginBottom: '4px',
    paddingTop: '4px',
    paddingLeft: '8px',
  },
  MuiInputBaseinput: {
    width: '91px',
  },
  dateLabel: {
    fontWeight: 'bold',
    margin: '10px',
    marginTop: '15px',
  },
  formControl: {
    margin: '0 10px',
    minWidth: 150,
  },
  clearFilters: {
    color: '#3485f7',
    fontSize: '12px',
    cursor: 'pointer',
    marginLeft: '15px'
  },
  iconStyle: {
    fill: '#3f51b5',
  },
  filterContainer: {
    position: 'relative',
  },
  export: {
    padding: '1px 9px',
    marginTop: '9px',
    marginLeft: '20px',
  },
}));

interface Props {
  merchantPanel: boolean;
  pagination: Pagination;
}

const initialFilters: SettlementFilter = {
  merchantUUID: '', settlement_currency: '', selectedDates: { from: '', to: '' }, payment_provider_id: '', status: '',
};

const Toolbar = ({ merchantPanel, pagination }: Props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [selectedDates, setSelectedDates] = React.useState<Dates>({ from: '', to: '' });
  const [selectedFilters, setSelectedFilters] = React.useState<SettlementFilter>(initialFilters);
  const [clearFilter, setClearFilter] = React.useState(false);
  const [showFilters, setShowFilters] = React.useState(false);
  const now = new Date(Date.now());
  const [selectedFromDate, setSelectedFromDate] = React.useState<Date | null>(
    new Date(now.getFullYear(), now.getMonth(), 1),
  );
  const [selectedToDate, setSelectedToDate] = React.useState<Date | null>(
    new Date(now.getFullYear(), now.getMonth(), (new Date(now.getFullYear(), now.getMonth(), 0)).getDate()),
  );
  const dateFormat = (date: Date | null) => {
    const year = date?.getFullYear();
    const month = `${(date && (date?.getMonth() + 1).toString().length === 1 ? `0${date?.getMonth() + 1}` : (date && date?.getMonth() + 1))}`;
    const day = `${date?.getDate().toString().length === 1 ? `0${date?.getDate()}` : date?.getDate()}`;
    return `${year}-${month}-${day}`;
  };

  useEffect(() => {
    dispatch(adminSettlementActions.getSettlementRefundList(pagination, selectedFilters));
    setClearFilter(false);
  }, [dispatch, merchantPanel, selectedFilters, pagination]);

  const settlementReducer = useSelector((state: RootState) => state.adminSettlementReducer);

  const handleFromDateChange = (date: Date | null) => {
    setSelectedFromDate(date);
    const dates = { to: selectedDates.to, from: dateFormat(date) };
    setSelectedDates(dates);
    setSelectedFilters({ ...selectedFilters, selectedDates: dates });
  };

  const handleToDateChange = (date: Date | null) => {
    setSelectedToDate(date);
    const dates = { to: dateFormat(date), from: selectedDates.from };
    setSelectedDates(dates);
    setSelectedFilters({ ...selectedFilters, selectedDates: dates });
  };

  const clearFilters = () => {
    setSelectedFilters(initialFilters);
    setSelectedDates({ from: '', to: '' });
    setSelectedFromDate(new Date());
    setSelectedToDate(new Date());
    setClearFilter(true);
  };

  useEffect(() => {
    if (settlementReducer?.exports?.filename) {
      window.open(settlementReducer?.exports?.filename, '_blank');
      dispatch(adminSettlementActions.resetState());
    }
  }, [settlementReducer, dispatch]);
  const exportData = () => {
    if (selectedFilters.selectedDates?.from && selectedFilters.selectedDates.to) {
      dispatch(adminSettlementActions.getRefundExport(selectedFilters));
    } else {
      const dates = { to: dateFormat(selectedToDate), from: dateFormat(selectedFromDate) };
      dispatch(adminSettlementActions.getRefundExport({ ...selectedFilters, selectedDates: dates }));
    }
  };

  return (
    <div>
      <Box>
        <Card>
          <CardContent>
            {
              showFilters === false ? (
                <Grid container wrap="wrap" justify="space-between" style={{ justifyContent: 'flex-end' }}>
                  <Box>
                    <IconButton onClick={() => setShowFilters(!showFilters)}>
                      <FilterListIcon className={classes.iconStyle} />
                    </IconButton>
                    <span>
                      {intl.get('FILTER')}
                    </span>
                  </Box>
                </Grid>
              )
                : null
            }
            {showFilters ? (
              <Grid className={classes.filterContainer} container spacing={1} wrap="wrap" justify="space-between">
                <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between" style={{ width: '100%' }}>
                  <Box display="flex" flexDirection="row" alignItems="center" justifyContent="flex-end">
                    <Box m={1} display="flex" flexDirection="row" flexWrap="wrap" alignItems="flex-end" justifyContent="center">
                      <div>
                        <StatusDropDown
                          i18nTag="STATUS"
                          minWidth={180}
                          defaultValue={selectedFilters && selectedFilters.status}
                          onChange={(e: string) => setSelectedFilters({ ...selectedFilters, status: e })}
                          margin="0px 15px 0px 15px"
                          clearFilter={clearFilter}
                        />
                      </div>
                      {!merchantPanel
                        ? (
                          <>
                            <div style={{ marginRight: '10px' }}>
                              <MerchantDropDown
                                i18nTag="MERCHANT"
                                minWidth={180}
                                defaultValue={selectedFilters.merchantUUID}
                                onChange={(e: string) => setSelectedFilters({ ...selectedFilters, merchantUUID: e })}
                                clearFilter={clearFilter}
                              />
                            </div>
                            <div>
                              <ProviderDropDown
                                i18nTag="PROVIDER"
                                minWidth={180}
                                defaultValue={selectedFilters.payment_provider_id}
                                onChange={(e: string) => setSelectedFilters({ ...selectedFilters, payment_provider_id: e })}
                                clearFilter={clearFilter}
                              />
                            </div>
                          </>
                        ) : null}
                      <div>
                        <CurrencyDropDown
                          i18nTag="CURRENCY"
                          minWidth={180}
                          defaultValue={selectedFilters && selectedFilters.settlement_currency}
                          onChange={(e: string) => setSelectedFilters({ ...selectedFilters, settlement_currency: e })}
                          margin="0px 0px 0px 15px"
                          clearFilter={clearFilter}
                        />
                      </div>
                      <span
                        className={classes.clearFilters}
                        onClick={() => clearFilters()}
                        aria-hidden="true"
                      >
                        {intl.get('CLEAR_ALL_FILTERS')}
                      </span>
                    </Box>
                  </Box>
                  <IconButton
                    onClick={() => setShowFilters(!showFilters)}
                    style={{
                      position: 'absolute',
                      top: '-5px',
                      right: '-5px',
                    }}
                  >
                    <CloseIcon
                      className={classes.iconStyle}
                    />
                  </IconButton>
                </Box>
              </Grid>
            ) : null}
            <div style={{
              display: 'flex', flexDirection: 'row', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'space-between',
            }}
            >
              <Box
                display="flex"
                flexWrap="wrap"
                justifyContent="flex-start"
                alignItems="center"
              >
                <ThemeProvider theme={customDatePicker}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Box
                      display="flex"
                      flexDirection="row"
                      justifyContent="flex-start"
                      alignItems="center"
                    >
                      <span className={classes.dateLabel}>{intl.get('FROM')}</span>
                      <DatePicker
                        disableToolbar
                        inputVariant="outlined"
                        format="MM/dd/yyyy"
                        margin="normal"
                        id="date-from"
                        className={classes.MuiInputBaseinput}
                        value={selectedFromDate}
                        onChange={handleFromDateChange}
                        style={{ marginRight: '10px' }}
                      />
                      <span className={classes.dateLabel}>{intl.get('TO')}</span>
                      <DatePicker
                        disableToolbar
                        inputVariant="outlined"
                        format="MM/dd/yyyy"
                        margin="normal"
                        id="date-to"
                        className={classes.MuiInputBaseinput}
                        value={selectedToDate}
                        onChange={handleToDateChange}
                      />
                    </Box>
                  </MuiPickersUtilsProvider>
                </ThemeProvider>
                <div>
                  <Button
                    className={classes.export}
                    onClick={exportData}
                    variant="outlined"
                    size="small"
                    style={{ display: 'flex', alignItems: 'flex-end', borderRadius: '5px' }}
                  >
                    {intl.get('EXPORT')}
                    <img src={ExportIcon} alt="" width="25" />
                  </Button>
                </div>
                <div style={{ marginTop: '9px', marginLeft: '20px' }}>
                  <SearchInput width="150px" onChange={() => { console.log('hello world'); }} />
                </div>
              </Box>
              <Box
                display="flex"
                justifyContent="flex-end"
                alignItems="center"
                style={{ marginTop: '10px' }}
              >
                <Link to="/dashboard/refunds/create" style={{ textDecoration: 'none' }}>
                  <Button
                    color="primary"
                    // style={{ background: '#3485f7', color: 'white' }}
                    variant="contained"
                  >
                    {intl.get('ADD_REFUND') || 'Add Refund'}
                  </Button>
                </Link>
              </Box>
            </div>
          </CardContent>
        </Card>
      </Box>
    </div>
  );
};

export default Toolbar;
