import React, { useState, useEffect } from 'react';
import intl from 'react-intl-universal';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
  Stepper,
  Step,
  StepButton,
} from '@material-ui/core';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { merchantActions } from '../../../store/actions';
import { RootState } from '../../../store/reducers';
import { DisplaySnackbar, Dropdown } from '../../index';
import { IMerchant, MerchantInitialForm } from '../../../types/merchant.types';
import { CountryDropDown } from '../../CountryDropDown';

const getSteps = () => [intl.get('STEP_1'), intl.get('STEP_2')];

const useStyles = makeStyles((theme: Theme) => createStyles({
  button: {
    margin: theme.spacing(1),
  },
}));

interface IProps {
  openDialog: boolean, closeMerchantForm: () => void;
  selectedMerchant: IMerchant | null;
}

export const MerchantForm: React.FC<IProps> = ({
  openDialog,
  closeMerchantForm,
  selectedMerchant,
}: IProps): React.ReactElement => {
  const [values, setValues] = useState({
    ...MerchantInitialForm, margin_type: 'percent', type: 'fiat',
  });
  const [open, setOpen] = React.useState(openDialog);
  const classes = useStyles();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValues({
      ...values,
      [(event.target as HTMLInputElement).name]: (event.target as HTMLInputElement).value,
    });
  };
  const dispatch = useDispatch();
  const onSubmit = () => {
    if (selectedMerchant) {
      dispatch(merchantActions.updateMerchant({ form: values, uuid: values.uuid }));
    } else {
      dispatch(merchantActions.addMerchant(values));
    }
  };
  const merchantState = useSelector((state: RootState) => state.merchantReducer);

  /**
   * This will run when merchant reducer updates
   */
  useEffect(() => {
    if (merchantState.isUpdated) {
      /**
       * Reset form only if a new merchant added
       */
      if (!selectedMerchant) {
        setValues(MerchantInitialForm);
      }
    }
  }, [merchantState, selectedMerchant]);

  /**
   * This will run when we open/close merchant form dialog
   */
  useEffect(() => {
    setOpen(openDialog);
  }, [openDialog]);

  /**
   * This will check if merchant is being adding or editing
   */
  useEffect(() => {
    if (selectedMerchant) {
      setValues(selectedMerchant);
    }
  }, [selectedMerchant]);

  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();

  const handleNext = () => {
    setActiveStep(activeStep < 1 ? activeStep + 1 : activeStep);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step: number) => {
    setActiveStep(step);
  };

  return (
    <div>
      {merchantState && merchantState.updateError
        ? merchantState.errorMessages && Object.values(merchantState.errorMessages).length
        && Object.values(merchantState.errorMessages).reverse().map(
          (error: any) => error?.length && error.map(
            (msg: string) => <DisplaySnackbar type="error" message={msg} />,
          ),
        ) : null}
      {/* {merchantState && merchantState.updateError && !merchantState.errorMessages.length
        ? <DisplaySnackbar type="error" message={intl.get('SOMETHING_WENT_WRONG')} /> : null} */}
      {merchantState && merchantState.isUpdated
        ? <DisplaySnackbar type="success" message={intl.get('UPDATED_SUCCESSFULLY')} /> : null}
      <div>
        <Dialog fullWidth maxWidth="md" open={open} onClose={closeMerchantForm} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">{!selectedMerchant ? intl.get('ADD_MERCHANT') : intl.get('EDIT_MERCHANT')}</DialogTitle>
          <DialogContent>
            <form
              autoComplete="off"
              noValidate
            >
              <Card>
                <CardContent>
                  <Stepper nonLinear activeStep={activeStep}>
                    {steps.map((label, index) => (
                      <Step key={label}>
                        <StepButton onClick={() => handleStep(index)}>
                          {label}
                        </StepButton>
                      </Step>
                    ))}
                  </Stepper>
                  {activeStep === 0 ? (
                    <Grid
                      container
                      spacing={3}
                    >
                      <Grid
                        item
                        md={6}
                        xs={12}
                      >
                        <TextField
                          size="small"
                          fullWidth
                          label={intl.get('COMPANY_NAME')}
                          name="company_name"
                          onChange={handleChange}
                          required
                          value={values.company_name}
                          variant="outlined"
                          inputProps={{ style: { fontSize: 22 } }}
                          InputLabelProps={{ style: { fontSize: 17 } }}
                        />
                      </Grid>
                      <Grid
                        item
                        md={6}
                        xs={12}
                      >
                        <TextField
                          size="small"
                          fullWidth
                          label={intl.get('COMPANY_CODE')}
                          name="company_code"
                          onChange={handleChange}
                          value={values.company_code}
                          variant="outlined"
                          disabled={!!selectedMerchant}
                          inputProps={{ style: { fontSize: 22 } }}
                          InputLabelProps={{ style: { fontSize: 17 } }}
                        />
                      </Grid>
                      <Grid
                        item
                        md={6}
                        xs={12}
                      >
                        <TextField
                          size="small"
                          fullWidth
                          label={intl.get('MARGIN_RATE')}
                          name="margin_rate"
                          onChange={handleChange}
                          required
                          value={values.margin_rate}
                          variant="outlined"
                          inputProps={{ style: { fontSize: 22 } }}
                          InputLabelProps={{ style: { fontSize: 17 } }}
                        />
                      </Grid>
                      <Grid
                        item
                        md={6}
                        xs={12}
                      >
                        <Dropdown
                          list={['percent', 'flat']}
                          defaultValue={values.margin_type}
                          i18nTag="MARGIN_TYPE"
                          label="Margin Type"
                          onChange={(e: string) => setValues({ ...values, margin_type: e })}
                        />
                      </Grid>
                      <Grid
                        item
                        md={6}
                        xs={12}
                      >
                        <TextField
                          size="small"
                          fullWidth
                          label={intl.get('CURRENCY')}
                          name="currency"
                          onChange={handleChange}
                          required
                          value={values.currency}
                          variant="outlined"
                          inputProps={{ style: { fontSize: 22 } }}
                          InputLabelProps={{ style: { fontSize: 17 } }}
                        />
                      </Grid>
                      <Grid
                        item
                        md={6}
                        xs={12}
                      >
                        <CountryDropDown
                          defaultValue={values.company_country}
                          i18nTag="COMPANY_COUNTRY"
                          label="Company Country"
                          onChange={(e: string) => setValues({ ...values, company_country: e })}
                        />
                      </Grid>
                      <Grid
                        item
                        md={6}
                        xs={12}
                      >
                        <TextField
                          size="small"
                          fullWidth
                          label={intl.get('VAT_NUMBER')}
                          name="vat_number"
                          onChange={handleChange}
                          value={values.vat_number}
                          variant="outlined"
                          inputProps={{ style: { fontSize: 22 } }}
                          InputLabelProps={{ style: { fontSize: 17 } }}
                        />
                      </Grid>
                      <Grid
                        item
                        md={6}
                        xs={12}
                      >
                        <TextField
                          size="small"
                          fullWidth
                          label={intl.get('BILLING_PERIOD_IN_DAYS')}
                          name="billing_period"
                          onChange={handleChange}
                          value={values.billing_period}
                          variant="outlined"
                          inputProps={{ style: { fontSize: 22 } }}
                          InputLabelProps={{ style: { fontSize: 17 } }}
                        />
                      </Grid>
                      <Grid
                        item
                        md={12}
                      >
                        <TextField
                          size="small"
                          fullWidth
                          label={intl.get('MDR') || 'MDR(%)'}
                          name="mdr"
                          onChange={handleChange}
                          value={values.mdr}
                          variant="outlined"
                          inputProps={{ style: { fontSize: 22 } }}
                          InputLabelProps={{ style: { fontSize: 17 } }}
                        />
                      </Grid>
                    </Grid>
                  ) : null}
                  {activeStep === 1 ? (
                    <Grid
                      container
                      spacing={3}
                    >
                      <Grid
                        item
                        md={6}
                        xs={12}
                      >
                        <TextField
                          size="small"
                          fullWidth
                          label={intl.get('PRIMARY_CONTACT_NAME')}
                          name="primary_contact_name"
                          onChange={handleChange}
                          value={values.primary_contact_name}
                          variant="outlined"
                          inputProps={{ style: { fontSize: 22 } }}
                          InputLabelProps={{ style: { fontSize: 17 } }}
                        />
                      </Grid>
                      <Grid
                        item
                        md={6}
                        xs={12}
                      >
                        <TextField
                          size="small"
                          fullWidth
                          label={intl.get('PRIMARY_CONTACT_EMAIL')}
                          name="primary_contact_email"
                          onChange={handleChange}
                          value={values.primary_contact_email}
                          variant="outlined"
                          inputProps={{ style: { fontSize: 22 } }}
                          InputLabelProps={{ style: { fontSize: 17 } }}
                        />
                      </Grid>
                      <Grid
                        item
                        md={6}
                        xs={12}
                      >
                        <TextField
                          size="small"
                          fullWidth
                          label={intl.get('PRIMARY_CONTACT_PHONE')}
                          name="primary_contact_phone"
                          onChange={handleChange}
                          value={values.primary_contact_phone}
                          variant="outlined"
                          inputProps={{ style: { fontSize: 22 } }}
                          InputLabelProps={{ style: { fontSize: 17 } }}
                        />
                      </Grid>
                      <Grid
                        item
                        md={6}
                        xs={12}
                      >
                        <TextField
                          size="small"
                          fullWidth
                          label={intl.get('TECHNICAL_CONTACT_NAME')}
                          name="technical_contact_name"
                          onChange={handleChange}
                          value={values.technical_contact_name}
                          variant="outlined"
                          inputProps={{ style: { fontSize: 22 } }}
                          InputLabelProps={{ style: { fontSize: 17 } }}
                        />
                      </Grid>
                      <Grid
                        item
                        md={6}
                        xs={12}
                      >
                        <TextField
                          size="small"
                          fullWidth
                          label={intl.get('TECHNICAL_CONTACT_EMAIL')}
                          name="technical_contact_email"
                          onChange={handleChange}
                          value={values.technical_contact_email}
                          variant="outlined"
                          inputProps={{ style: { fontSize: 22 } }}
                          InputLabelProps={{ style: { fontSize: 17 } }}
                        />
                      </Grid>
                      <Grid
                        item
                        md={6}
                        xs={12}
                      >
                        <TextField
                          size="small"
                          fullWidth
                          label={intl.get('TECHNICAL_CONTACT_PHONE')}
                          name="technical_contact_phone"
                          onChange={handleChange}
                          value={values.technical_contact_phone}
                          variant="outlined"
                          inputProps={{ style: { fontSize: 22 } }}
                          InputLabelProps={{ style: { fontSize: 17 } }}
                        />
                      </Grid>
                      <Grid
                        item
                        md={6}
                        xs={12}
                      >
                        <TextField
                          size="small"
                          fullWidth
                          label={intl.get('DEPOSIT_ADDRESS')}
                          name="deposit_address"
                          onChange={handleChange}
                          value={values.deposit_address}
                          variant="outlined"
                          inputProps={{ style: { fontSize: 22 } }}
                          InputLabelProps={{ style: { fontSize: 17 } }}
                        />
                      </Grid>
                      <Grid
                        item
                        md={6}
                        xs={12}
                      >
                        <Dropdown
                          list={['fiat', 'crypto']}
                          defaultValue={values.type}
                          i18nTag="TYPE"
                          label="Type"
                          onChange={(e: string) => setValues({ ...values, type: e })}
                        />
                      </Grid>
                      <Grid
                        item
                        md={4}
                        xs={12}
                      >
                        <FormControl>
                          <FormLabel>{intl.get('KYC_APPROVED')}</FormLabel>
                          <RadioGroup
                            aria-label="kyc_approved"
                            name="kyc_approved"
                            value={values.kyc_approved}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                              setValues(
                                { ...values, kyc_approved: e.target.value === 'yes' },
                              );
                            }}
                            style={{ flexDirection: 'row' }}
                          >
                            <FormControlLabel value="yes" checked={values.kyc_approved} control={<Radio />} label={intl.get('YES') || 'Yes'} />
                            <FormControlLabel value="no" checked={!values.kyc_approved} control={<Radio />} label={intl.get('NO') || 'No'} />
                          </RadioGroup>
                        </FormControl>
                      </Grid>
                      <Grid
                        item
                        md={4}
                        xs={12}
                      >
                        <FormControl>
                          <FormLabel>{intl.get('ON_HOLD')}</FormLabel>
                          <RadioGroup
                            aria-label="on_hold"
                            name="on_hold"
                            value={values.on_hold}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                              setValues(
                                { ...values, on_hold: e.target.value === 'yes' },
                              );
                            }}
                            style={{ flexDirection: 'row' }}
                          >
                            <FormControlLabel value="yes" checked={values.on_hold} control={<Radio />} label={intl.get('YES') || 'Yes'} />
                            <FormControlLabel value="no" checked={!values.on_hold} control={<Radio />} label={intl.get('NO') || 'No'} />
                          </RadioGroup>
                        </FormControl>
                      </Grid>
                      <Grid
                        item
                        md={4}
                        xs={12}
                      >
                        <FormControl>
                          <FormLabel>{intl.get('OBSOLETE')}</FormLabel>
                          <RadioGroup
                            aria-label="obsolete"
                            name="obsolete"
                            value={values.obsolete}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                              setValues(
                                { ...values, obsolete: e.target.value === 'yes' },
                              );
                            }}
                            style={{ flexDirection: 'row' }}
                          >
                            <FormControlLabel value="yes" checked={values.obsolete} control={<Radio />} label={intl.get('YES') || 'Yes'} />
                            <FormControlLabel value="no" checked={!values.obsolete} control={<Radio />} label={intl.get('NO') || 'No'} />
                          </RadioGroup>
                        </FormControl>
                      </Grid>

                    </Grid>
                  ) : null}
                  <Button
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    className={classes.button}
                    style={{
                      marginLeft: '20px', background: '#f5f5f5', color: '#3f51b5', border: '1px solid #3f51b5',
                    }}
                  >
                    Back
                  </Button>
                  <Button
                    style={{ background: '#3f51b5', color: 'white' }}
                    variant="contained"
                    onClick={handleNext}
                    disabled={activeStep === 1}
                    className={classes.button}
                  >
                    Next
                  </Button>
                </CardContent>
              </Card>
            </form>
          </DialogContent>
          <DialogActions>
            <Grid
              item
            >
              <Button
                color="primary"
                variant="contained"
                onClick={() => onSubmit()}
              >
                {intl.get('SAVE')}
              </Button>
              <Button
                color="default"
                variant="contained"
                style={{ marginLeft: '20px' }}
                onClick={closeMerchantForm}
              >
                {intl.get('CLOSE')}
              </Button>
            </Grid>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
};
