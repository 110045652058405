import React, { useEffect } from 'react';
import { Button } from '@material-ui/core';
import intl from 'react-intl-universal';
import { NewPasswordInput } from '.';

interface Types {
    changePassword: (e: any) => void;
}
const PasswordResusable = ({ changePassword }: Types) => {
  const [password, setPassword] = React.useState('');
  const [isSpecialCharacter, setIsSpecialCharacter] = React.useState(false);
  const [isUperCharacter, setIsUperCharacter] = React.useState(false);
  const [isLowerCharacter, setIsLowerCharacter] = React.useState(false);
  const [isNumber, setIsNumber] = React.useState(false);
  const [length, setLength] = React.useState(false);

  const clearState = () => {
    setIsSpecialCharacter(false);
    setIsUperCharacter(false);
    setIsLowerCharacter(false);
    setIsNumber(false);
    setLength(false);
  };

  useEffect(() => {
    clearState();
  }, []);
  const checkValidation = (e: any) => {
    if (/[@$!%*#?&]/.test(e)) {
      setIsSpecialCharacter(true);
    } else {
      setIsSpecialCharacter(false);
    }
    if ((/[A-Z]/.test(e))) {
      setIsUperCharacter(true);
    } else {
      setIsUperCharacter(false);
    }
    if ((/[a-z]/.test(e))) {
      setIsLowerCharacter(true);
    } else {
      setIsLowerCharacter(false);
    }
    if ((/[0-9]/.test(e))) {
      setIsNumber(true);
    } else {
      setIsNumber(false);
    }
    if (e.length > 11) {
      setLength(true);
    } else {
      setLength(false);
    }
  };
  const setPassValue = (e: string) => {
    setPassword(e);
    changePassword(e);
    checkValidation(e);
    // setIsValidate(false);
  };

  const shuffleArray = (array: any) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      const temp = array[i];
      array[i] = array[j];
      array[j] = temp;
    }
    return array;
  };

  const generatePassword = () => {
    const passwordLength = 12;
    const numberChars = '0123456789';
    const upperChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const lowerChars = 'abcdefghijklmnopqrstuvwxyz';
    const specialChars = '#?!@$%^&*-';
    const allChars = numberChars + upperChars + lowerChars + specialChars;
    let randPasswordArray = Array(passwordLength);
    randPasswordArray[0] = numberChars;
    randPasswordArray[1] = upperChars;
    randPasswordArray[2] = lowerChars;
    randPasswordArray[3] = specialChars;
    randPasswordArray = randPasswordArray.fill(allChars, 4);
    return shuffleArray(randPasswordArray.map((x) => x[Math.floor(Math.random() * x.length)])).join('');
  };
  const generatePass = () => {
    const passValue = generatePassword();
    setPassword(passValue);
    changePassword(passValue);
    setIsSpecialCharacter(true);
    setIsUperCharacter(true);
    setIsLowerCharacter(true);
    setLength(true);
    setIsNumber(true);
  };

  return (
    <div>
      <NewPasswordInput
        width="250px"
        value={password}
        isSpecialCharacter={isSpecialCharacter}
        isUperCharacter={isUperCharacter}
        isLowerCharacter={isLowerCharacter}
        length={length}
        isNumber={isNumber}
        onChange={(e: string) => setPassValue(e)}
      />
      <div style={{ marginTop: '15px' }}>
        <Button
          variant="contained"
          size="small"
          style={{ background: '#f5f5f5', color: '#3f51b5', border: '1px solid #3f51b5' }}
          onClick={generatePass}
        >
          {intl.get('GENERATE_PASSWORD')}
        </Button>
      </div>
    </div>
  );
};

export default PasswordResusable;
