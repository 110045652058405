import { API } from '../../config';
import { Pagination } from '../../types/common.types';
import { IMerchantAccountIds, IMerchantAccountUpdate, IMerchantAccountAdd } from '../../types/merchant-account.types';

const getMerchantAccountList = (paginationData: Pagination) => API.get(`/merchant/accounts?limit=${paginationData.limit}&page=${paginationData.current_page}`);

const getMerchantAccountById = (payload: IMerchantAccountIds) => API.get(`/merchant/accounts/${payload.merchantAccountUUID}`);

const updateMerchantAccount = (body: IMerchantAccountUpdate) => API.post(`/merchant/accounts/${body.Ids.merchantAccountUUID}`, body.form);

const addMerchantAccount = (body: IMerchantAccountAdd) => API.post('/merchant/accounts', body.form);

const deleteMerchantAccount = (payload: IMerchantAccountIds) => API.delete(`/merchant/accounts/${payload.merchantAccountUUID}`);

export const merchantPanelAccountService = {
  getMerchantAccountList,
  getMerchantAccountById,
  addMerchantAccount,
  updateMerchantAccount,
  deleteMerchantAccount,
};
