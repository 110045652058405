import React, { useEffect } from 'react';
import intl from 'react-intl-universal';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Card,
  CardContent,
  Grid,
  // TextField,
} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { changePassword } from './types';
import { userActions } from '../../../store/actions';
import { DisplaySnackbar } from '../../../components';
import { RootState } from '../../../store/reducers';
import PasswordResusable from '../../../components/PasswordResusable';

interface Types {
  openDialog: boolean, closeUserForm: () => void, selectedUser: any | null
}

export const ChangePassword: React.FC<Types> = ({ openDialog, closeUserForm, selectedUser }: Types) => {
  const [open, setOpen] = React.useState(openDialog);
  const userState = useSelector((state: RootState) => state.userReducer);
  const [values, setValues] = React.useState(changePassword);

  useEffect(() => {
    setOpen(openDialog);
  }, [openDialog]);
  const dispatch = useDispatch();

  const submit = () => {
    dispatch(userActions.changePasswordUser({ ...values }, selectedUser?.uuid));
  };

  useEffect(() => {
    if (userState.changePassword) {
      setOpen(!openDialog);
    }
  }, [userState.changePassword, openDialog]);
  return (
    <div>
      <div>
        {userState && userState.isError
          ? userState.errorMessages && Object.values(userState.errorMessages).length
          && Object.values(userState.errorMessages).map(
            (error: any) => error?.length && error.map(
              (msg: string) => <DisplaySnackbar type="error" message={msg} />,
            ),
          ) : null}
      </div>
      <Dialog open={open} onClose={closeUserForm} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">{selectedUser ? intl.get('CHANGE_PASSWORD') || 'Change Password' : null}</DialogTitle>
        <DialogContent>
          <form
            autoComplete="off"
            noValidate
          >
            <Card>
              <CardContent>
                <Grid
                  container
                  spacing={1}
                >
                  <Grid
                    item
                    md={12}
                    xs={12}
                  >
                    <PasswordResusable changePassword={(e) => {
                      setValues({ ...values, password: e });
                    }}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </form>
        </DialogContent>
        <DialogActions>
          <Grid
            item
          >
            <Button
              style={{ background: '#3f51b5', color: 'white' }}
              variant="contained"
              onClick={submit}
            >
              {intl.get('SAVE') || 'Save'}
            </Button>
            <Button
              variant="contained"
              style={{
                marginLeft: '20px', background: '#f5f5f5', color: '#3f51b5', border: '1px solid #3f51b5',
              }}
              onClick={closeUserForm}
            >
              {intl.get('CLOSE') || 'Close'}
            </Button>
          </Grid>
        </DialogActions>
      </Dialog>
      {
        userState && userState.changePassword
          ? <DisplaySnackbar type="success" message={intl.get('UPDATED_PASSWORD_SUCCESSFULLY') || 'Updated Password successfully'} /> : null
      }
    </div>
  );
};
