import { Pagination } from '../../types/common.types';
import { AccountOptions } from '../../types/merchant-billing.types';
import { IMerchant, MerchantPsp } from '../../types/merchant.types';

interface Types {
  GET_MERCHANT_LIST_REQUEST: string;
  GET_MERCHANT_LIST_SUCCESS: string;
  GET_MERCHANT_LIST_FAILURE: string;

  GET_MERCHANT_BY_ID_REQUEST: string;
  GET_MERCHANT_BY_ID_SUCCESS: string;
  GET_MERCHANT_BY_ID_FAILURE: string;

  ADD_MERCHANT_REQUEST: string;
  ADD_MERCHANT_SUCCESS: string;
  ADD_MERCHANT_FAILURE: string;

  UPDATE_MERCHANT_REQUEST: string;
  UPDATE_MERCHANT_SUCCESS: string;
  UPDATE_MERCHANT_FAILURE: string;

  DELETE_MERCHANT_REQUEST: string;
  DELETE_MERCHANT_SUCCESS: string;
  DELETE_MERCHANT_FAILURE: string;

  GET_MERCHANT_PSP_LIST_REQUEST: string;
  GET_MERCHANT_PSP_LIST_SUCCESS: string;
  GET_MERCHANT_PSP_LIST_FAILURE: string;

  GET_MERCHANT_PSP_BY_ID_REQUEST: string;
  GET_MERCHANT_PSP_BY_ID_SUCCESS: string;
  GET_MERCHANT_PSP_BY_ID_FAILURE: string;

  ADD_MERCHANT_PSP_REQUEST: string;
  ADD_MERCHANT_PSP_SUCCESS: string;
  ADD_MERCHANT_PSP_FAILURE: string;

  UPDATE_MERCHANT_PSP_REQUEST: string;
  UPDATE_MERCHANT_PSP_SUCCESS: string;
  UPDATE_MERCHANT_PSP_FAILURE: string;

  DELETE_MERCHANT_PSP_REQUEST: string;
  DELETE_MERCHANT_PSP_SUCCESS: string;
  DELETE_MERCHANT_PSP_FAILURE: string;

  GET_PSP_BY_MERCHANT_UUID_REQUEST: string;
  GET_PSP_BY_MERCHANT_UUID_SUCCESS: string;
  GET_PSP_BY_MERCHANT_UUID_FAILURE: string;

  MERCHANTS_PROVIDER_REQUEST: string;
  MERCHANTS_PROVIDER_SUCCESS: string;
  MERCHANTS_PROVIDER_FAILURE: string;

}

export const merchantConstants: Types = {

  GET_MERCHANT_LIST_REQUEST: 'GET_MERCHANT_LIST_REQUEST',
  GET_MERCHANT_LIST_SUCCESS: 'GET_MERCHANT_LIST_SUCCESS',
  GET_MERCHANT_LIST_FAILURE: 'GET_MERCHANT_LIST_FAILURE',

  GET_MERCHANT_BY_ID_REQUEST: 'GET_MERCHANT_BY_ID_REQUEST',
  GET_MERCHANT_BY_ID_SUCCESS: 'GET_MERCHANT_BY_ID_SUCCESS',
  GET_MERCHANT_BY_ID_FAILURE: 'GET_MERCHANT_BY_ID_FAILURE',

  ADD_MERCHANT_REQUEST: 'ADD_MERCHANT_REQUEST',
  ADD_MERCHANT_SUCCESS: 'ADD_MERCHANT_SUCCESS',
  ADD_MERCHANT_FAILURE: 'ADD_MERCHANT_FAILURE',

  UPDATE_MERCHANT_REQUEST: 'UPDATE_MERCHANT_REQUEST',
  UPDATE_MERCHANT_SUCCESS: 'UPDATE_MERCHANT_SUCCESS',
  UPDATE_MERCHANT_FAILURE: 'UPDATE_MERCHANT_FAILURE',

  DELETE_MERCHANT_REQUEST: 'DELETE_MERCHANT_REQUEST',
  DELETE_MERCHANT_SUCCESS: 'DELETE_MERCHANT_SUCCESS',
  DELETE_MERCHANT_FAILURE: 'DELETE_MERCHANT_FAILURE',

  GET_MERCHANT_PSP_LIST_REQUEST: 'GET_MERCHANT_PSP_LIST_REQUEST',
  GET_MERCHANT_PSP_LIST_SUCCESS: 'GET_MERCHANT_PSP_LIST_SUCCESS',
  GET_MERCHANT_PSP_LIST_FAILURE: 'GET_MERCHANT_PSP_LIST_FAILURE',

  GET_MERCHANT_PSP_BY_ID_REQUEST: 'GET_MERCHANT_PSP_BY_ID_REQUEST',
  GET_MERCHANT_PSP_BY_ID_SUCCESS: 'GET_MERCHANT_PSP_BY_ID_SUCCESS',
  GET_MERCHANT_PSP_BY_ID_FAILURE: 'GET_MERCHANT_PSP_BY_ID_FAILURE',

  ADD_MERCHANT_PSP_REQUEST: 'ADD_MERCHANT_PSP_REQUEST',
  ADD_MERCHANT_PSP_SUCCESS: 'ADD_MERCHANT_PSP_SUCCESS',
  ADD_MERCHANT_PSP_FAILURE: 'ADD_MERCHANT_PSP_FAILURE',

  UPDATE_MERCHANT_PSP_REQUEST: 'UPDATE_MERCHANT_PSP_REQUEST',
  UPDATE_MERCHANT_PSP_SUCCESS: 'UPDATE_MERCHANT_PSP_SUCCESS',
  UPDATE_MERCHANT_PSP_FAILURE: 'UPDATE_MERCHANT_PSP_FAILURE',

  DELETE_MERCHANT_PSP_REQUEST: 'DELETE_MERCHANT_PSP_REQUEST',
  DELETE_MERCHANT_PSP_SUCCESS: 'DELETE_MERCHANT_PSP_SUCCESS',
  DELETE_MERCHANT_PSP_FAILURE: 'DELETE_MERCHANT_PSP_FAILURE',

  GET_PSP_BY_MERCHANT_UUID_REQUEST: 'GET_PSP_BY_MERCHANT_UUID_REQUEST',
  GET_PSP_BY_MERCHANT_UUID_SUCCESS: 'GET_PSP_BY_MERCHANT_UUID_SUCCESS',
  GET_PSP_BY_MERCHANT_UUID_FAILURE: 'GGET_PSP_BY_MERCHANT_UUID_FAILURE',

  MERCHANTS_PROVIDER_REQUEST: 'MERCHANTS_PROVIDER_REQUEST',
  MERCHANTS_PROVIDER_SUCCESS: 'MERCHANTS_PROVIDER_SUCCESS',
  MERCHANTS_PROVIDER_FAILURE: 'MERCHANTS_PROVIDER_FAILURE',
};

interface LoadGetMerchantListAction {
  type: typeof merchantConstants.GET_MERCHANT_LIST_REQUEST
  allMerchantList: IMerchant[]
  payload: IMerchant
  uuid: string
  errors?: any;
  merchantPspList: MerchantPsp[];
  merchantPspDetails: MerchantPsp;
  selectedPspId: number;
  pagination: Pagination;
  merchantOptions:AccountOptions[];
}

interface LoadedGetMerchantListAction {
  type: typeof merchantConstants.GET_MERCHANT_LIST_SUCCESS
  allMerchantList: IMerchant[]
  payload: IMerchant
  uuid: string
  errors?: any;
  merchantPspList: MerchantPsp[];
  merchantPspDetails: MerchantPsp;
  selectedPspId: number;
  pagination: Pagination;
  merchantOptions:AccountOptions[];
}

interface FailedGetMerchantListAction {
  type: typeof merchantConstants.GET_MERCHANT_LIST_FAILURE
  allMerchantList: IMerchant[]
  payload: IMerchant
  uuid: string
  errors?: any;
  merchantPspList: MerchantPsp[];
  merchantPspDetails: MerchantPsp;
  selectedPspId: number;
  pagination: Pagination;
  merchantOptions:AccountOptions[];
}

interface LoadGetMerchantByIdAction {
  type: typeof merchantConstants.GET_MERCHANT_BY_ID_REQUEST
  allMerchantList: IMerchant[]
  payload: IMerchant
  uuid: string
  errors?: any;
  merchantPspList: MerchantPsp[];
  merchantPspDetails: MerchantPsp;
  selectedPspId: number;
  pagination: Pagination;
  merchantOptions:AccountOptions[];
}

interface LoadedGetMerchantByIdAction {
  type: typeof merchantConstants.GET_MERCHANT_BY_ID_SUCCESS
  allMerchantList: IMerchant[]
  payload: IMerchant
  uuid: string
  errors?: any;
  merchantPspList: MerchantPsp[];
  merchantPspDetails: MerchantPsp;
  selectedPspId: number;
  pagination: Pagination;
  merchantOptions:AccountOptions[];
}

interface FailedGetMerchantByIdAction {
  type: typeof merchantConstants.GET_MERCHANT_BY_ID_FAILURE
  allMerchantList: IMerchant[]
  payload: IMerchant
  uuid: string
  errors?: any;
  merchantPspList: MerchantPsp[];
  merchantPspDetails: MerchantPsp;
  selectedPspId: number;
  pagination: Pagination;
  merchantOptions:AccountOptions[];
}

interface LoadGetPspByMerchantByUUIDAction {
  type: typeof merchantConstants.GET_PSP_BY_MERCHANT_UUID_REQUEST
  allMerchantList: IMerchant[]
  payload: IMerchant
  uuid: string
  errors?: any;
  merchantPspList: MerchantPsp[];
  merchantPspDetails: MerchantPsp;
  selectedPspId: number;
  pagination: Pagination;
  merchantOptions:AccountOptions[];
}

interface LoadedGetPspMerchantByUUIDAction {
  type: typeof merchantConstants.GET_PSP_BY_MERCHANT_UUID_SUCCESS
  allMerchantList: IMerchant[]
  payload: IMerchant
  uuid: string
  errors?: any;
  merchantPspList: MerchantPsp[];
  merchantPspDetails: MerchantPsp;
  selectedPspId: number;
  pagination: Pagination;
  merchantOptions:AccountOptions[];
}

interface FailedGetPspMerchantByUUIDAction {
  type: typeof merchantConstants.GET_PSP_BY_MERCHANT_UUID_FAILURE
  allMerchantList: IMerchant[]
  payload: IMerchant
  uuid: string
  errors?: any;
  merchantPspList: MerchantPsp[];
  merchantPspDetails: MerchantPsp;
  selectedPspId: number;
  pagination: Pagination;
  merchantOptions:AccountOptions[];
}
interface LoadAddMerchantAction {
  type: typeof merchantConstants.ADD_MERCHANT_REQUEST
  allMerchantList: IMerchant[]
  payload: IMerchant
  uuid: string
  errors?: any;
  merchantPspList: MerchantPsp[];
  merchantPspDetails: MerchantPsp;
  selectedPspId: number;
  pagination: Pagination;
  merchantOptions:AccountOptions[];
}

interface LoadedAddMerchantAction {
  type: typeof merchantConstants.ADD_MERCHANT_SUCCESS
  allMerchantList: IMerchant[]
  payload: IMerchant
  uuid: string
  errors?: any;
  merchantPspList: MerchantPsp[];
  merchantPspDetails: MerchantPsp;
  selectedPspId: number;
  pagination: Pagination;
  merchantOptions:AccountOptions[];
}

interface FailedAddMerchantAction {
  type: typeof merchantConstants.ADD_MERCHANT_FAILURE
  allMerchantList: IMerchant[]
  payload: IMerchant
  uuid: string
  errors?: any;
  merchantPspList: MerchantPsp[];
  merchantPspDetails: MerchantPsp;
  selectedPspId: number;
  pagination: Pagination;
  merchantOptions:AccountOptions[];
}

interface LoadUpdateMerchantAction {
  type: typeof merchantConstants.UPDATE_MERCHANT_REQUEST
  allMerchantList: IMerchant[]
  payload: IMerchant
  uuid: string
  errors?: any;
  merchantPspList: MerchantPsp[];
  merchantPspDetails: MerchantPsp;
  selectedPspId: number;
  pagination: Pagination;
  merchantOptions:AccountOptions[];
}

interface LoadedUpdateMerchantAction {
  type: typeof merchantConstants.UPDATE_MERCHANT_SUCCESS
  allMerchantList: IMerchant[]
  payload: IMerchant
  uuid: string
  errors?: any;
  merchantPspList: MerchantPsp[];
  merchantPspDetails: MerchantPsp;
  selectedPspId: number;
  pagination: Pagination;
  merchantOptions:AccountOptions[];
}

interface FailedUpdateMerchantAction {
  type: typeof merchantConstants.UPDATE_MERCHANT_FAILURE
  allMerchantList: IMerchant[]
  payload: IMerchant
  uuid: string
  errors?: any;
  merchantPspList: MerchantPsp[];
  merchantPspDetails: MerchantPsp;
  selectedPspId: number;
  pagination: Pagination;
  merchantOptions:AccountOptions[];
}

interface LoadDeleteMerchantAction {
  type: typeof merchantConstants.DELETE_MERCHANT_REQUEST
  allMerchantList: IMerchant[]
  payload: IMerchant
  uuid: string
  errors?: any;
  merchantPspList: MerchantPsp[];
  merchantPspDetails: MerchantPsp;
  selectedPspId: number;
  pagination: Pagination;
  merchantOptions:AccountOptions[];
}

interface LoadedDeleteMerchantAction {
  type: typeof merchantConstants.DELETE_MERCHANT_SUCCESS
  allMerchantList: IMerchant[]
  payload: IMerchant
  uuid: string
  errors?: any;
  merchantPspList: MerchantPsp[];
  merchantPspDetails: MerchantPsp;
  selectedPspId: number;
  pagination: Pagination;
  merchantOptions:AccountOptions[];
}

interface FailedDeleteMerchantAction {
  type: typeof merchantConstants.DELETE_MERCHANT_FAILURE
  allMerchantList: IMerchant[]
  payload: IMerchant
  uuid: string
  errors?: any;
  merchantPspList: MerchantPsp[];
  merchantPspDetails: MerchantPsp;
  selectedPspId: number;
  pagination: Pagination;
  merchantOptions:AccountOptions[];
}

interface LoadGetMerchantPspListAction {
  type: typeof merchantConstants.GET_MERCHANT_PSP_LIST_REQUEST
  allMerchantList: IMerchant[]
  payload: IMerchant
  uuid: string
  errors?: any;
  merchantPspList: MerchantPsp[];
  merchantPspDetails: MerchantPsp;
  selectedPspId: number;
  pagination: Pagination;
  merchantOptions:AccountOptions[];
}

interface LoadedGetMerchantPspListAction {
  type: typeof merchantConstants.GET_MERCHANT_PSP_LIST_SUCCESS
  allMerchantList: IMerchant[]
  payload: IMerchant
  uuid: string
  errors?: any;
  merchantPspList: MerchantPsp[];
  merchantPspDetails: MerchantPsp;
  selectedPspId: number;
  pagination: Pagination;
  merchantOptions:AccountOptions[];
}

interface FailedGetMerchantPspListAction {
  type: typeof merchantConstants.GET_MERCHANT_PSP_LIST_FAILURE
  allMerchantList: IMerchant[]
  payload: IMerchant
  uuid: string
  errors?: any;
  merchantPspList: MerchantPsp[];
  merchantPspDetails: MerchantPsp;
  selectedPspId: number;
  pagination: Pagination;
  merchantOptions:AccountOptions[];
}

interface LoadGetMerchantPspByIdAction {
  type: typeof merchantConstants.GET_MERCHANT_PSP_BY_ID_REQUEST
  allMerchantList: IMerchant[]
  payload: IMerchant
  uuid: string
  errors?: any;
  merchantPspList: MerchantPsp[];
  merchantPspDetails: MerchantPsp;
  selectedPspId: number;
  pagination: Pagination;
  merchantOptions:AccountOptions[];
}

interface LoadedGetMerchantPspByIdAction {
  type: typeof merchantConstants.GET_MERCHANT_PSP_BY_ID_SUCCESS
  allMerchantList: IMerchant[]
  payload: IMerchant
  uuid: string
  errors?: any;
  merchantPspList: MerchantPsp[];
  merchantPspDetails: MerchantPsp;
  selectedPspId: number;
  pagination: Pagination;
  merchantOptions:AccountOptions[];
}

interface FailedGetMerchantPspByIdAction {
  type: typeof merchantConstants.GET_MERCHANT_PSP_BY_ID_FAILURE
  allMerchantList: IMerchant[]
  payload: IMerchant
  uuid: string
  errors?: any;
  merchantPspList: MerchantPsp[];
  merchantPspDetails: MerchantPsp;
  selectedPspId: number;
  pagination: Pagination;
  merchantOptions:AccountOptions[];
}

interface LoadAddMerchantPspAction {
  type: typeof merchantConstants.ADD_MERCHANT_PSP_REQUEST
  allMerchantList: IMerchant[]
  payload: IMerchant
  uuid: string
  errors?: any;
  merchantPspList: MerchantPsp[];
  merchantPspDetails: MerchantPsp;
  selectedPspId: number;
  pagination: Pagination;
  merchantOptions:AccountOptions[];
}

interface LoadedAddMerchantPspAction {
  type: typeof merchantConstants.ADD_MERCHANT_PSP_SUCCESS
  allMerchantList: IMerchant[]
  payload: IMerchant
  uuid: string
  errors?: any;
  merchantPspList: MerchantPsp[];
  merchantPspDetails: MerchantPsp;
  selectedPspId: number;
  pagination: Pagination;
  merchantOptions:AccountOptions[];
}

interface FailedAddMerchantPspAction {
  type: typeof merchantConstants.ADD_MERCHANT_PSP_FAILURE
  allMerchantList: IMerchant[]
  payload: IMerchant
  uuid: string
  errors?: any;
  merchantPspList: MerchantPsp[];
  merchantPspDetails: MerchantPsp;
  selectedPspId: number;
  pagination: Pagination;
  merchantOptions:AccountOptions[];
}

interface LoadUpdateMerchantPspAction {
  type: typeof merchantConstants.UPDATE_MERCHANT_PSP_REQUEST
  allMerchantList: IMerchant[]
  payload: IMerchant
  uuid: string
  errors?: any;
  merchantPspList: MerchantPsp[];
  merchantPspDetails: MerchantPsp;
  selectedPspId: number;
  pagination: Pagination;
  merchantOptions:AccountOptions[];
}

interface LoadedUpdateMerchantPspAction {
  type: typeof merchantConstants.UPDATE_MERCHANT_PSP_SUCCESS
  allMerchantList: IMerchant[]
  payload: IMerchant
  uuid: string
  errors?: any;
  merchantPspList: MerchantPsp[];
  merchantPspDetails: MerchantPsp;
  selectedPspId: number;
  pagination: Pagination;
  merchantOptions:AccountOptions[];
}

interface FailedUpdateMerchantPspAction {
  type: typeof merchantConstants.UPDATE_MERCHANT_PSP_FAILURE
  allMerchantList: IMerchant[]
  payload: IMerchant
  uuid: string
  errors?: any;
  merchantPspList: MerchantPsp[];
  merchantPspDetails: MerchantPsp;
  selectedPspId: number;
  pagination: Pagination;
  merchantOptions:AccountOptions[];
}

interface LoadDeleteMerchantPspAction {
  type: typeof merchantConstants.DELETE_MERCHANT_PSP_REQUEST
  allMerchantList: IMerchant[]
  payload: IMerchant
  uuid: string
  errors?: any;
  merchantPspList: MerchantPsp[];
  merchantPspDetails: MerchantPsp;
  selectedPspId: number;
  pagination: Pagination;
  merchantOptions:AccountOptions[];
}

interface LoadedDeleteMerchantPspAction {
  type: typeof merchantConstants.DELETE_MERCHANT_PSP_SUCCESS
  allMerchantList: IMerchant[]
  payload: IMerchant
  uuid: string
  errors?: any;
  merchantPspList: MerchantPsp[];
  merchantPspDetails: MerchantPsp;
  selectedPspId: number;
  pagination: Pagination;
  merchantOptions:AccountOptions[];
}

interface FailedDeleteMerchantPspAction {
  type: typeof merchantConstants.DELETE_MERCHANT_PSP_FAILURE
  allMerchantList: IMerchant[]
  payload: IMerchant
  uuid: string
  errors?: any;
  merchantPspList: MerchantPsp[];
  merchantPspDetails: MerchantPsp;
  selectedPspId: number;
  pagination: Pagination;
  merchantOptions:AccountOptions[];
}

interface LoadMerchantsOptions {
  type: typeof merchantConstants.MERCHANTS_PROVIDER_REQUEST  
  allMerchantList: IMerchant[]
  payload: IMerchant
  uuid: string
  errors?: any;
  merchantPspList: MerchantPsp[];
  merchantPspDetails: MerchantPsp;
  selectedPspId: number;
  pagination: Pagination;
  merchantOptions:AccountOptions[];
}

interface LoadedMerchantsOptions {
  type: typeof merchantConstants.MERCHANTS_PROVIDER_SUCCESS
  allMerchantList: IMerchant[]
  payload: IMerchant
  uuid: string
  errors?: any;
  merchantPspList: MerchantPsp[];
  merchantPspDetails: MerchantPsp;
  selectedPspId: number;
  pagination: Pagination;
  merchantOptions:AccountOptions[];
}

interface FailedMerchantsOptions {
  type: typeof merchantConstants.MERCHANTS_PROVIDER_FAILURE
  allMerchantList: IMerchant[]
  payload: IMerchant
  uuid: string
  errors?: any;
  merchantPspList: MerchantPsp[];
  merchantPspDetails: MerchantPsp;
  selectedPspId: number;
  pagination: Pagination;
  merchantOptions:AccountOptions[];
}

export type MerchantActionTypes
  = LoadGetMerchantListAction
  | LoadedGetMerchantListAction
  | FailedGetMerchantListAction

  | LoadGetMerchantByIdAction
  | LoadedGetMerchantByIdAction
  | FailedGetMerchantByIdAction

  | LoadAddMerchantAction
  | LoadedAddMerchantAction
  | FailedAddMerchantAction

  | LoadUpdateMerchantAction
  | LoadedUpdateMerchantAction
  | FailedUpdateMerchantAction

  | LoadDeleteMerchantAction
  | LoadedDeleteMerchantAction
  | FailedDeleteMerchantAction

  | LoadGetMerchantPspListAction
  | LoadedGetMerchantPspListAction
  | FailedGetMerchantPspListAction

  | LoadGetMerchantPspByIdAction
  | LoadedGetMerchantPspByIdAction
  | FailedGetMerchantPspByIdAction

  | LoadAddMerchantPspAction
  | LoadedAddMerchantPspAction
  | FailedAddMerchantPspAction

  | LoadUpdateMerchantPspAction
  | LoadedUpdateMerchantPspAction
  | FailedUpdateMerchantPspAction

  | LoadDeleteMerchantPspAction
  | LoadedDeleteMerchantPspAction
  | FailedDeleteMerchantPspAction

  | LoadGetPspByMerchantByUUIDAction
  | LoadedGetPspMerchantByUUIDAction
  | FailedGetPspMerchantByUUIDAction
  
  | LoadMerchantsOptions
  | LoadedMerchantsOptions
  | FailedMerchantsOptions;
