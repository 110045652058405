import { Pagination, DownloadFile } from '../../types/common.types';
import { ExportData } from '../../types/merchant-billing.types';
import { SettlementReport, updateSettlementForm } from '../../types/settlement.types';
import { adminSettlementConstants, AdminSettlemetActionTypes } from '../constants';

export interface IState {
  loading: boolean;
  isError: boolean;
  updateError: boolean;
  allSettlementList: SettlementReport[];
  pagination: Pagination;
  downloadFile: DownloadFile;
  addForm: boolean;
  updateForm: boolean;
  settlementForEdit: SettlementReport;
  refundSettlementList: SettlementReport[];
  errorMessages: string[];
  exports: ExportData;
}

const initialState: IState = {
  loading: false,
  isError: false,
  updateError: false,
  allSettlementList: [],
  addForm: false,
  updateForm: false,
  pagination: { limit: 10, current_page: 1 },
  downloadFile: { url: '' },
  settlementForEdit: updateSettlementForm,
  refundSettlementList: [],
  errorMessages: [],
  exports: { filename: '' },
};

export const adminSettlementReducer = (state = initialState, action: AdminSettlemetActionTypes): IState => {
  switch (action.type) {
    case adminSettlementConstants.GET_ADMIN_SETTLEMENT_LIST_REQUEST:
      return {
        ...state, loading: true, isError: false, addForm: false, updateForm: false, updateError: false, errorMessages: [],
      };
    case adminSettlementConstants.GET_ADMIN_SETTLEMENT_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        isError: false,
        allSettlementList: action.allSettlementList,
        pagination: action.pagination,
        addForm: false,
        updateForm: false,
      };
    case adminSettlementConstants.GET_ADMIN_SETTLEMENT_LIST_FAILURE:
      return {
        ...state, loading: false, isError: true, addForm: false, updateForm: false, errorMessages: action.errors,
      };

    case adminSettlementConstants.ADD_SETTLEMENT_REQUEST:
      return {
        ...state, loading: true, isError: false, addForm: false, updateForm: false, errorMessages: [],
      };
    case adminSettlementConstants.ADD_SETTLEMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        isError: false,
        addForm: true,
        updateForm: false,
      };
    case adminSettlementConstants.ADD_SETTLEMENT_FAILURE:
      return {
        ...state, loading: false, isError: true, addForm: false, updateForm: false, errorMessages: action.errors,
      };

    case adminSettlementConstants.GET_ADMIN_SETTLEMENT_LIST_DONWLOAD_REQUEST:
      return { ...state, loading: true, isError: false };
    case adminSettlementConstants.GET_ADMIN_SETTLEMENT_LIST_DONWLOAD_SUCCESS:
      return {
        ...state, loading: false, isError: false, downloadFile: action.downloadFile,
      };
    case adminSettlementConstants.GET_ADMIN_SETTLEMENT_LIST_DONWLOAD_FAILURE:
      return {
        ...state, loading: false, isError: true,
      };

    case adminSettlementConstants.UPDATE_SETTLEMENT_REQUEST:
      return {
        ...state, loading: true, updateError: false, addForm: false, updateForm: false,
      };
    case adminSettlementConstants.UPDATE_SETTLEMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        updateError: false,
        addForm: false,
        updateForm: true,
      };
    case adminSettlementConstants.UPDATE_SETTLEMENT_FAILURE:
      return {
        ...state, loading: false, updateError: true, addForm: false, updateForm: false,
      };
    case adminSettlementConstants.RESET_STATE:
      return {
        ...state,
        loading: false,
        isError: false,
        downloadFile: { url: '' },
        exports: { filename: '' },
      };

    case adminSettlementConstants.SETTLEMENT_FOR_EDIT_REQUEST:
      return {
        ...state, loading: true, isError: false, addForm: false, updateForm: false,
      };
    case adminSettlementConstants.SETTLEMENT_FOR_EDIT_SUCCESS:
      return {
        ...state,
        loading: false,
        isError: false,
        addForm: false,
        settlementForEdit: action.settlementForEdit,
      };
    case adminSettlementConstants.SETTLEMENT_FOR_EDIT_FAILURE:
      return {
        ...state, loading: false, isError: true, addForm: false, updateForm: false,
      };

    case adminSettlementConstants.GET_SETTLEMENT_REFUND_LIST_REQUEST:
      return {
        ...state, loading: true, isError: false, addForm: false, updateForm: false, updateError: false, errorMessages: [],
      };
    case adminSettlementConstants.GET_SETTLEMENT_REFUND_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        isError: false,
        refundSettlementList: action.refundSettlementList,
        pagination: action.pagination,
        addForm: false,
        updateForm: false,
      };
    case adminSettlementConstants.GET_SETTLEMENT_REFUND_LIST_FAILURE:
      return {
        ...state, loading: false, isError: true, addForm: false, updateForm: false, errorMessages: action.errors,
      };

    case adminSettlementConstants.ADD_REFUND_REQUEST:
      return {
        ...state, loading: true, isError: false, addForm: false, errorMessages: [],
      };
    case adminSettlementConstants.ADD_REFUND_SUCCESS:
      return {
        ...state,
        loading: false,
        isError: false,
        addForm: true,
      };
    case adminSettlementConstants.ADD_REFUND_FAILURE:
      return {
        ...state, loading: false, isError: true, addForm: false, errorMessages: action.errors,
      };
    case adminSettlementConstants.GET_REFUNDS_EXPORT_REQUEST:
      return { ...state, loading: true, isError: false };
    case adminSettlementConstants.GET_REFUNDS_EXPORT_SUCCESS:
      return {
        ...state, loading: false, isError: false, exports: action.exports,
      };
    case adminSettlementConstants.GET_REFUNDS_EXPORT_FAILURE:
      return {
        ...state, loading: false, isError: true,
      };
    default:
      return state;
  }
};
