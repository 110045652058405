/* eslint camelcase:  off */
import { Dates } from '../../../types/common.types';
import { IMerchant } from '../../../types/merchant.types';

export interface IUser {
  uuid?: string,
  merchant_id?: string | null,
  email: string,
  first_name?: string,
  last_name?: string,
  role: string,
  two_factor_auth_code: boolean,
  enabled?: boolean,
  merchant?: IMerchant,
  password?: string
}

export interface IEditUser {
  uuid?: string,
  first_name?: string,
  last_name?: string,
  role?: string,
  enabled?: boolean,
}

export interface IUserForm extends IUser {
  password: string,
}

export interface ChangePassword {
  id?: string;
  password: string;
}

export const changePassword: ChangePassword = {
  password: '',
};
export const addUserForm: IUserForm = {
  merchant_id: null,
  role: 'merchant',
  first_name: '',
  last_name: '',
  email: '',
  password: '',
  enabled: true,
  two_factor_auth_code: false,
};

export const editUserForm: IUser = {
  uuid: '',
  merchant_id: null,
  role: 'admin',
  first_name: '',
  last_name: '',
  email: '',
  enabled: true,
  two_factor_auth_code: false,
};

export interface UserFilter {
  role: string;
  enabled: number | string;
  selectedDates?: Dates;
  search?:string;
}
