import React from 'react';
import { MerchantAccounts } from '../../AdminPanel/MerchantAccounts/MerchantAccounts';

export const BankAccounts = () => {
  const isMerchantPanel = true;
  return (
    <>
      <MerchantAccounts merchantPanel={isMerchantPanel} />
    </>
  );
};
