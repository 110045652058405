import React, { useEffect } from 'react';
import intl from 'react-intl-universal';
import {
  Button,
  Grid,
  TextField,
} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useDispatch, useSelector } from 'react-redux';
import { InitialAddPayoutAccounts } from '../../../types/merchant-billing.types';
import { merchantBillingActions } from '../../../store/actions';
import { BankNameDropdown } from '../BankNameDropdown';
import { RootState } from '../../../store/reducers';
import { DisplaySnackbar } from '../..';
import { history } from '../../../helpers';
import { merchantActions } from '../../../store/actions/Merchant';
import { MerchantCurrencyBalances } from '../../../types/merchant.types';

interface IProps {
  openDialog: boolean, closePayoutForm: () => void;
}

export const PayoutForm: React.FC<IProps> = ({
  openDialog,
  closePayoutForm,
}: IProps): React.ReactElement => {
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(openDialog);
  const [values, setValues] = React.useState({ ...InitialAddPayoutAccounts });
  const merchantBillingState = useSelector((state: RootState) => state.merchantBillingReducer);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValues({
      ...values,
      [(event.target as HTMLInputElement).name]: (event.target as HTMLInputElement).value,
    });
  };

  const onSubmit = () => {
    dispatch(merchantBillingActions.addPayoutAccounts(values));
  };

  const disabled = values.account_uuid.length < 1 || values.currency.length < 1 || values.amount < 1;
  /**
   * This will run when we open/close payout form dialog
   */
  useEffect(() => {
    setOpen(openDialog);
    setValues({ ...InitialAddPayoutAccounts });
  }, [openDialog]);
  /** *
   * This will run when new payout add then go to specifice uuid
   */
  useEffect(() => {
    if (merchantBillingState.addForm) {
      setTimeout(() => {
        history.push(`/dashboard/payouts/${merchantBillingState.merchantBillingDetails.uuid}`);
      }, 1000);
    }
  }, [merchantBillingState.addForm, merchantBillingState.merchantBillingDetails.uuid]);

  useEffect(() => {
    dispatch(merchantActions.merchantCurrency());
  }, [dispatch]);

  const merchantReducer = useSelector((state: RootState) => state.merchantPanelReducer);
  const merchantCurrencyList: MerchantCurrencyBalances[] = merchantReducer.merchantBalances || [];

  return (
    <>
      <div>
        {merchantBillingState && merchantBillingState.isError
          ? <DisplaySnackbar type="error" message={merchantBillingState.errorMessages.toString()} /> : null}
        {
          merchantBillingState && merchantBillingState.addForm
            ? <DisplaySnackbar type="success" message={intl.get('ADDED_SUCCESSFULLY')} /> : null
        }
        <Dialog maxWidth="md" open={open} onClose={closePayoutForm} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">{intl.get('REQUEST_PAYOUTS')}</DialogTitle>
          <DialogContent>
            <form
              autoComplete="off"
              noValidate
            >
              <Grid
                container
                spacing={2}
              >
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <BankNameDropdown
                    defaultValue={values && values.account_uuid}
                    onChange={(e: string) => setValues({ ...values, account_uuid: e })}
                  />
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    fullWidth
                    label={intl.get('CURRENCY')}
                    size="small"
                    name="currency"
                    required
                    select
                    value={values.currency}
                    onChange={(e) => { setValues({ ...values, currency: e.target.value }); }}
                  >
                    {merchantCurrencyList?.map((value: MerchantCurrencyBalances) => (
                      <option
                        key={value.value}
                        value={value.value}
                      >
                        {value.label}
                      </option>
                    ))}
                  </TextField>
                </Grid>
                <Grid
                  item
                  md={12}
                >
                  <TextField
                    fullWidth
                    label={intl.get('AMOUNT')}
                    name="amount"
                    onChange={handleChange}
                    value={values.amount}
                  />
                </Grid>
              </Grid>
            </form>
          </DialogContent>
          <DialogActions>
            <Grid
              item
            >
              <Button
                style={{
                  background: '#3981f7', color: 'white', cursor: 'pointer', opacity: disabled ? '0.6' : '1',
                }}
                variant="contained"
                onClick={() => onSubmit()}
                disabled={disabled}
              >
                {intl.get('SAVE') || 'Save'}
              </Button>
              <Button
                style={{
                  marginLeft: '20px', background: '#f5f5f5', color: '#3981f7', border: '1px solid #3981f7',
                }}
                variant="contained"
                onClick={closePayoutForm}
              >
                {intl.get('CLOSE') || 'Close'}
              </Button>
            </Grid>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
};
