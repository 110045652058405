import { API } from '../config';
import { Dates } from '../types/common.types';

const getWidgetPayouts = (dates: Dates) => API.get(`/admin/widgets/payouts?from=${dates.from}&to=${dates.to}`);

const getWidgetProviderBalance = (dates: Dates) => API.get(`/admin/widgets/providers-balance?from=${dates.from}&to=${dates.to}`);

const getWidgetTransactions = (dates: Dates) => API.get(`/admin/widgets/transactions?from=${dates.from}&to=${dates.to}`);

const getWidgetErrors = () => API.get('/admin/widgets/errors-summary');

const getMerchantBalances = () => API.get('/admin/widgets/balances');

const getMerchantBalanceSpecific = (merchantUUID?: string) => API.get(`/admin/merchants/${merchantUUID}/balances`);

export const dashboardService = {
  getWidgetPayouts,
  getWidgetProviderBalance,
  getWidgetTransactions,
  getWidgetErrors,
  getMerchantBalances,
  getMerchantBalanceSpecific,
};
