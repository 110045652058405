import { AdminTransactionActionTypes, adminTransactionConstants } from '../constants';
import { IAdminTransaction, IAdminTransactionState } from '../../types/admin-transaction.types';
import { Option, Pagination } from '../../types/common.types';
import { ExportData } from '../../types/merchant-billing.types';

export interface IState {
  loading: boolean
  isError: boolean
  allAdminTransactionList: IAdminTransaction[]
  adminTransactionDetails: IAdminTransaction;
  providerOptions: Option[];
  paymentMethodOptions: Option[];
  exports: ExportData;
  pagination: Pagination;
}

const initialState: IState = {
  loading: false,
  isError: false,
  allAdminTransactionList: [],
  adminTransactionDetails: IAdminTransactionState,
  providerOptions: [],
  paymentMethodOptions: [],
  exports: { filename: '' },
  pagination: { limit: 10, current_page: 1 },
};

export const adminTransactionReducer = (state = initialState, action: AdminTransactionActionTypes): IState => {
  switch (action.type) {
    case adminTransactionConstants.GET_ADMIN_TRANSACTION_LIST_REQUEST:
      return { ...state, loading: true, isError: false };
    case adminTransactionConstants.GET_ADMIN_TRANSACTION_LIST_SUCCESS:
      return {
        ...state, loading: false, isError: false, allAdminTransactionList: action.allAdminTransactionList, pagination: action.pagination,
      };
    case adminTransactionConstants.GET_ADMIN_TRANSACTION_LIST_FAILURE:
      return {
        ...state, loading: false, isError: true,
      };

    case adminTransactionConstants.GET_ADMIN_TRANSACTION_BY_ID_REQUEST:
      return { ...state, loading: true, isError: false };
    case adminTransactionConstants.GET_ADMIN_TRANSACTION_BY_ID_SUCCESS:
      return {
        ...state, loading: false, isError: false, adminTransactionDetails: action.payload,
      };
    case adminTransactionConstants.GET_ADMIN_TRANSACTION_BY_ID_FAILURE:
      return {
        ...state, loading: false, isError: true,
      };

    case adminTransactionConstants.GET_PAYMENT_PROVIDER_OPTIONS_REQUEST:
      return { ...state, loading: true, isError: false };
    case adminTransactionConstants.GET_PAYMENT_PROVIDER_OPTIONS_SUCCESS:
      return {
        ...state, loading: false, isError: false, providerOptions: action.providerOptions,
      };
    case adminTransactionConstants.GET_PAYMENT_PROVIDER_OPTIONS_FAILURE:
      return {
        ...state, loading: false, isError: true,
      };

    case adminTransactionConstants.GET_PAYMENT_METHOD_OPTIONS_REQUEST:
      return { ...state, loading: true, isError: false };
    case adminTransactionConstants.GET_PAYMENT_METHOD_OPTIONS_SUCCESS:
      return {
        ...state, loading: false, isError: false, paymentMethodOptions: action.paymentMethodOptions,
      };
    case adminTransactionConstants.GET_PAYMENT_METHOD_OPTIONS_FAILURE:
      return {
        ...state, loading: false, isError: true,
      };

    case adminTransactionConstants.GET_TRANSACTIONS_EXPORT_REQUEST:
      return { ...state, loading: true, isError: false };
    case adminTransactionConstants.GET_TRANSACTIONS_EXPORT_SUCCESS:
      return {
        ...state, loading: false, isError: false, exports: action.exports,
      };
    case adminTransactionConstants.GET_TRANSACTIONS_EXPORT_FAILURE:
      return {
        ...state, loading: false, isError: true,
      };

    case adminTransactionConstants.RESET_EXPORT_DATA_STATE:
      return {
        ...state, loading: false, isError: false, exports: { filename: '' },
      };

    default:
      return state;
  }
};
