import { API } from '../../config';
import { PayoutFilter } from '../../types/dashboard.types';
import { AddPayoutAccount, AddPayoutAccounts, PayoutAccountIds } from '../../types/merchant-billing.types';

const getMerchantBillingList = (filters?: PayoutFilter) => {
  let queryParams = `${filters?.status ? `&status=${filters.status}` : ''}`;
  if (filters?.currency) {
    queryParams += `&currency=${filters.currency}`;
  }
  if (filters?.selectedDates?.from && filters?.selectedDates?.to) {
    queryParams += `&from=${filters?.selectedDates.from}&to=${filters?.selectedDates.to}`;
  }
  if (filters?.paidOn) {
    queryParams += `&paid_on=${filters?.paidOn}`;
  }
  return API.get(`/merchant/payouts?with=payments,merchant,accounts,transactions.account${queryParams}`);
};

const getPayoutsExport = (filters?: PayoutFilter) => {
  let queryParams = `${filters?.status ? `&status=${filters.status}` : ''}`;
  if (filters?.currency) {
    queryParams += `&currency=${filters.currency}`;
  }
  if (filters?.selectedDates?.from && filters?.selectedDates?.to) {
    queryParams += `&from=${filters?.selectedDates.from}&to=${filters?.selectedDates.to}`;
  }
  if (filters?.paidOn) {
    queryParams += `&paid_on=${filters?.paidOn}`;
  }
  return API.get(`/merchant/exports/payouts?with=payments,merchant,accounts,transactions.account${queryParams}`);
};

const getMerchantBillingById = (uuid: string) => API.get(`/merchant/payouts/${uuid}?with=payments,merchant,accounts,transactions.account`);

const updateMerchantBillingStatus = (body: any) => API.post(`/merchant/payouts/${body.id}/request`);

const getMerchantAccountOptions = () => API.get('/merchant/options/accounts');

const getPayoutTransactionList = (merchantBillUUID: string) => API.get(`/merchant/payouts/${merchantBillUUID}/transactions`);
const addPayoutAccount = (body: AddPayoutAccount) => API.post(`/merchant/payouts/${body.merchantBillUUID}/accounts`, body.form);

const deletePayoutAccount = (payload: PayoutAccountIds) => API
  .delete(`/merchant/payouts/${payload.merchantBillUUID}/accounts/${payload.payoutAccountUUID}`);

const addPayoutAccounts = (body: AddPayoutAccounts) => API.post('/merchant/balances/withdraw', body);

export const billingService = {
  getMerchantBillingList,
  getMerchantBillingById,
  updateMerchantBillingStatus,
  getMerchantAccountOptions,
  getPayoutTransactionList,
  addPayoutAccount,
  deletePayoutAccount,
  getPayoutsExport,
  addPayoutAccounts,
};
