import React from 'react';
import { Role } from '../../types/common.types';
import { AdminMenuItems } from './AdminRouting';
import { MerchantMenuItems } from './MerchantRouting';

interface Props {
  role: string;
  path: string;
  name: string;
}

export const MenuItems: React.FC<Props> = ({ role, path, name }: Props) => (
  <>
    {role && role === Role.Admin
      ? <AdminMenuItems path={path} name={name} />
      : <MerchantMenuItems path={path} name={name} />}
  </>
);
