import { API } from '../config';
import { Pagination } from '../types/common.types';

// eslint-disable-next-line max-len
const getSettlementList = (paginationData: Pagination) => API.get(`/merchant/settlements?limit=${paginationData.limit}&page=${paginationData.current_page}`).then((res) => res.data,
  (err) => err);

const settlementDetails = (settlementUUID: string) => API.get(`/merchant/settlements/${settlementUUID}`).then((res) => res.data,
  (err) => err);

export const merchantSettlementService = {
  getSettlementList,
  settlementDetails,
};
