import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store/reducers';
import Toolbar from './Toolbar';
import PaymentProviderList from './PaymentProviderList';
import { IPaymentProvider } from '../../../types/payment-provider.types';
import { paymentProviderActions } from '../../../store/actions';
import PageHeader from '../../../components/StyledComponents/PageHeader';

export const PaymentProviders = () => {
  const dispatch = useDispatch();
  const [paginationPar, setPaginationPar] = React.useState({ limit: 10, current_page: 1 });
  useEffect(() => {
    dispatch(paymentProviderActions.getPaymentProviderList({ ...paginationPar }));
  }, [dispatch, paginationPar]);
  const paymentProviderReducer = useSelector((state: RootState) => state.paymentProviderReducer);
  const paymentProviderList: IPaymentProvider[] = paymentProviderReducer.allPaymentProviderList || [];
  return (
    <>
      <PageHeader
        label="PAYMENT_PROVIDERS"
        componentToPassDown={<Toolbar />}
      />
      <PaymentProviderList paymentProviders={paymentProviderList} changePaginationOptions={(e) => setPaginationPar(e)} />
    </>
  );
};
