import React from 'react';
import intl from 'react-intl-universal';
import { TextField } from '@material-ui/core';

export const Dropdown = ({
  list, label, i18nTag, onChange, defaultValue, disabled,
}: any) => {
  const [selectedValue, setSelectedValue] = React.useState(defaultValue);

  return (
    <TextField
      fullWidth
      label={intl.get(i18nTag) || label}
      name={label}
      required
      select
      SelectProps={{ native: true }}
      onChange={(e) => { onChange(e.target.value); setSelectedValue(e.target.value); }}
      variant="outlined"
      value={selectedValue}
      disabled={disabled || false}
      style={{ paddingTop: '10px' }}
    >
      {list.map((value: string) => (
        <option
          key={value}
          value={value}
        >
          {value}
        </option>
      ))}
    </TextField>
  );
};
