/* eslint camelcase:  off */
export interface IPaymentProvider {
  id?: number,
  name: string,
  code: string,
  margin_type: string,
  margin_rate: string,
  created_at?: string,
  updated_at?: string,
  parameters: any;
  descriptor: string;
  day_limit: number;
  backup_psp_id: string;
  day_usage: number;
  card_type: string;
  real_day_limit?: number;
  type?: string;
}

export const IPaymentProviderState: IPaymentProvider = {
  name: '',
  code: '',
  margin_type: '',
  margin_rate: '',
  parameters: {},
  descriptor: '',
  day_limit: 0,
  backup_psp_id: '',
  day_usage: 0,
  card_type: '',
};

export const PaymentProviderInitialForm: IPaymentProvider = {
  name: '',
  code: '',
  margin_type: '',
  margin_rate: '',
  parameters: {},
  descriptor: '',
  day_limit: 0,
  backup_psp_id: '',
  day_usage: 0,
  card_type: 'all',
};

export interface PaymentProviderCode {
  id: string,
  label: string
}

export const PaymentProviderState: PaymentProviderCode = {
  id: '',
  label: '',
};
