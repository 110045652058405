export const Role = {
  Admin: 'admin',
  Merchant: 'merchant',
};

export interface Dates {
  to: string;
  from: string;
}

export interface Option {
  label: string;
  value: string | number;
}

export interface TwoFAData {
  secret: string; message: string; qrImage: string;
}
export interface ValidateToken {
  // eslint-disable-next-line camelcase
  two_fa_token: string;
  // eslint-disable-next-line camelcase
  two_fa_code: string | null;
}

export interface Pagination {
  limit: number;
  // eslint-disable-next-line camelcase
  current_page: number;
  total?: number;
}

export interface DownloadFile {
  url: string;
}
