import {
  IMerchantBilling, BillingPayment, AccountOptions, PayoutTransaction, ExportData,
} from '../../types/merchant-billing.types';

interface Types {

  GET_MERCHANT_BILLING_LIST_REQUEST: string;
  GET_MERCHANT_BILLING_LIST_SUCCESS: string;
  GET_MERCHANT_BILLING_LIST_FAILURE: string;

  GET_MERCHANT_BILLING_BY_ID_REQUEST: string;
  GET_MERCHANT_BILLING_BY_ID_SUCCESS: string;
  GET_MERCHANT_BILLING_BY_ID_FAILURE: string;

  ADD_MERCHANT_BILLING_REQUEST: string;
  ADD_MERCHANT_BILLING_SUCCESS: string;
  ADD_MERCHANT_BILLING_FAILURE: string;

  UPDATE_MERCHANT_BILLING_REQUEST: string;
  UPDATE_MERCHANT_BILLING_SUCCESS: string;
  UPDATE_MERCHANT_BILLING_FAILURE: string;

  UPDATE_BILLING_STATUS_REQUEST: string;
  UPDATE_BILLING_STATUS_SUCCESS: string;
  UPDATE_BILLING_STATUS_FAILURE: string;

  GET_MERCHANT_ACCOUNT_OPTIONS_REQUEST: string;
  GET_MERCHANT_ACCOUNT_OPTIONS_SUCCESS: string;
  GET_MERCHANT_ACCOUNT_OPTIONS_FAILURE: string;

  GET_PAYOUT_TRANSACTION_LIST_REQUEST: string;
  GET_PAYOUT_TRANSACTION_LIST_SUCCESS: string;
  GET_PAYOUT_TRANSACTION_LIST_FAILURE: string;

  ADD_PAYOUT_ACCOUNT_REQUEST: string;
  ADD_PAYOUT_ACCOUNT_SUCCESS: string;
  ADD_PAYOUT_ACCOUNT_FAILURE: string;

  DELETE_PAYOUT_ACCOUNT_REQUEST: string;
  DELETE_PAYOUT_ACCOUNT_SUCCESS: string;
  DELETE_PAYOUT_ACCOUNT_FAILURE: string;

  GET_PAYOUTS_EXPORT_REQUEST: string;
  GET_PAYOUTS_EXPORT_SUCCESS: string;
  GET_PAYOUTS_EXPORT_FAILURE: string;

  ADD_PAYOUTS_ACCOUNT_REQUEST: string;
  ADD_PAYOUTS_ACCOUNT_SUCCESS: string;
  ADD_PAYOUTS_ACCOUNT_FAILURE: string;

  RESET_EXPORT_DATA_STATE: string;

}

export const merchantBillingConstants: Types = {

  GET_MERCHANT_BILLING_LIST_REQUEST: 'GET_MERCHANT_BILLING_LIST_REQUEST',
  GET_MERCHANT_BILLING_LIST_SUCCESS: 'GET_MERCHANT_BILLING_LIST_SUCCESS',
  GET_MERCHANT_BILLING_LIST_FAILURE: 'GET_MERCHANT_BILLING_LIST_FAILURE',

  GET_MERCHANT_BILLING_BY_ID_REQUEST: 'GET_MERCHANT_BILLING_BY_ID_REQUEST',
  GET_MERCHANT_BILLING_BY_ID_SUCCESS: 'GET_MERCHANT_BILLING_BY_ID_SUCCESS',
  GET_MERCHANT_BILLING_BY_ID_FAILURE: 'GET_MERCHANT_BILLING_BY_ID_FAILURE',

  ADD_MERCHANT_BILLING_REQUEST: 'ADD_MERCHANT_BILLING_REQUEST',
  ADD_MERCHANT_BILLING_SUCCESS: 'ADD_MERCHANT_BILLING_SUCCESS',
  ADD_MERCHANT_BILLING_FAILURE: 'ADD_MERCHANT_BILLING_FAILURE',

  UPDATE_MERCHANT_BILLING_REQUEST: 'UPDATE_MERCHANT_BILLING_REQUEST',
  UPDATE_MERCHANT_BILLING_SUCCESS: 'UPDATE_MERCHANT_BILLING_SUCCESS',
  UPDATE_MERCHANT_BILLING_FAILURE: 'UPDATE_MERCHANT_BILLING_FAILURE',

  UPDATE_BILLING_STATUS_REQUEST: 'UPDATE_BILLING_STATUS_REQUEST',
  UPDATE_BILLING_STATUS_SUCCESS: 'UPDATE_BILLING_STATUS_SUCCESS',
  UPDATE_BILLING_STATUS_FAILURE: 'UPDATE_BILLING_STATUS_FAILURE',

  GET_MERCHANT_ACCOUNT_OPTIONS_REQUEST: 'GET_MERCHANT_ACCOUNT_OPTIONS_REQUEST',
  GET_MERCHANT_ACCOUNT_OPTIONS_SUCCESS: 'GET_MERCHANT_ACCOUNT_OPTIONS_SUCCESS',
  GET_MERCHANT_ACCOUNT_OPTIONS_FAILURE: 'GET_MERCHANT_ACCOUNT_OPTIONS_FAILURE',

  GET_PAYOUT_TRANSACTION_LIST_REQUEST: 'GET_PAYOUT_TRANSACTION_LIST_REQUEST',
  GET_PAYOUT_TRANSACTION_LIST_SUCCESS: 'GET_PAYOUT_TRANSACTION_LIST_SUCCESS',
  GET_PAYOUT_TRANSACTION_LIST_FAILURE: 'GET_PAYOUT_TRANSACTION_LIST_FAILURE',

  ADD_PAYOUT_ACCOUNT_REQUEST: 'ADD_PAYOUT_ACCOUNT_REQUEST',
  ADD_PAYOUT_ACCOUNT_SUCCESS: 'ADD_PAYOUT_ACCOUNT_SUCCESS',
  ADD_PAYOUT_ACCOUNT_FAILURE: 'ADD_PAYOUT_ACCOUNT_FAILURE',

  DELETE_PAYOUT_ACCOUNT_REQUEST: 'DELETE_PAYOUT_ACCOUNT_REQUEST',
  DELETE_PAYOUT_ACCOUNT_SUCCESS: 'DELETE_PAYOUT_ACCOUNT_SUCCESS',
  DELETE_PAYOUT_ACCOUNT_FAILURE: 'DELETE_PAYOUT_ACCOUNT_FAILURE',

  GET_PAYOUTS_EXPORT_REQUEST: 'GET_PAYOUTS_EXPORT_REQUEST',
  GET_PAYOUTS_EXPORT_SUCCESS: 'GET_PAYOUTS_EXPORT_SUCCESS',
  GET_PAYOUTS_EXPORT_FAILURE: 'GET_PAYOUTS_EXPORT_FAILURE',

  ADD_PAYOUTS_ACCOUNT_REQUEST: 'ADD_PAYOUTS_ACCOUNT_REQUEST',
  ADD_PAYOUTS_ACCOUNT_SUCCESS: 'ADD_PAYOUTS_ACCOUNT_SUCCESS',
  ADD_PAYOUTS_ACCOUNT_FAILURE: 'ADD_PAYOUTS_ACCOUNT_FAILURE',

  RESET_EXPORT_DATA_STATE: 'RESET_EXPORT_DATA_STATE',

};

interface LoadGetMerchantBillingListAction {
  type: typeof merchantBillingConstants.GET_MERCHANT_BILLING_LIST_REQUEST;
  merchantBillingList: IMerchantBilling[];
  payload: IMerchantBilling;
  id: number;
  uuid: string;
  billingPayments: BillingPayment[];
  accountOptions: AccountOptions[];
  payoutTransactions: PayoutTransaction[];
  exports: ExportData;
  errors?: any;
}

interface LoadedGetMerchantBillingListAction {
  type: typeof merchantBillingConstants.GET_MERCHANT_BILLING_LIST_SUCCESS;
  merchantBillingList: IMerchantBilling[];
  payload: IMerchantBilling;
  id: number;
  uuid: string;
  billingPayments: BillingPayment[];
  accountOptions: AccountOptions[];
  payoutTransactions: PayoutTransaction[];
  exports: ExportData;
  errors?: any;

}

interface FailedGetMerchantBillingListAction {
  type: typeof merchantBillingConstants.GET_MERCHANT_BILLING_LIST_FAILURE;
  merchantBillingList: IMerchantBilling[];
  payload: IMerchantBilling;
  id: number;
  uuid: string;
  billingPayments: BillingPayment[];
  accountOptions: AccountOptions[];
  payoutTransactions: PayoutTransaction[];
  exports: ExportData;
  errors?: any;
}

interface LoadGetPayoutTransactionListAction {
  type: typeof merchantBillingConstants.GET_PAYOUT_TRANSACTION_LIST_REQUEST;
  merchantBillingList: IMerchantBilling[];
  payload: IMerchantBilling;
  id: number;
  uuid: string;
  billingPayments: BillingPayment[];
  accountOptions: AccountOptions[];
  payoutTransactions: PayoutTransaction[];
  exports: ExportData;
  errors?: any;
}

interface LoadedGetPayoutTransactionListAction {
  type: typeof merchantBillingConstants.GET_PAYOUT_TRANSACTION_LIST_SUCCESS;
  merchantBillingList: IMerchantBilling[];
  payload: IMerchantBilling;
  id: number;
  uuid: string;
  billingPayments: BillingPayment[];
  accountOptions: AccountOptions[];
  payoutTransactions: PayoutTransaction[];
  exports: ExportData;
  errors?: any;
}

interface FailedGetPayoutTransactionListAction {
  type: typeof merchantBillingConstants.GET_PAYOUT_TRANSACTION_LIST_FAILURE;
  merchantBillingList: IMerchantBilling[];
  payload: IMerchantBilling;
  id: number;
  uuid: string;
  billingPayments: BillingPayment[];
  accountOptions: AccountOptions[];
  payoutTransactions: PayoutTransaction[];
  exports: ExportData;
  errors?: any;
}

interface LoadGetBillingPaymentListAction {
  type: typeof merchantBillingConstants.GET_MERCHANT_BILLING_LIST_REQUEST;
  merchantBillingList: IMerchantBilling[];
  payload: IMerchantBilling;
  id: number;
  uuid: string;
  billingPayments: BillingPayment[];
  accountOptions: AccountOptions[];
  payoutTransactions: PayoutTransaction[];
  exports: ExportData;
  errors?: any;
}

interface LoadedGetBillingPaymentListAction {
  type: typeof merchantBillingConstants.GET_MERCHANT_BILLING_LIST_SUCCESS;
  merchantBillingList: IMerchantBilling[];
  payload: IMerchantBilling;
  id: number;
  uuid: string;
  billingPayments: BillingPayment[];
  accountOptions: AccountOptions[];
  payoutTransactions: PayoutTransaction[];
  exports: ExportData;
  errors?: any;
}

interface FailedGetBillingPaymentListAction {
  type: typeof merchantBillingConstants.GET_MERCHANT_BILLING_LIST_FAILURE;
  merchantBillingList: IMerchantBilling[];
  payload: IMerchantBilling;
  id: number;
  uuid: string;
  billingPayments: BillingPayment[];
  accountOptions: AccountOptions[];
  payoutTransactions: PayoutTransaction[];
  exports: ExportData;
  errors?: any;
}

interface LoadGetMerchantBillingByIdAction {
  type: typeof merchantBillingConstants.GET_MERCHANT_BILLING_BY_ID_REQUEST;
  merchantBillingList: IMerchantBilling[];
  payload: IMerchantBilling;
  id: number;
  uuid: string;
  billingPayments: BillingPayment[];
  accountOptions: AccountOptions[];
  payoutTransactions: PayoutTransaction[];
  exports: ExportData;
  errors?: any;
}

interface LoadedGetMerchantBillingByIdAction {
  type: typeof merchantBillingConstants.GET_MERCHANT_BILLING_BY_ID_SUCCESS;
  merchantBillingList: IMerchantBilling[];
  payload: IMerchantBilling;
  id: number;
  uuid: string;
  billingPayments: BillingPayment[];
  accountOptions: AccountOptions[];
  payoutTransactions: PayoutTransaction[];
  exports: ExportData;
  errors?: any;
}

interface FailedGetMerchantBillingByIdAction {
  type: typeof merchantBillingConstants.GET_MERCHANT_BILLING_BY_ID_FAILURE;
  merchantBillingList: IMerchantBilling[];
  payload: IMerchantBilling;
  id: number;
  uuid: string;
  billingPayments: BillingPayment[];
  accountOptions: AccountOptions[];
  payoutTransactions: PayoutTransaction[];
  exports: ExportData;
  errors?: any;
}

interface LoadAddMerchantBillingAction {
  type: typeof merchantBillingConstants.ADD_MERCHANT_BILLING_REQUEST;
  merchantBillingList: IMerchantBilling[];
  payload: IMerchantBilling;
  id: number;
  uuid: string;
  billingPayments: BillingPayment[];
  accountOptions: AccountOptions[];
  payoutTransactions: PayoutTransaction[];
  exports: ExportData;
  errors?: any;
}

interface LoadedAddMerchantBillingAction {
  type: typeof merchantBillingConstants.ADD_MERCHANT_BILLING_SUCCESS;
  merchantBillingList: IMerchantBilling[];
  payload: IMerchantBilling;
  id: number;
  uuid: string;
  billingPayments: BillingPayment[];
  accountOptions: AccountOptions[];
  payoutTransactions: PayoutTransaction[];
  exports: ExportData;
  errors?: any;
}

interface FailedAddMerchantBillingAction {
  type: typeof merchantBillingConstants.ADD_MERCHANT_BILLING_FAILURE;
  merchantBillingList: IMerchantBilling[];
  payload: IMerchantBilling;
  id: number;
  uuid: string;
  billingPayments: BillingPayment[];
  accountOptions: AccountOptions[];
  payoutTransactions: PayoutTransaction[];
  exports: ExportData;
  errors?: any;
}

interface LoadUpdateMerchantBillingAction {
  type: typeof merchantBillingConstants.UPDATE_MERCHANT_BILLING_REQUEST;
  merchantBillingList: IMerchantBilling[];
  payload: IMerchantBilling;
  id: number;
  uuid: string;
  billingPayments: BillingPayment[];
  accountOptions: AccountOptions[];
  payoutTransactions: PayoutTransaction[];
  exports: ExportData;
  errors?: any;
}

interface LoadedUpdateMerchantBillingAction {
  type: typeof merchantBillingConstants.UPDATE_MERCHANT_BILLING_SUCCESS;
  merchantBillingList: IMerchantBilling[];
  payload: IMerchantBilling;
  id: number;
  uuid: string;
  billingPayments: BillingPayment[];
  accountOptions: AccountOptions[];
  payoutTransactions: PayoutTransaction[];
  exports: ExportData;
  errors?: any;
}

interface FailedUpdateMerchantBillingAction {
  type: typeof merchantBillingConstants.UPDATE_MERCHANT_BILLING_FAILURE;
  merchantBillingList: IMerchantBilling[];
  payload: IMerchantBilling;
  id: number;
  uuid: string;
  billingPayments: BillingPayment[];
  accountOptions: AccountOptions[];
  payoutTransactions: PayoutTransaction[];
  exports: ExportData;
  errors?: any;
}

interface LoadUpdateBillingStatusAction {
  type: typeof merchantBillingConstants.UPDATE_MERCHANT_BILLING_REQUEST;
  merchantBillingList: IMerchantBilling[];
  payload: IMerchantBilling;
  id: number;
  uuid: string;
  billingPayments: BillingPayment[];
  accountOptions: AccountOptions[];
  payoutTransactions: PayoutTransaction[];
  exports: ExportData;
  errors?: any;
}

interface LoadedUpdateBillingStatusAction {
  type: typeof merchantBillingConstants.UPDATE_MERCHANT_BILLING_SUCCESS;
  merchantBillingList: IMerchantBilling[];
  payload: IMerchantBilling;
  id: number;
  uuid: string;
  billingPayments: BillingPayment[];
  accountOptions: AccountOptions[];
  payoutTransactions: PayoutTransaction[];
  exports: ExportData;
  errors?: any;
}

interface FailedUpdateBillingStatusAction {
  type: typeof merchantBillingConstants.UPDATE_MERCHANT_BILLING_FAILURE;
  merchantBillingList: IMerchantBilling[];
  payload: IMerchantBilling;
  id: number;
  uuid: string;
  billingPayments: BillingPayment[];
  accountOptions: AccountOptions[];
  payoutTransactions: PayoutTransaction[];
  exports: ExportData;
  errors?: any;
}

interface LoadGetMerchantAccountOptionsAction {
  type: typeof merchantBillingConstants.GET_MERCHANT_ACCOUNT_OPTIONS_REQUEST
  merchantBillingList: IMerchantBilling[];
  payload: IMerchantBilling;
  id: number;
  uuid: string;
  billingPayments: BillingPayment[];
  accountOptions: AccountOptions[];
  payoutTransactions: PayoutTransaction[];
  exports: ExportData;
  errors?: any;

}

interface LoadedGetMerchantAccountOptionsAction {
  type: typeof merchantBillingConstants.GET_MERCHANT_ACCOUNT_OPTIONS_SUCCESS
  merchantBillingList: IMerchantBilling[];
  payload: IMerchantBilling;
  id: number;
  uuid: string;
  billingPayments: BillingPayment[];
  accountOptions: AccountOptions[];
  payoutTransactions: PayoutTransaction[];
  exports: ExportData;
  errors?: any;
}

interface FailedGetMerchantAccountOptionsAction {
  type: typeof merchantBillingConstants.GET_MERCHANT_ACCOUNT_OPTIONS_FAILURE
  merchantBillingList: IMerchantBilling[];
  payload: IMerchantBilling;
  id: number;
  uuid: string;
  billingPayments: BillingPayment[];
  accountOptions: AccountOptions[];
  payoutTransactions: PayoutTransaction[];
  exports: ExportData;
  errors?: any;
}

interface LoadGetPayoutsExportAction {
  type: typeof merchantBillingConstants.GET_PAYOUTS_EXPORT_REQUEST;
  merchantBillingList: IMerchantBilling[];
  payload: IMerchantBilling;
  id: number;
  uuid: string;
  billingPayments: BillingPayment[];
  accountOptions: AccountOptions[];
  payoutTransactions: PayoutTransaction[];
  exports: ExportData;
  errors?: any;
}

interface LoadedGetPayoutsExportAction {
  type: typeof merchantBillingConstants.GET_PAYOUTS_EXPORT_SUCCESS;
  merchantBillingList: IMerchantBilling[];
  payload: IMerchantBilling;
  id: number;
  uuid: string;
  billingPayments: BillingPayment[];
  accountOptions: AccountOptions[];
  payoutTransactions: PayoutTransaction[];
  exports: ExportData;
  errors?: any;
}

interface FailedGetPayoutsExportAction {
  type: typeof merchantBillingConstants.GET_PAYOUTS_EXPORT_FAILURE;
  merchantBillingList: IMerchantBilling[];
  payload: IMerchantBilling;
  id: number;
  uuid: string;
  billingPayments: BillingPayment[];
  accountOptions: AccountOptions[];
  payoutTransactions: PayoutTransaction[];
  exports: ExportData;
  errors?: any;
}

interface LoadAddPayoutsAction {
  type: typeof merchantBillingConstants.ADD_PAYOUTS_ACCOUNT_REQUEST;
  merchantBillingList: IMerchantBilling[];
  payload: IMerchantBilling;
  id: number;
  uuid: string;
  billingPayments: BillingPayment[];
  accountOptions: AccountOptions[];
  payoutTransactions: PayoutTransaction[];
  exports: ExportData;
  errors?: any;
}

interface LoadedAddPayoutsAction {
  type: typeof merchantBillingConstants.ADD_PAYOUTS_ACCOUNT_SUCCESS;
  merchantBillingList: IMerchantBilling[];
  payload: IMerchantBilling;
  id: number;
  uuid: string;
  billingPayments: BillingPayment[];
  accountOptions: AccountOptions[];
  payoutTransactions: PayoutTransaction[];
  exports: ExportData;
  errors?: any;
}

interface FailedAddPayoutsAction {
  type: typeof merchantBillingConstants.ADD_PAYOUTS_ACCOUNT_FAILURE;
  merchantBillingList: IMerchantBilling[];
  payload: IMerchantBilling;
  id: number;
  uuid: string;
  billingPayments: BillingPayment[];
  accountOptions: AccountOptions[];
  payoutTransactions: PayoutTransaction[];
  exports: ExportData;
  errors?: any;
}
export type MerchantBillingActionTypes
  = LoadGetMerchantBillingListAction
  | LoadedGetMerchantBillingListAction
  | FailedGetMerchantBillingListAction

  | LoadGetMerchantBillingByIdAction
  | LoadedGetMerchantBillingByIdAction
  | FailedGetMerchantBillingByIdAction

  | LoadAddMerchantBillingAction
  | LoadedAddMerchantBillingAction
  | FailedAddMerchantBillingAction

  | LoadUpdateMerchantBillingAction
  | LoadedUpdateMerchantBillingAction
  | FailedUpdateMerchantBillingAction

  | LoadGetBillingPaymentListAction
  | LoadedGetBillingPaymentListAction
  | FailedGetBillingPaymentListAction

  | LoadUpdateBillingStatusAction
  | LoadedUpdateBillingStatusAction
  | FailedUpdateBillingStatusAction

  | LoadGetMerchantAccountOptionsAction
  | LoadedGetMerchantAccountOptionsAction
  | FailedGetMerchantAccountOptionsAction

  | LoadGetPayoutTransactionListAction
  | LoadedGetPayoutTransactionListAction
  | FailedGetPayoutTransactionListAction

  | LoadGetPayoutsExportAction
  | LoadedGetPayoutsExportAction
  | FailedGetPayoutsExportAction

  | LoadAddPayoutsAction
  | LoadedAddPayoutsAction
  | FailedAddPayoutsAction;
