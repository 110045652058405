import { API } from '../config';
import { ValidateToken } from '../types/common.types';

const login = (email: string, password: string) => API.post('/auth/login', { email, password });
// .then((res) => res, (err) => err);

const logout = () => {
  // remove user from local storage to log user out
  localStorage.removeItem('auth');
};

const resetPasswordLink = (email: string) => API.post('/auth/password-reset-request', { email });
const updatePassword = (password: string) => API.post('/auth/password-update', { password });

// .then((res) => res, (err) => err);

const setNewPassword = (token: string, password: string) => API.post('/auth/password-reset', { token, password });

const activate2FA = (code: number | null) => API.post('/auth/2fa/activate', { code });
// .then((res) => res, (err) => err);

const request2FA = () => API.post('/auth/2fa/request').then((res) => res, (err) => err);

const validate2FA = (payload: ValidateToken) => API.post('/auth/2fa/validate', payload);
// .then((res) => res, (err) => err);

export const authService = {
  login,
  logout,
  resetPasswordLink,
  setNewPassword,
  activate2FA,
  request2FA,
  validate2FA,
  updatePassword,
};
