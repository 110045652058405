import { Dispatch } from 'redux';
import { AxiosError } from 'axios';
import { merchantConstants } from '../../constants/Merchant';
import { merchantService } from '../../../services/Merchant';
import { IMerchant, MerchantCurrencyBalances } from '../../../types/merchant.types';

const getMerchant = () => {
  const request = () => ({ type: merchantConstants.GET_MERCHANT_REQUEST });
  const success = (merchant: IMerchant) => (
    { type: merchantConstants.GET_MERCHANT_SUCCESS, merchant }
  );
  const failure = (error: any) => ({ type: merchantConstants.GET_MERCHANT_FAILURE, error });
  return (dispatch: Dispatch) => {
    dispatch(request());
    merchantService.getMerchant()
      .then(
        (res) => {
          dispatch(success(res.data));
        },
      ).catch((error) => {
        dispatch(failure(error.message));
      });
  };
};

const updateMerchant = (body: any) => {
  const request = () => ({ type: merchantConstants.UPDATE_MERCHANT_REQUEST });
  const success = (merchant: IMerchant) => ({ type: merchantConstants.UPDATE_MERCHANT_SUCCESS, merchant });
  const failure = (errors: any) => ({ type: merchantConstants.UPDATE_MERCHANT_FAILURE, errors });
  return (dispatch: Dispatch) => {
    dispatch(request());
    merchantService.updateMerchant(body)
      .then(
        (res) => {
          dispatch(success(res.data));
        },
      ).catch((error: AxiosError) => dispatch(failure(error.response?.data?.errors)));
  };
};

const merchantCurrency = () => {
  const request = () => ({ type: merchantConstants.GET_MERCHANT_BALANCES_REQUEST });
  const success = (merchantBalances: MerchantCurrencyBalances[]) => (
    { type: merchantConstants.GET_MERCHANT_BALANCES_SUCCESS, merchantBalances }
  );
  const failure = (errors: any) => ({ type: merchantConstants.GET_MERCHANT_BALANCES_FAILURE, errors });
  return (dispatch: Dispatch) => {
    dispatch(request());
    merchantService.merchantCurrency()
      .then(
        (res) => {
          dispatch(success(res.data));
        },
      ).catch((error: AxiosError) => dispatch(failure(error.response?.data?.errors)));
  };
};

export const merchantActions = {
  getMerchant,
  updateMerchant,
  merchantCurrency,
};
