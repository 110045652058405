import { Dispatch } from 'redux';
import { AxiosError } from 'axios';
import { authConstants } from '../constants';
import { authService } from '../../services';
import { history } from '../../helpers';
import { TwoFAData, ValidateToken } from '../../types/common.types';

const login = (username: string, password: string) => {
  const request = () => ({ type: authConstants.LOGIN_REQUEST });
  const success = (loggedInUser: any, resetPassword: boolean) => ({ type: authConstants.LOGIN_SUCCESS, loggedInUser, resetPassword });
  const failure = (errors: any) => ({ type: authConstants.LOGIN_FAILURE, errors });

  return (dispatch: Dispatch) => {
    dispatch(request());

    authService.login(username, password)
      .then(
        (res) => {
          if (res && res.status === 200) {
            if (res.data && res.data.two_fa_token) {
              dispatch(success({ name: res.data.name, role: res.data.role, FAToken: res.data.two_fa_token }, res.data.resetPassword));
            } else if (res.data && res.data.resetPassword) {
              localStorage.setItem('reset_password_token', res.data.access_token);
              dispatch(success({
                name: res.data.name, role: res.data.role, FAToken: '', loggedIn: false,
              }, res.data.resetPassword));
            } else {
              const resetPasswordTokenAvailable = localStorage.getItem('reset_password_token') || '{}';
              if (resetPasswordTokenAvailable) {
                localStorage.removeItem('reset_password_token');
              }
              localStorage.setItem('auth', JSON.stringify(res.data));
              dispatch(success({
                name: res.data.name, role: res.data.role, FAToken: '', loggedIn: true,
              }, res.data.resetPassword));
              history.push('/dashboard');
              // if (res.data.role === 'admin') {
              // } else {
              //   history.push('/merchant');
              // }
            }
          } else {
            dispatch(failure('Something went wrong'));
          }
        }, (error) => {
          dispatch(failure(error.toString()));
        },
      );
  };
};

const updatePassword = (newPassword: string) => {
  const request = () => ({ type: authConstants.UPDATE_PASSWORD_REQUEST });
  const success = (loggedInUser: any) => ({ type: authConstants.UPDATE_PASSWORD_SUCCESS, loggedInUser });
  const failure = (errors: any) => ({ type: authConstants.UPDATE_PASSWORD_FAILURE, errors });

  return (dispatch: Dispatch) => {
    dispatch(request());

    authService.updatePassword(newPassword)
      .then(
        (res) => {
          if (res && res.status === 200) {
            const resetPasswordTokenAvailable = localStorage.getItem('reset_password_token') || '{}';
            if (resetPasswordTokenAvailable) {
              localStorage.removeItem('reset_password_token');
            }
            localStorage.setItem('auth', JSON.stringify(res.data));
            dispatch(success({
              name: res.data.name, role: res.data.role, FAToken: '', loggedIn: true,
            }));
          } else {
            dispatch(failure('Something went wrong'));
          }
        },
        // (error) => {
        //   dispatch(failure(error.toString()));
        // },
      ).catch((error: AxiosError) => dispatch(failure(error.response?.data?.errors || [])));
  };
};

const resetPasswordLink = (email: string) => {
  const request = () => ({ type: authConstants.RESET_PASSWORD_LINK_REQUEST });
  const success = () => ({ type: authConstants.RESET_PASSWORD_LINK_SUCCESS });
  const failure = (errors: any) => ({ type: authConstants.RESET_PASSWORD_LINK_FAILURE, errors });

  return (dispatch: Dispatch) => {
    dispatch(request());

    authService.resetPasswordLink(email)
      .then(
        (res) => {
          if (res && res.status === 200) {
            dispatch(success());
          } else {
            dispatch(failure('Something went wrong'));
          }
        },
        // (error) => {
        //   dispatch(failure(error.toString()));
        // },
      ).catch((error: AxiosError) => dispatch(failure(error.response?.data?.errors || [])));
  };
};

const activate2FA = (code: number | null) => {
  const request = () => ({ type: authConstants.ACTIVATE_2FA_REQUEST });
  const success = () => ({ type: authConstants.ACTIVATE_2FA_SUCCESS });
  const failure = (errors: any) => ({ type: authConstants.ACTIVATE_2FA_FAILURE, errors });

  return (dispatch: Dispatch) => {
    dispatch(request());

    authService.activate2FA(code)
      .then(
        (res) => {
          if (res && res.status === 200) {
            dispatch(success());
          } else {
            dispatch(failure('Something went wrong'));
          }
        },
      ).catch((error: AxiosError) => dispatch(failure(error.response?.data?.errors || [])));
  };
};

const request2FA = () => {
  const request = () => ({ type: authConstants.REQUEST_2FA_REQUEST });
  const success = (twoFAData: TwoFAData) => ({ type: authConstants.REQUEST_2FA_SUCCESS, twoFAData });
  const failure = (errors: any) => ({ type: authConstants.REQUEST_2FA_FAILURE, errors });

  return (dispatch: Dispatch) => {
    dispatch(request());

    authService.request2FA()
      .then(
        (res) => {
          dispatch(success(res.data));
        },
      ).catch((error: AxiosError) => {
        dispatch(failure(error.response?.data?.errors || []));
      });
  };
};
const validate2FA = (payload: ValidateToken) => {
  const request = () => ({ type: authConstants.VALIDATE_2FA_REQUEST });
  const success = (resetPassword: boolean) => ({ type: authConstants.VALIDATE_2FA_SUCCESS, resetPassword });
  const failure = (errors: any) => ({ type: authConstants.VALIDATE_2FA_FAILURE, errors });

  return (dispatch: Dispatch) => {
    dispatch(request());

    authService.validate2FA(payload)
      .then(
        (res) => {
          if (res && res.status === 200) {
            if (res.data.resetPassword) {
              dispatch(success(res.data.resetPassword));
            } else {
              dispatch(success(res.data.resetPassword));
              localStorage.setItem('auth', JSON.stringify(res.data));
              history.push('/dashboard');
            }
          } else {
            dispatch(failure('Something went wrong'));
          }
        },
      ).catch((error: AxiosError) => {
        dispatch(failure(error.response?.data?.errors || []));
      });
  };
};
export const resetState = () => {
  const request = () => ({ type: authConstants.RESET_TWO_FA_STATE });

  return (dispatch: Dispatch) => {
    dispatch(request());
  };
};

const logout = () => {
  authService.logout();
  history.push('/');
  return { type: authConstants.LOGOUT };
};

const setNewPassword = (token: string, password: string) => {
  const request = () => ({ type: authConstants.SET_NEW_PASSWORD_REQUEST });
  const success = () => ({ type: authConstants.SET_NEW_PASSWORD_SUCCESS });
  const failure = (errors: any) => ({ type: authConstants.SET_NEW_PASSWORD_FAILURE, errors });

  return (dispatch: Dispatch) => {
    dispatch(request());

    authService.setNewPassword(token, password)
      .then(
        (res) => {
          if (res && res.status === 200) {
            dispatch(success());
            localStorage.removeItem('resetPasswordToken');
            setTimeout(() => {
              history.push('/login');
            }, 3000);
          }
        },
      ).catch((error: AxiosError) => dispatch(failure(error.response?.data?.errors || [])));
  };
};

export const authActions = {
  login,
  logout,
  resetPasswordLink,
  setNewPassword,
  activate2FA,
  request2FA,
  validate2FA,
  resetState,
  updatePassword,
};
