import React, { useEffect } from 'react';
import intl from 'react-intl-universal';
import 'date-fns';
import {
  Box,
  IconButton,
  Button,
  ThemeProvider,
  Card,
  CardContent,
} from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles, Theme } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  DatePicker,
} from '@material-ui/pickers';
import { useDispatch, useSelector } from 'react-redux';
import FilterListIcon from '@material-ui/icons/FilterList';
import { MerchantForm } from '../../../components';
import { SearchInput } from '../../../components/SearchInput';
import { customDatePicker } from '../../../assets/styles/date-picker';
import ExportIcon from '../../../assets/icons/Export.svg';
import { Dates, Pagination } from '../../../types/common.types';
import { MerchantFilter } from '../../../types/merchant.types';
import { merchantBillingActions, merchantActions } from '../../../store/actions';
import { RootState } from '../../../store/reducers';

const useStyles = makeStyles((theme: Theme) => ({
  iconStyle: {
    fill: '#3485f7',
  },
  dateLabel: {
    fontWeight: 'bold',
    margin: '10px',
    marginTop: '15px',
  },
  formControl: {
    marginRight: theme.spacing(2),
    minWidth: 130,
  },
  MuiInputBaseinput: {
    width: '91px',
  },
  clearFilters: {
    color: '#3485f7',
    fontSize: '12px',
    cursor: 'pointer',
  },
  filterContainer: {
    marginBottom: '10px',
    position: 'relative',
  },
}));

export interface Props {
  pagination: Pagination;
}

const initialFilters: MerchantFilter = {
  searchTerm: '', active: '', kyc_approved: '', on_hold: '', obsolete: '', selectedDates: { from: '', to: '' },
};

export default ({ pagination }: Props) => {
  const [displayMerchantForm, setdisplayMerchantForm] = React.useState(false);

  const openMerchantForm = () => {
    setdisplayMerchantForm(true);
  };

  const closeMerchantForm = () => {
    setdisplayMerchantForm(false);
  };
  const MerchantState = useSelector((state: RootState) => state.merchantReducer);

  React.useEffect(() => {
    if (MerchantState.isUpdated) {
      setdisplayMerchantForm(false);
    }
  }, [MerchantState.isUpdated]);

  const classes = useStyles();
  const dispatch = useDispatch();
  const [selectedDates, setSelectedDates] = React.useState<Dates>({ from: '', to: '' });
  const [selectedFilters, setSelectedFilters] = React.useState<MerchantFilter>(initialFilters);
  const now = new Date(Date.now());
  const [selectedFromDate, setSelectedFromDate] = React.useState<Date | null>(
    new Date(now.getFullYear(), now.getMonth(), 1),
  );
  const [selectedToDate, setSelectedToDate] = React.useState<Date | null>(
    new Date(now.getFullYear(), now.getMonth(), (new Date(now.getFullYear(), now.getMonth(), 0)).getDate()),
  );
  const [showFilters, setShowFilters] = React.useState(false);

  const dateFormat = (date: Date | null) => {
    const year = date?.getFullYear();
    const month = `${(date && (date?.getMonth() + 1).toString().length === 1 ? `0${date?.getMonth() + 1}` : (date && date?.getMonth() + 1))}`;
    const day = `${date?.getDate().toString().length === 1 ? `0${date?.getDate()}` : date?.getDate()}`;
    return `${year}-${month}-${day}`;
  };

  const handleFromDateChange = (date: Date | null) => {
    setSelectedFromDate(date);
    const dates = { to: selectedDates.to, from: dateFormat(date) };
    setSelectedDates(dates);
    setSelectedFilters({ ...selectedFilters, selectedDates: dates });
  };

  const handleToDateChange = (date: Date | null) => {
    setSelectedToDate(date);
    const dates = { to: dateFormat(date), from: selectedDates.from };
    setSelectedDates(dates);
    setSelectedFilters({ ...selectedFilters, selectedDates: dates });
  };

  const changeActive = (event: React.ChangeEvent<{ value: unknown }>) => {
    const selectedValue = event.target.value as boolean;
    setSelectedFilters({ ...selectedFilters, active: selectedValue });
  };

  const changeKYC = (event: React.ChangeEvent<{ value: unknown }>) => {
    const selectedValue = event.target.value as boolean;
    setSelectedFilters({ ...selectedFilters, kyc_approved: selectedValue });
  };

  const changeOnHold = (event: React.ChangeEvent<{ value: unknown }>) => {
    const selectedValue = event.target.value as boolean;
    setSelectedFilters({ ...selectedFilters, on_hold: selectedValue });
  };

  const changeObsolete = (event: React.ChangeEvent<{ value: unknown }>) => {
    const selectedValue = event.target.value as boolean;
    setSelectedFilters({ ...selectedFilters, obsolete: selectedValue });
  };

  useEffect(() => {
    dispatch(merchantActions.getMerchantList(pagination, selectedFilters));
  }, [dispatch, selectedFilters, pagination]);

  const clearFilters = () => {
    setSelectedFilters(initialFilters);
    setSelectedDates({ from: '', to: '' });
    setSelectedFromDate(new Date());
    setSelectedToDate(new Date());
  };

  const exportData = () => {
    if (selectedFilters.selectedDates?.from && selectedFilters.selectedDates.to) {
      dispatch(merchantBillingActions.getPayoutsExport(false, selectedFilters));
    } else {
      const dates = { to: dateFormat(selectedToDate), from: dateFormat(selectedFromDate) };
      dispatch(merchantBillingActions.getPayoutsExport(false, { ...selectedFilters, selectedDates: dates }));
    }
  };

  const merchantBillingReducer = useSelector((state: RootState) => state.merchantBillingReducer);
  useEffect(() => {
    if (merchantBillingReducer?.exports?.filename) {
      window.open(merchantBillingReducer?.exports?.filename, '_blank');
      dispatch(merchantBillingActions.resetPayoutsExportState());
    }
  }, [merchantBillingReducer, dispatch]);

  return (
    <div>
      <Box>
        <Card>
          <CardContent>
            {
              showFilters === false ? (
                <Grid container wrap="wrap" justify="space-between" style={{ justifyContent: 'flex-end' }}>
                  <Box>
                    <IconButton onClick={() => setShowFilters(!showFilters)}>
                      <FilterListIcon className={classes.iconStyle} />
                    </IconButton>
                    <span>
                      {intl.get('FILTER')}
                    </span>
                  </Box>
                </Grid>
              )
                : null
            }
            {showFilters ? (
              <Grid className={classes.filterContainer} container spacing={1} wrap="wrap" justify="space-between">
                <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between" style={{ width: '100%' }}>
                  <Box display="flex" flexDirection="row" alignItems="center" justifyContent="flex-end">
                    <Box m={1} display="flex" flexDirection="row" flexWrap="wrap" alignItems="flex-end" justifyContent="center">

                      <FormControl className={classes.formControl}>
                        <InputLabel id="demo-simple-select-label">{intl.get('ACTIVE')}</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={selectedFilters.active}
                          onChange={changeActive}
                        >
                          <MenuItem value="">all</MenuItem>
                          <MenuItem value="true">{intl.get('YES')}</MenuItem>
                          <MenuItem value="false">{intl.get('NO')}</MenuItem>
                        </Select>
                      </FormControl>

                      <FormControl className={classes.formControl}>
                        <InputLabel id="demo-simple-select-label">{intl.get('KYC_APPROVED')}</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={selectedFilters.kyc_approved}
                          onChange={changeKYC}
                        >
                          <MenuItem value="">all</MenuItem>
                          <MenuItem value="true">{intl.get('YES')}</MenuItem>
                          <MenuItem value="false">{intl.get('NO')}</MenuItem>
                        </Select>
                      </FormControl>

                      <FormControl className={classes.formControl}>
                        <InputLabel id="demo-simple-select-label">{intl.get('ON_HOLD')}</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={selectedFilters.on_hold}
                          onChange={changeOnHold}
                        >
                          <MenuItem value="">all</MenuItem>
                          <MenuItem value="true">{intl.get('YES')}</MenuItem>
                          <MenuItem value="false">{intl.get('NO')}</MenuItem>
                        </Select>
                      </FormControl>

                      <FormControl className={classes.formControl}>
                        <InputLabel id="demo-simple-select-label">{intl.get('OBSOLETE')}</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={selectedFilters.obsolete}
                          onChange={changeObsolete}
                        >
                          <MenuItem value="">all</MenuItem>
                          <MenuItem value="true">{intl.get('YES')}</MenuItem>
                          <MenuItem value="false">{intl.get('NO')}</MenuItem>
                        </Select>
                      </FormControl>

                      <span
                        className={classes.clearFilters}
                        onClick={() => clearFilters()}
                        aria-hidden="true"
                      >
                        {intl.get('CLEAR_ALL_FILTERS')}
                      </span>
                    </Box>
                  </Box>
                  <IconButton
                    onClick={() => setShowFilters(!showFilters)}
                    style={{
                      position: 'absolute',
                      top: '-5px',
                      right: '-5px',
                    }}
                  >
                    <CloseIcon className={classes.iconStyle} />
                  </IconButton>
                </Box>
              </Grid>
            ) : null}

            <Grid container wrap="wrap" justify="space-between" alignItems="center">
              <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                <Grid container justify="flex-start" alignItems="center" wrap="wrap">
                  <ThemeProvider theme={customDatePicker}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <Box
                        display="flex"
                        flexDirection="row"
                        justifyContent="flex-start"
                        alignItems="center"
                      >
                        <span className={classes.dateLabel}>{intl.get('FROM')}</span>
                        <DatePicker
                          disableToolbar
                          inputVariant="outlined"
                          format="MM/dd/yyyy"
                          margin="normal"
                          id="date-from"
                          className={classes.MuiInputBaseinput}
                          value={selectedFromDate}
                          onChange={handleFromDateChange}
                          style={{ marginRight: '10px' }}
                        />
                        <span className={classes.dateLabel}>{intl.get('TO')}</span>
                        <DatePicker
                          disableToolbar
                          inputVariant="outlined"
                          format="MM/dd/yyyy"
                          margin="normal"
                          id="date-to"
                          className={classes.MuiInputBaseinput}
                          value={selectedToDate}
                          onChange={handleToDateChange}
                        />
                      </Box>
                    </MuiPickersUtilsProvider>
                  </ThemeProvider>
                  <div style={{ marginTop: '9px', marginLeft: '20px' }}>
                    <Button
                      onClick={exportData}
                      variant="outlined"
                      size="small"
                      style={{ display: 'flex', alignItems: 'center', borderRadius: '5px' }}
                    >
                      {intl.get('EXPORT')}
                      <img src={ExportIcon} alt="" width="25" height="20" />
                    </Button>
                  </div>
                  <div style={{ marginTop: '9px', marginLeft: '20px' }}>
                    <SearchInput width="150px" onChange={() => { console.log('hello world'); }} />
                  </div>
                </Grid>
              </div>
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                  style={{ color: 'white', marginTop: '10px' }}
                  color="primary"
                  variant="contained"
                  onClick={openMerchantForm}
                >
                  {intl.get('ADD_MERCHANT') || 'Add Merchant'}
                </Button>
              </div>
            </Grid>
            <MerchantForm openDialog={displayMerchantForm} closeMerchantForm={closeMerchantForm} selectedMerchant={null} />
          </CardContent>
        </Card>
      </Box>
    </div>
  );
};
