import React, { useEffect } from 'react';
import intl from 'react-intl-universal';
import {
  Button,
  Grid,
} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

interface Types {
  openDialog: boolean, closeConfirmationBox: () => void, submitConfirmationBox: () => void,
}

export const ConfirmationBox: React.FC<Types> = ({ openDialog, closeConfirmationBox, submitConfirmationBox }: Types) => {
  const [open, setOpen] = React.useState(openDialog);

  useEffect(() => {
    setOpen(openDialog);
  }, [openDialog]);

  return (
    <div>
      <div>
        <Dialog open={open} onClose={closeConfirmationBox} aria-labelledby="form-dialog-title" fullWidth maxWidth="sm">
          <DialogContent>
            <h3 style={{ textAlign: 'center' }}>Are you sure to proceed?</h3>
          </DialogContent>
          <DialogActions>
            <Grid
              item
            >
              <Button
                style={{ background: '#3485f7', color: 'white' }}
                variant="contained"
                onClick={() => {
                  submitConfirmationBox();
                  closeConfirmationBox();
                }}
              >
                {intl.get('YES') || 'Yes'}
              </Button>
              <Button
                variant="contained"
                style={{
                  marginLeft: '20px', background: '#f5f5f5', color: '#3485f7', border: '1px solid #3485f7',
                }}
                onClick={closeConfirmationBox}
              >
                {intl.get('NO') || 'No'}
              </Button>
            </Grid>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
};
