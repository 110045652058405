import { Dates } from './common.types';
import { IPaymentProvider } from './payment-provider.types';

/* eslint camelcase:  off */
export interface IMerchant {
  uuid?: string,
  company_name: string,
  company_code?: string,
  margin_rate: string,
  margin_type: string,
  kyc_approved: boolean,
  obsolete: boolean,
  on_hold: boolean,
  api_key?: string,
  api_secret?: string,
  created_at?: string,
  updated_at?: string
  base_fee?: string
  billing_period?: number
  company_country?: string
  kyc_approved_on?: string
  obsolete_on?: string
  on_hold_on?: string
  primary_contact_email?: string
  primary_contact_name?: string
  primary_contact_phone?: string
  technical_contact_email?: string
  technical_contact_name?: string
  technical_contact_phone?: string
  vat_number?: string,
  currency: string,
  mdr?: number;
  type: string;
  deposit_address?: string;
}

export const IMerchantState: IMerchant = {
  uuid: '',
  company_name: '',
  company_code: '',
  margin_rate: '',
  margin_type: '',
  kyc_approved: false,
  obsolete: false,
  on_hold: false,
  api_key: '',
  api_secret: '',
  created_at: '',
  updated_at: '',
  base_fee: '',
  currency: '',
  billing_period: 0,
  company_country: '',
  kyc_approved_on: '',
  obsolete_on: '',
  on_hold_on: '',
  primary_contact_email: '',
  primary_contact_name: '',
  primary_contact_phone: '',
  technical_contact_email: '',
  technical_contact_name: '',
  technical_contact_phone: '',
  vat_number: '',
  mdr: 0,
  type: '',
};

export const MerchantInitialForm: IMerchant = {
  company_name: '',
  company_code: '',
  margin_rate: '',
  margin_type: '',
  company_country: '',
  vat_number: '',
  currency: '',
  primary_contact_email: '',
  primary_contact_name: '',
  primary_contact_phone: '',
  technical_contact_email: '',
  technical_contact_name: '',
  technical_contact_phone: '',
  kyc_approved: false,
  obsolete: false,
  on_hold: false,
  type: '',
  deposit_address: '',
};

export interface MerchantFilter {
  searchTerm?: string;
  active?: boolean | string;
  kyc_approved?: boolean | string;
  on_hold?: boolean | string;
  obsolete?: boolean | string;
  selectedDates?: Dates;
}

export interface MerchantPsp {
  id?: number;
  merchant_uuid: string;
  payment_provider_id: number | string;
  payment_method: string;
  active: boolean;
  ratio: number;
  merchant?: IMerchant;
  payment_provider?: IPaymentProvider;
  created_at?: string;
  updated_at?: string;
}

export const MerchantPspInitialState: MerchantPsp = {
  merchant_uuid: '',
  payment_provider_id: '',
  payment_method: '',
  active: true,
  ratio: 0,
};

export interface MerchantCurrencyBalances {
  value: string;
  label: string;
}
