import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import intl from 'react-intl-universal';
import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  makeStyles,
  ThemeProvider,
  TablePagination,
  LinearProgress,
} from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import DeleteIcon from '@material-ui/icons/Delete';
import { Link, useRouteMatch } from 'react-router-dom';
import { paymentProviderActions } from '../../../store/actions';
import { RootState } from '../../../store/reducers';
import { DisplaySnackbar, StyledTableHeadCell } from '../../../components';
import { IPaymentProvider } from '../../../types/payment-provider.types';
import { tableTheme } from '../../../assets/styles/table-style';
import { paginationTheme } from '../../../components/StyledComponents/paginateTheme';
import { ConfirmationBox } from '../../../components/ConfirmationBox';

interface IPaymentProviderTypes {
  paymentProviders: IPaymentProvider[];
  changePaginationOptions: (e: any) => void;
}

const useStyles = makeStyles({
  root: {
    marginTop: '10px',
  },
  table: {
    minWidth: 650,
  },
  tableHeadCell: {
    fontSize: '13px',
    fontWeight: 'bold',
    lineHeight: '1.2',
    letterSpacing: '0.9',
    color: '#232931',
  },
  tableBodyCell: {
    color: '#232931',
    fontSize: '11px',
  },
  deleteIcon: {
    color: 'black',
    cursor: 'pointer',
    '&:hover': {
      color: 'red',
    },
  },
  colorPrimary: {
    backgroundColor: '#3485f7',
  },
  barColorPrimary: {
    backgroundColor: '#3485f7',
  },
});

export default ({ paymentProviders, changePaginationOptions }: IPaymentProviderTypes) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [confirmation, setConfirmation] = React.useState(false);
  const paymentProviderState = useSelector((state: RootState) => state.paymentProviderReducer);
  const { path } = useRouteMatch();
  const [pagination, setPagination] = React.useState({
    limit: 10,
    current_page: 1,
  });
  const { total } = paymentProviderState.pagination;

  const onDelete = (id: any) => {
    dispatch(paymentProviderActions.deletePaymentProvider(id));
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPagination({
      ...pagination,
      current_page: ++newPage,
    });
    changePaginationOptions({ ...pagination, current_page: newPage });
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPagination({
      ...pagination,
      limit: +event.target.value,
      current_page: 1,
    });
    changePaginationOptions({ ...pagination, limit: +event.target.value });
  };

  const { loading } = paymentProviderState;

  const openConfirmationBox = () => {
    setConfirmation(true);
  };

  const closeConfirmationBox = () => {
    setConfirmation(false);
  };

  return (
    <Card className={classes.root}>
      <TableContainer component={Paper}>
        <ThemeProvider theme={tableTheme}>
          {loading ? <LinearProgress classes={{ colorPrimary: classes.colorPrimary, barColorPrimary: classes.barColorPrimary }} /> : null}
          <Table size="small" className={classes.table} aria-label="paymentProvider list">
            <TableHead>
              <TableRow>
                <TableCell><StyledTableHeadCell>{intl.get('NAME')}</StyledTableHeadCell></TableCell>
                <TableCell><StyledTableHeadCell>{intl.get('CODE')}</StyledTableHeadCell></TableCell>
                <TableCell><StyledTableHeadCell>{intl.get('MARGIN_TYPE')}</StyledTableHeadCell></TableCell>
                <TableCell><StyledTableHeadCell>{intl.get('DESCRIPTOR')}</StyledTableHeadCell></TableCell>
                <TableCell><StyledTableHeadCell>{intl.get('DAY_LIMIT')}</StyledTableHeadCell></TableCell>
                <TableCell><StyledTableHeadCell>{intl.get('DAY_USAGE')}</StyledTableHeadCell></TableCell>
                <TableCell><StyledTableHeadCell>{intl.get('ACTIONS')}</StyledTableHeadCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {paymentProviders && paymentProviders.map((row: IPaymentProvider) => (
                <TableRow key={row?.id}>
                  <TableCell>{row.name}</TableCell>
                  <TableCell>{row.code}</TableCell>
                  <TableCell>{row.margin_type}</TableCell>
                  <TableCell>{row.descriptor}</TableCell>
                  <TableCell>{row.day_limit}</TableCell>
                  <TableCell>{row.day_usage}</TableCell>
                  <TableCell align="center" style={{ display: 'flex', justifyContent: 'space-around' }}>
                    <Link to={`${path}/${row.name.toLowerCase().replace(/\s/g, '-')}/${row.id}`}>{intl.get('DETAILS') || 'Details'}</Link>
                    <DeleteIcon className={classes.deleteIcon} onClick={() => openConfirmationBox()} />
                  </TableCell>
                  <ConfirmationBox openDialog={confirmation} closeConfirmationBox={closeConfirmationBox} submitConfirmationBox={() => onDelete(row.id)} />
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </ThemeProvider>
      </TableContainer>
      {
        total && paymentProviders && paymentProviders.length
          ? (
            <ThemeProvider theme={paginationTheme}>
              <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={total}
                rowsPerPage={pagination.limit}
                page={pagination.current_page - 1}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </ThemeProvider>
          )
          : null
      }
      {
        paymentProviderState && paymentProviderState.isDeleted
          ? <DisplaySnackbar type="success" message={intl.get('DELETED_SUCCESSFULLY') || 'Deleted successfully'} /> : null
      }
    </Card>
  );
};
