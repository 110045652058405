import React from 'react';
import intl from 'react-intl-universal';
import {
  Switch,
  Route,
  NavLink,
} from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import { history } from '../../helpers';
import { Users } from '../AdminPanel/Users';
import { Merchants, MerchantDetail } from '../AdminPanel/Merchants';
import { PaymentProviders, PaymentProviderDetail } from '../AdminPanel/PaymentProviders';
import { Transactions } from '../AdminPanel/Transactions';
import { BillDetails, MerchantBillings, PayBill } from '../AdminPanel/MerchantBillings';
import { Dashboard } from '../AdminPanel/Dashboard/Dashboard';
import HomeIcon from '../../assets/icons/Home.png';
import HomeActiveIcon from '../../assets/icons/Home_Active.png';
import UserIcon from '../../assets/icons/User.png';
import UserActiveIcon from '../../assets/icons/User_Active.png';
import PaymentProviderIcon from '../../assets/icons/Payment_Provider.png';
import PaymentProviderActiveIcon from '../../assets/icons/Payment_Provider_Active.png';
import TransactionIcon from '../../assets/icons/Transaction.png';
import TransactionActiveIcon from '../../assets/icons/Transaction_Active.png';
import SettlementIcon from '../../assets/icons/settlement.png';
import SettlementActiveIcon from '../../assets/icons/settlement_Active.png';
import RefundIcon from '../../assets/icons/Refund.png';
import RefundActiveIcon from '../../assets/icons/Refund_Active.png';
import PayoutIcon from '../../assets/icons/Payout.svg';
import PayoutActiveIcon from '../../assets/icons/Payout_1.png';
import ImagePlaceholder from '../../assets/icons/avatar.jpg';
import TransactionReports from '../AdminPanel/SettlementReports/TransactionReports';
import SettlementReportsForm from '../AdminPanel/SettlementReports/SettlementReportsForm';
import { EditSettlementReportForm } from '../AdminPanel/SettlementReports/EditSettlementReportForm';
import { utils } from '../../helpers/utils';
import Refund from '../AdminPanel/SettlementRefund/Refund';
import { RefundForm } from '../../components/Forms';
import { TwoFAAuthentication } from '../MerchantPanel/Merchant/twoFA';

interface Props {
  path: string;
}

interface MenuProps extends Props {
  name: string;
}

const useStyles = makeStyles(() => ({
  root: {
    marginTop: '30%',
    '@global': {
      '*::-webkit-scrollbar': {
        width: '0',
        background: 'transparent',
      },
    },
  },

  linkStyle: {
    textDecoration: 'none',
    color: '#FFF',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingBottom: '30px',
    marginTop: '10px',
    position: 'relative',
  },
  iconStyle: {
    margin: '8px',
    color: '#fff',
  },
  activeLink: {
    background: 'rgba(84, 97, 114, 0.4)',
    width: '95%',
  },
  lineStyle: {
    border: 'solid 5px #3485f7',
    width: '0',
    position: 'absolute',
    right: '-6px',
    height: '88px',
    marginTop: '30px',
    borderRadius: '5px',
  },
  user: {
    color: '#000',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    margin: '5px',
    background: 'rgba(84, 97, 114, 0.4)',
    borderRadius: '5px',
    width: '90%',
    padding: '10px 0',
  },
}));

export const AdminMenuItems: React.FC<MenuProps> = ({ path, name }: MenuProps) => {
  const classes = useStyles();
  return (
    <>
      <div className={classes.root}>
        <div className="left-menu">
          <NavLink to={path} className={classes.linkStyle} activeClassName={classes.activeLink} exact>
            {path === history.location.pathname ? <img src={HomeActiveIcon} alt="" width="32" className={classes.iconStyle} />
              : <img src={HomeIcon} alt="" width="32" className={classes.iconStyle} />}
            <span>{intl.get('HOME')}</span>
            {path === history.location.pathname ? <span className={classes.lineStyle} /> : null}
          </NavLink>
          <NavLink to={`${path}/admin-transactions`} className={classes.linkStyle} activeClassName={classes.activeLink} exact>
            {`${path}/admin-transactions` === `${history.location.pathname}`
              ? <img src={TransactionActiveIcon} alt="" width="32" className={classes.iconStyle} />
              : <img src={TransactionIcon} alt="" width="32" className={classes.iconStyle} />}
            <span>{intl.get('TRANSACTIONS')}</span>
            {`${path}/admin-transactions` === `${history.location.pathname}` ? <span className={classes.lineStyle} /> : null}
          </NavLink>
          <NavLink to={`${path}/payouts`} className={classes.linkStyle} activeClassName={classes.activeLink}>
            {history.location.pathname.includes('payouts')
              ? <img src={PayoutActiveIcon} alt="" width="32" className={classes.iconStyle} />
              : <img src={PayoutIcon} alt="" width="32" className={classes.iconStyle} />}
            <span>{intl.get('PAYOUTS')}</span>
            {history.location.pathname.includes('payouts') ? <span className={classes.lineStyle} /> : null}
          </NavLink>
          <NavLink to={`${path}/refunds`} className={classes.linkStyle} activeClassName={classes.activeLink}>
            {history.location.pathname.includes('refunds')
              ? <img src={RefundActiveIcon} alt="" width="32" className={classes.iconStyle} />
              : <img src={RefundIcon} alt="" width="32" className={classes.iconStyle} />}
            <span>{intl.get('REFUNDS')}</span>
            {history.location.pathname.includes('refunds') ? <span className={classes.lineStyle} /> : null}
          </NavLink>
          <NavLink to={`${path}/settlements`} className={classes.linkStyle} activeClassName={classes.activeLink}>
            {history.location.pathname.includes('settlements')
              ? <img src={SettlementActiveIcon} alt="" width="32" className={classes.iconStyle} />
              : <img src={SettlementIcon} alt="" width="32" className={classes.iconStyle} />}
            <span>{intl.get('SETTLEMENTS')}</span>
            {history.location.pathname.includes('settlements') ? <span className={classes.lineStyle} /> : null}
          </NavLink>
          <NavLink to={`${path}/merchants`} className={classes.linkStyle} activeClassName={classes.activeLink} exact>
            {`${path}/merchants` === `${history.location.pathname}` ? <img src={UserActiveIcon} alt="" width="32" className={classes.iconStyle} />
              : <img src={UserIcon} alt="" width="32" className={classes.iconStyle} />}
            <span>{intl.get('MERCHANTS')}</span>
            {`${path}/merchants` === `${history.location.pathname}` ? <span className={classes.lineStyle} /> : null}
          </NavLink>
          <NavLink to={`${path}/payment-providers`} className={classes.linkStyle} activeClassName={classes.activeLink}>
            {history.location.pathname.includes('payment-providers')
              ? <img src={PaymentProviderActiveIcon} alt="" width="32" className={classes.iconStyle} />
              : <img src={PaymentProviderIcon} alt="" width="32" className={classes.iconStyle} />}
            <span>{intl.get('PAYMENT_PROVIDERS')}</span>
            {history.location.pathname.includes('payment-providers') ? <span className={classes.lineStyle} /> : null}
          </NavLink>
          <NavLink to={`${path}/users`} className={classes.linkStyle} activeClassName={classes.activeLink} exact>
            {`${path}/users` === `${history.location.pathname}` ? <img src={UserActiveIcon} alt="" width="32" className={classes.iconStyle} />
              : <img src={UserIcon} alt="" width="32" className={classes.iconStyle} />}
            <span>{intl.get('USERS')}</span>
            {`${path}/users` === `${history.location.pathname}` ? <span className={classes.lineStyle} /> : null}
          </NavLink>
          <div className={classes.user}>
            <NavLink to={`${path}/twoFA`} className={classes.linkStyle} activeClassName={classes.activeLink} exact>
              <Avatar alt="Merchant image" src={ImagePlaceholder} style={{ margin: '5px', width: '32px', height: '32px' }} />
              <span>{name}</span>
            </NavLink>
          </div>
        </div>
      </div>
    </>
  );
};

export const AdminRoutes: React.FC<Props> = ({ path }: Props) => (
  <>
    <Switch>
      <Route exact path={path}>
        <Dashboard />
      </Route>
      <Route exact path={`${path}/users`}>
        <Users />
      </Route>
      <Route exact path={`${path}/merchants`}>
        <Merchants />
      </Route>
      <Route exact path={`${path}/merchants/:companyName/:merchantId`}>
        <MerchantDetail />
      </Route>
      <Route exact path={`${path}/payment-providers`}>
        <PaymentProviders />
      </Route>
      <Route exact path={`${path}/payment-providers/:name/:id`}>
        <PaymentProviderDetail />
      </Route>
      <Route exact path={`${path}/admin-transactions`}>
        <Transactions merchantPanel={false} />
      </Route>
      <Route exact path={`${path}/refunds`}>
        <Refund merchantPanel={false} />
      </Route>
      <Route exact path={`${path}/refunds/create`}>
        <RefundForm merchantPanel={false} />
      </Route>
      <Route exact path={`${path}/payouts`}>
        <MerchantBillings merchantPanel={false} />
      </Route>
      <Route exact path={`${path}/payouts/:merchantId/:billingId`}>
        <BillDetails />
      </Route>
      <Route exact path={`${path}/billings/pay-bill/:billingId`}>
        <PayBill />
      </Route>
      <Route exact path={`${path}/settlements`}>
        <TransactionReports merchantPanel={utils.userType() === 'merchant'} />
      </Route>
      <Route exact path={`${path}/settlements/Form`}>
        <SettlementReportsForm merchantPanel={false} />
      </Route>
      <Route exact path={`${path}/settlements/EditForm/:settlementUUID`}>
        <EditSettlementReportForm merchantPanel={false} />
      </Route>
      <Route exact path={`${path}/twoFA`}>
        <TwoFAAuthentication />
      </Route>
    </Switch>
  </>
);
