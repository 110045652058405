import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  useParams,
} from 'react-router-dom';
import { RootState } from '../../../../store/reducers';
import Toolbar from './Toolbar';
import MerchantPspList from './PspAccountList';
import { merchantActions } from '../../../../store/actions';
import { MerchantPsp } from '../../../../types/merchant.types';
import PageCommon from '../../../../components/StyledComponents/PageCommon';

type RouteParams = {
  merchantId: string;
}
interface Types {
  merchantPanel: boolean;
}

export const PSPAccounts = ({ merchantPanel }: Types) => {
  const dispatch = useDispatch();
  const params = useParams<RouteParams>();
  useEffect(() => {
    if (params.merchantId) {
      dispatch(merchantActions.getPspByMerchantUUID(params.merchantId));
    } else {
      dispatch(merchantActions.getMerchantPspList());
    }
  }, [dispatch, params.merchantId]);
  const merchantReducer = useSelector((state: RootState) => state.merchantReducer);
  const merchantPspList: MerchantPsp[] = merchantReducer.merchantPspList || [];
  return (
    <>
      <PageCommon
        label="PAYMENT_PROVIDERS"
        componentToPassDown={<Toolbar merchantUUID={params.merchantId} merchantPanel={merchantPanel} />}
      />
      <MerchantPspList merchantPspList={merchantPspList} merchantUUID={params.merchantId} merchantPanel={merchantPanel} />
    </>
  );
};
