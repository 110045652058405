import React, { useState, useEffect } from 'react';
import intl from 'react-intl-universal';
import { useSelector, useDispatch } from 'react-redux';
import {
  Card,
  Box,
  Divider,
  Grid,
  Button,
  Radio,
  RadioGroup,
  FormControlLabel,
  TextField,
  MenuItem,
} from '@material-ui/core';
import {
  makeStyles, Theme, createStyles,
} from '@material-ui/core/styles';
import countryList from 'react-select-country-list';
import { IMerchantAccount, MerchantAccountState, IMerchantAccountUpdate } from '../../../types/merchant-account.types';
import { merchantAccountActions } from '../../../store/actions';
import { RootState } from '../../../store/reducers';
// import { DisplaySnackbar } from '../../index';


interface IProps {
  closeMerchantAccountForm: () => void;
  selectedMerchantAccount: IMerchantAccount | null;
  merchantUUID: string;
  merchantPanel: boolean;
}

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    marginTop: '10px',
    color: '#fff',
  },
  expandedPanel: {
    padding: '10px',
    backgroundColor: '#2c333d',
    fontSize: '12px',
  },
  divider: {
    background: '#fff',
    margin: '10px auto',
  },
  detailPanelSection: {
    padding: theme.spacing(2),
  },
  inputContainer: {
    margin: '10px',
  },
  inputContainer1: {
    margin: '10px',
    padding: '20px',
  },
  detailPanelInput: {
    all: 'unset',
    padding: '5px',
    margin: '5px',
    backgroundColor: '#3d4651 !important',
  },
  fullWidth: {
    width: '100%',
  },
  input: {
    color: '#fff',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  option: {
    color: 'black',
  },
}));

export const MerchantAccountEdit: React.FC<IProps> = ({
  closeMerchantAccountForm,
  selectedMerchantAccount,
  merchantUUID,
  merchantPanel,
}: IProps): React.ReactElement => {
  const classes = useStyles();
  const [form, setForm] = useState({ ...MerchantAccountState });
  const [selectMerchantAccountUUID, setSelectMerchantAccountUUID] = useState<string>('');

  const dispatch = useDispatch();
  const onSubmit = () => {
    if (selectedMerchantAccount) {
      const forUpdate: IMerchantAccountUpdate = { Ids: { merchantUUID, merchantAccountUUID: selectMerchantAccountUUID }, form };
      dispatch(merchantAccountActions.updateMerchantAccount(forUpdate, merchantPanel));
    } else {
      dispatch(merchantAccountActions.addMerchantAccount({ merchantUUID, form }, merchantPanel));
    }
  };
  const merchantAccountState = useSelector((state: RootState) => state.merchantAccountReducer);

  /**
      * This will run when merchantAccount reducer updates
      */
  useEffect(() => {
    if (merchantAccountState.isUpdated) {
      /**
          * Reset form only if a new merchantAccount added
          */
      if (!selectedMerchantAccount) {
        setForm({ ...MerchantAccountState });
      }
    }
  }, [merchantAccountState, selectedMerchantAccount]);

  /**
    * This will check if merchantAccount is being adding or editing
    */
  useEffect(() => {
    if (selectedMerchantAccount) {
      setSelectMerchantAccountUUID(selectedMerchantAccount.uuid || '');
      setForm({ ...selectedMerchantAccount, type: selectedMerchantAccount.type || '', note: selectedMerchantAccount.note || '' });
    }
  }, [selectedMerchantAccount]);

  const list = ['fiat', 'crypto'];
  const option = countryList().getData();
  return (
    <Card className={classes.root}>
      <div className={classes.expandedPanel}>
        <Box
          display="flex"
          justifyContent="flex-start"
          flexDirection="row"
          flexWrap="wrap"
        >
          {
            form.type === 'crypto' ? (
              <>

                <Grid
                  item
                  md={12}
                >
                  <Box
                    display="flex"
                    flexDirection="row"
                    flexWrap="wrap"
                    justifyContent="flex-start"
                    alignItems="center"
                  >
                    <div className={classes.inputContainer1}>
                      <Box
                        display="flex"
                        flexDirection="row"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <span>{intl.get('DISPLAY_NAME')}</span>
                        <input
                          type="text"
                          className={classes.detailPanelInput}
                          onChange={(e) => setForm({ ...form, display_name: e.target.value })}
                          value={form.display_name}
                        />
                      </Box>
                      <Divider classes={{ root: classes.divider }} />
                    </div>
                    <div className={classes.inputContainer1}>
                      <Box
                        display="flex"
                        flexDirection="row"
                        justifyContent="space-between"
                        alignItems="center"
                        style={{ width: '100%' }}
                      >
                        <span>{intl.get('TYPE')}</span>
                        <TextField
                          required
                          select
                          SelectProps={{
                            MenuProps: {
                              className: classes.option,
                            },
                          }}
                          onChange={(e) => { setForm({ ...form, type: e.target.value }); }}
                          className={classes.detailPanelInput}
                          value={form.type}
                          InputProps={{
                            className: classes.input,
                          }}
                          style={{ color: '#fff' }}
                        >
                          {list.map((value: string) => (
                            <MenuItem
                              key={value}
                              value={value}
                            >
                              {value}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Box>
                      <Divider classes={{ root: classes.divider }} />
                    </div>
                    <div className={classes.inputContainer1}>
                      <Box
                        display="flex"
                        flexDirection="row"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <span>{intl.get('CURRENCY')}</span>
                        <input
                          type="text"
                          className={classes.detailPanelInput}
                          onChange={(e) => setForm({ ...form, currency: e.target.value })}
                          value={form.currency}
                        />
                      </Box>
                      <Divider classes={{ root: classes.divider }} />
                    </div>
                    <div className={classes.inputContainer1}>
                      <Box
                        display="flex"
                        flexDirection="row"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <span>{intl.get('CRYPTO_ADDRESS')}</span>
                        <input
                          type="text"
                          className={classes.detailPanelInput}
                          onChange={(e) => setForm({ ...form, deposit_address: e.target.value })}
                          value={form.deposit_address}
                        />
                      </Box>
                      <Divider classes={{ root: classes.divider }} />
                    </div>
                    <div className={classes.inputContainer1}>
                      <Box
                        display="flex"
                        flexDirection="row"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <span>{intl.get('PRIMARY')}</span>
                        <RadioGroup
                          aria-label="primary"
                          name="primary"
                          value={form.primary}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            setForm(
                              { ...form, primary: e.target.value === 'yes' },
                            );
                          }}
                          style={{ flexDirection: 'row' }}
                        >
                          <FormControlLabel value="yes" checked={form.primary} control={<Radio />} label={intl.get('YES') || 'Yes'} />
                          <FormControlLabel value="no" checked={!form.primary} control={<Radio />} label={intl.get('NO') || 'No'} />
                        </RadioGroup>
                      </Box>
                      <Divider classes={{ root: classes.divider }} />
                    </div>
                  </Box>
                </Grid>
              </>
            ) : (
              <>
                <Grid
                  item
                  md={12}
                >
                  <Box
                    display="flex"
                    flexDirection="row"
                    flexWrap="wrap"
                    justifyContent="flex-start"
                    alignItems="center"
                  >
                    <div className={classes.inputContainer}>
                      <Box
                        display="flex"
                        flexDirection="row"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <span>{intl.get('DISPLAY_NAME')}</span>
                        <input
                          type="text"
                          className={classes.detailPanelInput}
                          onChange={(e) => setForm({ ...form, display_name: e.target.value })}
                          value={form.display_name}
                        />
                      </Box>
                      <Divider classes={{ root: classes.divider }} />

                      <Box
                        display="flex"
                        flexDirection="row"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <span>{intl.get('BANK_NAME')}</span>
                        <input
                          type="text"
                          onChange={(e) => setForm({ ...form, bank_name: e.target.value })}
                          className={classes.detailPanelInput}
                          value={form.bank_name}
                        />
                      </Box>
                      <Divider classes={{ root: classes.divider }} />
                    </div>
                    <div className={classes.inputContainer} style={{ position: 'relative', paddingBottom: '3.5rem' }}>
                      <Box
                        display="flex"
                        flexDirection="row"
                        justifyContent="space-between"
                        alignItems="center"
                        style={{ height: '37px' }}
                      >
                        <span>{intl.get('TYPE')}</span>
                        <TextField
                          required
                          select
                          onChange={(e) => { setForm({ ...form, type: e.target.value }); }}
                          className={classes.detailPanelInput}
                          value={form.type}
                          InputProps={{
                            className: classes.input,
                          }}
                        >
                          {list.map((value: string) => (
                            <MenuItem
                              key={value}
                              value={value}
                            >
                              {value}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Box>
                      <Divider classes={{ root: classes.divider }} />
                    </div>
                    <div className={classes.inputContainer}>
                      <Box
                        display="flex"
                        flexDirection="row"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <span>{intl.get('NAME_ON_ACCOUNT')}</span>
                        <input
                          type="text"
                          className={classes.detailPanelInput}
                          onChange={(e) => setForm({ ...form, name_on_account: e.target.value })}
                          value={form.name_on_account}
                        />
                      </Box>
                      <Divider classes={{ root: classes.divider }} />

                      <Box
                        display="flex"
                        flexDirection="row"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <span>{intl.get('ACCOUNT_NUMBER')}</span>
                        <input
                          type="text"
                          onChange={(e) => setForm({ ...form, account_number: e.target.value })}
                          className={classes.detailPanelInput}
                          value={form.account_number}
                        />
                      </Box>
                      <Divider classes={{ root: classes.divider }} />
                    </div>
                    <div className={classes.inputContainer}>
                      <Box
                        display="flex"
                        flexDirection="row"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <span>{intl.get('CURRENCY')}</span>
                        <input
                          type="text"
                          className={classes.detailPanelInput}
                          onChange={(e) => setForm({ ...form, currency: e.target.value })}
                          value={form.currency}
                        />
                      </Box>
                      <Divider classes={{ root: classes.divider }} />

                      <Box
                        display="flex"
                        flexDirection="row"
                        justifyContent="start"
                        alignItems="center"
                      >
                        <span>{intl.get('COUNTRY_CODE')}</span>
                        <TextField
                          required
                          select
                          onChange={(e) => { setForm({ ...form, bank_country_code: e.target.value }); }}
                          className={classes.detailPanelInput}
                          value={form.bank_country_code}
                          InputProps={{
                            className: classes.input,
                          }}
                        >
                          {option.map((value) => (
                            <MenuItem
                              key={value.value}
                              value={value.value}
                            >
                              {value.label}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Box>
                      <Divider classes={{ root: classes.divider }} />
                    </div>
                    <div className={classes.inputContainer}>
                      <Box
                        display="flex"
                        flexDirection="row"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <span>{intl.get('REGION')}</span>
                        <input
                          type="text"
                          onChange={(e) => setForm({ ...form, bank_region: e.target.value })}
                          className={classes.detailPanelInput}
                          value={form.bank_region}
                        />
                      </Box>
                      <Divider classes={{ root: classes.divider }} />
                      <Box
                        display="flex"
                        flexDirection="row"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <span>{intl.get('CITY')}</span>
                        <input
                          type="text"
                          onChange={(e) => setForm({ ...form, bank_city: e.target.value })}
                          className={classes.detailPanelInput}
                          value={form.bank_city}
                        />
                      </Box>
                      <Divider classes={{ root: classes.divider }} />
                    </div>

                    <div className={classes.inputContainer}>
                      <Box
                        display="flex"
                        flexDirection="row"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <span>{intl.get('SORT_CODE')}</span>
                        <input
                          type="text"
                          onChange={(e) => setForm({ ...form, sort_code: e.target.value })}
                          className={classes.detailPanelInput}
                          value={form.sort_code}
                        />
                      </Box>
                      <Divider classes={{ root: classes.divider }} />

                      <Box
                        display="flex"
                        flexDirection="row"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <span>{intl.get('BIC_CODE')}</span>
                        <input
                          type="text"
                          onChange={(e) => setForm({ ...form, bic_code: e.target.value })}
                          className={classes.detailPanelInput}
                          value={form.bic_code}
                        />
                      </Box>
                      <Divider classes={{ root: classes.divider }} />
                    </div>
                    <div className={classes.inputContainer}>
                      <Box
                        display="flex"
                        flexDirection="row"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <span>{intl.get('SWIFT_CODE')}</span>
                        <input
                          type="text"
                          onChange={(e) => setForm({ ...form, swift_code: e.target.value })}
                          className={classes.detailPanelInput}
                          value={form.swift_code}
                        />
                      </Box>
                      <Divider classes={{ root: classes.divider }} />

                      <Box
                        display="flex"
                        flexDirection="row"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <span>{intl.get('IBAN_CODE')}</span>
                        <input
                          type="text"
                          onChange={(e) => setForm({ ...form, iban_code: e.target.value })}
                          className={classes.detailPanelInput}
                          value={form.iban_code}
                        />
                      </Box>
                      <Divider classes={{ root: classes.divider }} />
                    </div>
                    <div className={classes.inputContainer}>
                      <Box
                        display="flex"
                        flexDirection="row"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <span>{intl.get('ABA_NUMBER')}</span>
                        <input
                          type="text"
                          onChange={(e) => setForm({ ...form, aba_number: e.target.value })}
                          className={classes.detailPanelInput}
                          value={form.aba_number}
                        />
                      </Box>
                      <Divider classes={{ root: classes.divider }} />

                      <Box
                        display="flex"
                        flexDirection="row"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <span>{intl.get('PRIMARY')}</span>
                        <RadioGroup
                          aria-label="primary"
                          name="primary"
                          value={form.primary}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            setForm(
                              { ...form, primary: e.target.value === 'yes' },
                            );
                          }}
                          style={{ flexDirection: 'row' }}
                        >
                          <FormControlLabel value="yes" checked={form.primary} control={<Radio />} label={intl.get('YES') || 'Yes'} />
                          <FormControlLabel value="no" checked={!form.primary} control={<Radio />} label={intl.get('NO') || 'No'} />
                        </RadioGroup>
                      </Box>
                      <Divider classes={{ root: classes.divider }} />
                    </div>
                  </Box>
                </Grid>

                <Grid
                  item
                  md={12}
                >
                  <Box
                    display="flex"
                    flexDirection="row"
                    flexWrap="wrap"
                    justifyContent="flex-start"
                    alignItems="center"
                  >
                    <Grid
                      md={2}
                    >
                      <div>
                        <Box
                          display="flex"
                          flexDirection="row"
                          flexWrap="wrap"
                          justifyContent="flex-start"
                          alignItems="center"
                        >
                          <span>{intl.get('POSTCODE')}</span>
                          <input
                            type="text"
                            onChange={(e) => setForm({ ...form, bank_postcode: e.target.value })}
                            className={[classes.detailPanelInput, classes.fullWidth].join(' ')}
                            value={form.bank_postcode}
                            style={{ marginLeft: 0 }}
                          />
                        </Box>
                        <Divider classes={{ root: classes.divider }} />
                      </div>
                    </Grid>
                    <Grid
                      item
                      md={5}
                    >
                      <div className={classes.inputContainer}>
                        <Box
                          display="flex"
                          flexDirection="row"
                          flexWrap="wrap"
                          justifyContent="flex-start"
                          alignItems="center"
                        >
                          <span>{intl.get('ADDRESS_LINE_1')}</span>
                          <input
                            type="text"
                            onChange={(e) => setForm({ ...form, bank_address_line_1: e.target.value })}
                            className={[classes.detailPanelInput, classes.fullWidth].join(' ')}
                            value={form.bank_address_line_1}
                            style={{ marginLeft: 0 }}
                          />
                        </Box>
                        <Divider classes={{ root: classes.divider }} />
                      </div>
                    </Grid>
                    <Grid
                      item
                      md={5}
                    >
                      <div className={classes.inputContainer}>
                        <Box
                          display="flex"
                          flexDirection="row"
                          flexWrap="wrap"
                          justifyContent="flex-start"
                          alignItems="center"
                        >
                          <span>{intl.get('ADDRESS_LINE_2')}</span>
                          <input
                            type="text"
                            onChange={(e) => setForm({ ...form, bank_address_line_2: e.target.value })}
                            className={[classes.detailPanelInput, classes.fullWidth].join(' ')}
                            value={form.bank_address_line_2}
                            style={{ marginLeft: 0 }}
                          />
                        </Box>
                        <Divider classes={{ root: classes.divider }} />
                      </div>
                    </Grid>
                  </Box>
                </Grid>

                <Grid
                  item
                  md={12}
                >
                  <Box
                    display="flex"
                    flexDirection="row"
                    flexWrap="wrap"
                    justifyContent="flex-start"
                    alignItems="center"
                  >
                    {selectedMerchantAccount
                      ? (
                        <>
                          <Grid
                            md={2}
                          >
                            <div className={classes.inputContainer}>
                              <Box
                                display="flex"
                                flexDirection="row"
                                justifyContent="flex-start"
                                alignItems="center"
                              >
                                <span>{intl.get('CREATED_AT')}</span>
                                <span style={{ margin: '0 5px' }}>{form.created_at && new Date(form.created_at).toLocaleDateString()}</span>
                              </Box>
                              <Divider classes={{ root: classes.divider }} />
                            </div>
                          </Grid>
                          <Grid
                            md={2}
                          >
                            <div className={classes.inputContainer}>
                              <Box
                                display="flex"
                                flexDirection="row"
                                justifyContent="flex-start"
                                alignItems="center"
                              >
                                <span>{intl.get('UPDATED_AT')}</span>
                                <span style={{ margin: '0 5px' }}>{form.updated_at && new Date(form.updated_at).toLocaleDateString()}</span>
                              </Box>
                              <Divider classes={{ root: classes.divider }} />
                            </div>
                          </Grid>
                        </>
                      ) : null}
                    <Grid
                      item
                      md={5}
                    >
                      <div className={classes.inputContainer}>
                        <Box
                          display="flex"
                          flexDirection="row"
                          flexWrap="wrap"
                          justifyContent="flex-start"
                          alignItems="center"
                        >
                          <span>{intl.get('BENEFICIARY_LINE_1')}</span>
                          <input
                            type="text"
                            onChange={(e) => setForm({ ...form, beneficiary_address_line_1: e.target.value })}
                            className={[classes.detailPanelInput, classes.fullWidth].join(' ')}
                            value={form.beneficiary_address_line_1}
                            style={{ marginLeft: 0 }}
                          />
                        </Box>
                        <Divider classes={{ root: classes.divider }} />
                      </div>
                    </Grid>
                    <Grid
                      item
                      md={3}
                    >
                      <div className={classes.inputContainer}>
                        <Box
                          display="flex"
                          flexDirection="row"
                          flexWrap="wrap"
                          justifyContent="flex-start"
                          alignItems="center"
                        >
                          <span>{intl.get('BENEFICARY_POSTCODE')}</span>
                          <input
                            type="text"
                            onChange={(e) => setForm({ ...form, beneficiary_postcode: e.target.value })}
                            className={[classes.detailPanelInput, classes.fullWidth].join(' ')}
                            value={form.beneficiary_postcode}
                            style={{ marginLeft: 0 }}
                          />
                        </Box>
                        <Divider classes={{ root: classes.divider }} />
                      </div>
                    </Grid>
                    <Grid
                      md={2}
                    >
                      <div className={classes.inputContainer}>
                        <Box
                          display="flex"
                          flexDirection="row"
                          flexWrap="wrap"
                          justifyContent="flex-start"
                          alignItems="center"
                        >
                          <span>{intl.get('BENEFICIARY_CITY')}</span>
                          <input
                            type="text"
                            onChange={(e) => setForm({ ...form, beneficiary_city: e.target.value })}
                            className={classes.detailPanelInput}
                            value={form.beneficiary_city}
                            style={{ marginLeft: 0 }}
                          />
                        </Box>
                        <Divider classes={{ root: classes.divider }} />
                      </div>
                    </Grid>
                    <Grid
                      md={2}
                    >
                      <div className={classes.inputContainer}>
                        <Box
                          display="flex"
                          flexDirection="row"
                          flexWrap="wrap"
                          justifyContent="flex-start"
                          alignItems="center"
                        >
                          <span>{intl.get('BENEFICIARY_REGION')}</span>
                          <input
                            type="text"
                            onChange={(e) => setForm({ ...form, beneficiary_region: e.target.value })}
                            className={classes.detailPanelInput}
                            value={form.beneficiary_region}
                            style={{ marginLeft: 0 }}
                          />
                        </Box>
                        <Divider classes={{ root: classes.divider }} />
                      </div>
                    </Grid>
                    <Grid
                      item
                      md={5}
                    >
                      <div className={classes.inputContainer}>
                        <Box
                          display="flex"
                          flexDirection="row"
                          flexWrap="wrap"
                          justifyContent="flex-start"
                          alignItems="center"
                        >
                          <span>{intl.get('BENEFICIARY_LINE_2')}</span>
                          <input
                            type="text"
                            onChange={(e) => setForm({ ...form, beneficiary_address_line_2: e.target.value })}
                            className={[classes.detailPanelInput, classes.fullWidth].join(' ')}
                            value={form.beneficiary_address_line_2}
                            style={{ marginLeft: 0 }}
                          />
                        </Box>
                        <Divider classes={{ root: classes.divider }} />
                      </div>
                    </Grid>
                    <Grid
                      item
                      md={3}
                    >
                      <div className={classes.inputContainer}>
                        <Box
                          display="flex"
                          flexDirection="row"
                          justifyContent="start"
                          alignItems="center"
                        >
                          <span>{intl.get('BENEFICARY_COUNTRY_CODE')}</span>
                          <TextField
                            required
                            select
                            onChange={(e) => setForm({ ...form, beneficiary_country_code: e.target.value })}
                            className={classes.detailPanelInput}
                            value={form.beneficiary_country_code}
                            InputProps={{
                              className: classes.input,
                            }}
                          >
                            {option.map((value) => (
                              <MenuItem
                                key={value.value}
                                value={value.value}
                              >
                                {value.label}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Box>
                        <Divider classes={{ root: classes.divider }} />
                      </div>
                    </Grid>
                  </Box>
                </Grid>
              </>
            )
          }
          <Grid
            item
            md={12}
          >
            <Box
              display="flex"
              flexDirection="row"
              flexWrap="wrap"
              justifyContent="flex-end"
              alignItems="center"
            >
              {selectedMerchantAccount
                ? (
                  <Grid
                    item
                  >
                    <Button
                      color="default"
                      variant="contained"
                      onClick={() => onSubmit()}
                    >
                      {intl.get('UPDATE')}
                    </Button>
                    <Button
                      color="default"
                      variant="contained"
                      style={{ marginLeft: '20px' }}
                      onClick={closeMerchantAccountForm}
                    >
                      {intl.get('CLOSE')}
                    </Button>
                  </Grid>
                )
                : (
                  <>
                    {' '}
                    <Button
                      size="small"
                      color="default"
                      variant="contained"
                      onClick={() => onSubmit()}
                    >
                      {intl.get('SAVE')}
                    </Button>
                    <Button
                      size="small"
                      color="default"
                      variant="contained"
                      style={{ marginLeft: '20px' }}
                      onClick={closeMerchantAccountForm}
                    >
                      {intl.get('CLOSE')}
                    </Button>
                  </>
                )}
            </Box>
          </Grid>
        </Box>
      </div>
    </Card>
  );
};
