import React from 'react';
import intl from 'react-intl-universal';
import {
  Divider,
  Box,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

interface Props {
  label: string;
  value: number;
}

const useStyles = makeStyles(() => ({
  subSecNested: {
    padding: '10px',
    borderRadius: '10px',
    boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
    backgroundColor: '#f4f4f4',
    margin: '5px',
    width: '144px',
  },
  subSecTitle: {
    color: '#040532',
    fontSize: '14px',
    fontWeight: 'bold',
    textAlign: 'left',
  },
  subSecLabel: {
    minWidth: '100px',
    margin: '10px 10px 0 10px',
  },
  subSecValue: {
    color: '#040532',
    fontWeight: 'bold',
    margin: 0,
  },
  nestedDivider: {
    marginTop: '12px',
  },
}));

export const AmountBox: React.FC<Props> = ({ label, value }: Props) => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.subSecNested}>
        <div className={classes.subSecTitle}>{intl.get(label)}</div>
        <Divider className={classes.nestedDivider} />
        <div>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-around"
            alignItems="center"
            className={classes.subSecLabel}
          >
            <h2 className={classes.subSecValue}>{Number(value).toFixed(2)}</h2>
          </Box>
        </div>
      </div>
    </>
  );
};
