import React from 'react';
import intl from 'react-intl-universal';
import {
  makeStyles, createStyles,
} from '@material-ui/core/styles';
import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  ThemeProvider,
  TablePagination,
  TableContainer,
  Paper,
  LinearProgress,
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import { SettlementReport } from '../../../types/settlement.types';
import { RootState } from '../../../store/reducers';
import Badges from '../../../components/Badges';
import { tableTheme } from '../../../assets/styles/table-style';
import { StyledTableHeadCell } from '../../../components/StyledComponents';
import { paginationTheme } from '../../../components/StyledComponents/paginateTheme';

interface Props {
  settlement: SettlementReport[];
  changePaginationOptions: (e: any) => void;
  merchantPanel: boolean;
}
const useStyles = makeStyles(() => createStyles({
  root: {
    marginTop: '10px',
  },
  table: {
    minWidth: 650,
  },
  tableBodyCell: {
    fontSize: '12px',
    paddingTop: '15px',
    paddingBottom: '15px',
    lineHeight: '1.2',
  },
  colorPrimary: {
    backgroundColor: '#3485f7',
  },
  barColorPrimary: {
    backgroundColor: '#3485f7',
  },
}));

export default ({ settlement, changePaginationOptions, merchantPanel }: Props) => {
  const classes = useStyles();
  const settlemetState = useSelector((state: RootState) => state.adminSettlementReducer);
  const { total } = settlemetState.pagination;
  const [pagination, setPagination] = React.useState({
    limit: 10,
    current_page: 1,
  });
  const handleChangePage = (event: unknown, newPage: number) => {
    setPagination({
      ...pagination,
      current_page: ++newPage,
    });
    changePaginationOptions({ ...pagination, current_page: newPage });
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPagination({
      ...pagination,
      limit: +event.target.value,
      current_page: 1,
    });
    changePaginationOptions({ ...pagination, limit: +event.target.value });
  };
  const { loading } = settlemetState
  return (
    <Card className={classes.root}>
      <TableContainer component={Paper}>
        <ThemeProvider theme={tableTheme}>
          {loading ? <LinearProgress classes={{ colorPrimary: classes.colorPrimary, barColorPrimary: classes.barColorPrimary }} /> : null}
          <Table size="small" className={classes.table} aria-label="settlement Report list">
            <TableHead>
              <TableRow>
                <>
                  {!merchantPanel
                    ? <TableCell><StyledTableHeadCell>{intl.get('MERCHANT')}</StyledTableHeadCell></TableCell> : null}
                  {!merchantPanel
                    ? <TableCell><StyledTableHeadCell>{intl.get('PROVIDER')}</StyledTableHeadCell></TableCell> : null}
                  <TableCell><StyledTableHeadCell>{intl.get('TRANSACTION_NUMBER')}</StyledTableHeadCell></TableCell>
                  <TableCell><StyledTableHeadCell>{intl.get('ORDER_NUMBER')}</StyledTableHeadCell></TableCell>
                  <TableCell><StyledTableHeadCell>{intl.get('PROVIDER_NO')}</StyledTableHeadCell></TableCell>
                  <TableCell><StyledTableHeadCell>{intl.get('STATUS')}</StyledTableHeadCell></TableCell>
                  <TableCell><StyledTableHeadCell>{intl.get('DATE & TIME')}</StyledTableHeadCell></TableCell>
                </>
              </TableRow>
            </TableHead>
            <TableBody>
              {settlement && settlement.map((row) => (
                <TableRow style={{ position: 'relative' }}>
                  <>
                    {!merchantPanel
                      ? (
                        <TableCell className={classes.tableBodyCell}>
                          {row?.merchant?.company_name}
                        </TableCell>
                      ) : null}
                    {!merchantPanel
                      ? (
                        <TableCell className={classes.tableBodyCell}>
                          {row?.transaction?.psp_code}
                        </TableCell>
                      ) : null}
                    <TableCell className={classes.tableBodyCell}>{row?.transaction?.transaction_no}</TableCell>
                    <TableCell className={classes.tableBodyCell}>
                      {row?.transaction?.order_no}
                    </TableCell>
                    <TableCell className={classes.tableBodyCell}>
                      {row?.transaction?.provider_code}
                    </TableCell>
                    <TableCell className={classes.tableBodyCell}><Badges status={row?.status} /></TableCell>
                    <TableCell className={classes.tableBodyCell}>
                      {row?.created_at && `${new Date(row.created_at).toLocaleDateString()} ${new Date(row.created_at).toLocaleTimeString()}`}
                    </TableCell>
                  </>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </ThemeProvider>
      </TableContainer>
      {
        total && settlement && settlement.length
          ? (
            <ThemeProvider theme={paginationTheme}>
              <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={total}
                rowsPerPage={pagination.limit}
                page={pagination.current_page - 1}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </ThemeProvider>
          )
          : null
      }
    </Card>
  );
};
