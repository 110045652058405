import React from 'react';
import Button from '@material-ui/core/Button';
import intl from 'react-intl-universal';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import DownloadIcon from '../../../assets/icons/vfx_icon.svg';
import EyeIcon from '../../../assets/icons/vfx_doc.svg';
import Toolbar from './Toolbar';

const useStyles = makeStyles((theme: Theme) => createStyles({
  container: {
    marginTop: '15%',
    display: 'inline-flex',
  },
  btnStyle: {
    margin: theme.spacing(2),
    color: '#040732',
    backgroundColor: '#f4f4f4',
    fontSize: '20px',
    width: '200px',
    fontWeight: 600,
    borderRadius: '10px',
    textTransform: 'capitalize',
    display: 'flex',
    justifyContent: 'space-around',
  },
}));

export const Docs = () => {
  const classes = useStyles();
  const downloadPDF = () => {
    const href = `${window.location.origin}/api-doc-2.0.1.pdf`;
    const a = document.createElement('a');
    a.setAttribute('style', 'display:none;');
    document.body.appendChild(a);
    a.download = 'merchant-api-doc';
    a.href = href;
    a.target = '_blank';
    a.click();
    document.body.removeChild(a);
  };

  const viewPDF = () => {
    window.open(
      `${window.location.origin}/api-doc-2.0.1.pdf`,
      '_blank',
    );
  };

  return (
    <>
      <Toolbar />
      <div className={classes.container}>
        <Button
          className={classes.btnStyle}
          variant="contained"
          onClick={downloadPDF}
        >
          <img src={DownloadIcon} alt="Download Icon" width="35" />
          <span>{intl.get('DOWNLOAD')}</span>
        </Button>
        <Button
          className={classes.btnStyle}
          variant="contained"
          onClick={viewPDF}
        >
          <img src={EyeIcon} alt="Eye Icon" width="35" />
          <span>{intl.get('VIEW')}</span>
        </Button>
      </div>
    </>
  );
};
