import { Dispatch } from 'redux';
import { AxiosError } from 'axios';
import { fileConstants } from '../../constants/Merchant';
import { KYCService } from '../../../services/Merchant';
import { KYC, MerchantKycIds } from '../../../types/file.types';
import { fileService } from '../../../services';

const fileUpload = (body: any) => {
  const request = () => ({ type: fileConstants.FILE_UPLOAD_REQUEST });
  const success = (payload: KYC) => ({ type: fileConstants.FILE_UPLOAD_SUCCESS, payload });
  const failure = (error: any) => ({ type: fileConstants.FILE_UPLOAD_FAILURE, error });
  return (dispatch: Dispatch) => {
    dispatch(request());
    KYCService.uploadFile(body)
      .then(
        (res) => {
          dispatch(success(res.data));
        },
      ).catch((error: AxiosError) => dispatch(failure(error.response?.data?.errors || [])));
  };
};

const getFiles = (merchantPanel: boolean, merchantUUID: string) => {
  const request = () => ({ type: fileConstants.FILE_LIST_REQUEST });
  const success = (files: KYC[]) => ({ type: fileConstants.FILE_LIST_SUCCESS, files });
  const failure = (error: any) => ({ type: fileConstants.FILE_LIST_FAILURE, error });
  return (dispatch: Dispatch) => {
    dispatch(request());
    if (merchantPanel) {
      KYCService.getFiles()
        .then(
          (res) => {
            dispatch(success(res.data.data));
          },
        );
    } else {
      fileService.getFiles(merchantUUID)
        .then(
          (res) => {
            dispatch(success(res.data));
          },
        ).catch((error: AxiosError) => dispatch(failure(error.response?.data?.errors || [])));
    }
  };
};

const getKycById = (merchantPanel: boolean, payload: MerchantKycIds) => {
  const request = () => ({ type: fileConstants.VIEW_KYC_REQUEST });
  const success = (kyc: KYC) => ({ type: fileConstants.VIEW_KYC_SUCCESS, kyc });
  const failure = (error: any) => ({ type: fileConstants.VIEW_KYC_FAILURE, error });
  return (dispatch: Dispatch) => {
    dispatch(request());
    if (merchantPanel) {
      KYCService.viewKycById(payload.merchantKycUUID)
        .then(
          (res) => {
            dispatch(success(res.data));
          },
        ).catch((error: AxiosError) => dispatch(failure(error.response?.data?.errors || [])));
    } else {
      fileService.getKycById(payload)
        .then(
          (res) => {
            dispatch(success(res.data));
          },
        ).catch((error: AxiosError) => dispatch(failure(error.response?.data?.errors || [])));
    }
  };
};

const approveKyc = (payload: MerchantKycIds) => {
  const request = () => ({ type: fileConstants.APPROVE_KYC_REQUEST });
  const success = () => ({ type: fileConstants.APPROVE_KYC_SUCCESS });
  const failure = (error: any) => ({ type: fileConstants.APPROVE_KYC_FAILURE, error });
  return (dispatch: Dispatch) => {
    dispatch(request());
    fileService.approveMerchantKyc(payload)
      .then(
        () => {
          dispatch(success());
        },
      ).catch((error: AxiosError) => dispatch(failure(error.response?.data?.errors || [])));
  };
};

const deleteKyc = (payload: MerchantKycIds) => {
  const request = () => ({ type: fileConstants.DELETE_KYC_REQUEST });
  const success = () => ({ type: fileConstants.DELETE_KYC_SUCCESS });
  const failure = (error: any) => ({ type: fileConstants.DELETE_KYC_FAILURE, error });
  return (dispatch: Dispatch) => {
    dispatch(request());
    fileService.deleteMerchantKyc(payload)
      .then(
        () => {
          dispatch(success());
        },
      ).catch((error: AxiosError) => dispatch(failure(error.response?.data?.errors || [])));
  };
};
const resetState = () => {
  const request = () => ({ type: fileConstants.RESET_KYC_STATE });

  return (dispatch: Dispatch) => {
    dispatch(request());
  };
};

export const KYCActions = {
  fileUpload,
  getFiles,
  approveKyc,
  deleteKyc,
  getKycById,
  resetState,
};
