import { API } from '../config';
import { Pagination } from '../types/common.types';
import { ISettlements, SettlementFilter, Refunds } from '../types/settlement.types';

// eslint-disable-next-line max-len
const getSettlementList = (paginationData: Pagination, filters?: SettlementFilter) => {
  let queryParams = '';
  if (filters?.merchantUUID) {
    queryParams += `&merchant_uuid=${filters.merchantUUID}`;
  }
  if (filters?.selectedDates?.from && filters?.selectedDates?.to) {
    queryParams += `&from=${filters?.selectedDates.from}&to=${filters?.selectedDates.to}`;
  }
  if (filters?.payment_provider_id) {
    queryParams += `&payment_provider_id=${filters.payment_provider_id}`;
  }
  if (filters?.settlement_currency) {
    queryParams += `&settlement_currency=${filters.settlement_currency}`;
  }

  if (filters?.status) {
    queryParams += `&status=${filters.status}`;
  }
  return API.get(`/admin/settlements?limit=${paginationData.limit}&page=${paginationData.current_page}${queryParams}`);
};

const downloadFile = (settlementUUID: string) => API.get(`/admin/settlements/${settlementUUID}/download`);

const addSettlement = (body: ISettlements) => API.post('/admin/settlements', body);

const updateSettlement = (body: any, uuid: any) => API.post(`/admin/settlements/${uuid}`, body);

const settlementDetails = (settlementUUID: string) => API.get(`/admin/settlements/${settlementUUID}`);

const getSettlementRefund = (paginationData: Pagination, filters?: SettlementFilter) => {
  let queryParams = '';
  if (filters?.merchantUUID) {
    queryParams += `&merchant_uuid=${filters.merchantUUID}`;
  }
  if (filters?.selectedDates?.from && filters?.selectedDates?.to) {
    queryParams += `&from=${filters?.selectedDates.from}&to=${filters?.selectedDates.to}`;
  }
  if (filters?.payment_provider_id) {
    queryParams += `&payment_provider_id=${filters.payment_provider_id}`;
  }
  if (filters?.settlement_currency) {
    queryParams += `&settlement_currency=${filters.settlement_currency}`;
  }

  if (filters?.status) {
    queryParams += `&status=${filters.status}`;
  }
  return API.get(`/admin/refunds?limit=${paginationData.limit}&page=${paginationData.current_page}${queryParams}`);
};

const addRefund = (body: Refunds) => API.post('/admin/refunds', body);

const getRefundExport = (filters?: SettlementFilter) => {
  let queryParams = '?';
  if (filters?.selectedDates?.from && filters?.selectedDates?.to) {
    queryParams += `from=${filters?.selectedDates.from}&to=${filters?.selectedDates.to}`;
    if (filters?.merchantUUID) {
      queryParams += `&merchant_uuid=${filters.merchantUUID}`;
    }
    if (filters?.payment_provider_id) {
      queryParams += `&psp_id=${filters.payment_provider_id}`;
    }
    if (filters?.settlement_currency) {
      queryParams += `&currency=${filters.settlement_currency}`;
    }
    if (filters?.status) {
      queryParams += `&status=${filters.status}`;
    }
  }
  return API
    .get(`/admin/exports/refunds${queryParams}`);
};

export const adminSettlementService = {
  getSettlementList,
  downloadFile,
  addSettlement,
  updateSettlement,
  settlementDetails,
  getSettlementRefund,
  addRefund,
  getRefundExport,
};
