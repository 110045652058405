/* eslint camelcase:  off */
import { IMerchant, IMerchantState } from './merchant.types';
import { IMerchantAccount, MerchantAccountState } from './merchant-account.types';

export interface IMerchantBilling {
  uuid: string;
  merchant_id: number;
  merchant: IMerchant;
  merchant_account_id: string | null;
  account: IMerchantAccount;
  accounts?: IMerchantAccount[];
  currency: string;
  base_currency: string;
  base_fee: number;
  base_base_fee: number;
  total: number;
  base_total: number;
  base_transaction_total: number;
  service_fee: number;
  base_service_fee: number;
  final_total: number;
  base_final_total: number;
  period_start: string;
  period_end: string;
  billed_on: string;
  paid_on: string | null;
  paid: boolean;
  transaction_total?: number;
  status?: string;
  created_at: string;
  updated_at: string;
}

export interface PayoutTransaction {
  uuid: string;
  amount: number;
  currency: string;
  status: string;
  account: IMerchantAccount;
  completed_at: string;
  created_at: string;
  updated_at: string;
}

export interface AccountOptions {
  value: string,
  label: string
}

export interface IMerchantBillingPayload {
  merchant: string;
  period_start: string;
  period_end: string;
}

export const IMerchantBillingState = {
  uuid: '',
  merchant_id: 0,
  merchant: IMerchantState,
  merchant_account_id: null,
  account: MerchantAccountState,
  accounts: [],
  currency: '',
  base_currency: '',
  base_fee: 0,
  base_base_fee: 0,
  total: 0,
  base_total: 0,
  base_transaction_total: 0,
  service_fee: 0,
  base_service_fee: 0,
  final_total: 0,
  base_final_total: 0,
  period_start: '',
  period_end: '',
  billed_on: '',
  paid_on: null,
  paid: false,
  transaction_total: 0,
  created_at: '',
  updated_at: '',
  status: '',
};

export interface BillingPayment {
  uuid: string;
  merchant_id: number;
  transaction_id: number;
  merchants_bill_id: number;
  transaction_total: number;
  amount?: number;
  paid: boolean;
  paid_on: string;
  merchant_margin_amount: number;
  merchant_margin_type: string;
  merchant_margin_rate: number;
  psp_margin_amount: number;
  psp_cost: number;
  psp_margin_type: string;
  psp_margin_rate: number;
  service_fee: number;
  remaining_amount: number;
  currency: string;
  base_currency: string;
  fx_rate: number;
  created_at: string;
  updated_at: string;
}

export interface PayoutAccountIds {
  merchantBillUUID: string;
  payoutAccountUUID: string | undefined;
}

export interface AddPayoutAccount {
  merchantBillUUID: string;
  form: {
    account_uuid: string;
  },
}

export interface ExportData {
  filename: string;
}

export interface AddPayoutAccounts {
  account_uuid: string;
  currency: string;
  amount: number;
}

export const InitialAddPayoutAccounts: AddPayoutAccounts = {
  account_uuid: '',
  currency: '',
  amount: 0,
};
