import React from 'react';
import intl from 'react-intl-universal';
import { TextField } from '@material-ui/core';
import countryList from 'react-select-country-list';

export const CountryDropDown = ({
  label, i18nTag, onChange, defaultValue, disabled,
}: any) => {
  const [selectedValue, setSelectedValue] = React.useState(defaultValue);
  const list = countryList().getData();

  return (
    <TextField
      fullWidth
      label={intl.get(i18nTag) || label}
      name={label}
      required
      select
      // SelectProps={{ native: true }}
      onChange={(e) => { onChange(e.target.value); setSelectedValue(e.target.value); }}
      variant="outlined"
      value={selectedValue}
      disabled={disabled || false}
      style={{ paddingTop: '10px' }}
    >
      {list.map((value) => (
        <option
          key={value.value}
          value={value.value}
        >
          {value.label}
        </option>
      ))}
    </TextField>
  );
};
