/* eslint-disable react/require-default-props */
import { Grid } from '@material-ui/core';
import intl from 'react-intl-universal';
import React from 'react';
import { StyledDividerLine, StyledSectionHeading } from '.';

interface Props {
    label: string;
    componentToPassDown?: React.ReactNode;
}

const PageCommon = ({ label, componentToPassDown }: Props) => (
  <div>
    <Grid container spacing={1} wrap="wrap" justify="space-between">
      <StyledSectionHeading>{intl.get(`${label}`)}</StyledSectionHeading>
      {componentToPassDown}
    </Grid>
    <StyledDividerLine />

  </div>
);

export default PageCommon;
