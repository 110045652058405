import { KYC } from '../../../types/file.types';
import { FileActionTypes, fileConstants } from '../../constants/Merchant';

export interface IState {
  loading: boolean;
  isError: boolean;
  isUpdated: boolean;
  files: KYC[];
  kyc: KYC | null;
  isDeleted: boolean;
}

const initialState: IState = {
  loading: false,
  isError: false,
  isUpdated: false,
  files: [],
  kyc: null,
  isDeleted: false,
};

export const KYCReducer = (state = initialState, action: FileActionTypes): IState => {
  switch (action.type) {
    case fileConstants.FILE_UPLOAD_REQUEST:
      return {
        ...state, loading: true, isError: false, isUpdated: false, isDeleted: false,
      };
    case fileConstants.FILE_UPLOAD_SUCCESS:
      return {
        ...state, loading: false, isError: false, isUpdated: true,
      };
    case fileConstants.FILE_UPLOAD_FAILURE:
      return {
        ...state, loading: false, isError: true,
      };

    case fileConstants.FILE_LIST_REQUEST:
      return {
        ...state, loading: true, isError: false, isUpdated: false, isDeleted: false,
      };
    case fileConstants.FILE_LIST_SUCCESS:
      return {
        ...state, loading: false, isError: false, files: action.files, kyc: null,
      };
    case fileConstants.FILE_LIST_FAILURE:
      return {
        ...state, loading: false, isError: true,
      };

    case fileConstants.APPROVE_KYC_REQUEST:
      return {
        ...state, loading: true, isError: false, isUpdated: false,
      };
    case fileConstants.APPROVE_KYC_SUCCESS:
      return {
        ...state, loading: false, isError: false, isUpdated: true,
      };
    case fileConstants.APPROVE_KYC_FAILURE:
      return {
        ...state, loading: false, isError: true,
      };

    case fileConstants.DELETE_KYC_REQUEST:
      return {
        ...state, loading: true, isError: false, isUpdated: false,
      };
    case fileConstants.DELETE_KYC_SUCCESS:
      return {
        ...state, loading: false, isError: false, isDeleted: true,
      };
    case fileConstants.DELETE_KYC_FAILURE:
      return {
        ...state, loading: false, isError: true,
      };

    case fileConstants.VIEW_KYC_REQUEST:
      return {
        ...state, loading: true, isError: false, isUpdated: false, isDeleted: false,
      };
    case fileConstants.VIEW_KYC_SUCCESS:
      return {
        ...state, loading: false, isError: false, kyc: action.kyc,
      };
    case fileConstants.VIEW_KYC_FAILURE:
      return {
        ...state, loading: false, isError: true,
      };

    case fileConstants.RESET_KYC_STATE:
      return {
        ...state,
        loading: false,
        isError: false,
        isUpdated: false,
        // files: [],
        kyc: null,
        isDeleted: false,

      };

    default:
      return state;
  }
};
