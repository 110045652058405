import { API } from '../../config';

const uploadFile = (body: any) => API.post('/merchant/kyc', body);

const getFiles = () => API.get('/merchant/kyc');

const viewKycById = (uuid: string) => API.get(`/merchant/kyc/${uuid}`);

export const KYCService = {
  uploadFile,
  getFiles,
  viewKycById,
};
