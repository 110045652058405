import React, { useState, useEffect } from 'react';
import intl from 'react-intl-universal';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Container from '@material-ui/core/Container';
import MenuIcon from '@material-ui/icons/Menu';
import Button from '@material-ui/core/Button';
import {
  useRouteMatch,
  Link,
} from 'react-router-dom';
import Hidden from '@material-ui/core/Hidden';
import { MenuItems } from './Sidebar';
import { authActions } from '../../store/actions';
import { RootState } from '../../store/reducers';
import { Role } from '../../types/common.types';
import { AdminRoutes } from './AdminRouting';
import { MerchantRoutes } from './MerchantRouting';
import { useStyles } from './style';
import { history, utils } from '../../helpers';

export default () => {
  const classes = useStyles();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const { path } = useRouteMatch();
  // const [open, setOpen] = React.useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  // const handleDrawerOpen = () => {
  //   setOpen(true);
  // };
  // const handleDrawerClose = () => {
  //   setOpen(false);
  // };
  const dispatch = useDispatch();
  const logout = () => {
    dispatch(authActions.logout());
  };

  const [role, setRole] = useState('');
  const authReducer = useSelector((state: RootState) => state.authentication);
  useEffect(() => {
    setRole(utils.userType());
    document.body.style.backgroundColor = '#fafafa';
  }, [authReducer]);
  const userData = JSON.parse(localStorage.getItem('auth') || '{}');

  const drawer = (
    <div>
      <Link to={path}>
        <img src={process.env.REACT_APP_LOGO} className={classes.logoDark} width="80%" height="auto" alt="logo" />
      </Link>
      <Divider />
      <MenuItems role={role} path={path} name={userData.name} />
    </div>
  );

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="absolute" className={clsx(classes.appBar, mobileOpen && classes.appBarShift)}>
        <Toolbar className={classes.toolbar}>
          <div>
            <div
              className={clsx({ [classes.logoLightContainer]: !mobileOpen, [classes.hide]: mobileOpen })}
            >
              <IconButton
                edge="start"
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerToggle}
              >
                <MenuIcon />
              </IconButton>
              <Link to={path}>
                <img src={process.env.REACT_APP_LOGO} className={classes.logoLight} width="auto" height="auto" alt="logo" />
              </Link>
            </div>
          </div>
          <Button color="inherit" onClick={() => logout()} style={{ marginRight: '90px', color: '#3485f7' }}>{intl.get('LOGOUT')}</Button>
        </Toolbar>
      </AppBar>
      <nav className={classes.drawer} aria-label="mailbox folders">
        <Hidden smUp implementation="css">
          <Drawer
            // container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}

          </Drawer>
        </Hidden>
        <Hidden smDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
      <main className={classes.content} style={{ backgroundColor: history.location.pathname.includes('/billings/') ? '#e8ebed' : '' }}>
        <div>
          <Container maxWidth="lg" className={classes.container}>
            {role === Role.Admin
              ? <AdminRoutes path={path} />
              : <MerchantRoutes path={path} />}
          </Container>
        </div>
      </main>
    </div>
  );
};
