import React from 'react';
import intl from 'react-intl-universal';
import { TextField } from '@material-ui/core';

export const Input = ({
  i18nTag, onChange, isRequired, defaultValue, isDisable,
}: any) => (
  <TextField
    fullWidth
    label={intl.get(i18nTag)}
    onChange={(e) => onChange(e.target.value)}
    required={isRequired || false}
    value={defaultValue}
    // variant="outlined"
    disabled={!!isDisable}
  />
);
