import React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { utils } from '../helpers';

interface PrivateRouteProps extends Omit<RouteProps, 'component'> {
  component: React.ElementType;
}

export const PrivateRoute: React.FC<PrivateRouteProps> = ({ component: Component, ...rest }: PrivateRouteProps) => (
  <Route
    {...rest}
    render={(props) => (
      utils.isAuth()
        ? <Component {...props} />
        : <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
    )}
  />
);
