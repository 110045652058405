import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import intl from 'react-intl-universal';
import {
  makeStyles, Theme, createStyles,
} from '@material-ui/core/styles';
import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  TablePagination,
  ThemeProvider,
  LinearProgress,
} from '@material-ui/core';
import {
  Link,
  useRouteMatch,
} from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import DeleteIcon from '@material-ui/icons/Delete';
import { merchantActions } from '../../../store/actions';
import { RootState } from '../../../store/reducers';
import { DisplaySnackbar, BooleanLabel, StyledTableHeadCell } from '../../../components';
import { IMerchant } from '../../../types/merchant.types';
import { tableTheme } from '../../../assets/styles/table-style';
import { paginationTheme } from '../../../components/StyledComponents/paginateTheme';
import { ConfirmationBox } from '../../../components/ConfirmationBox';

interface IMerchantTypes {
  merchants: IMerchant[]
  changePaginationOptions: (e: any) => void;
}

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    margin: '10px auto',
  },
  table: {
    minWidth: 650,
  },
  tableBodyCell: {
    color: '#232931',
    fontSize: '12px',
    paddingTop: '8px',
    paddingBottom: '8px',
    borderBottom: 'none',
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  expandIcon: {
    backgroundColor: '#ffffff',
    borderRadius: '5px',
  },
  iconStyle: {
    fill: '#3485f7',
  },
  checkBox: {
    backgroundColor: '#fff',
    border: 'none',
  },
  deleteIcon: {
    color: 'black',
    cursor: 'pointer',
    '&:hover': {
      color: 'red',
    },
  },
  colorPrimary: {
    backgroundColor: '#3485f7',
  },
  barColorPrimary: {
    backgroundColor: '#3485f7',
  },
}));

export default ({ merchants, changePaginationOptions }: IMerchantTypes) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [confirmation, setConfirmation] = React.useState(false);
  const merchantState = useSelector((state: RootState) => state.merchantReducer);
  const { total } = merchantState.pagination;
  const { path } = useRouteMatch();
  const [pagination, setPagination] = React.useState({
    limit: 10,
    current_page: 1,
  });

  const handleChangePage = (event: unknown, newPage: number) => {
    setPagination({
      ...pagination,
      current_page: ++newPage,
    });
    changePaginationOptions({ ...pagination, current_page: newPage });
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPagination({
      ...pagination,
      limit: +event.target.value,
      current_page: 1,
    });
    changePaginationOptions({ ...pagination, limit: +event.target.value });
  };

  const onDelete = (uuid: any) => {
    dispatch(merchantActions.deleteMerchant(uuid));
  };

  const { loading } = merchantState;

  const openConfirmationBox = () => {
    setConfirmation(true);
  };

  const closeConfirmationBox = () => {
    setConfirmation(false);
  };

  return (
    <Card className={classes.root}>
      <TableContainer component={Paper}>
        <ThemeProvider theme={tableTheme}>
          {loading ? <LinearProgress classes={{ colorPrimary: classes.colorPrimary, barColorPrimary: classes.barColorPrimary }} /> : null}
          <Table size="small" className={classes.table} aria-label="merchant list">
            <TableHead>
              <TableRow>
                <TableCell><StyledTableHeadCell>{intl.get('COMPANY_CODE')}</StyledTableHeadCell></TableCell>
                <TableCell><StyledTableHeadCell>{intl.get('COMPANY_NAME')}</StyledTableHeadCell></TableCell>
                <TableCell><StyledTableHeadCell>{intl.get('MDR') || 'MDR'}</StyledTableHeadCell></TableCell>
                <TableCell><StyledTableHeadCell>{intl.get('MARGIN_RATE')}</StyledTableHeadCell></TableCell>
                <TableCell><StyledTableHeadCell>{intl.get('MARGIN_TYPE')}</StyledTableHeadCell></TableCell>
                <TableCell><StyledTableHeadCell>{intl.get('KYC_APPROVED')}</StyledTableHeadCell></TableCell>
                <TableCell><StyledTableHeadCell>{intl.get('ACTIONS')}</StyledTableHeadCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {merchants && merchants.map((row: IMerchant) => (
                <TableRow key={row.uuid}>
                  <TableCell align="center">
                    {row.company_code}
                  </TableCell>
                  <TableCell className={classes.tableBodyCell}>{row?.company_name}</TableCell>
                  <TableCell className={classes.tableBodyCell}>{row?.mdr}</TableCell>
                  <TableCell className={classes.tableBodyCell}>{row?.margin_rate}</TableCell>
                  <TableCell className={classes.tableBodyCell}>{row?.margin_type}</TableCell>
                  <TableCell className={classes.tableBodyCell}><BooleanLabel label={row?.kyc_approved} /></TableCell>
                  <TableCell className={classes.tableBodyCell} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around' }}>
                    <Link to={`${path}/${row.company_name.toLowerCase().replace(/\s/g, '-')}/${row.uuid}`}>{intl.get('DETAILS') || 'Details'}</Link>
                    <DeleteIcon className={classes.deleteIcon} onClick={() => openConfirmationBox()} />
                  </TableCell>
                  <ConfirmationBox openDialog={confirmation} closeConfirmationBox={closeConfirmationBox} submitConfirmationBox={() => onDelete(row.uuid)} />
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </ThemeProvider>
      </TableContainer>
      {
        total && merchants && merchants.length
          ? (
            <ThemeProvider theme={paginationTheme}>
              <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={total}
                rowsPerPage={pagination.limit}
                page={pagination.current_page - 1}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </ThemeProvider>
          )
          : null
      }
      {merchantState && merchantState.isDeleted
        ? <DisplaySnackbar type="success" message={intl.get('DELETED_SUCCESSFULLY') || 'Deleted successfully'} /> : null}
    </Card>
  );
};
