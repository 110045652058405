import {
  makeStyles, Theme, createStyles,
} from '@material-ui/core/styles';

const drawerWidth = 190;
export const useStyles = makeStyles((theme: Theme) => createStyles({

  root: {
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },

  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  drawer: {
    [theme.breakpoints.up('md')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
    backgroundColor: '#050534',
  },
  // space below toolbar
  // appBarSpacer: theme.mixins.toolbar,
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: '#ffffff',
    color: '#000',
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  toolbar: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      justifyContent: 'flex-end',
    },
    justifyContent: 'space-between',
    minHeight: '45px',
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: '#050534',
    overflowY: 'visible',
    overflowX: 'visible',
    height: '100%',
  },
  content: {
    flexGrow: 1,
    paddingTop: theme.spacing(5),
    // backgroundColor: '#e8ebed',
    minHeight: '100vh',
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  show: {
    display: 'block',
  },
  hide: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  logoDark: {
    marginTop: '25px',
  },
  logoLight: {
    height: '75px',
    top: '-16px',
    position: 'absolute',
  },
  logoLightContainer: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
}));
