import React, { useEffect } from 'react';
import intl from 'react-intl-universal';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core';

export const StatusDropDown = ({
  onChange, defaultValue, i18nTag, variant, label, clearFilter, width, minWidth, margin,
}: any) => {
  const [selectedValue, setSelectedValue] = React.useState(defaultValue);

  useEffect(() => {
    if (clearFilter === true) {
      setSelectedValue('');
    }
  }, [clearFilter]);

  const list: string[] = [
    'Pending',
    'Requested',
    'Complete',
    'InProgress',
    'Cancelled',
  ];

  return (
    <FormControl style={{ width, minWidth, margin }}>
      <InputLabel>{intl.get(i18nTag)}</InputLabel>
      <Select
        variant={variant}
        onChange={(e) => {
          onChange(e.target.value); setSelectedValue(e.target.value);
        }}
        label={label}
        value={selectedValue}
        style={{ textAlign: 'left' }}
        required
      >
        {
          list?.map((row: string) => <MenuItem value={row}>{row}</MenuItem>)
        }
      </Select>
    </FormControl>
  );
};
