import React, { useEffect } from 'react';
import intl from 'react-intl-universal';
import {
  Divider,
  Box,
  IconButton,
} from '@material-ui/core';
import {
  makeStyles, Theme, createStyles, withStyles,
} from '@material-ui/core/styles';
import FilterListIcon from '@material-ui/icons/FilterList';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import LinearProgress from '@material-ui/core/LinearProgress';
import { ProviderBalance } from '../../../types/dashboard.types';
import { ErrorSummary } from '../../../components/ErrorSummary';

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    color: '#959595',
    margin: 0,
  },
  sectionRow: {
    margin: '10px',
    padding: '10px 20px 40px 20px',
    borderRadius: '10px',
    boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
    backgroundColor: '#040532',
  },
  sectionRowLight: {
    margin: '115px 10px 0',
    position: 'relative',
    backgroundColor: 'rgba(0, 0, 0, 0.05)',
  },
  iconStyle: {
    color: '#000',
  },
  blueColor: {
    color: '#3d7df7',
  },
  rowStyle: {
    marginTop: theme.spacing(2),
  },
  progressContainer: {
    height: '30px',
    fontSize: '12px',
    padding: '10px 20px',
    borderRadius: '16px',
    boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
    backgroundColor: '#ffffff',
  },
}));
interface Props {
  providerBalanceData: ProviderBalance[];
}

export const RightSection: React.FC<Props> = ({ providerBalanceData }: Props) => {
  const classes = useStyles();
  const [total, setTotal] = React.useState(0);

  useEffect(() => {
    if (providerBalanceData.length) {
      const amount = (item: ProviderBalance) => item.count;

      const sum = (prev: number, next: number) => prev + next;

      setTotal(providerBalanceData.map(amount).reduce(sum));
    }
  }, [providerBalanceData]);

  const BorderLinearProgress = withStyles((theme: Theme) => createStyles({
    root: {
      height: 10,
      borderRadius: 5,
    },
    colorPrimary: {
      backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
    },
    bar: {
      borderRadius: 5,
      backgroundColor: '#1a90ff',
    },
  }))(LinearProgress);
  return (
    <>
      <div>
        <div className={[classes.sectionRow, classes.sectionRowLight].join(' ')}>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <h2 className={classes.title}>{intl.get('PAYMENT_PROVIDERS')}</h2>
            <h3 className={classes.blueColor}>{intl.get('BALANCE')}</h3>
          </Box>
          <Divider />
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="flex-end"
            alignItems="center"
          >
            <IconButton>
              <FilterListIcon className={classes.iconStyle} />
            </IconButton>

            <IconButton>
              <MoreVertIcon className={classes.iconStyle} />
            </IconButton>
          </Box>
          {providerBalanceData.length ? providerBalanceData?.map((item: ProviderBalance) => (
            <div key={item.code}>
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
                className={classes.rowStyle}
              >
                <h3 className={classes.title} style={{fontSize: '13px'}}>{item.code}</h3>
                <h4 className={classes.blueColor} style={{ margin: '8px' }}>
                  <span>{item.balance}</span>
                  <span style={{ marginLeft: '15px', fontSize: '11px' }}>USD</span>
                </h4>
              </Box>

              <Box
                display="flex"
                flexDirection="row"
                justifyContent="flex-start"
                alignItems="center"
                className={classes.progressContainer}
              >
                <span>TX</span>
                <div style={{ width: '100%', marginLeft: '20px', marginRight: '20px' }}>
                  <BorderLinearProgress variant="determinate" value={(item.count / total) * 100} />
                </div>
                <span>{item.count}</span>
              </Box>
            </div>
          ))
            : (
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="center"
                alignItems="center"
              >
                <h3 className={classes.title}>{intl.get('DATA_NOT_FOUND')}</h3>
              </Box>
            )}

        </div>
        <Box>
          <ErrorSummary />
        </Box>
      </div>
    </>
  );
};
