import React from 'react';
import intl from 'react-intl-universal';
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  ThemeProvider,
  IconButton,
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import { makeStyles, Theme } from '@material-ui/core/styles';
import DateFnsUtils from '@date-io/date-fns';
import FilterListIcon from '@material-ui/icons/FilterList';
import { SearchInput, PaymentProviderForm } from '../../../components';
import { RootState } from '../../../store/reducers';
import ExportIcon from '../../../assets/icons/Export.svg';
import { customDatePicker } from '../../../assets/styles/date-picker';

const useStyles = makeStyles((theme: Theme) => ({
  iconStyle: {
    fill: '#3485f7',
  },
  dateLabel: {
    fontWeight: 'bold',
    margin: '10px',
    marginTop: '15px',
  },
  formControl: {
    marginRight: theme.spacing(2),
    minWidth: 130,
  },
  MuiInputBaseinput: {
    width: '91px',
  },
  clearFilters: {
    color: '#3485f7',
    fontSize: '12px',
    cursor: 'pointer',
  },
  filterContainer: {
    marginBottom: '10px',
    position: 'relative',
  },
}));

export default () => {
  const [displayPaymentProviderForm, setdisplayPaymentProviderForm] = React.useState(false);

  const openPaymentProviderForm = () => {
    setdisplayPaymentProviderForm(true);
  };

  const closePaymentProviderForm = () => {
    setdisplayPaymentProviderForm(false);
  };

  const paymentProviderState = useSelector((state: RootState) => state.paymentProviderReducer);
  const classes = useStyles();
  const [value, setValue] = React.useState<Date | null>(
    new Date(),
  );

  const handleChange = (newValue: Date | null) => {
    setValue(newValue);
  };
  /**
   * This will run when data added/edited
   */
  React.useEffect(() => {
    if (paymentProviderState.isUpdated) {
      setdisplayPaymentProviderForm(false);
    }
  }, [paymentProviderState.isUpdated]);

  return (
    <div>
      <Box mb={1}>
        <Card>
          <CardContent>
            <Grid container wrap="wrap" justify="space-between" style={{ justifyContent: 'flex-end' }}>
              <Box>
                <IconButton>
                  <FilterListIcon className={classes.iconStyle} />
                </IconButton>
                <span>
                  {intl.get('FILTER')}
                </span>
              </Box>
            </Grid>
            <Grid container wrap="wrap" justify="space-between" alignItems="center">
              <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                <Grid container justify="flex-start" alignItems="center" wrap="wrap">
                  <ThemeProvider theme={customDatePicker}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <Box
                        display="flex"
                        flexDirection="row"
                        justifyContent="flex-start"
                        alignItems="center"
                      >
                        <span className={classes.dateLabel}>{intl.get('FROM')}</span>
                        <DatePicker
                          disableToolbar
                          inputVariant="outlined"
                          format="MM/dd/yyyy"
                          margin="normal"
                          id="date-from"
                          className={classes.MuiInputBaseinput}
                          value={value}
                          onChange={handleChange}
                          style={{ marginRight: '10px' }}
                        />
                        <span className={classes.dateLabel}>{intl.get('TO')}</span>
                        <DatePicker
                          disableToolbar
                          inputVariant="outlined"
                          format="MM/dd/yyyy"
                          margin="normal"
                          id="date-to"
                          className={classes.MuiInputBaseinput}
                          value={value}
                          onChange={handleChange}
                        />
                      </Box>
                    </MuiPickersUtilsProvider>
                  </ThemeProvider>
                  <div style={{ marginTop: '9px', marginLeft: '20px' }}>
                    <Button
                      variant="outlined"
                      size="small"
                      style={{ display: 'flex', alignItems: 'center', borderRadius: '5px' }}
                    >
                      {intl.get('EXPORT')}
                      <img src={ExportIcon} alt="" width="25" height="20" />
                    </Button>
                  </div>
                  <div style={{ marginTop: '9px', marginLeft: '20px' }}>
                    <SearchInput width="150px" onChange={() => { console.log('hello world'); }} />
                  </div>
                </Grid>
              </div>
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                  style={{ color: 'white', marginTop: '10px' }}
                  variant="contained"
                  color="primary"
                  onClick={openPaymentProviderForm}
                >
                  {intl.get('ADD') || 'Add'}
                  {' '}
                  {intl.get('PAYMENT') || 'Payment'}
                  {' '}
                  {intl.get('PROVIDER') || 'Provider'}
                </Button>
              </div>
            </Grid>
          </CardContent>
        </Card>
      </Box>
      <PaymentProviderForm
        openDialog={displayPaymentProviderForm}
        closePaymentProviderForm={closePaymentProviderForm}
        selectedPaymentProvider={null}
      />
    </div>
  );
};
