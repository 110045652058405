import React from 'react';
import { MerchantBillings } from '../../AdminPanel/MerchantBillings';
import { utils } from '../../../helpers/utils';

export const Billings = () => {
  const isMerchantPanel = utils.userType() === 'merchant';
  return (
    <>
      <MerchantBillings merchantPanel={isMerchantPanel} />
    </>
  );
};
