import { API } from '../config';
import { Pagination } from '../types/common.types';
import { IPaymentProvider } from '../types/payment-provider.types';

// eslint-disable-next-line max-len
const getPaymentProviderList = (paginationData: Pagination) => API.get(`/admin/payments-providers?limit=${paginationData.limit}&page=${paginationData.current_page}`);

const getPaymentProviderById = (id: number) => API.get(`/admin/payments-providers/${id}`);

const updatePaymentProvider = (body: any) => API.post(`/admin/payments-providers/${body.id}`, body.form);

const addPaymentProvider = (body: IPaymentProvider) => API.post('/admin/payments-providers', body);

const deletePaymentProvider = (id: number) => API.delete(`/admin/payments-providers/${id}`);

const getPaymentProviderCode = () => API.get('/admin/options/payment-providers-codes');

const getPspOptions = () => API.get('/admin/options/pspconfig');

export const paymentProviderService = {
  getPaymentProviderList,
  getPaymentProviderById,
  addPaymentProvider,
  updatePaymentProvider,
  deletePaymentProvider,
  getPaymentProviderCode,
  getPspOptions,
};
