import { MerchantActionTypes, merchantConstants } from '../../constants/Merchant';
import { IMerchant, IMerchantState, MerchantCurrencyBalances } from '../../../types/merchant.types';

export interface IState {
  loading: boolean
  isError: boolean
  merchantDetails: IMerchant
  updateError: boolean,
  isUpdated: boolean,
  errorMessages: string[],
  merchantBalances: MerchantCurrencyBalances[],
}

const initialState: IState = {
  loading: false,
  isError: false,
  updateError: false,
  isUpdated: false,
  merchantDetails: IMerchantState,
  errorMessages: [],
  merchantBalances: [],
};

export const merchantPanelReducer = (state = initialState, action: MerchantActionTypes): IState => {
  switch (action.type) {
    case merchantConstants.GET_MERCHANT_REQUEST:
      return {
        ...state, loading: true, isError: false, isUpdated: false,
      };
    case merchantConstants.GET_MERCHANT_SUCCESS:
      return {
        ...state, loading: false, isError: false, merchantDetails: action.merchant,
      };
    case merchantConstants.GET_MERCHANT_FAILURE:
      return {
        ...state, loading: false, isError: true,
      };

    case merchantConstants.UPDATE_MERCHANT_REQUEST:
      return {
        ...state, loading: true, updateError: false, isUpdated: false,
      };
    case merchantConstants.UPDATE_MERCHANT_SUCCESS:
      return {
        ...state,
        loading: false,
        updateError: false,
        isUpdated: true,
        merchantDetails: action.merchant,
      };
    case merchantConstants.UPDATE_MERCHANT_FAILURE:
      return {
        ...state, loading: false, updateError: true, errorMessages: action.errors,
      };

    case merchantConstants.GET_MERCHANT_BALANCES_REQUEST:
      return {
        ...state, loading: true, isError: false,
      };
    case merchantConstants.GET_MERCHANT_BALANCES_SUCCESS:
      return {
        ...state, loading: false, isError: false, merchantBalances: action.merchantBalances,
      };
    case merchantConstants.GET_MERCHANT_BALANCES_FAILURE:
      return {
        ...state, loading: false, isError: true,
      };

    default:
      return state;
  }
};
