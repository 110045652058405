import React, { useState, useEffect } from 'react';
import intl from 'react-intl-universal';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
} from '@material-ui/core';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { userActions, merchantActions } from '../../../store/actions';
import { RootState } from '../../../store/reducers';
import { DisplaySnackbar, Dropdown } from '../../index';
import { addUserForm, editUserForm, IUser } from '../../../modules/AdminPanel/Users/types';
import { AccountOptions } from '../../../types/merchant-billing.types';
import PasswordResusable from '../../PasswordResusable';
import { MerchantDropDown } from '../../MerchantDropDown';

const roles = ['admin', 'merchant'];

interface Types {
  openDialog: boolean, closeUserForm: () => void, selectedUser: IUser | null
}

export const UserForm: React.FC<Types> = ({ openDialog, closeUserForm, selectedUser }: Types) => {
  const [values, setValues] = useState(!selectedUser ? addUserForm : editUserForm);
  const [open, setOpen] = React.useState(openDialog);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValues({
      ...values,
      [(event.target as HTMLInputElement).name]: (event.target as HTMLInputElement).value,
    });
  };

  const dispatch = useDispatch();
  const onSubmit = () => {
    if (selectedUser) {
      const forUpdate = {
        first_name: values.first_name, last_name: values.last_name, enabled: values.enabled, role: values.role, merchant_id: values.merchant_id,
      };
      dispatch(userActions.updateUser({ form: forUpdate, uuid: values.uuid }));
    } else {
      dispatch(userActions.addUser(values));
    }
  };
  const userState = useSelector((state: RootState) => state.userReducer);

  /**
   * This will run when user reducer updates
   */
  useEffect(() => {
    if (userState.isUpdated) {
      /**
       * Reset form only if a new user added
       */
      if (!selectedUser) {
        setValues(addUserForm);
      }
    }
  }, [userState, selectedUser]);

  /**
   * This will run when we open/close user form dialog
   */
  useEffect(() => {
    setOpen(openDialog);
  }, [openDialog]);

  /**
   * This will check if user is being adding or editing
   */
  useEffect(() => {
    if (selectedUser) {
      setValues({ ...selectedUser, merchant_id: selectedUser.role === 'merchant' ? selectedUser.merchant && selectedUser.merchant.uuid : null });
    }
  }, [selectedUser]);

  useEffect(() => {
    if (values.role === 'merchant') {
      dispatch(merchantActions.merchantOptions());
    }
  }, [dispatch, values.role]);
  const merchantReducer = useSelector((state: RootState) => state.merchantReducer);
  const merchantList: AccountOptions[] = merchantReducer.merchantOptions || [];

  return (
    <div>
      {userState && userState.updateError
        ? userState.errorMessages && Object.values(userState.errorMessages).length
        && Object.values(userState.errorMessages).map(
          (error: any) => error?.length && error.map(
            (msg: string) => <DisplaySnackbar type="error" message={msg} />,
          ),
        ) : null}
      <div>
        <Dialog fullWidth maxWidth="md" open={open} onClose={closeUserForm} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">{selectedUser?.uuid ? intl.get('EDIT_USER') || 'Edit User' : intl.get('ADD_USER') || 'Add User'}</DialogTitle>
          <DialogContent>
            <form
              autoComplete="off"
              noValidate
            >
              <Card>
                <CardContent>
                  <Grid
                    container
                    spacing={3}
                  >
                    <Grid
                      item
                      md={values.role === 'merchant' ? 12 : 12}
                      xs={12}
                    >
                      <Dropdown
                        // size="small"
                        list={roles}
                        defaultValue={values.role}
                        i18nTag="SELECT_ROLE"
                        label="Select Role"
                        onChange={(e: string) => {
                          setValues({ ...values, role: e, merchant_id: e === 'admin' ? null : values.merchant_id });
                        }}
                      />
                    </Grid>
                    {values.role === 'merchant' && merchantList && merchantList.length ? (
                      <Grid
                        item
                        md={6}
                        xs={12}
                      >
                        <MerchantDropDown
                          i18nTag="SELECT_MERCHANT"
                          variant="outlined"
                          width="100%"
                          padding="25px 0px 0px 0px"
                          defaultValue={values && values.merchant_id}
                          onChange={(e: string) => setValues({ ...values, merchant_id: e })}
                        />
                      </Grid>
                    ) : null}
                    <Grid
                      item
                      md={12}
                      xs={12}
                    >
                      <FormControl>
                        <FormLabel>{intl.get('ENABLED') || 'Enabled'}</FormLabel>
                        <RadioGroup
                          aria-label="enabled"
                          name="enabled"
                          value={values.enabled}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            setValues({ ...values, enabled: e.target.value === 'yes' });
                          }}
                          style={{ flexDirection: 'row' }}
                        >
                          <FormControlLabel value="yes" checked={values.enabled} control={<Radio />} label={intl.get('YES') || 'Yes'} />
                          <FormControlLabel value="no" checked={!values.enabled} control={<Radio />} label={intl.get('NO') || 'No'} />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                    <Grid
                      item
                      md={6}
                      xs={12}
                    >
                      <TextField
                        fullWidth
                        size="small"
                        label={intl.get('FIRST_NAME') || 'First Name'}
                        name="first_name"
                        onChange={handleChange}
                        required
                        value={values.first_name}
                        variant="outlined"
                        inputProps={{ style: { fontSize: 22 } }}
                        InputLabelProps={{ style: { fontSize: 17 } }}
                      />
                    </Grid>
                    <Grid
                      item
                      md={6}
                      xs={12}
                    >
                      <TextField
                        fullWidth
                        size="small"
                        label={intl.get('LAST_NAME') || 'Last Name'}
                        name="last_name"
                        onChange={handleChange}
                        required
                        value={values.last_name}
                        variant="outlined"
                        inputProps={{ style: { fontSize: 22 } }}
                        InputLabelProps={{ style: { fontSize: 17 } }}
                      />
                    </Grid>
                    <Grid
                      item
                      md={6}
                      xs={12}
                    >
                      <TextField
                        fullWidth
                        size="small"
                        label={intl.get('EMAIL') || 'Email'}
                        name="email"
                        onChange={handleChange}
                        required
                        value={values.email}
                        variant="outlined"
                        inputProps={{ style: { fontSize: 22 } }}
                        InputLabelProps={{ style: { fontSize: 17 } }}
                      />
                    </Grid>
                    {!selectedUser
                      ? (
                        <Grid
                          item
                          md={12}
                          xs={12}
                        >
                          <PasswordResusable changePassword={(e) => {
                            setValues({ ...values, password: e });
                          }}
                          />
                        </Grid>
                      ) : null}
                  </Grid>
                </CardContent>
              </Card>
            </form>
          </DialogContent>
          <DialogActions>
            <Grid
              item
            >
              <Button
                style={{ background: '#3f51b5', color: 'white' }}
                variant="contained"
                onClick={() => onSubmit()}
              >
                {intl.get('SAVE') || 'Save'}
              </Button>
              <Button
                variant="contained"
                style={{
                  marginLeft: '20px', background: '#f5f5f5', color: '#3f51b5', border: '1px solid #3f51b5',
                }}
                onClick={closeUserForm}
              >
                {intl.get('CLOSE') || 'Close'}
              </Button>
            </Grid>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
};
