import React from 'react';
import intl from 'react-intl-universal';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
} from '@material-ui/core';

export const CurrencyDropDown = ({
  onChange, defaultValue, i18nTag, variant, width, minWidth, margin, clearFilter,
}: any) => {
  const [selectedValue, setSelectedValue] = React.useState(defaultValue);

  React.useEffect(() => {
    if (clearFilter === true) {
      setSelectedValue('');
    }
  }, [clearFilter]);

  const list: string[] = [
    'USD',
    'EUR',
    'MYR',
    'CAD',
    'GBP',
    'HUF',
    'AUD',
  ];

  return (
    <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <FormControl style={{ width, minWidth, margin }}>
        <InputLabel>{intl.get(i18nTag)}</InputLabel>
        <Select
          style={{ textAlign: 'left' }}
          variant={variant}
          onChange={(e) => { onChange(e.target.value); setSelectedValue(e.target.value); }}
          value={selectedValue}
          required
        >
          {
            list.map((option: string) => (
              <MenuItem value={option} key={option}>
                {option}
              </MenuItem>
            ))
          }
        </Select>
      </FormControl>
    </Box>
  );
};
