import { MerchantActionTypes, merchantConstants } from '../constants';
import {
  IMerchant, IMerchantState, MerchantPsp, MerchantPspInitialState,
} from '../../types/merchant.types';
import { Pagination } from '../../types/common.types';
import { AccountOptions } from '../../types/merchant-billing.types';

export interface IState {
  loading: boolean;
  isError: boolean;
  allMerchantList: IMerchant[];
  merchantDetails: IMerchant;
  updateError: boolean;
  isUpdated: boolean;
  isDeleted: boolean;
  errorMessages: string[];
  merchantPspList: MerchantPsp[];
  merchantPspDetails?: MerchantPsp;
  selectedPspId?: number;
  pagination: Pagination;
  merchantOptions:AccountOptions[];
}

const initialState: IState = {
  loading: false,
  isError: false,
  allMerchantList: [],
  updateError: false,
  isUpdated: false,
  isDeleted: false,
  merchantDetails: IMerchantState,
  errorMessages: [],
  merchantPspList: [],
  merchantPspDetails: MerchantPspInitialState,
  pagination: { limit: 10, current_page: 1 },
  merchantOptions:[]
};

export const merchantReducer = (state = initialState, action: MerchantActionTypes): IState => {
  switch (action.type) {
    case merchantConstants.GET_MERCHANT_LIST_REQUEST:
      return {
        ...state, loading: true, isError: false, isUpdated: false, isDeleted: false, errorMessages: [],
      };
    case merchantConstants.GET_MERCHANT_LIST_SUCCESS:
      return {
        ...state, loading: false, isError: false, allMerchantList: action.allMerchantList, pagination: action.pagination,
      };
    case merchantConstants.GET_MERCHANT_LIST_FAILURE:
      return {
        ...state, loading: false, isError: true,
      };

    case merchantConstants.GET_MERCHANT_BY_ID_REQUEST:
      return {
        ...state, loading: true, isError: false, errorMessages: [],
      };
    case merchantConstants.GET_MERCHANT_BY_ID_SUCCESS:
      return {
        ...state, loading: false, isError: false, merchantDetails: action.payload,
      };
    case merchantConstants.GET_MERCHANT_BY_ID_FAILURE:
      return {
        ...state, loading: false, isError: true,
      };

    case merchantConstants.ADD_MERCHANT_REQUEST:
      return {
        ...state, loading: true, updateError: false, isUpdated: false, isError: false, errorMessages: [],
      };
    case merchantConstants.ADD_MERCHANT_SUCCESS:
      return {
        ...state,
        loading: false,
        updateError: false,
        isUpdated: true,
        isError: false,
        allMerchantList: [...state.allMerchantList, action.payload],
      };
    case merchantConstants.ADD_MERCHANT_FAILURE:
      return {
        ...state, loading: false, updateError: true, errorMessages: action.errors,
      };

    case merchantConstants.UPDATE_MERCHANT_REQUEST:
      return {
        ...state, loading: true, updateError: false, isUpdated: false, isError: false, errorMessages: [],
      };
    case merchantConstants.UPDATE_MERCHANT_SUCCESS: {
      const merchantIndex = state.allMerchantList.findIndex((merchant) => merchant.uuid === action.payload.uuid);
      const updatedList = [...state.allMerchantList];
      updatedList[merchantIndex] = action.payload;
      return {
        ...state,
        loading: false,
        updateError: false,
        isError: false,
        isUpdated: true,
        allMerchantList: updatedList,
      };
    }
    case merchantConstants.UPDATE_MERCHANT_FAILURE:
      return {
        ...state, loading: false, updateError: true, errorMessages: action.errors,
      };

    case merchantConstants.DELETE_MERCHANT_REQUEST:
      return {
        ...state, loading: true, isError: false, updateError: false, isDeleted: false, errorMessages: [],
      };
    case merchantConstants.DELETE_MERCHANT_SUCCESS:
      return {
        ...state,
        loading: false,
        isError: false,
        updateError: false,
        isDeleted: true,
        allMerchantList: state.allMerchantList.filter((item) => item.uuid !== action.uuid),
      };
    case merchantConstants.DELETE_MERCHANT_FAILURE:
      return {
        ...state, loading: false, isError: true,
      };

    case merchantConstants.GET_MERCHANT_PSP_LIST_REQUEST:
      return {
        ...state, loading: true, isError: false, isUpdated: false, isDeleted: false, errorMessages: [],
      };
    case merchantConstants.GET_MERCHANT_PSP_LIST_SUCCESS:
      return {
        ...state, loading: false, isError: false, merchantPspList: action.merchantPspList,
      };
    case merchantConstants.GET_MERCHANT_PSP_LIST_FAILURE:
      return {
        ...state, loading: false, isError: true,
      };

    case merchantConstants.GET_MERCHANT_PSP_BY_ID_REQUEST:
      return {
        ...state, loading: true, isError: false, errorMessages: [],
      };
    case merchantConstants.GET_MERCHANT_PSP_BY_ID_SUCCESS:
      return {
        ...state, loading: false, isError: false, merchantPspDetails: action.merchantPspDetails,
      };
    case merchantConstants.GET_MERCHANT_PSP_BY_ID_FAILURE:
      return {
        ...state, loading: false, isError: true,
      };
    case merchantConstants.ADD_MERCHANT_PSP_REQUEST:
      return {
        ...state, loading: true, updateError: false, isUpdated: false, isError: false, errorMessages: [],
      };
    case merchantConstants.ADD_MERCHANT_PSP_SUCCESS:
      return {
        ...state,
        loading: false,
        updateError: false,
        isUpdated: true,
        isError: false,
        merchantPspList: [...state.merchantPspList, action.merchantPspDetails],
      };
    case merchantConstants.ADD_MERCHANT_PSP_FAILURE:
      return {
        ...state, loading: false, updateError: true, errorMessages: action.errors,
      };

    case merchantConstants.UPDATE_MERCHANT_PSP_REQUEST:
      return {
        ...state, loading: true, updateError: false, isUpdated: false, isError: false, errorMessages: [],
      };
    case merchantConstants.UPDATE_MERCHANT_PSP_SUCCESS: {
      const merchantPspIndex = state.merchantPspList.findIndex((merchantPsp) => merchantPsp.id === action.merchantPspDetails.id);
      const updatedList = [...state.merchantPspList];
      updatedList[merchantPspIndex] = action.merchantPspDetails;
      return {
        ...state,
        loading: false,
        updateError: false,
        isError: false,
        isUpdated: true,
        merchantPspList: updatedList,
      };
    }
    case merchantConstants.UPDATE_MERCHANT_PSP_FAILURE:
      return {
        ...state, loading: false, updateError: true, errorMessages: action.errors,
      };

    case merchantConstants.DELETE_MERCHANT_PSP_REQUEST:
      return {
        ...state, loading: true, isError: false, updateError: false, isDeleted: false, errorMessages: [],
      };
    case merchantConstants.DELETE_MERCHANT_PSP_SUCCESS:
      return {
        ...state,
        loading: false,
        isError: false,
        updateError: false,
        isDeleted: true,
        merchantPspList: state.merchantPspList.filter((item) => item.id !== action.selectedPspId),
      };
    case merchantConstants.DELETE_MERCHANT_PSP_FAILURE:
      return {
        ...state, loading: false, isError: true,
      };

    case merchantConstants.GET_PSP_BY_MERCHANT_UUID_REQUEST:
      return {
        ...state, loading: true, isError: false, isUpdated: false, isDeleted: false, errorMessages: [],
      };
    case merchantConstants.GET_PSP_BY_MERCHANT_UUID_SUCCESS:
      return {
        ...state, loading: false, isError: false, merchantPspList: action.merchantPspList,
      };
    case merchantConstants.GET_PSP_BY_MERCHANT_UUID_FAILURE:
      return {
        ...state, loading: false, isError: true,
      };

      case merchantConstants.MERCHANTS_PROVIDER_REQUEST:
        return {
          ...state, loading: true, isError: false, isUpdated: false, isDeleted: false, errorMessages: [],
        };
      case merchantConstants.MERCHANTS_PROVIDER_SUCCESS:
        return {
          ...state, loading: false, isError: false, merchantOptions: action.merchantOptions,
        };
      case merchantConstants.MERCHANTS_PROVIDER_FAILURE:
        return {
          ...state, loading: false, isError: true,
        };

    default:
      return state;
  }
};
