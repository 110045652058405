import React from 'react';
import intl from 'react-intl-universal';
import {
  Box,
  IconButton,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { makeStyles } from '@material-ui/core/styles';
import {
  MerchantPspForm,
} from '../../../../components';

interface Types {
  merchantPanel: boolean;
  merchantUUID: string;
}

const useStyles = makeStyles(() => ({
  iconStyle: {
    fill: '#3485f7',
  },
}));

const Toolbar: React.FC<Types> = ({ merchantUUID, merchantPanel }: Types) => {
  const classes = useStyles();
  const [displayMerchantAccountForm, setdisplayMerchantAccountForm] = React.useState(false);

  const openAccountMerchantForm = () => {
    setdisplayMerchantAccountForm(true);
  };

  const closeMerchantAccountForm = () => {
    setdisplayMerchantAccountForm(false);
  };

  return (
    <div style={{ display: 'flex' }}>
      <Box mr={1} display="flex" flexDirection="row" alignItems="center">
        <IconButton onClick={openAccountMerchantForm}>
          <AddIcon className={classes.iconStyle} />
        </IconButton>
        <span>
          {intl.get('ADD')}
        </span>
      </Box>
      <MerchantPspForm
        openDialog={displayMerchantAccountForm}
        closeMerchantAccountForm={closeMerchantAccountForm}
        selectedMerchantPsp={null}
        merchantUUID={merchantUUID}
        merchantPanel={merchantPanel}
      />
    </div>
  );
};
export default Toolbar;
