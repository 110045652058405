import React from 'react';
import { useDispatch } from 'react-redux';
import intl from 'react-intl-universal';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import {
  Card,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  ThemeProvider,
  TablePagination,
  Collapse,
} from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import DeleteIcon from '@material-ui/icons/Delete';
import { merchantActions } from '../../../../store/actions';
import {
  MerchantPspForm, PspForm,
} from '../../../../components';
import { tableTheme } from '../../../../assets/styles/table-style';
import { MerchantPsp, MerchantPspInitialState } from '../../../../types/merchant.types';

interface Props {
  merchantPspList: MerchantPsp[];
  merchantUUID: string;
  merchantPanel: boolean;
}

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    margin: '10px auto',
  },
  table: {
    minWidth: 650,
  },
  tableHeadCell: {
    fontSize: '12px',
    fontWeight: 700,
  },
  tableBodyCell: {
    color: '#232931',
    fontSize: '12px',
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  expandIcon: {
    backgroundColor: '#ffffff',
    borderRadius: '5px',
  },
  iconStyle: {
    fill: '#3485f7',
  },
  checkBox: {
    backgroundColor: '#fff',
    border: 'none',
  },
}));

export default ({ merchantPspList, merchantUUID, merchantPanel }: Props) => {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);
  const [expandedItemId, setExpandedItemId] = React.useState<number | undefined>(0);
  const [displayMerchantAccountForm, setdisplayMerchantAccountForm] = React.useState(false);
  const [selectedMerchantPsp, setSelectedMerchantPsp] = React.useState(MerchantPspInitialState);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const handleExpandClick = (merchantPsp: MerchantPsp) => {
    setExpandedItemId(merchantPsp.id);
    setExpanded(!expanded);
    setSelectedMerchantPsp(merchantPsp);
  };
  const dispatch = useDispatch();
  // const merchantPspListtate = useSelector((state: RootState) => state.merchantAccountReducer);

  const onDelete = (id: any) => {
    dispatch(merchantActions.deleteMerchantPsp(id));
  };

  const closeMerchantAccountForm = () => {
    setdisplayMerchantAccountForm(false);
    setExpanded(false);
    setExpandedItemId(0);
    setSelectedMerchantPsp(MerchantPspInitialState);
  };

  return (
    <>
      <Card className={classes.root}>
        <TableContainer component={Paper}>
          <ThemeProvider theme={tableTheme}>
            <Table className={classes.table} aria-label="billing list">
              <TableHead>
                <TableRow>
                  <TableCell />
                  <TableCell className={classes.tableHeadCell}>{intl.get('MERCHANT')}</TableCell>
                  <TableCell className={classes.tableHeadCell}>{intl.get('PAYMENT_PROVIDER')}</TableCell>
                  <TableCell className={classes.tableHeadCell}>{intl.get('PAYMENT_METHOD')}</TableCell>
                  <TableCell className={classes.tableHeadCell}>{intl.get('RATIO')}</TableCell>
                  <TableCell className={classes.tableHeadCell}>{intl.get('ACTIVE')}</TableCell>
                  <TableCell className={classes.tableHeadCell} />
                </TableRow>
              </TableHead>
              <TableBody>
                {merchantPspList
                  && merchantPspList?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row: MerchantPsp) => (
                    <>
                      <TableRow key={row.id}>
                        <TableCell />
                        <TableCell className={classes.tableBodyCell}>{row.merchant?.company_name}</TableCell>
                        <TableCell className={classes.tableBodyCell}>{row.payment_provider?.name}</TableCell>
                        <TableCell className={classes.tableBodyCell}>{row.payment_method}</TableCell>
                        <TableCell className={classes.tableBodyCell}>{row.ratio}</TableCell>
                        <TableCell className={classes.tableBodyCell}>{row.active ? 'Yes' : 'No'}</TableCell>
                        <TableCell align="center" style={{ padding: 0 }}>
                          <IconButton onClick={() => onDelete(row.id)} aria-label="delete">
                            <DeleteIcon className={classes.iconStyle} />
                          </IconButton>
                          <IconButton
                            className={classes.expandIcon}
                            aria-label="expand row"
                            size="small"
                            onClick={() => handleExpandClick(row)}
                          >
                            {expanded && row.id === expandedItemId ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                          </IconButton>
                        </TableCell>
                      </TableRow>
                      {expanded && row.id === expandedItemId
                        ? (
                          <TableRow>
                            <TableCell
                              style={{
                                paddingBottom: 0, paddingTop: 0, backgroundColor: '#2c333d', color: '#fff', borderRadius: '10px',
                              }}
                              colSpan={12}
                            >
                              <Collapse in={expanded && row.id === expandedItemId} timeout="auto" unmountOnExit>
                                <PspForm
                                  closeMerchantAccountForm={closeMerchantAccountForm}
                                  selectedMerchantPsp={selectedMerchantPsp}
                                  merchantUUID={merchantUUID}
                                />
                              </Collapse>
                            </TableCell>
                          </TableRow>
                        ) : null}
                    </>
                  ))}
              </TableBody>
            </Table>
          </ThemeProvider>
        </TableContainer>
        {merchantPspList && merchantPspList.length > 9
          ? (
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={merchantPspList.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          )
          : null}
      </Card>

      <MerchantPspForm
        openDialog={displayMerchantAccountForm}
        closeMerchantAccountForm={closeMerchantAccountForm}
        selectedMerchantPsp={selectedMerchantPsp}
        merchantUUID={merchantUUID}
        merchantPanel={merchantPanel}
      />
      {/* {merchantAccountState && merchantAccountState.isDeleted
        ? <DisplaySnackbar type="success" message={intl.get('DELETED_SUCCESSFULLY') || 'Deleted successfully'} /> : null} */}
    </>
  );
};
