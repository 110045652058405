import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import intl from 'react-intl-universal';
import {
  Button,
  Card,
  CardHeader,
  CardActions,
  CardContent,
  Divider,
  Typography,
  Grid,
  Box,
} from '@material-ui/core';
import ArrowBackSharpIcon from '@material-ui/icons/ArrowBackSharp';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import {
  useParams,
} from 'react-router-dom';
import { RootState } from '../../../store/reducers';
import { IMerchant, IMerchantState } from '../../../types/merchant.types';
import { merchantActions } from '../../../store/actions';
import { KYCActions } from '../../../store/actions/Merchant';
import { MerchantForm, StyledDividerLine, StyledSectionHeading } from '../../../components';
import { MerchantAccounts } from '../MerchantAccounts';
import { PSPAccounts } from './PSP/PspAccounts';
import { Files } from '../../MerchantPanel/Merchant/files';
import MerchantBalancesTable from '../Dashboard/MerchantBalancesTable';
import { history } from '../../../helpers';

type RouteParams = {
  merchantId: string;
}

const useStyles = makeStyles(() => createStyles({
  root: {
    maxWidth: '80%',
    margin: '10px auto',
  },
  back: {
    marginRight: '30px',
    '&:hover': {
      color: '#3485f7',
      cursor: 'pointer',
    },
  },
}));

export const MerchantDetail: React.FC = () => {
  const dispatch = useDispatch();
  const params = useParams<RouteParams>();
  const [merchant, setMerchant] = useState<IMerchant>(IMerchantState);
  const [displayMerchantForm, setdisplayMerchantForm] = React.useState(false);
  const classes = useStyles();
  const isMerchantPanel = false;

  useEffect(() => {
    dispatch(merchantActions.getMerchantById(params.merchantId));
    dispatch(KYCActions.getFiles(isMerchantPanel, params.merchantId));
  }, [dispatch, params, isMerchantPanel]);

  const KYCReducer = useSelector((state: RootState) => state.KYCReducer);

  const merchantReducer = useSelector((state: RootState) => state.merchantReducer);

  useEffect(() => {
    if (merchantReducer.merchantDetails) {
      setMerchant(merchantReducer.merchantDetails);
    }
  }, [merchantReducer.merchantDetails]);

  useEffect(() => {
    if (merchantReducer.isUpdated) {
      dispatch(merchantActions.getMerchantById(params.merchantId));
    }
  }, [merchantReducer.isUpdated, dispatch, params]);

  useEffect(() => {
    if (KYCReducer.isUpdated || KYCReducer.isDeleted) {
      dispatch(KYCActions.getFiles(isMerchantPanel, params.merchantId));
    }
  }, [KYCReducer, dispatch, params, isMerchantPanel]);

  const openMerchantForm = () => {
    setdisplayMerchantForm(true);
  };

  const closeMerchantForm = () => {
    setdisplayMerchantForm(false);
  };

  const backChange = () => {
    setTimeout(() => {
      history.push('/dashboard/merchants');
    }, 100);
  };

  /**
   * This will run when data added/edited
   */
  React.useEffect(() => {
    if (merchantReducer.isUpdated) {
      setdisplayMerchantForm(false);
    }
  }, [merchantReducer.isUpdated]);

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', paddingBottom: '5px' }}>
        <Box className={classes.back}>
          <ArrowBackSharpIcon onClick={backChange} />
        </Box>
        <Box>
          <StyledSectionHeading>{intl.get('MERCHANT_DETAILS')}</StyledSectionHeading>
        </Box>
      </div>
      <Card className={classes.root}>
        <CardHeader
          title={merchant.company_name}
          subheader={`${intl.get('COMPANY_CODE')} ${merchant.company_code}`}
        />
        <CardContent>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              sm={6}
            >
              <Typography
                color="textSecondary"
                variant="body1"
                align="left"
              >
                {`${intl.get('VAT_NUMBER')}:${merchant.vat_number}`}
              </Typography>
              <Typography
                color="textSecondary"
                variant="body1"
                align="left"
              >
                {`${intl.get('API_KEY')}:${merchant.api_key}`}
              </Typography>
              <Typography
                color="textSecondary"
                variant="body1"
                align="left"
              >
                {`${intl.get('API_SECRET')}:${merchant.api_secret}`}
              </Typography>
              <Typography
                color="textSecondary"
                variant="body1"
                align="left"
              >
                {`${intl.get('MARGIN_RATE')}:${merchant.margin_rate}`}
              </Typography>
              <Typography
                color="textSecondary"
                variant="body1"
                align="left"
              >
                {`${intl.get('MARGIN_TYPE')}:${merchant.margin_type}`}
              </Typography>
              <Typography
                color="textSecondary"
                variant="body1"
                align="left"
              >
                {`${intl.get('KYC_APPROVED')}:${merchant.kyc_approved ? 'Yes' : 'No'}`}
              </Typography>
              <Typography
                color="textSecondary"
                variant="body1"
                align="left"
              >
                {`${intl.get('ON_HOLD')}:${merchant.on_hold ? 'Yes' : 'No'}`}
              </Typography>
              <Typography
                color="textSecondary"
                variant="body1"
                align="left"
              >
                {`${intl.get('OBSOLETE')}:${merchant.obsolete ? 'Yes' : 'No'}`}
              </Typography>
            </Grid>
            <Grid
              item
              sm={6}
            >
              <Typography
                color="textSecondary"
                variant="body1"
                align="left"
              >
                {`${intl.get('COMPANY_COUNTRY')}:${merchant.company_country}`}
              </Typography>
              <Typography
                color="textSecondary"
                variant="body1"
                align="left"
              >
                {`${intl.get('BILLING_PERIOD_IN_DAYS')}:${merchant.billing_period}`}
              </Typography>
              <Typography
                color="textSecondary"
                variant="body1"
                align="left"
              >
                {`${intl.get('PRIMARY_CONTACT_NAME')}:${merchant.primary_contact_name}`}
              </Typography>
              <Typography
                color="textSecondary"
                variant="body1"
                align="left"
              >
                {`${intl.get('PRIMARY_CONTACT_EMAIL')}:${merchant.primary_contact_email}`}
              </Typography>
              <Typography
                color="textSecondary"
                variant="body1"
                align="left"
              >
                {`${intl.get('PRIMARY_CONTACT_PHONE')}:${merchant.primary_contact_phone}`}
              </Typography>
              <Typography
                color="textSecondary"
                variant="body1"
                align="left"
              >
                {`${intl.get('TECHNICAL_CONTACT_NAME')}:${merchant.technical_contact_name}`}
              </Typography>
              <Typography
                color="textSecondary"
                variant="body1"
                align="left"
              >
                {`${intl.get('TECHNICAL_CONTACT_EMAIL')}:${merchant.technical_contact_email}`}
              </Typography>
              <Typography
                color="textSecondary"
                variant="body1"
                align="left"
              >
                {`${intl.get('TECHNICAL_CONTACT_PHONE')}:${merchant.technical_contact_phone}`}
              </Typography>

            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <CardActions>
          <Button
            color="primary"
            fullWidth
            variant="text"
            onClick={() => openMerchantForm()}
          >
            {intl.get('EDIT')}
          </Button>
        </CardActions>
      </Card>
      <MerchantAccounts merchantPanel={isMerchantPanel} />
      <StyledSectionHeading>{intl.get('MERCHANT_BALANCES')}</StyledSectionHeading>
      <MerchantBalancesTable merchantUUID={params.merchantId} dashboard />
      <PSPAccounts merchantPanel={isMerchantPanel} />
      <StyledSectionHeading>KYC</StyledSectionHeading>
      <StyledDividerLine />
      <Files files={KYCReducer?.files} merchantPanel={isMerchantPanel} merchantUUID={params.merchantId} />
      <MerchantForm openDialog={displayMerchantForm} closeMerchantForm={closeMerchantForm} selectedMerchant={merchant} />
    </>
  );
};
