import { API } from '../config';
import { ChangePassword, IUser, UserFilter } from '../modules/AdminPanel/Users/types';
import { Pagination } from '../types/common.types';

const getUserList = (paginationData: Pagination, filters?: UserFilter) => {
  let queryParams = '';
  if (filters?.role) {
    queryParams += `&role=${filters.role}`;
  }
  if (filters?.enabled) {
    queryParams += `&enabled=${filters.enabled}`;
  }
  if (filters?.selectedDates?.from && filters?.selectedDates?.to) {
    queryParams += `&from=${filters?.selectedDates.from}&to=${filters?.selectedDates.to}`;
  }
  if (filters?.search) {
    queryParams += `&search=${filters.search}`;
  }
  return API.get(`/admin/users?with=merchant&limit=${paginationData.limit}&page=${paginationData.current_page}${queryParams}`);
};

const updateUser = (body: any) => API.post(`admin/users/${body.uuid}`, body.form);

const addUser = (body: IUser) => API.post('admin/users', body);

const deleteUser = (uuid: string) => API.delete(`admin/users/${uuid}`);

const changePasswordUser = (body: ChangePassword, uuid: any) => API.post(`admin/users/${uuid}/password-reset`, body);
export const userService = {
  getUserList,
  addUser,
  updateUser,
  deleteUser,
  changePasswordUser,
};
