import { createMuiTheme } from '@material-ui/core/styles';

export const tableTheme = createMuiTheme({
  overrides: {
    MuiTable: {
      root: {
        borderSpacing: '0 10px',
        borderCollapse: 'separate',
      },
    },
    MuiTableBody: {
      root: {
        backgroundColor: '#e8ebed',
      },
    },
  },
});
