import React, { useEffect } from 'react';
import intl from 'react-intl-universal';
import { useSelector, useDispatch } from 'react-redux';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core';
import { adminTransactionActions } from '../store/actions';
import { RootState } from '../store/reducers';
import { Option } from '../types/common.types';

export const ProviderDropDown = ({
  onChange, defaultValue, i18nTag, variant, label, clearFilter, merchantPanel, width, minWidth, margin,
}: any) => {
  const [selectedValue, setSelectedValue] = React.useState(defaultValue);
  const dispatch = useDispatch();

  useEffect(() => {
    if (clearFilter === true) {
      setSelectedValue('');
    }
  }, [clearFilter]);
  useEffect(() => {
    dispatch(adminTransactionActions.getProviderOptions(merchantPanel));
  }, [dispatch, merchantPanel]);

  const paymentProviderState = useSelector((state: RootState) => state.adminTransactionReducer);
  const list: Option[] = paymentProviderState.providerOptions;
  return (
    <FormControl style={{ width, minWidth, margin }}>
      <InputLabel>{intl.get(i18nTag)}</InputLabel>
      <Select
        variant={variant}
        onChange={(e) => {
          onChange(e.target.value); setSelectedValue(e.target.value);
        }}
        label={label}
        value={selectedValue}
        style={{ textAlign: 'left' }}
        required
      >
        {
          list?.map((row: Option) => <MenuItem value={row.value}>{row.label}</MenuItem>)
        }
      </Select>
    </FormControl>
  );
};
