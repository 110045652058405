import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import intl from 'react-intl-universal';
import {
  Button,
  Card,
  CardContent,
  Divider,
  Typography,
  Grid,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  Box,
  TableContainer,
  Paper,
} from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import {
  useParams,
} from 'react-router-dom';
// import InputLabel from '@material-ui/core/InputLabel';
// import MenuItem from '@material-ui/core/MenuItem';
// import FormControl from '@material-ui/core/FormControl';
// import Select from '@material-ui/core/Select';
import { RootState } from '../../../store/reducers';
import {
  AccountOptions, IMerchantBilling, IMerchantBillingState, PayoutTransaction,
} from '../../../types/merchant-billing.types';
import { merchantBillingActions } from '../../../store/actions';
import {
  BankAccount, PayoutTransactions,
} from './index';
import { StyledSectionHeading, DisplaySnackbar } from '../../../components';
import ExportIcon from '../../../assets/icons/Export.svg';
import { ConfirmationBox } from '../../../components/ConfirmationBox';

type RouteParams = {
  billingId: string;
  merchantId: string;
}

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    margin: '10px auto',
  },
  section: {
    borderRadius: '15px',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  table: {
    marginBottom: theme.spacing(3),
  },
  tableHeadCell: {
    fontSize: '12px',
    fontWeight: 700,
  },
  tableBodyCell: {
    fontSize: '12px',
  },
  label: {
    color: '#626972',
  },
  value: {
    color: '#3485f7',
    fontSize: '16px',
  },
  smallSize: {
    fontSize: '16px !important',
  },
  paymentBtn: {
    backgroundColor: '#040732',
    paddingLeft: '20px',
    paddingRight: '20px',
    width: '100%',
    padding: '10px 0',
    borderRadius: '10px',
    textTransform: 'capitalize',
  },
  formControl: {
    margin: theme.spacing(1),
    width: '90%',
  },
}));

export const BillDetails: React.FC = () => {
  const dispatch = useDispatch();
  const params = useParams<RouteParams>();
  const [cancel] = React.useState(false);
  const [confirmation, setConfirmation] = React.useState(false);
  const [bill, setBill] = useState<IMerchantBilling>(IMerchantBillingState);
  const [bankOptions, setBankOptions] = useState<AccountOptions[]>([]);
  const [payoutTransactions, setPayoutTransactions] = useState<PayoutTransaction[]>([]);
  const classes = useStyles();
  const merchantPanel = !params.merchantId;
  useEffect(() => {
    dispatch(merchantBillingActions.getMerchantBillingById(params.billingId, merchantPanel));
    if (!merchantPanel) {
      dispatch(merchantBillingActions.getMerchantAccountOptions(params.merchantId, merchantPanel));
      dispatch(merchantBillingActions.getPayoutTransactionList(params.billingId, merchantPanel));
    } else {
      dispatch(merchantBillingActions.getMerchantAccountOptions(params.billingId, merchantPanel));
      dispatch(merchantBillingActions.getPayoutTransactionList(params.billingId, merchantPanel));
    }
  }, [dispatch, params, merchantPanel]);

  const merchantBillingReducer = useSelector((state: RootState) => state.merchantBillingReducer);
  // const billingPayments: BillingPayment[] = merchantBillingReducer.billingPayments || [];
  useEffect(() => {
    if (merchantBillingReducer.merchantBillingDetails) {
      setBill(merchantBillingReducer.merchantBillingDetails);
    }
    if (merchantBillingReducer.accountOptions) {
      setBankOptions(merchantBillingReducer.accountOptions);
    }
    if (merchantBillingReducer.payoutTransactions) {
      setPayoutTransactions(merchantBillingReducer.payoutTransactions);
    }
  }, [merchantBillingReducer]);

  useEffect(() => {
    if (merchantBillingReducer.isUpdated) {
      dispatch(merchantBillingActions.getMerchantBillingById(params.billingId, merchantPanel));
    }
  }, [merchantBillingReducer.isUpdated, dispatch, params, merchantPanel]);

  const onSubmit = () => {
    let body;
    if (merchantPanel) {
      body = { id: params.billingId, form: { paid: true } };
    } else {
      if (cancel === false) {
        body = { id: params.billingId, form: { paid: true, provider: 'xinpay' } };
      }
      body = { id: params.billingId, form: { status: 'cancelled' } };
    }
    dispatch(merchantBillingActions.updateMerchantBillingStatus(body, merchantPanel));
  };

  const addPayoutAccount = (uuid: string) => {
    dispatch(merchantBillingActions.addPayoutAccount({ merchantBillUUID: params.billingId, form: { account_uuid: uuid } }, merchantPanel));
  };
  const deletePayoutAccount = (uuid: string | undefined) => {
    dispatch(merchantBillingActions.deletePayoutAccount({ merchantBillUUID: params.billingId, payoutAccountUUID: uuid }, merchantPanel));
  };

  const openConfirmationBox = () => {
    setConfirmation(true);
  };

  const closeConfirmationBox = () => {
    setConfirmation(false);
  };

  return (
    <>
      <div className={classes.root}>
        <Card className={classes.section}>
          <Grid container wrap="wrap" justify="space-between" alignItems="center">
            <StyledSectionHeading>{bill.merchant?.company_name}</StyledSectionHeading>
            <div>
              <Button variant="outlined" size="small" style={{ display: 'flex', alignItems: 'flex-end' }}>
                {intl.get('EXPORT')}
                {' '}
                PDF
                {' '}
                <img src={ExportIcon} alt="" width="25" />
              </Button>
            </div>
          </Grid>
        </Card>

        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            md={4}
            xs={12}
          >
            <Card className={classes.section}>
              <CardContent>
                <Typography
                  color="textSecondary"
                  variant="body1"
                  align="left"
                >
                  {intl.get('FINAL_TOTAL')}
                </Typography>
                <StyledSectionHeading>
                  <div dangerouslySetInnerHTML={{
                    __html: `${bill?.final_total?.toString().split('.')?.length
                      ? (
                        `${bill?.final_total?.toString().split('.')[0]
                        }.<span class=${classes.smallSize}>${bill?.final_total?.toString().split('.')[1]}</span>`
                      )
                      : bill?.final_total}`,
                  }}
                  />
                </StyledSectionHeading>
                {!merchantPanel
                  ? (
                    <>
                      {/* <FormControl className={classes.formControl}>
                        <InputLabel>{intl.get('PAYMENT_PROVIDERS')}</InputLabel>
                        <Select value="xinpay">
                          <MenuItem value="xinpay">xinpay (b 1,000,000)</MenuItem>
                        </Select>
                      </FormControl> */}
                      <Button
                        variant="contained"
                        size="small"
                        className={classes.paymentBtn}
                        onClick={openConfirmationBox}
                        disabled={!!bill.paid_on}
                      >
                        {bill.paid ? intl.get('PAID') : intl.get('MARK_AS_PAID')}
                      </Button>
                      {/* <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        style={{ marginTop: '6px' }}
                        className={classes.paymentBtn}
                        onClick={() => {
                          setCancel(true);
                          openConfirmationBox();
                        }}
                      >
                        {intl.get('CANCEL_PAYOUT')}
                      </Button> */}
                    </>
                  )
                  : (
                    <Button
                      variant="contained"
                      size="small"
                      color="primary"
                      className={classes.paymentBtn}
                      onClick={openConfirmationBox}
                      disabled={bill.status !== 'pending'}
                    >
                      {intl.get('CONFIRM_PAYMENT_REQUEST')}
                    </Button>
                  )}
                <p style={{ color: '#3981f7', fontSize: '12px' }}>{intl.get('PAYOUT_TRANSACTION_NOTE', { limit: 100 })}</p>
                <Box my={5} display="flex" flexDirection="column" justifyContent="space-between">
                  <div>
                    <Divider />
                    <Box mt={2} mb={2} display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
                      <span className={classes.label}>
                        {intl.get('TRANSACTION_TOTAL')}
                      </span>
                      <span className={classes.value}>
                        {bill?.transaction_total || bill?.total}
                        {' '}
                        {bill.currency}
                      </span>
                    </Box>
                    <Divider />
                    {/* <Box mt={2} mb={2} display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
                      <span className={classes.label}>
                        {intl.get('BASE_TRANSACTION_TOTAL')}
                      </span>
                      <span className={classes.value}>
                        {bill.base_transaction_total || 'N/A'}
                      </span>
                    </Box>
                    <Divider /> */}
                    <Box mt={2} mb={2} display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
                      <span className={classes.label}>
                        {intl.get('SERVICE_FEE')}
                      </span>
                      <span className={classes.value}>
                        {bill.service_fee}
                        {' '}
                        {bill.currency}
                      </span>
                    </Box>
                    <Divider />
                    <Box mt={2} mb={2} display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
                      <span className={classes.label}>
                        {intl.get('CURRENCY')}
                      </span>
                      <span className={classes.value}>
                        {bill.currency}
                      </span>
                    </Box>
                    <Divider />
                    <Box mt={2} mb={2} display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
                      <span className={classes.label}>
                        {intl.get('FINAL_TOTAL')}
                      </span>
                      <span className={classes.value}>
                        {bill.final_total}
                        {' '}
                        {bill.currency}
                      </span>
                    </Box>
                    <Divider />
                  </div>
                  <div style={{ marginTop: '20%' }}>
                    <Divider />
                    <Box mt={2} mb={2} display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
                      <span className={classes.label}>
                        {intl.get('PAID_ON')}
                      </span>
                      <span className={classes.value}>
                        {bill.paid_on ? new Date(bill.paid_on).toLocaleDateString() : ''}
                      </span>
                    </Box>
                    <Divider />
                    <Box mt={2} mb={2} display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
                      <span className={classes.label}>
                        {intl.get('STATUS')}
                      </span>
                      <span className={classes.value}>
                        {bill.status}
                      </span>
                    </Box>
                    <Divider />
                  </div>
                </Box>
              </CardContent>
              <Divider />
              <ConfirmationBox openDialog={confirmation} closeConfirmationBox={closeConfirmationBox} submitConfirmationBox={onSubmit} />

              {/* <CardActions>
                {!merchantPanel
                  ? (
                    <Link to={`/dashboard/billings/pay-bill/${params.billingId}`}>
                      <Button
                        color="primary"
                        fullWidth
                        variant="text"
                        disabled={!!bill.paid_on}
                      >
                        {bill.paid ? intl.get('PAID') : intl.get('MARK_AS_PAID')}
                      </Button>
                    </Link>
                  )
                  : (
                    <Link to={`/dashboard/billings/request-payment/${params.billingId}`}>
                      <Button
                        color="primary"
                        fullWidth
                        variant="text"
                        disabled={bill.status !== 'pending'}
                      >
                        {bill.status === 'pending' ? intl.get('REQUEST_TO_BE_PAID') : bill.status}
                      </Button>
                    </Link>
                  )}

              </CardActions> */}
            </Card>
            {/* <Card>
              <CardHeader
                title={intl.get('PAYOUT_DETAILS')}
              />
              <CardContent>
                <Grid
                  container
                  spacing={3}
                >
                  <Grid
                    item
                    sm={6}
                  >
                    <Typography
                      color="textSecondary"
                      variant="body1"
                      align="left"
                    >
                      {`${intl.get('PAID_ON')} : ${bill.paid_on ? new Date(bill.paid_on).toLocaleDateString() : ''}`}
                    </Typography>
                    <Typography
                      color="textSecondary"
                      variant="body1"
                      align="left"
                    >
                      {`${intl.get('TRANSACTION_AMOUNT')} : ${bill.base_transaction_total || ''}`}
                    </Typography>
                    <Typography
                      color="textSecondary"
                      variant="body1"
                      align="left"
                    >
                      {`${intl.get('SERVICE_FEE')} : ${bill.base_service_fee}`}
                    </Typography>
                    <Typography
                      color="textSecondary"
                      variant="body1"
                      align="left"
                    >
                      {`${intl.get('FINAL_TOTAL')} : ${bill.base_final_total}`}
                    </Typography>
                    <Typography
                      color="textSecondary"
                      variant="body1"
                      align="left"
                    >
                      {`${intl.get('CURRENCY')} : ${bill.currency}`}
                    </Typography>
                  </Grid>

                  <Grid
                    item
                    sm={6}
                  >
                    <Typography
                      color="textSecondary"
                      variant="body1"
                      align="left"
                    >
                      {`${intl.get('STATUS')} : ${bill.status}`}
                    </Typography>
                    {!merchantPanel
                      ? (
                        <Typography
                          color="textSecondary"
                          variant="body1"
                          align="left"
                        >
                          {`${intl.get('MERCHANT')} : ${bill.merchant?.company_name}`}
                        </Typography>
                      ) : null}
                    <Typography
                      color="textSecondary"
                      variant="body1"
                      align="left"
                    >
                      {`${intl.get('BASE_CURRENCY')} : ${bill.base_currency}`}
                    </Typography>
                    <Typography
                      color="textSecondary"
                      variant="body1"
                      align="left"
                    >
                      {`${intl.get('BILLED_ON')} : ${bill.billed_on ? new Date(bill.billed_on).toLocaleDateString() : 'N/A'}`}
                    </Typography>
                    <Typography
                      color="textSecondary"
                      variant="body1"
                      align="left"
                    >
                      {`${intl.get('BASE_FEE')} : ${bill.base_fee}`}
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
              <Divider />
              <CardActions>
                {!merchantPanel
                  ? (
                    <Link to={`/dashboard/billings/pay-bill/${params.billingId}`}>
                      <Button
                        color="primary"
                        fullWidth
                        variant="text"
                        disabled={!!bill.paid_on}
                      >
                        {bill.paid ? intl.get('PAID') : intl.get('MARK_AS_PAID')}
                      </Button>
                    </Link>
                  )
                  : (
                    <Link to={`/dashboard/billings/request-payment/${params.billingId}`}>
                      <Button
                        color="primary"
                        fullWidth
                        variant="text"
                        disabled={bill.status !== 'pending'}
                      >
                        {bill.status === 'pending' ? intl.get('REQUEST_TO_BE_PAID') : bill.status}
                      </Button>
                    </Link>
                  )}

              </CardActions>
            </Card> */}
          </Grid>
          <Grid
            item
            md={8}
            xs={12}
          >
            <Card className={classes.section} style={{ padding: 0 }}>
              <TableContainer component={Paper}>
                <Table aria-label="billing list" className={classes.table}>
                  <TableHead>
                    <TableRow>
                      <TableCell className={classes.tableHeadCell}>{intl.get('MERCHANT')}</TableCell>
                      <TableCell className={classes.tableHeadCell}>{intl.get('VAT_NUMBER')}</TableCell>
                      <TableCell className={classes.tableHeadCell}>{intl.get('COUNTRY')}</TableCell>
                      <TableCell className={classes.tableHeadCell}>{intl.get('PRIMARY_CONTACT_NAME')}</TableCell>
                      <TableCell className={classes.tableHeadCell}>{intl.get('PRIMARY_CONTACT_PHONE')}</TableCell>
                      <TableCell className={classes.tableHeadCell}>{intl.get('PRIMARY_CONTACT_EMAIL')}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell className={classes.tableBodyCell}>
                        {bill?.merchant?.company_name}
                      </TableCell>
                      <TableCell className={classes.tableBodyCell}>{bill?.merchant?.vat_number}</TableCell>
                      <TableCell className={classes.tableBodyCell}>{bill?.merchant?.company_country}</TableCell>
                      <TableCell className={classes.tableBodyCell}>{bill?.merchant?.primary_contact_name}</TableCell>
                      <TableCell className={classes.tableBodyCell}>{bill?.merchant?.primary_contact_phone}</TableCell>
                      <TableCell className={classes.tableBodyCell}>{bill?.merchant?.primary_contact_email}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
                <Divider />
              </TableContainer>
            </Card>

            {/* <Card className={classes.section}>
              <h3 style={{ textAlign: 'left' }}>{intl.get('BANK_ACCOUNT')}</h3>
              <Table aria-label="billing list">
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.tableBodyCell}>{intl.get('BILLED_ON')}</TableCell>
                    <TableCell className={classes.tableBodyCell}>{intl.get('TRANSACTION_AMOUNT')}</TableCell>
                    <TableCell className={classes.tableBodyCell}>{intl.get('SERVICE_FEE')}</TableCell>
                    <TableCell className={classes.tableBodyCell}>{intl.get('FINAL_TOTAL')}</TableCell>
                    <TableCell className={classes.tableBodyCell}>{intl.get('CURRENCY')}</TableCell>
                    <TableCell className={classes.tableBodyCell}>{intl.get('STATUS')}</TableCell>
                    <TableCell className={classes.tableBodyCell}>{intl.get('PAID_ON')}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell className={classes.tableBodyCell}>
                      {bill.billed_on ? new Date(bill.billed_on).toLocaleDateString() : 'N/A'}
                    </TableCell>
                    <TableCell className={classes.tableBodyCell}>{bill.base_service_fee}</TableCell>
                    <TableCell className={classes.tableBodyCell}>{bill.base_final_total}</TableCell>
                    <TableCell className={classes.tableBodyCell}>{bill.currency}</TableCell>
                    <TableCell className={classes.tableBodyCell}>{bill.status}</TableCell>
                    <TableCell className={classes.tableBodyCell}>{bill.paid_on ? new Date(bill.paid_on).toLocaleDateString() : 'N/A'}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Card> */}
            <BankAccount
              accounts={bill?.accounts}
              accountOptions={bankOptions}
              addPayoutAccount={addPayoutAccount}
              deletePayoutAccount={deletePayoutAccount}
              merchantPanel={merchantPanel}
              billStatus={bill?.status}
            />
            {
              !merchantPanel
                ? <PayoutTransactions payoutTransactions={payoutTransactions} /> : null
            }
          </Grid>
        </Grid>
        {/* <BillingPaymentList billingPayments={billingPayments} merchantPanel={merchantPanel} /> */}
        {merchantBillingReducer && merchantBillingReducer.isUpdated
          ? <DisplaySnackbar type="success" message={intl.get('UPDATED_SUCCESSFULLY')} /> : null}

        {
          merchantBillingReducer && merchantBillingReducer.isDeleted
            ? <DisplaySnackbar type="success" message={intl.get('DELETED_SUCCESSFULLY')} /> : null
        }

        {
          merchantBillingReducer && (merchantBillingReducer.updateError || merchantBillingReducer.isError)
            ? <DisplaySnackbar type="error" message={intl.get('SOMETHING_WENT_WRONG')} /> : null
        }
      </div>
    </>
  );
};
