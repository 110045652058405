import { authConstants, AuthActionTypes } from '../constants';
import { utils } from '../../helpers';
import { TwoFAData } from '../../types/common.types';

export interface ILoginState {
  loggedIn: boolean;
  loading: boolean;
  isError: boolean;
  faError: boolean;
  loggedInUser: { name: string, role: string, FAToken: string };
  resetLinkSent: boolean;
  newPasswordUpdated: boolean;
  errorMessages: string[];
  twoFAActivated: boolean;
  twoFARequestSent: boolean;
  twoFAValidated: boolean;
  resetPassword: boolean;
  twoFAData: TwoFAData;

}
const userData = JSON.parse(localStorage.getItem('auth') || '{}');

const initialState: ILoginState = {
  loggedIn: utils.isAuth(),
  loading: false,
  isError: false,
  faError: false,
  loggedInUser: { name: userData.name || '', role: userData.role || '', FAToken: userData.FAToken || '' },
  resetLinkSent: false,
  newPasswordUpdated: false,
  errorMessages: [],
  twoFAActivated: false,
  twoFARequestSent: false,
  twoFAValidated: false,
  resetPassword: false,
  twoFAData: { secret: '', message: '', qrImage: '' },
};

export const authentication = (state = initialState, action: AuthActionTypes): ILoginState => {
  switch (action.type) {
    case authConstants.LOGIN_REQUEST:
      return {
        ...state,
        loading: true,
        resetLinkSent: false,
        newPasswordUpdated: false,
        errorMessages: [],
        isError: false,
        faError: false,
        twoFAActivated: false,
        twoFARequestSent: false,
        twoFAValidated: false,
        resetPassword: false,

      };
    case authConstants.LOGIN_SUCCESS:
      return {
        ...state,
        loggedIn: action.loggedInUser.loggedIn,
        loading: false,
        loggedInUser: action.loggedInUser,
        resetPassword: action.resetPassword,
      };
    case authConstants.LOGIN_FAILURE:
      return {
        ...state,
        loading: false,
        loggedIn: false,
        isError: true,
        errorMessages: action.errors,
      };
    case authConstants.UPDATE_PASSWORD_REQUEST:
      return {
        ...state,
        loading: true,
        resetLinkSent: false,
        newPasswordUpdated: false,
        errorMessages: [],
        isError: false,
        faError: false,
        twoFAActivated: false,
        twoFARequestSent: false,
        twoFAValidated: false,
        resetPassword: false,

      };
    case authConstants.UPDATE_PASSWORD_SUCCESS:
      return {
        ...state,
        loggedIn: action.loggedInUser.loggedIn,
        loading: false,
        loggedInUser: action.loggedInUser,
        resetPassword: action.resetPassword,
        newPasswordUpdated: true,
      };
    case authConstants.UPDATE_PASSWORD_FAILURE:
      return {
        ...state,
        loading: false,
        loggedIn: false,
        isError: true,
        errorMessages: action.errors,
      };

    case authConstants.LOGOUT:
      return {
        ...state,
        loading: false,
        loggedIn: false,
        isError: false,
        faError: false,
      };

    case authConstants.RESET_PASSWORD_LINK_REQUEST:
      return {
        ...state,
        loading: true,
        resetLinkSent: false,
        newPasswordUpdated: false,
        errorMessages: [],
        isError: false,
        faError: false,
        twoFAActivated: false,
        twoFARequestSent: false,
        twoFAValidated: false,
        resetPassword: false,

      };
    case authConstants.RESET_PASSWORD_LINK_SUCCESS:
      return {
        ...state,
        loggedIn: true,
        loading: false,
        resetLinkSent: true,
      };
    case authConstants.RESET_PASSWORD_LINK_FAILURE:
      return {
        ...state,
        loading: false,
        loggedIn: false,
        isError: true,
        errorMessages: action.errors,
      };

    case authConstants.SET_NEW_PASSWORD_REQUEST:
      return {
        ...state,
        loading: true,
        resetLinkSent: false,
        newPasswordUpdated: false,
        errorMessages: [],
        isError: false,
        faError: false,
        twoFAActivated: false,
        twoFARequestSent: false,
        twoFAValidated: false,
        resetPassword: false,

      };
    case authConstants.SET_NEW_PASSWORD_SUCCESS:
      return {
        ...state,
        loggedIn: true,
        loading: false,
        newPasswordUpdated: true,
      };
    case authConstants.SET_NEW_PASSWORD_FAILURE:
      return {
        ...state,
        loading: false,
        loggedIn: false,
        isError: true,
        errorMessages: action.errors,
      };

    case authConstants.ACTIVATE_2FA_REQUEST:
      return {
        ...state,
        loading: true,
        resetLinkSent: false,
        newPasswordUpdated: false,
        errorMessages: [],
        isError: false,
        faError: false,
        twoFAActivated: false,
        twoFARequestSent: false,
        twoFAValidated: false,
        resetPassword: false,

      };
    case authConstants.ACTIVATE_2FA_SUCCESS:
      return {
        ...state,
        loggedIn: true,
        loading: false,
        twoFAActivated: true,
      };
    case authConstants.ACTIVATE_2FA_FAILURE:
      return {
        ...state,
        loading: false,
        loggedIn: false,
        isError: false,
        faError: true,
        twoFAActivated: false,
        errorMessages: action.errors,
      };

    case authConstants.REQUEST_2FA_REQUEST:
      return {
        ...state,
        loading: true,
        resetLinkSent: false,
        newPasswordUpdated: false,
        errorMessages: [],
        isError: false,
        faError: false,
        twoFARequestSent: false,
        twoFAActivated: false,
        twoFAValidated: false,
        resetPassword: false,

      };
    case authConstants.REQUEST_2FA_SUCCESS:
      return {
        ...state, loggedIn: true, loading: false, twoFARequestSent: true, twoFAData: action.twoFAData,
      };
    case authConstants.REQUEST_2FA_FAILURE:
      return {
        ...state,
        loading: false,
        loggedIn: false,
        isError: false,
        faError: true,
        twoFARequestSent: false,
        errorMessages: action.errors,
      };
    case authConstants.VALIDATE_2FA_REQUEST:
      return {
        ...state,
        loading: true,
        resetLinkSent: false,
        newPasswordUpdated: false,
        errorMessages: [],
        isError: false,
        faError: false,
        twoFARequestSent: false,
        twoFAActivated: false,
        twoFAValidated: false,
        resetPassword: false,

      };
    case authConstants.VALIDATE_2FA_SUCCESS:
      return {
        ...state, loggedIn: true, loading: false, twoFAValidated: true, resetPassword: action.resetPassword,

      };
    case authConstants.VALIDATE_2FA_FAILURE:
      return {
        ...state,
        loading: false,
        loggedIn: false,
        isError: false,
        faError: true,
        twoFAValidated: false,
        errorMessages: action.errors,
      };
    case authConstants.RESET_TWO_FA_STATE:
      return {
        ...state,
        twoFAData: { qrImage: '', message: '', secret: '' },
        isError: false,
        faError: false,
        twoFAActivated: false,
        loggedInUser: { FAToken: '', name: '', role: '' },
        errorMessages: action.errors,
        resetPassword: false,
        newPasswordUpdated: false,

      };
    default:
      return state;
  }
};
