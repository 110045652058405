import React from 'react';

const badge = ({ status }: any) => {
  let color = 'rgb(191 191 191)';
  let textColor = '#656565';

  if (status === 'Pending' || status === 'pending') {
    color = 'rgb(191 191 191)';
    textColor = 'rgb(30 30 30)';
  } else if (status === 'Complete' || status === 'complete' || status === 'Refunded' || status === 'Approved') {
    color = 'green';
    textColor = '#fff';
  } else if (status === 'InProgress') {
    color = '#8792f7';
    textColor = '#ffff';
  } else if (status === 'Cancelled' || status === 'Rejected') {
    color = '#ffdfde';
    textColor = '#ed2c3d';
  }

  return (
    <div>
      <span style={{
        borderRadius: '50px',
        padding: '10px 30px 10px 30px',
        fontSize: '12px',
        fontWeight: 'bold',
        backgroundColor: `${color}`,
        color: `${textColor}`,
      }}
      >
        {status}
      </span>
    </div>
  );
};

export default badge;
