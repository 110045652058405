import { Pagination, DownloadFile } from '../../types/common.types';
import { ExportData } from '../../types/merchant-billing.types';
import { SettlementReport, ISettlements, Refunds } from '../../types/settlement.types';

interface Types {
    GET_ADMIN_SETTLEMENT_LIST_REQUEST: string;
    GET_ADMIN_SETTLEMENT_LIST_SUCCESS: string;
    GET_ADMIN_SETTLEMENT_LIST_FAILURE: string;

    GET_ADMIN_SETTLEMENT_LIST_DONWLOAD_REQUEST: string;
    GET_ADMIN_SETTLEMENT_LIST_DONWLOAD_SUCCESS: string;
    GET_ADMIN_SETTLEMENT_LIST_DONWLOAD_FAILURE: string;

    ADD_SETTLEMENT_REQUEST: string;
    ADD_SETTLEMENT_SUCCESS: string;
    ADD_SETTLEMENT_FAILURE: string;

    UPDATE_SETTLEMENT_REQUEST: string;
    UPDATE_SETTLEMENT_SUCCESS: string;
    UPDATE_SETTLEMENT_FAILURE: string;

    SETTLEMENT_FOR_EDIT_REQUEST: string;
    SETTLEMENT_FOR_EDIT_SUCCESS: string;
    SETTLEMENT_FOR_EDIT_FAILURE: string;

    GET_SETTLEMENT_REFUND_LIST_REQUEST: string;
    GET_SETTLEMENT_REFUND_LIST_SUCCESS: string;
    GET_SETTLEMENT_REFUND_LIST_FAILURE: string;

    ADD_REFUND_REQUEST: string;
    ADD_REFUND_SUCCESS: string;
    ADD_REFUND_FAILURE: string;

    GET_REFUNDS_EXPORT_REQUEST: string;
    GET_REFUNDS_EXPORT_SUCCESS: string;
    GET_REFUNDS_EXPORT_FAILURE: string;


    RESET_STATE: string;
}

export const adminSettlementConstants: Types = {

    GET_ADMIN_SETTLEMENT_LIST_REQUEST: 'GET_ADMIN_SETTLEMENT_LIST_REQUEST',
    GET_ADMIN_SETTLEMENT_LIST_SUCCESS: 'GET_ADMIN_SETTLEMENT_LIST_SUCCESS',
    GET_ADMIN_SETTLEMENT_LIST_FAILURE: 'GET_ADMIN_SETTLEMENT_LIST_FAILURE',

    GET_ADMIN_SETTLEMENT_LIST_DONWLOAD_REQUEST: 'GET_ADMIN_SETTLEMENT_LIST_DONWLOAD_REQUEST',
    GET_ADMIN_SETTLEMENT_LIST_DONWLOAD_SUCCESS: 'GET_ADMIN_SETTLEMENT_LIST_DONWLOAD_SUCCESS',
    GET_ADMIN_SETTLEMENT_LIST_DONWLOAD_FAILURE: 'GET_ADMIN_SETTLEMENT_LIST_DONWLOAD_FAILURE',

    ADD_SETTLEMENT_REQUEST: 'ADD_SETTLEMENT_REQUEST',
    ADD_SETTLEMENT_SUCCESS: 'ADD_SETTLEMENT_SUCCESS',
    ADD_SETTLEMENT_FAILURE: 'ADD_SETTLEMENT_FAILURE',

    UPDATE_SETTLEMENT_REQUEST: 'UPDATE_SETTLEMENT_REQUEST',
    UPDATE_SETTLEMENT_SUCCESS: 'UPDATE_SETTLEMENT_SUCCESS',
    UPDATE_SETTLEMENT_FAILURE: 'UPDATE_SETTLEMENT_FAILURE',

    SETTLEMENT_FOR_EDIT_REQUEST: 'SETTLEMENT_FOR_EDIT_REQUEST',
    SETTLEMENT_FOR_EDIT_SUCCESS: 'SETTLEMENT_FOR_EDIT_SUCCESS',
    SETTLEMENT_FOR_EDIT_FAILURE: 'SETTLEMENT_FOR_EDIT_FAILURE',

    GET_SETTLEMENT_REFUND_LIST_REQUEST: 'GET_SETTLEMENT_REFUND_LIST_REQUEST',
    GET_SETTLEMENT_REFUND_LIST_SUCCESS: 'GET_SETTLEMENT_REFUND_LIST_SUCCESS',
    GET_SETTLEMENT_REFUND_LIST_FAILURE: 'GET_SETTLEMENT_REFUND_LIST_FAILURE',

    ADD_REFUND_REQUEST: 'ADD_REFUND_REQUEST',
    ADD_REFUND_SUCCESS: 'ADD_REFUND_SUCCESS',
    ADD_REFUND_FAILURE: 'ADD_REFUND_FAILURE',

    GET_REFUNDS_EXPORT_REQUEST: 'GET_REFUNDS_EXPORT_REQUEST',
    GET_REFUNDS_EXPORT_SUCCESS: 'GET_REFUNDS_EXPORT_SUCCESS',
    GET_REFUNDS_EXPORT_FAILURE: 'GET_REFUNDS_EXPORT_FAILURE',

    RESET_STATE: 'RESET_STATE',
};

interface LoadGetAdminSettlemetListAction {
    type: typeof adminSettlementConstants.GET_ADMIN_SETTLEMENT_LIST_REQUEST;
    allSettlementList: SettlementReport[];
    payload: ISettlements;
    pagination: Pagination;
    downloadFile: DownloadFile;
    updatePayload: SettlementReport;
    settlementForEdit: SettlementReport;
    refundSettlementList: SettlementReport[];
    addRefund: Refunds;
    exports: ExportData;
    errors?:any;
}

interface LoadedGetAdminSettlemetListAction {
    type: typeof adminSettlementConstants.GET_ADMIN_SETTLEMENT_LIST_SUCCESS;
    allSettlementList: SettlementReport[];
    payload: ISettlements;
    pagination: Pagination;
    downloadFile: DownloadFile;
    updatePayload: SettlementReport;
    settlementForEdit: SettlementReport;
    refundSettlementList: SettlementReport[];
    addRefund: Refunds;
    exports: ExportData;
    errors?:any;
}

interface FailedGetAdminSettlemetListAction {
    type: typeof adminSettlementConstants.GET_ADMIN_SETTLEMENT_LIST_FAILURE;
    allSettlementList: SettlementReport[];
    payload: ISettlements;
    pagination: Pagination;
    downloadFile: DownloadFile;
    updatePayload: SettlementReport;
    settlementForEdit: SettlementReport;
    refundSettlementList: SettlementReport[];
    addRefund: Refunds;
    exports: ExportData;
    errors?:any;

}

interface LoadAddAdminSettlemetAction {
    type: typeof adminSettlementConstants.ADD_SETTLEMENT_REQUEST;
    allSettlementList: SettlementReport[];
    payload: ISettlements;
    pagination: Pagination;
    downloadFile: DownloadFile;
    updatePayload: SettlementReport;
    settlementForEdit: SettlementReport;
    refundSettlementList: SettlementReport[];
    addRefund: Refunds;
    exports: ExportData;
    errors?:any;

}

interface LoadedAddAdminSettlemetAction {
    type: typeof adminSettlementConstants.ADD_SETTLEMENT_SUCCESS;
    allSettlementList: SettlementReport[];
    payload: ISettlements;
    pagination: Pagination;
    downloadFile: DownloadFile;
    updatePayload: SettlementReport;
    settlementForEdit: SettlementReport;
    refundSettlementList: SettlementReport[];
    addRefund: Refunds;
    exports: ExportData;
    errors?:any;

}

interface FailedAddAdminSettlemetAction {
    type: typeof adminSettlementConstants.ADD_SETTLEMENT_FAILURE;
    allSettlementList: SettlementReport[];
    payload: ISettlements;
    pagination: Pagination;
    downloadFile: DownloadFile;
    updatePayload: SettlementReport;
    settlementForEdit: SettlementReport;
    refundSettlementList: SettlementReport[];
    addRefund: Refunds;
    exports: ExportData;
    errors?:any;
}

interface LoadGetAdminSettlemetListDownloadAction {
    type: typeof adminSettlementConstants.GET_ADMIN_SETTLEMENT_LIST_DONWLOAD_REQUEST;
    allSettlementList: SettlementReport[];
    payload: ISettlements;
    pagination: Pagination;
    downloadFile: DownloadFile;
    updatePayload: SettlementReport;
    settlementForEdit: SettlementReport;
    refundSettlementList: SettlementReport[];
    addRefund: Refunds;
    exports: ExportData;
    errors?:any;

}

interface LoadedGetAdminSettlemetListDownloadAction {
    type: typeof adminSettlementConstants.GET_ADMIN_SETTLEMENT_LIST_DONWLOAD_SUCCESS;
    allSettlementList: SettlementReport[];
    payload: ISettlements;
    pagination: Pagination;
    downloadFile: DownloadFile;
    updatePayload: SettlementReport;
    settlementForEdit: SettlementReport;
    refundSettlementList: SettlementReport[];
    addRefund: Refunds;
    exports: ExportData;
    errors?:any;
}
interface FailedGetAdminSettlemetListDownloadAction {
    type: typeof adminSettlementConstants.GET_ADMIN_SETTLEMENT_LIST_DONWLOAD_FAILURE;
    allSettlementList: SettlementReport[];
    payload: ISettlements;
    pagination: Pagination;
    downloadFile: DownloadFile;
    updatePayload: SettlementReport;
    settlementForEdit: SettlementReport;
    refundSettlementList: SettlementReport[];
    addRefund: Refunds;
    exports: ExportData;
    errors?:any;
}

interface LoadGetAdminSettlemetUpdateAction {
    type: typeof adminSettlementConstants.UPDATE_SETTLEMENT_REQUEST;
    allSettlementList: SettlementReport[];
    payload: ISettlements;
    pagination: Pagination;
    downloadFile: DownloadFile;
    updatePayload: SettlementReport;
    settlementForEdit: SettlementReport;
    refundSettlementList: SettlementReport[];
    addRefund: Refunds;
    exports: ExportData;
    errors?:any;

}

interface LoadedGetAdminSettlemetUpdateAction {
    type: typeof adminSettlementConstants.UPDATE_SETTLEMENT_SUCCESS;
    allSettlementList: SettlementReport[];
    payload: ISettlements;
    pagination: Pagination;
    downloadFile: DownloadFile;
    updatePayload: SettlementReport;
    settlementForEdit: SettlementReport;
    refundSettlementList: SettlementReport[];
    addRefund: Refunds;
    exports: ExportData;
    errors?:any;
}

interface FailedGetAdminSettlemetUpdateAction {
    type: typeof adminSettlementConstants.UPDATE_SETTLEMENT_FAILURE;
    allSettlementList: SettlementReport[];
    payload: ISettlements;
    pagination: Pagination;
    downloadFile: DownloadFile;
    updatePayload: SettlementReport;
    settlementForEdit: SettlementReport;
    refundSettlementList: SettlementReport[];
    addRefund: Refunds;
    exports: ExportData;
    errors?:any;
}

interface LoadGetAdminSettlemetEditAction {
    type: typeof adminSettlementConstants.SETTLEMENT_FOR_EDIT_REQUEST;
    allSettlementList: SettlementReport[];
    payload: ISettlements;
    pagination: Pagination;
    downloadFile: DownloadFile;
    updatePayload: SettlementReport;
    settlementForEdit: SettlementReport;
    refundSettlementList: SettlementReport[];
    addRefund: Refunds;
    exports: ExportData;
    errors?:any;

}

interface LoadedGetAdminSettlemetEditAction {
    type: typeof adminSettlementConstants.SETTLEMENT_FOR_EDIT_SUCCESS;
    allSettlementList: SettlementReport[];
    payload: ISettlements;
    pagination: Pagination;
    downloadFile: DownloadFile;
    updatePayload: SettlementReport;
    settlementForEdit: SettlementReport;
    refundSettlementList: SettlementReport[];
    addRefund: Refunds;
    exports: ExportData;
    errors?:any;
}

interface FailedGetAdminSettlemetEditAction {
    type: typeof adminSettlementConstants.SETTLEMENT_FOR_EDIT_FAILURE;
    allSettlementList: SettlementReport[];
    payload: ISettlements;
    pagination: Pagination;
    downloadFile: DownloadFile;
    updatePayload: SettlementReport;
    settlementForEdit: SettlementReport;
    refundSettlementList: SettlementReport[];
    addRefund: Refunds;
    exports: ExportData;
    errors?:any;
}

interface LoadGetSettlemetRefundListAction {
    type: typeof adminSettlementConstants.GET_SETTLEMENT_REFUND_LIST_REQUEST;
    allSettlementList: SettlementReport[];
    payload: ISettlements;
    pagination: Pagination;
    downloadFile: DownloadFile;
    updatePayload: SettlementReport;
    settlementForEdit: SettlementReport;
    refundSettlementList: SettlementReport[];
    addRefund: Refunds;
    exports: ExportData;
    errors?:any;
}

interface LoadedGetSettlemetRefundListAction {
    type: typeof adminSettlementConstants.GET_SETTLEMENT_REFUND_LIST_SUCCESS;
    allSettlementList: SettlementReport[];
    payload: ISettlements;
    pagination: Pagination;
    downloadFile: DownloadFile;
    updatePayload: SettlementReport;
    settlementForEdit: SettlementReport;
    refundSettlementList: SettlementReport[];
    addRefund: Refunds;
    exports: ExportData;
    errors?:any;
}

interface FailedGetSettlemetRefundListAction {
    type: typeof adminSettlementConstants.GET_SETTLEMENT_REFUND_LIST_FAILURE;
    allSettlementList: SettlementReport[];
    payload: ISettlements;
    pagination: Pagination;
    downloadFile: DownloadFile;
    updatePayload: SettlementReport;
    settlementForEdit: SettlementReport;
    refundSettlementList: SettlementReport[];
    addRefund: Refunds;
    exports: ExportData;
    errors?:any;
}

interface LoadAddAdminRefundsAction {
    type: typeof adminSettlementConstants.ADD_REFUND_REQUEST;
    allSettlementList: SettlementReport[];
    payload: ISettlements;
    pagination: Pagination;
    downloadFile: DownloadFile;
    updatePayload: SettlementReport;
    settlementForEdit: SettlementReport;
    refundSettlementList: SettlementReport[];
    addRefund: Refunds;
    exports: ExportData;
    errors?:any;
}

interface LoadedAddAdminRefundsAction {
    type: typeof adminSettlementConstants.ADD_REFUND_SUCCESS;
    allSettlementList: SettlementReport[];
    payload: ISettlements;
    pagination: Pagination;
    downloadFile: DownloadFile;
    updatePayload: SettlementReport;
    settlementForEdit: SettlementReport;
    refundSettlementList: SettlementReport[];
    addRefund: Refunds;
    exports: ExportData;
    errors?:any;
}

interface FailedAddAdminRefundsAction {
    type: typeof adminSettlementConstants.ADD_REFUND_FAILURE;
    allSettlementList: SettlementReport[];
    payload: ISettlements;
    pagination: Pagination;
    downloadFile: DownloadFile;
    updatePayload: SettlementReport;
    settlementForEdit: SettlementReport;
    refundSettlementList: SettlementReport[];
    addRefund: Refunds;
    exports: ExportData;
    errors?:any;
}
interface LoadGetRefundExportAction {
    type: typeof adminSettlementConstants.ADD_REFUND_REQUEST;
    allSettlementList: SettlementReport[];
    payload: ISettlements;
    pagination: Pagination;
    downloadFile: DownloadFile;
    updatePayload: SettlementReport;
    settlementForEdit: SettlementReport;
    refundSettlementList: SettlementReport[];
    addRefund: Refunds;
    exports: ExportData;
    errors?:any;
}

interface LoadedGetRefundExportAction {
    type: typeof adminSettlementConstants.ADD_REFUND_SUCCESS;
    allSettlementList: SettlementReport[];
    payload: ISettlements;
    pagination: Pagination;
    downloadFile: DownloadFile;
    updatePayload: SettlementReport;
    settlementForEdit: SettlementReport;
    refundSettlementList: SettlementReport[];
    addRefund: Refunds;
    exports: ExportData;
    errors?:any;
}

interface FailedGetRefundExportAction {
    type: typeof adminSettlementConstants.ADD_REFUND_FAILURE;
    allSettlementList: SettlementReport[];
    payload: ISettlements;
    pagination: Pagination;
    downloadFile: DownloadFile;
    updatePayload: SettlementReport;
    settlementForEdit: SettlementReport;
    refundSettlementList: SettlementReport[];
    addRefund: Refunds;
    exports: ExportData;
    errors?:any;
}

export type AdminSettlemetActionTypes

    = LoadGetAdminSettlemetListAction
    | LoadedGetAdminSettlemetListAction
    | FailedGetAdminSettlemetListAction

    | LoadGetAdminSettlemetListDownloadAction
    | LoadedGetAdminSettlemetListDownloadAction
    | FailedGetAdminSettlemetListDownloadAction

    | LoadAddAdminSettlemetAction
    | LoadedAddAdminSettlemetAction
    | FailedAddAdminSettlemetAction

    | LoadGetAdminSettlemetUpdateAction
    | LoadedGetAdminSettlemetUpdateAction
    | FailedGetAdminSettlemetUpdateAction

    | LoadGetAdminSettlemetEditAction
    | LoadedGetAdminSettlemetEditAction
    | FailedGetAdminSettlemetEditAction

    | LoadGetSettlemetRefundListAction
    | LoadedGetSettlemetRefundListAction
    | FailedGetSettlemetRefundListAction

    | LoadAddAdminRefundsAction
    | LoadedAddAdminRefundsAction
    | FailedAddAdminRefundsAction
    
    | LoadGetRefundExportAction
    | LoadedGetRefundExportAction
    | FailedGetRefundExportAction;
