import React from 'react';
import intl from 'react-intl-universal';
import {
  StyledSectionHeading, StyledDividerLine,
} from '../../../components';

const Toolbar: React.FC = () => (
  <div>
    <StyledSectionHeading>{intl.get('2FA')}</StyledSectionHeading>
    <StyledDividerLine />
  </div>
);
export default Toolbar;
