import { API } from '../../config';
import { TransactionFilter } from '../../types/admin-transaction.types';
import { Pagination } from '../../types/common.types';

const getTransactionList = (paginationData: Pagination, filters?: TransactionFilter) => {
  let queryParams = '';
  if (filters?.selectedDates?.from && filters?.selectedDates?.to) {
    queryParams += `&from=${filters?.selectedDates.from}&to=${filters?.selectedDates.to}`;
  }
  if (filters?.currency) {
    queryParams += `&currency=${filters.currency}`;
  }
  if (filters?.order_no) {
    queryParams += `&order_no=${filters.order_no}`;
  }
  if (filters?.succesfull) {
    queryParams += `&succesfull=${filters.succesfull}`;
  }
  return API.get(`/merchant/transactions?limit=${paginationData.limit}&page=${paginationData.current_page}${queryParams}`);
};

const getTransactionExport = (filters?: TransactionFilter) => {
  let queryParams = '';
  if (filters?.selectedDates?.from && filters?.selectedDates?.to) {
    queryParams += queryParams ? '&' : '?';
    queryParams += `from=${filters?.selectedDates.from}&to=${filters?.selectedDates.to}`;
    if (filters?.currency) {
      queryParams += queryParams ? '&' : '?';
      queryParams += `currency=${filters.currency}`;
    }
  }
  return API.get(`/merchant/exports/transactions${queryParams}`);
};

const getTransactionById = (uuid: string) => API.get(`/merchant/transactions/${uuid}`);

export const transactionService = {
  getTransactionList,
  getTransactionById,
  getTransactionExport,
};
