import { Pagination } from '../../types/common.types';
import { IPaymentProvider, PaymentProviderCode } from '../../types/payment-provider.types';

interface Types {

  GET_PAYMENT_PROVIDER_LIST_REQUEST: string;
  GET_PAYMENT_PROVIDER_LIST_SUCCESS: string;
  GET_PAYMENT_PROVIDER_LIST_FAILURE: string;

  GET_PAYMENT_PROVIDER_BY_ID_REQUEST: string;
  GET_PAYMENT_PROVIDER_BY_ID_SUCCESS: string;
  GET_PAYMENT_PROVIDER_BY_ID_FAILURE: string;

  ADD_PAYMENT_PROVIDER_REQUEST: string;
  ADD_PAYMENT_PROVIDER_SUCCESS: string;
  ADD_PAYMENT_PROVIDER_FAILURE: string;

  UPDATE_PAYMENT_PROVIDER_REQUEST: string;
  UPDATE_PAYMENT_PROVIDER_SUCCESS: string;
  UPDATE_PAYMENT_PROVIDER_FAILURE: string;

  DELETE_PAYMENT_PROVIDER_REQUEST: string;
  DELETE_PAYMENT_PROVIDER_SUCCESS: string;
  DELETE_PAYMENT_PROVIDER_FAILURE: string;

  PAYMENT_PROVIDER_CODE_REQUEST: string;
  PAYMENT_PROVIDER_CODE_SUCCESS: string;
  PAYMENT_PROVIDER_CODE_FAILURE: string;

  PAYMENT_PROVIDER_OPTIONS_REQUEST: string;
  PAYMENT_PROVIDER_OPTIONS_SUCCESS: string;
  PAYMENT_PROVIDER_OPTIONS_FAILURE: string;

}

export const paymentProviderConstants: Types = {

  GET_PAYMENT_PROVIDER_LIST_REQUEST: 'GET_PAYMENT_PROVIDER_LIST_REQUEST',
  GET_PAYMENT_PROVIDER_LIST_SUCCESS: 'GET_PAYMENT_PROVIDER_LIST_SUCCESS',
  GET_PAYMENT_PROVIDER_LIST_FAILURE: 'GET_PAYMENT_PROVIDER_LIST_FAILURE',

  GET_PAYMENT_PROVIDER_BY_ID_REQUEST: 'GET_PAYMENT_PROVIDER_BY_ID_REQUEST',
  GET_PAYMENT_PROVIDER_BY_ID_SUCCESS: 'GET_PAYMENT_PROVIDER_BY_ID_SUCCESS',
  GET_PAYMENT_PROVIDER_BY_ID_FAILURE: 'GET_PAYMENT_PROVIDER_BY_ID_FAILURE',

  ADD_PAYMENT_PROVIDER_REQUEST: 'ADD_PAYMENT_PROVIDER_REQUEST',
  ADD_PAYMENT_PROVIDER_SUCCESS: 'ADD_PAYMENT_PROVIDER_SUCCESS',
  ADD_PAYMENT_PROVIDER_FAILURE: 'ADD_PAYMENT_PROVIDER_FAILURE',

  UPDATE_PAYMENT_PROVIDER_REQUEST: 'UPDATE_PAYMENT_PROVIDER_REQUEST',
  UPDATE_PAYMENT_PROVIDER_SUCCESS: 'UPDATE_PAYMENT_PROVIDER_SUCCESS',
  UPDATE_PAYMENT_PROVIDER_FAILURE: 'UPDATE_PAYMENT_PROVIDER_FAILURE',

  DELETE_PAYMENT_PROVIDER_REQUEST: 'DELETE_PAYMENT_PROVIDER_REQUEST',
  DELETE_PAYMENT_PROVIDER_SUCCESS: 'DELETE_PAYMENT_PROVIDER_SUCCESS',
  DELETE_PAYMENT_PROVIDER_FAILURE: 'DELETE_PAYMENT_PROVIDER_FAILURE',

  PAYMENT_PROVIDER_CODE_REQUEST: ' PAYMENT_PROVIDER_CODE_REQUEST',
  PAYMENT_PROVIDER_CODE_SUCCESS: 'PAYMENT_PROVIDER_CODE_SUCCESS',
  PAYMENT_PROVIDER_CODE_FAILURE: 'PAYMENT_PROVIDER_CODE_FAILURE',

  PAYMENT_PROVIDER_OPTIONS_REQUEST: 'PAYMENT_PROVIDER_OPTIONS_REQUEST',
  PAYMENT_PROVIDER_OPTIONS_SUCCESS: 'PAYMENT_PROVIDER_OPTIONS_SUCCESS',
  PAYMENT_PROVIDER_OPTIONS_FAILURE: 'PAYMENT_PROVIDER_OPTIONS_FAILURE',

};

interface LoadGetPaymentProviderListAction {
  type: typeof paymentProviderConstants.GET_PAYMENT_PROVIDER_LIST_REQUEST
  allPaymentProviderList: IPaymentProvider[]
  payload: IPaymentProvider;
  pspCodeOptions: PaymentProviderCode[];
  id: number;
  pspConfigOptions: any;
  pagination: Pagination;
  errors?: any;
}

interface LoadedGetPaymentProviderListAction {
  type: typeof paymentProviderConstants.GET_PAYMENT_PROVIDER_LIST_SUCCESS
  allPaymentProviderList: IPaymentProvider[]
  payload: IPaymentProvider;
  pspCodeOptions: PaymentProviderCode[]
  id: number;
  pspConfigOptions: any;
  pagination: Pagination;
  errors?: any;
}

interface FailedGetPaymentProviderListAction {
  type: typeof paymentProviderConstants.GET_PAYMENT_PROVIDER_LIST_FAILURE
  allPaymentProviderList: IPaymentProvider[]
  payload: IPaymentProvider;
  pspCodeOptions: PaymentProviderCode[]
  id: number;
  pspConfigOptions: any;
  pagination: Pagination;
  errors?: any;
}

interface LoadGetPaymentProviderByIdAction {
  type: typeof paymentProviderConstants.GET_PAYMENT_PROVIDER_BY_ID_REQUEST
  allPaymentProviderList: IPaymentProvider[]
  payload: IPaymentProvider;
  pspCodeOptions: PaymentProviderCode[]
  id: number;
  pspConfigOptions: any;
  pagination: Pagination;
  errors?: any;
}

interface LoadedGetPaymentProviderByIdAction {
  type: typeof paymentProviderConstants.GET_PAYMENT_PROVIDER_BY_ID_SUCCESS
  allPaymentProviderList: IPaymentProvider[]
  payload: IPaymentProvider;
  pspCodeOptions: PaymentProviderCode[]
  id: number;
  pspConfigOptions: any;
  pagination: Pagination;
  errors?: any;
}

interface FailedGetPaymentProviderByIdAction {
  type: typeof paymentProviderConstants.GET_PAYMENT_PROVIDER_BY_ID_FAILURE
  allPaymentProviderList: IPaymentProvider[]
  payload: IPaymentProvider;
  pspCodeOptions: PaymentProviderCode[]
  id: number;
  pspConfigOptions: any;
  pagination: Pagination;
  errors?: any;
}

interface LoadAddPaymentProviderAction {
  type: typeof paymentProviderConstants.ADD_PAYMENT_PROVIDER_REQUEST
  allPaymentProviderList: IPaymentProvider[]
  payload: IPaymentProvider;
  pspCodeOptions: PaymentProviderCode[]
  id: number;
  pspConfigOptions: any;
  pagination: Pagination;
  errors?: any;
}

interface LoadedAddPaymentProviderAction {
  type: typeof paymentProviderConstants.ADD_PAYMENT_PROVIDER_SUCCESS
  allPaymentProviderList: IPaymentProvider[]
  payload: IPaymentProvider;
  pspCodeOptions: PaymentProviderCode[]
  id: number;
  pspConfigOptions: any;
  pagination: Pagination;
  errors?: any;
}

interface FailedAddPaymentProviderAction {
  type: typeof paymentProviderConstants.ADD_PAYMENT_PROVIDER_FAILURE
  allPaymentProviderList: IPaymentProvider[]
  payload: IPaymentProvider;
  pspCodeOptions: PaymentProviderCode[]
  id: number;
  pspConfigOptions: any;
  pagination: Pagination;
  errors?: any;
}

interface LoadUpdatePaymentProviderAction {
  type: typeof paymentProviderConstants.UPDATE_PAYMENT_PROVIDER_REQUEST
  allPaymentProviderList: IPaymentProvider[]
  payload: IPaymentProvider;
  pspCodeOptions: PaymentProviderCode[]
  id: number;
  pspConfigOptions: any;
  pagination: Pagination;
  errors?: any;
}

interface LoadedUpdatePaymentProviderAction {
  type: typeof paymentProviderConstants.UPDATE_PAYMENT_PROVIDER_SUCCESS
  allPaymentProviderList: IPaymentProvider[]
  payload: IPaymentProvider;
  pspCodeOptions: PaymentProviderCode[]
  id: number;
  pspConfigOptions: any;
  pagination: Pagination;
  errors?: any;
}

interface FailedUpdatePaymentProviderAction {
  type: typeof paymentProviderConstants.UPDATE_PAYMENT_PROVIDER_FAILURE
  allPaymentProviderList: IPaymentProvider[]
  payload: IPaymentProvider;
  pspCodeOptions: PaymentProviderCode[]
  id: number;
  pspConfigOptions: any;
  pagination: Pagination;
  errors?: any;
}

interface LoadDeletePaymentProviderAction {
  type: typeof paymentProviderConstants.DELETE_PAYMENT_PROVIDER_REQUEST
  allPaymentProviderList: IPaymentProvider[]
  payload: IPaymentProvider;
  pspCodeOptions: PaymentProviderCode[]
  id: number;
  pspConfigOptions: any;
  pagination: Pagination;
  errors?: any;
}

interface LoadedDeletePaymentProviderAction {
  type: typeof paymentProviderConstants.DELETE_PAYMENT_PROVIDER_SUCCESS
  allPaymentProviderList: IPaymentProvider[]
  payload: IPaymentProvider;
  pspCodeOptions: PaymentProviderCode[]
  id: number;
  pspConfigOptions: any;
  pagination: Pagination;
  errors?: any;
}

interface FailedDeletePaymentProviderAction {
  type: typeof paymentProviderConstants.DELETE_PAYMENT_PROVIDER_FAILURE
  allPaymentProviderList: IPaymentProvider[]
  payload: IPaymentProvider;
  pspCodeOptions: PaymentProviderCode[]
  id: number;
  pspConfigOptions: any;
  pagination: Pagination;
  errors?: any;
}

interface LoadGetPaymentProviderCodeAction {
  type: typeof paymentProviderConstants.PAYMENT_PROVIDER_CODE_REQUEST
  allPaymentProviderList: IPaymentProvider[];
  payload: IPaymentProvider;
  pspCodeOptions: PaymentProviderCode[];
  id: number;
  pspConfigOptions: any;
  pagination: Pagination;
  errors?: any;
}

interface LoadedGetPaymentProviderCodeAction {
  type: typeof paymentProviderConstants.PAYMENT_PROVIDER_CODE_SUCCESS
  allPaymentProviderList: IPaymentProvider[];
  payload: IPaymentProvider;
  pspCodeOptions: PaymentProviderCode[];
  id: number;
  pspConfigOptions: any;
  pagination: Pagination;
  errors?: any;
}

interface FailedGetPaymentProviderCodeAction {
  type: typeof paymentProviderConstants.PAYMENT_PROVIDER_CODE_FAILURE
  allPaymentProviderList: IPaymentProvider[];
  payload: IPaymentProvider;
  pspCodeOptions: PaymentProviderCode[];
  id: number;
  pspConfigOptions: any;
  pagination: Pagination;
  errors?: any;
}

interface LoadGetPaymentProviderOptionsAction {
  type: typeof paymentProviderConstants.PAYMENT_PROVIDER_OPTIONS_REQUEST
  allPaymentProviderList: IPaymentProvider[];
  payload: IPaymentProvider;
  pspCodeOptions: PaymentProviderCode[];
  id: number;
  pspConfigOptions: any;
  pagination: Pagination;
  errors?: any;
}

interface LoadedGetPaymentProviderOptionsAction {
  type: typeof paymentProviderConstants.PAYMENT_PROVIDER_OPTIONS_SUCCESS
  allPaymentProviderList: IPaymentProvider[];
  payload: IPaymentProvider;
  pspCodeOptions: PaymentProviderCode[];
  id: number;
  pspConfigOptions: any;
  pagination: Pagination;
  errors?: any;
}

interface FailedGetPaymentProviderOptionsAction {
  type: typeof paymentProviderConstants.PAYMENT_PROVIDER_OPTIONS_FAILURE
  allPaymentProviderList: IPaymentProvider[];
  payload: IPaymentProvider;
  pspCodeOptions: PaymentProviderCode[];
  id: number;
  pspConfigOptions: any;
  pagination: Pagination;
  errors?: any;
}

export type PaymentProviderActionTypes
  = LoadGetPaymentProviderListAction
  | LoadedGetPaymentProviderListAction
  | FailedGetPaymentProviderListAction

  | LoadGetPaymentProviderByIdAction
  | LoadedGetPaymentProviderByIdAction
  | FailedGetPaymentProviderByIdAction

  | LoadAddPaymentProviderAction
  | LoadedAddPaymentProviderAction
  | FailedAddPaymentProviderAction

  | LoadUpdatePaymentProviderAction
  | LoadedUpdatePaymentProviderAction
  | FailedUpdatePaymentProviderAction

  | LoadDeletePaymentProviderAction
  | LoadedDeletePaymentProviderAction
  | FailedDeletePaymentProviderAction

  | LoadGetPaymentProviderCodeAction
  | LoadedGetPaymentProviderCodeAction
  | FailedGetPaymentProviderCodeAction

  | LoadGetPaymentProviderOptionsAction
  | LoadedGetPaymentProviderOptionsAction
  | FailedGetPaymentProviderOptionsAction;
