import React, { useEffect } from 'react';
import intl from 'react-intl-universal';
import 'date-fns';
import {
  Box,
  IconButton,
  Button,
  ThemeProvider,
  Card,
  CardContent,
} from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import FilterListIcon from '@material-ui/icons/FilterList';
import CloseIcon from '@material-ui/icons/Close';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  DatePicker,
} from '@material-ui/pickers';
import TextField from '@material-ui/core/TextField';
import { useDispatch, useSelector } from 'react-redux';
import { customDatePicker } from '../../../assets/styles/date-picker';
import ExportIcon from '../../../assets/icons/Export.svg';
import { Dates, Option, Pagination } from '../../../types/common.types';
import { TransactionFilter } from '../../../types/admin-transaction.types';
import { adminTransactionActions } from '../../../store/actions';
import { RootState } from '../../../store/reducers';
import { MerchantDropDown } from '../../../components/MerchantDropDown';
import { ProviderDropDown } from '../../../components/ProviderDropDown';
import { SearchInput } from '../../../components';
import { CurrencyDropDown } from '../../../components/Currency';

const useStyles = makeStyles(() => ({
  iconStyle: {
    fill: '#3485f7',
  },
  dateLabel: {
    fontWeight: 'bold',
    margin: '10px',
    marginTop: '15px',
  },
  formControl: {
    margin: '5px 10px',
    minWidth: '160px',
  },
  clearFilters: {
    color: '#3485f7',
    fontSize: '12px',
    cursor: 'pointer',
    marginLeft: '20px',
  },
  filterContainer: {
    position: 'relative',
  },
  MuiInputBaseinput: {
    width: '91px',
  },
  export: {
    padding: '1px 9px',
    marginTop: '9px',
    marginLeft: '20px',
  },
}));

export interface Props {
  merchantPanel: boolean;
  pagination: Pagination;
}

const initialFilters: TransactionFilter = {
  merchantUUID: '', currency: '', selectedDates: { from: '', to: '' }, payment_method: '', provider: '', transaction_no: '', order_no: '', succesfull: '', search: '',
};

export default ({ merchantPanel, pagination }: Props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [selectedDates, setSelectedDates] = React.useState<Dates>({ from: '', to: '' });
  const [selectedFilters, setSelectedFilters] = React.useState<TransactionFilter>(initialFilters);
  const [clearFilter, setClearFilter] = React.useState(false);
  const now = new Date(Date.now());
  const [selectedFromDate, setSelectedFromDate] = React.useState<Date | null>(
    new Date(now.getFullYear(), now.getMonth(), 1),
  );
  const [selectedToDate, setSelectedToDate] = React.useState<Date | null>(
    new Date(now.getFullYear(), now.getMonth(), (new Date(now.getFullYear(), now.getMonth(), 0)).getDate()),
  );
  const [showFilters, setShowFilters] = React.useState(false);

  const dateFormat = (date: Date | null) => {
    const year = date?.getFullYear();
    const month = `${(date && (date?.getMonth() + 1).toString().length === 1 ? `0${date?.getMonth() + 1}` : (date && date?.getMonth() + 1))}`;
    const day = `${date?.getDate().toString().length === 1 ? `0${date?.getDate()}` : date?.getDate()}`;
    return `${year}-${month}-${day}`;
  };

  const handleFromDateChange = (date: Date | null) => {
    setSelectedFromDate(date);
    const dates = { to: selectedDates.to, from: dateFormat(date) };
    setSelectedDates(dates);
    setSelectedFilters({ ...selectedFilters, selectedDates: dates });
  };

  const handleToDateChange = (date: Date | null) => {
    setSelectedToDate(date);
    const dates = { to: dateFormat(date), from: selectedDates.from };
    setSelectedDates(dates);
    setSelectedFilters({ ...selectedFilters, selectedDates: dates });
  };

  const changeSucces = (event: React.ChangeEvent<{ value: unknown }>) => {
    const selectedValue = event.target.value as string;
    setSelectedFilters({ ...selectedFilters, succesfull: selectedValue });
  };

  const changePaymentMethod = (event: React.ChangeEvent<{ value: unknown }>) => {
    const selectedValue = event.target.value as string;
    setSelectedFilters({ ...selectedFilters, payment_method: selectedValue });
  };

  useEffect(() => {
    dispatch(adminTransactionActions.getProviderOptions(merchantPanel));
    dispatch(adminTransactionActions.getPaymentMethodOptions(merchantPanel));
  }, [dispatch, merchantPanel]);

  useEffect(() => {
    setClearFilter(false);
    dispatch(adminTransactionActions.getAdminTransactionList(merchantPanel, pagination, selectedFilters));
  }, [dispatch, merchantPanel, selectedFilters, pagination]);

  const transactionReducer = useSelector((state: RootState) => state.adminTransactionReducer);

  const paymentMethodOptions: Option[] = transactionReducer.paymentMethodOptions || [];

  const clearFilters = () => {
    setSelectedFilters(initialFilters);
    setSelectedDates({ from: '', to: '' });
    setSelectedFromDate(new Date());
    setSelectedToDate(new Date());
    setClearFilter(true);
  };

  useEffect(() => {
    if (transactionReducer?.exports?.filename) {
      window.open(transactionReducer?.exports?.filename, '_blank');
      dispatch(adminTransactionActions.resetTransactionsExportState());
    }
  }, [transactionReducer, dispatch]);

  const exportData = () => {
    if (selectedFilters.selectedDates?.from && selectedFilters.selectedDates.to) {
      dispatch(adminTransactionActions.getTransactionExport(merchantPanel, selectedFilters));
    } else {
      const dates = { to: dateFormat(selectedToDate), from: dateFormat(selectedFromDate) };
      dispatch(adminTransactionActions.getTransactionExport(merchantPanel, { ...selectedFilters, selectedDates: dates }));
    }
  };

  return (
    <div>
      <Box>
        <Card>
          <CardContent>
            {
              showFilters === false ? (
                <Grid container wrap="wrap" justify="space-between" style={{ justifyContent: 'flex-end' }}>
                  <Box>
                    <IconButton onClick={() => setShowFilters(!showFilters)}>
                      <FilterListIcon className={classes.iconStyle} />
                    </IconButton>
                    <span>
                      {intl.get('FILTER')}
                    </span>
                  </Box>
                </Grid>
              )
                : null
            }
            {showFilters ? (
              <Grid className={classes.filterContainer} container spacing={1}>
                <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
                  <Box display="flex" flexDirection="row" flexWrap="wrap" alignItems="flex-end" justifyContent="flex-start">
                    {!merchantPanel
                      ? (
                        <>
                          <div>
                            <MerchantDropDown
                              i18nTag="MERCHANT"
                              minWidth={160}
                              defaultValue={selectedFilters.merchantUUID}
                              onChange={(e: string) => setSelectedFilters({ ...selectedFilters, merchantUUID: e })}
                              clearFilter={clearFilter}
                              margin="5px 10px"
                            />
                          </div>
                          <div>
                            <ProviderDropDown
                              i18nTag="PROVIDER"
                              minWidth={160}
                              defaultValue={selectedFilters.provider}
                              onChange={(e: string) => setSelectedFilters({ ...selectedFilters, provider: e })}
                              clearFilter={clearFilter}
                              margin="5px 10px"
                            />
                          </div>
                          <div>
                            <FormControl className={classes.formControl}>
                              <InputLabel>{intl.get('METHODS')}</InputLabel>
                              <Select
                                style={{ textAlign: 'left' }}
                                value={selectedFilters.payment_method}
                                onChange={changePaymentMethod}
                              >
                                {
                                  paymentMethodOptions && paymentMethodOptions.length && paymentMethodOptions.map((option: Option) => (
                                    <MenuItem value={option.value}>
                                      {option.label}
                                    </MenuItem>
                                  ))
                                }
                              </Select>
                            </FormControl>
                          </div>
                        </>
                      ) : null}
                    <div>
                      <FormControl className={classes.formControl}>
                        <InputLabel>{intl.get('SUCCESS')}</InputLabel>
                        <Select
                          style={{ textAlign: 'left' }}
                          value={selectedFilters.succesfull}
                          onChange={changeSucces}
                        >
                          <MenuItem value="true">Succesfull</MenuItem>
                          <MenuItem value="false">Failed</MenuItem>
                        </Select>
                      </FormControl>
                    </div>

                    <div>
                      <TextField
                        style={{ maxWidth: '160px' }}
                        className={classes.formControl}
                        id="order-input"
                        label={intl.get('ORDER_NUMBER')}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => setSelectedFilters(
                          { ...selectedFilters, order_no: (event.target as HTMLInputElement).value },
                        )}
                        value={selectedFilters.order_no}
                      />
                    </div>
                    {/* <div>
                      <TextField
                        className={classes.formControl}
                        id="currency-input"
                        label={intl.get('CURRENCY')}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => setSelectedFilters(
                          { ...selectedFilters, currency: (event.target as HTMLInputElement).value },
                        )}
                        value={selectedFilters.currency}
                      />
                    </div> */}
                    <div>
                      <CurrencyDropDown
                        i18nTag="CURRENCY"
                        minWidth={160}
                        defaultValue={selectedFilters && selectedFilters.currency}
                        onChange={(e: string) => setSelectedFilters({ ...selectedFilters, currency: e })}
                        margin="5px 10px"
                        clearFilter={clearFilter}
                      />
                    </div>
                    {
                      !merchantPanel ? (
                        <div>
                          <TextField
                            style={{ maxWidth: '160px' }}
                            className={classes.formControl}
                            id="order-input"
                            label={intl.get('TRANSACTION NO')}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => setSelectedFilters(
                              { ...selectedFilters, transaction_no: (event.target as HTMLInputElement).value },
                            )}
                            value={selectedFilters.transaction_no}
                          />
                        </div>
                      ) : null
                    }
                    {/* <Box
                      display="flex"
                      flexDirection="row"
                      justifyContent="flex-start"
                      alignItems="center"
                    >
                      <TextField
                        className={classes.formControl}
                        label={intl.get('FROM')}
                      />
                      <TextField
                        className={classes.formControl}
                        label={intl.get('TO')}
                      />
                    </Box> */}
                    <span
                      style={{ margin: '5px 10px' }}
                      className={classes.clearFilters}
                      onClick={() => clearFilters()}
                      aria-hidden="true"
                    >
                      {intl.get('CLEAR_ALL_FILTERS')}
                    </span>
                  </Box>
                  <IconButton
                    onClick={() => setShowFilters(!showFilters)}
                    style={{
                      position: 'absolute',
                      top: '-5px',
                      right: '-5px',
                    }}
                  >
                    <CloseIcon
                      className={classes.iconStyle}
                    />
                  </IconButton>
                </Box>
              </Grid>
            ) : null}
            <div style={{
              display: 'flex', flexDirection: 'row', flexWrap: 'wrap', alignItems: 'center',
            }}
            >
              <Box>
                <ThemeProvider theme={customDatePicker}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Box
                      display="flex"
                      flexDirection="row"
                      justifyContent="flex-start"
                      alignItems="center"
                    >
                      <span className={classes.dateLabel}>{intl.get('FROM')}</span>
                      <DatePicker
                        disableToolbar
                        inputVariant="outlined"
                        format="MM/dd/yyyy"
                        margin="normal"
                        id="date-from"
                        className={classes.MuiInputBaseinput}
                        value={selectedFromDate}
                        onChange={handleFromDateChange}
                        style={{ marginRight: '10px' }}
                      />
                      <span className={classes.dateLabel}>{intl.get('TO')}</span>
                      <DatePicker
                        disableToolbar
                        inputVariant="outlined"
                        format="MM/dd/yyyy"
                        margin="normal"
                        id="date-to"
                        className={classes.MuiInputBaseinput}
                        value={selectedToDate}
                        onChange={handleToDateChange}
                      />
                    </Box>
                  </MuiPickersUtilsProvider>
                </ThemeProvider>
              </Box>
              <div>
                <Button
                  className={classes.export}
                  onClick={exportData}
                  variant="outlined"
                  size="small"
                  style={{ display: 'flex', alignItems: 'flex-end', borderRadius: '5px' }}
                >
                  {intl.get('EXPORT')}
                  <img src={ExportIcon} alt="" width="25" />
                </Button>
              </div>
              <div style={{ marginTop: '9px', marginLeft: '20px' }}>
                <SearchInput
                  width="150px"
                  value={selectedFilters.search}
                  onChange={(e: string) => setSelectedFilters({ ...selectedFilters, search: e })}
                />
              </div>
            </div>
          </CardContent>
        </Card>
      </Box>
    </div>
  );
};
