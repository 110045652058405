import { API } from '../../config';
import { Dates } from '../../types/common.types';

const getWidgetPayouts = (dates: Dates) => API.get(`/merchant/widgets/payouts?from=${dates.from}&to=${dates.to}`);

const getWidgetPaymentMethods = (dates: Dates) => API.get(`/merchant/widgets/payment-methods?from=${dates.from}&to=${dates.to}`);

const getWidgetDailyTransactions = (dates: Dates) => API.get(`/merchant/widgets/daily-transactions?from=${dates.from}&to=${dates.to}`);

const getWidgetErrors = () => API.get('/merchant/widgets/errors-summary');

const getWidgetTransactions = (dates: Dates) => API.get(`/merchant/widgets/transactions?from=${dates.from}&to=${dates.to}`);

const balances = () => API.get('/merchant/balances');

export const merchantDashboardService = {
  getWidgetPayouts,
  getWidgetPaymentMethods,
  getWidgetDailyTransactions,
  getWidgetErrors,
  getWidgetTransactions,
  balances,
};
