/* eslint-disable no-shadow */
import React, { useEffect } from 'react';
import intl from 'react-intl-universal';
import {
  makeStyles,
  Box,
} from '@material-ui/core';
import {
  useParams,
} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import { PayoutData, WidgetTransactions } from '../../../types/dashboard.types';
import { dashboardActions } from '../../../store/actions';
import { RootState } from '../../../store/reducers';
import { CharData } from '../../../components/ChartData';
import DashboardTableComponent from './DashboardTable';
import { SearchInput } from '../../../components/SearchInput';
import { ErrorSummary } from '../../../components/ErrorSummary';
import { Dates } from '../../../types/common.types';
import { utils } from '../../../helpers';
import MerchantBalancesTable from './MerchantBalancesTable';
import WidgetTransaction from '../../../components/WidgetTransaction';

type RouteParams = {
  merchantId: string;
}

const useStyles = makeStyles(() => ({
  root: {
    marginTop: '10px',
    backgroundColor: '#EDEDED',
  },
  chip: {
    backgroundColor: '#36a66c',
    color: '#fff',
    paddingLeft: '5px',
    paddingRight: '5px',
    fontSize: '12px',
    fontWeight: 'bold',
  },
  tableHead: {
    fontSize: '12px',
    fontWeight: 'bold',
    color: '#0f0e3b',
    borderBottom: '1px solid #707070',
  },
  tableBody: {
    fontSize: '11px',
    color: '#0f0e3b',
    borderBottom: '1px solid #707070',
  },
  tableBodyBold: {
    fontSize: '11px',
    fontWeight: 'bold',
    color: '#0f0e3b',
    borderBottom: '1px solid #707070',
  },
  title: {
    color: '#959595',
    display: 'flex',
  },
  expandIcon: {
    backgroundColor: '#f4f4f4',
    color: '#880a15',
    borderRadius: '5px',
  },
  main: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  bottmSec: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    alignItems: 'center',
    // marginTop: '20px',
  },
  sectionRow: {
    margin: '10px',
    padding: '10px 20px',
    borderRadius: '10px',
    boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
    backgroundColor: 'rgba(0, 0, 0, 0.05)',
  },
  subSecNested: {
    padding: '10px',
    borderRadius: '10px',
    boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
    backgroundColor: '#f4f4f4',
    margin: '5px',
    width: '144px',
  },
  subSecTitle: {
    color: '#000',
    fontSize: '14px',
    fontWeight: 'bold',
    textAlign: 'left',
  },
  subSecLabel: {
    minWidth: '100px',
    margin: '10px 10px 0 10px',
  },
  sectionRowLight: {
    margin: '9px 10px',
    position: 'relative',
    backgroundColor: 'rgba(0, 0, 0, 0.05)',
  },
  currenyLabel: {
    margin: '10px',
    position: 'absolute',
    right: '30px',
    top: '-40px',
    color: '#959595',
  },
  formControl: {
    minWidth: '20%',
  },
}));

interface Props {
  payoutData: PayoutData;
  selectedDates: Dates;
  prevselectDate: Dates;

}

export const LeftSection: React.FC<Props> = ({ payoutData, selectedDates, prevselectDate }: Props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const params = useParams<RouteParams>();
  const merchantPanel = utils.userType() === 'merchant';
  const dashboard = false;
  useEffect(() => {
    let currentDate: Dates;
    let lastmonthDate: Dates;
    const year = new Date().getFullYear();
    const monthIndex = new Date().getMonth();
    const lastmonthIndex = new Date().getMonth() - 1;
    const lastDay = (new Date(year, monthIndex + 1, 0)).getDate();
    const lastDayPrev = (new Date(year, lastmonthIndex + 1, 0)).getDate();
    currentDate = {
      from: (`${year}-${(monthIndex + 1).toString().length === 1 ? `0${monthIndex + 1}` : (monthIndex + 1)}-01`),
      to: (`${year}-${(monthIndex + 1).toString().length === 1 ? `0${monthIndex + 1}` : (monthIndex + 1)}-${lastDay}`),
    };
    lastmonthDate = {
      from: (`${year}-${(lastmonthIndex + 1).toString().length === 1 ? `0${lastmonthIndex + 1}` : (lastmonthIndex + 1)}-01`),
      to: (`${year}-${(lastmonthIndex + 1).toString().length === 1 ? `0${lastmonthIndex + 1}` : (lastmonthIndex + 1)}-${lastDayPrev}`),
    };

    if (selectedDates?.from && prevselectDate?.from) {
      dispatch(dashboardActions.getWidgetTransactionsCurrent(selectedDates, merchantPanel));
      dispatch(dashboardActions.getWidgetTransactionsprevious(prevselectDate, merchantPanel));
    } else {
      dispatch(dashboardActions.getWidgetTransactionsCurrent(currentDate, merchantPanel));
      dispatch(dashboardActions.getWidgetTransactionsprevious(lastmonthDate, merchantPanel));
    }
  }, [dispatch, merchantPanel, selectedDates, prevselectDate]);
  const dashboardReducer = useSelector((state: RootState) => state.dashboardReducer);
  const widgetPrev: WidgetTransactions = dashboardReducer.previousMonth || {};
  const widget: WidgetTransactions = dashboardReducer.currentMonth || {};
  const currentMonthTotalAmount = widget?.amounts?.reduce((sum, amount) => {
    const updatedSum = sum + Number(amount.amount);
    return updatedSum;
  }, 0);
  const previousMonthTotalAmount = widgetPrev?.amounts?.reduce((sum, amount) => {
    const updatedSum = sum + Number(amount.amount);
    return updatedSum;
  }, 0);

  const currentMonthAvg = widget?.averages?.reduce((sum, amount) => {
    const updatedSum = sum + Number(amount.amount);
    return updatedSum;
  }, 0);
  const previousMonthAvg = widgetPrev?.averages?.reduce((sum, amount) => {
    const updatedSum = sum + Number(amount.amount);
    return updatedSum;
  }, 0);

  let count = Number(widget.transactions?.toFixed(2)) - Number(widgetPrev.transactions?.toFixed(2));
  count /= 100;

  let total = Number(currentMonthTotalAmount?.toFixed(2)) - Number(previousMonthTotalAmount?.toFixed(2));
  total /= 100;
  total = Number(total.toFixed(2));

  let average = Number(currentMonthAvg) - Number(previousMonthAvg);
  average /= 100;
  average = Number(average.toFixed(2));

  return (
    <>
      <WidgetTransaction
        count={count}
        total={total}
        average={average}
        currentMonthTotalAmount={currentMonthTotalAmount}
        previousMonthTotalAmount={previousMonthTotalAmount}
        currentMonthAvg={currentMonthAvg}
        previousMonthAvg={previousMonthAvg}
        widgetPrev={widgetPrev.transactions}
        widgetCurrent={widget.transactions}
      />
      <div>
        <div style={{ paddingTop: '20px' }}>
          <SearchInput width="100%" onChange={() => { console.log('hello world'); }} />
        </div>
        <hr style={{ width: '100%', marginTop: '10px' }} />
      </div>
      <div className={classes.bottmSec}>
        <Grid
          container
          spacing={2}
        >
          <Grid
            md={6}
            xs={12}
            style={{ padding: '10px 20px 40px 0px' }}
          >
            <Box
              style={{ borderBottom: '1px solid #959595' }}
              display="flex"
            >
              <h3 className={classes.title}>{intl.get('TRANSACTIONS')}</h3>
            </Box>
            <DashboardTableComponent widget={widget} />
          </Grid>
          <Grid
            md={6}
            xs={12}
            style={{ padding: '10px 20px 40px 0px' }}
          >
            <Box
              style={{ borderBottom: '1px solid #959595' }}
              display="flex"
            >
              <h3 className={classes.title}>{intl.get('MERCHANT_BALANCES')}</h3>
            </Box>
            <MerchantBalancesTable merchantUUID={params.merchantId} dashboard={dashboard} />
          </Grid>
        </Grid>
      </div>

      <div className={classes.bottmSec}>

        <Grid
          container
          spacing={2}
        >
          <Grid
            item
            md={6}
            xs={12}
          >
            <div>
              <Box
                style={{ borderBottom: '1px solid #959595' }}
                display="flex"
              >
                <h3 className={classes.title} style={{ width: '100%', display: 'flex' }}>{intl.get('ERROR_SUMMARY')}</h3>
              </Box>
              <div style={{ height: '100%' }}>
                <ErrorSummary />
              </div>
            </div>
          </Grid>
          <Grid
            item
            md={6}
            xs={12}
          >
            <div>
              <div>
                <div style={{
                  display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderBottom: '1px solid #959595',
                }}
                >
                  <div>
                    <h3 className={classes.title}>
                      Rate by Month
                    </h3>
                  </div>
                  {/* <div>
                    <h3 className={classes.title}>SUCCESSFUL/FAILED</h3>
                  </div> */}
                </div>
                <div className={[classes.sectionRow, classes.sectionRowLight].join(' ')}>
                  <Grid
                    container
                    spacing={2}
                  >
                    <Grid
                      item
                      md={10}
                      xs={12}
                    >
                      <div>
                        <CharData color="#f7961d" />
                      </div>
                    </Grid>
                    <Grid
                      item
                      md={2}
                      xs={12}
                    >
                      <div style={{ paddingRight: '20px' }}>
                        <h4
                          style={{
                            border: '1px solid #f7961d', borderRadius: '3px', color: '#f7961d', opacity: 0.6,
                          }}
                        >
                          2.8%
                        </h4>
                        <h4 style={{
                          color: '#f7961d', border: '1px solid #f7961d', borderRadius: '3px',
                        }}
                        >
                          3.2%
                        </h4>
                        <span className={classes.chip}>+ 5 %</span>
                      </div>
                    </Grid>
                  </Grid>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>

      </div>
    </>
  );
};
