import React from 'react';
import intl from 'react-intl-universal';
import { useSelector, useDispatch } from 'react-redux';
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  IconButton,
  ThemeProvider,
} from '@material-ui/core';
import FilterListIcon from '@material-ui/icons/FilterList';
import CloseIcon from '@material-ui/icons/Close';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import { makeStyles, Theme } from '@material-ui/core/styles';
import DateFnsUtils from '@date-io/date-fns';
import { SearchInput, UserForm } from '../../../components';
import { RootState } from '../../../store/reducers';
import { customDatePicker } from '../../../assets/styles/date-picker';
// import ExportIcon from '../../../assets/icons/Export.svg';
import { UserFilter } from './types';
import { Dates, Pagination } from '../../../types/common.types';
import { userActions } from '../../../store/actions';

const useStyles = makeStyles((theme: Theme) => ({
  iconStyle: {
    fill: '#3485f7',
  },
  dateLabel: {
    fontWeight: 'bold',
    margin: '10px',
    marginTop: '15px',
  },
  formControl: {
    marginRight: theme.spacing(2),
    minWidth: 130,
  },
  MuiInputBaseinput: {
    width: '91px',
  },
  clearFilters: {
    color: '#3485f7',
    fontSize: '12px',
    cursor: 'pointer',
  },
  filterContainer: {
    marginBottom: '10px',
    position: 'relative',
  },
}));

export interface Props {
  pagination: Pagination;
}

const initialFilters: UserFilter = {
  role: '', enabled: '', selectedDates: { from: '', to: '' }, search: '',
};

export default ({ pagination }: Props) => {
  const dispatch = useDispatch();
  const [displayUserForm, setdisplayUserForm] = React.useState(false);
  const [selectedFilters, setSelectedFilters] = React.useState<UserFilter>(initialFilters);
  const [selectedDates, setSelectedDates] = React.useState<Dates>({ from: '', to: '' });
  const [showFilters, setShowFilters] = React.useState(false);
  const now = new Date(Date.now());
  const [selectedFromDate, setSelectedFromDate] = React.useState<Date | null>(
    new Date(now.getFullYear(), now.getMonth(), 1),
  );
  const [selectedToDate, setSelectedToDate] = React.useState<Date | null>(
    new Date(now.getFullYear(), now.getMonth(), (new Date(now.getFullYear(), now.getMonth(), 0)).getDate()),
  );

  const openUserForm = () => {
    setdisplayUserForm(true);
  };

  const closeUserForm = () => {
    setdisplayUserForm(false);
  };

  const userState = useSelector((state: RootState) => state.userReducer);
  const classes = useStyles();
  // const [value, setValue] = React.useState<Date | null>(
  //   new Date(),
  // );

  const dateFormat = (date: Date | null) => {
    const year = date?.getFullYear();
    const month = `${(date && (date?.getMonth() + 1).toString().length === 1 ? `0${date?.getMonth() + 1}` : (date && date?.getMonth() + 1))}`;
    const day = `${date?.getDate().toString().length === 1 ? `0${date?.getDate()}` : date?.getDate()}`;
    return `${year}-${month}-${day}`;
  };


  // const handleChange = (newValue: Date | null) => {
  //   setValue(newValue);
  // };

  const handleFromDateChange = (date: Date | null) => {
    setSelectedFromDate(date);
    const dates = { to: selectedDates.to, from: dateFormat(date) };
    setSelectedDates(dates);
    setSelectedFilters({ ...selectedFilters, selectedDates: dates });
  };

  const handleToDateChange = (date: Date | null) => {
    setSelectedToDate(date);
    const dates = { to: dateFormat(date), from: selectedDates.from };
    setSelectedDates(dates);
    setSelectedFilters({ ...selectedFilters, selectedDates: dates });
  };
  /**
   * This will run when user added/edited
   */
  React.useEffect(() => {
    if (userState.isUpdated) {
      setdisplayUserForm(false);
    }
  }, [userState.isUpdated]);

  React.useEffect(() => {
    dispatch(userActions.getUserList(pagination, selectedFilters));
  }, [dispatch, pagination, selectedFilters]);

  const changeRole = (event: React.ChangeEvent<{ value: unknown }>) => {
    const selectedValue = event.target.value as string;
    setSelectedFilters({ ...selectedFilters, role: selectedValue });
  };

  const changeEnabled = (event: React.ChangeEvent<{ value: unknown }>) => {
    const selectedValue = event.target.value as number;
    setSelectedFilters({ ...selectedFilters, enabled: selectedValue });
  };

  const clearFilters = () => {
    setSelectedFilters(initialFilters);
    setSelectedDates({ from: '', to: '' });
    setSelectedFromDate(new Date());
    setSelectedToDate(new Date());
  };

  return (
    <div>
      <Box mb={1}>
        <Card>
          <CardContent>
            {
              showFilters === false ? (
                <Grid container wrap="wrap" justify="space-between" style={{ justifyContent: 'flex-end' }}>
                  <Box>
                    <IconButton onClick={() => setShowFilters(!showFilters)}>
                      <FilterListIcon className={classes.iconStyle} />
                    </IconButton>
                    <span>
                      {intl.get('FILTER')}
                    </span>
                  </Box>
                </Grid>
              )
                : null
            }
            {showFilters ? (
              <Grid className={classes.filterContainer} container spacing={1} wrap="wrap" justify="space-between">
                <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between" style={{ width: '100%' }}>
                  <Box m={1} display="flex" flexDirection="row" flexWrap="wrap" alignItems="flex-end" justifyContent="flex-start">
                    <div>
                      <FormControl className={classes.formControl}>
                        <InputLabel>{intl.get('ROLE')}</InputLabel>
                        <Select
                          value={selectedFilters.role}
                          onChange={changeRole}
                        >
                          <MenuItem value="">all</MenuItem>
                          <MenuItem value="admin">admin</MenuItem>
                          <MenuItem value="merchant">merchant</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                    <div>
                      <FormControl className={classes.formControl}>
                        <InputLabel>{intl.get('ENABLED')}</InputLabel>
                        <Select
                          value={selectedFilters.enabled}
                          onChange={changeEnabled}
                        >
                          <MenuItem value={0}>all</MenuItem>
                          <MenuItem value={1}>Enable</MenuItem>
                          <MenuItem value={0}>Disable</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                    <span
                      className={classes.clearFilters}
                      onClick={() => clearFilters()}
                      aria-hidden="true"
                    >
                      {intl.get('CLEAR_ALL_FILTERS')}
                    </span>
                  </Box>
                  <IconButton
                    onClick={() => setShowFilters(!showFilters)}
                    style={{
                      position: 'absolute',
                      top: '-5px',
                      right: '-5px',
                    }}
                  >
                    <CloseIcon
                      className={classes.iconStyle}
                    />
                  </IconButton>
                </Box>
              </Grid>
            ) : null}
            <Grid container wrap="wrap" justify="space-between" alignItems="center">
              <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                <Grid container justify="flex-start" alignItems="center" wrap="wrap">
                  <ThemeProvider theme={customDatePicker}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <Box
                        display="flex"
                        flexDirection="row"
                        justifyContent="flex-start"
                        alignItems="center"
                      >
                        <span className={classes.dateLabel}>{intl.get('FROM')}</span>
                        <DatePicker
                          disableToolbar
                          inputVariant="outlined"
                          format="MM/dd/yyyy"
                          margin="normal"
                          id="date-from"
                          className={classes.MuiInputBaseinput}
                          value={selectedFromDate}
                          onChange={handleFromDateChange}
                          style={{ marginRight: '10px' }}
                        />
                        <span className={classes.dateLabel}>{intl.get('TO')}</span>
                        <DatePicker
                          disableToolbar
                          inputVariant="outlined"
                          format="MM/dd/yyyy"
                          margin="normal"
                          id="date-to"
                          className={classes.MuiInputBaseinput}
                          value={selectedToDate}
                          onChange={handleToDateChange}
                        />
                      </Box>
                    </MuiPickersUtilsProvider>
                  </ThemeProvider>
                  {/* <div style={{ marginTop: '9px', marginLeft: '20px' }}>
                    <Button
                      variant="outlined"
                      size="small"
                      style={{ display: 'flex', alignItems: 'center', borderRadius: '5px' }}
                    >
                      {intl.get('EXPORT')}
                      <img src={ExportIcon} alt="" width="25" height="20" />
                    </Button>
                  </div> */}
                  <div style={{ marginTop: '9px', marginLeft: '20px' }}>
                    <SearchInput
                      width="150px"
                      value={selectedFilters.search}
                      onChange={(e: string) => setSelectedFilters({ ...selectedFilters, search: e })}
                    />
                  </div>
                </Grid>
              </div>
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                  style={{ color: 'white', marginTop: '10px' }}
                  variant="contained"
                  color="primary"
                  onClick={openUserForm}
                >
                  {intl.get('ADD_USER') || 'Add User'}
                </Button>
              </div>
            </Grid>
          </CardContent>
        </Card>
      </Box>
      <UserForm openDialog={displayUserForm} closeUserForm={closeUserForm} selectedUser={null} />
    </div>
  );
};
