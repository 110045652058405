import React from 'react';
import intl from 'react-intl-universal';
import { useSelector, useDispatch } from 'react-redux';
import {
  TextField,
} from '@material-ui/core';
import {
  useParams,
} from 'react-router-dom';
import { RootState } from '../../store/reducers';
import { merchantBillingActions } from '../../store/actions';
import { AccountOptions } from '../../types/merchant-billing.types';

type RouteParams = {
  merchantId: string;
}

export const BankNameDropdown = ({ onChange, defaultValue, label }: any) => {
  const [selectedValue, setSelectedValue] = React.useState(defaultValue);
  const dispatch = useDispatch();
  const params = useParams<RouteParams>();
  const merchantPanel = !params.merchantId;
  React.useEffect(() => {
    dispatch(merchantBillingActions.getMerchantAccountOptions(params.merchantId, merchantPanel));
  }, [dispatch, params, merchantPanel]);
  const merchantAccountReducer = useSelector((state: RootState) => state.merchantBillingReducer);
  const bankAccountList: AccountOptions[] = merchantAccountReducer.accountOptions || [];

  return (
    <>
      <TextField
        fullWidth
        label={intl.get('BANK_ACCOUNT')}
        name={label}
        size="small"
        required
        select
        onChange={(e) => { onChange(e.target.value); setSelectedValue(e.target.value); }}
        value={selectedValue}
      >
        {bankAccountList?.map((value: AccountOptions) => (
          <option
            key={value.value}
            value={value.value}
          >
            {value.label}
          </option>
        ))}
      </TextField>
    </>
  );
};
