import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import intl from 'react-intl-universal';
import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  makeStyles,
  ThemeProvider,
  TablePagination,
  LinearProgress,
} from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import { userActions } from '../../../store/actions';
import { RootState } from '../../../store/reducers';
import { DisplaySnackbar, UserForm, BooleanLabel, StyledTableHeadCell } from '../../../components';
import { editUserForm, IUser } from './types';
import { ChangePassword } from './ChangePassword';
import { tableTheme } from '../../../assets/styles/table-style';
import { paginationTheme } from '../../../components/StyledComponents/paginateTheme';
import { ConfirmationBox } from '../../../components/ConfirmationBox';

interface IUserTypes {
  users: IUser[]
  changePaginationOptions: (e: any) => void;
}

const useStyles = makeStyles({
  root: {
    marginTop: '10px',
  },
  table: {
    minWidth: 650,
  },
  tableHeadCell: {
    fontSize: '13px',
    fontWeight: 'bold',
    lineHeight: '1.2',
    letterSpacing: '0.9',
    color: '#232931',
  },
  EditIcon: {
    color: 'black',
    cursor: 'pointer',
    '&:hover': {
      color: 'blue',
    },
  },
  LockIcon: {
    color: 'black',
    cursor: 'pointer',
    '&:hover': {
      color: '#3f51b5',
    },
  },
  deleteIcon: {
    color: 'black',
    cursor: 'pointer',
    '&:hover': {
      color: 'red',
    },
  },
  colorPrimary: {
    backgroundColor: '#3485f7',
  },
  barColorPrimary: {
    backgroundColor: '#3485f7',
  },
});

export default ({ users, changePaginationOptions }: IUserTypes) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const userState = useSelector((state: RootState) => state.userReducer);
  const { total } = userState.pagination;
  const [displayUserForm, setdisplayUserForm] = React.useState(false);
  const [changePasswordForm, setChangePasswordForm] = React.useState(false);
  const [confirmation, setConfirmation] = React.useState(false);
  const [selectedUser, setSelectedUser] = React.useState(editUserForm);
  const [pagination, setPagination] = React.useState({
    limit: 10,
    current_page: 1,
  });

  const onDelete = (uuid: any) => {
    dispatch(userActions.deleteUser(uuid));
  };

  const openUserForm = (user: IUser) => {
    setSelectedUser(user);
    setdisplayUserForm(true);
  };

  const closeUserForm = () => {
    setdisplayUserForm(false);
  };

  const openChangePasswordForm = (user: IUser) => {
    setSelectedUser(user);
    setChangePasswordForm(true);
  };

  const ChangePasswordcloseUserForm = () => {
    setChangePasswordForm(false);
  };

  const openConfirmationBox = () => {
    setConfirmation(true);
  };

  const closeConfirmationBox = () => {
    setConfirmation(false);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPagination({
      ...pagination,
      current_page: ++newPage,
    });
    changePaginationOptions({ ...pagination, current_page: newPage });
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPagination({
      ...pagination,
      limit: +event.target.value,
      current_page: 1,
    });
    changePaginationOptions({ ...pagination, limit: +event.target.value });
  };
  /**
   * This will run when user added/edited
   */
  React.useEffect(() => {
    if (userState.isUpdated) {
      setdisplayUserForm(false);
    }
  }, [userState.isUpdated]);

  const { loading } = userState;
  return (
    <Card className={classes.root}>
      <TableContainer component={Paper}>
        <ThemeProvider theme={tableTheme}>
          {loading ? <LinearProgress classes={{ colorPrimary: classes.colorPrimary, barColorPrimary: classes.barColorPrimary }} /> : null}
          <Table size="small" className={classes.table} aria-label="user list">
            <TableHead>
              <TableRow>
                <TableCell align="center"><StyledTableHeadCell>{intl.get('ROLE')}</StyledTableHeadCell></TableCell>
                <TableCell align="center"><StyledTableHeadCell>{intl.get('MERCHANT')}</StyledTableHeadCell></TableCell>
                <TableCell align="center"><StyledTableHeadCell>{intl.get('FIRST_NAME')}</StyledTableHeadCell></TableCell>
                <TableCell align="center"><StyledTableHeadCell>{intl.get('LAST_NAME')}</StyledTableHeadCell></TableCell>
                <TableCell align="center" />
                <TableCell><StyledTableHeadCell>{intl.get('EMAIL')}</StyledTableHeadCell></TableCell>
                <TableCell align="center"><StyledTableHeadCell>{intl.get('ENABLED')}</StyledTableHeadCell></TableCell>
                <TableCell align="center" />
                <TableCell align="center"><StyledTableHeadCell>{intl.get('ACTIONS')}</StyledTableHeadCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users && users.map((row: IUser) => (
                <TableRow key={row.uuid}>
                  <TableCell align="center">{row.role}</TableCell>
                  <TableCell align="center">
                    {row.role === 'merchant' ? row.merchant?.company_name : null}
                  </TableCell>
                  <TableCell align="center">{row.first_name || 'N/A'}</TableCell>
                  <TableCell align="center">{row.last_name || 'N/A'}</TableCell>
                  <TableCell align="center" />
                  <TableCell>{row.email}</TableCell>
                  <TableCell align="center"><BooleanLabel label={row?.enabled} /></TableCell>
                  <TableCell align="center">
                    {
                      row?.two_factor_auth_code === true ? <VpnKeyIcon style={{ color: 'green', cursor: 'pointer' }} onClick={() => openChangePasswordForm(row)} />
                        : <VpnKeyIcon style={{ color: 'red', cursor: 'pointer' }} onClick={() => openChangePasswordForm(row)} />
                    }
                  </TableCell>
                  <TableCell align="center">
                    <EditIcon className={classes.EditIcon} onClick={() => openUserForm(row)} />
                    <DeleteIcon className={classes.deleteIcon} onClick={() => openConfirmationBox()} />
                  </TableCell>
                  <ConfirmationBox openDialog={confirmation} closeConfirmationBox={closeConfirmationBox} submitConfirmationBox={() => onDelete(row.uuid)} />
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </ThemeProvider>
      </TableContainer>
      <UserForm openDialog={displayUserForm} closeUserForm={closeUserForm} selectedUser={selectedUser} />
      <ChangePassword openDialog={changePasswordForm} closeUserForm={ChangePasswordcloseUserForm} selectedUser={selectedUser} />

      {
        total && users && users.length
          ? (
            <ThemeProvider theme={paginationTheme}>
              <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={total}
                rowsPerPage={pagination.limit}
                page={pagination.current_page - 1}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </ThemeProvider>
          )
          : null
      }
      {userState && userState.isDeleted
        ? <DisplaySnackbar type="success" message={intl.get('DELETED_SUCCESSFULLY') || 'Deleted successfully'} /> : null}
    </Card>
  );
};
