import { API } from '../config';
import { Pagination } from '../types/common.types';
import { IMerchant, MerchantFilter, MerchantPsp } from '../types/merchant.types';

// company_name=searchterm&company_code&obsolete&margin_type&kyc_approved&on_hold
const getMerchantList = (paginationData?: Pagination, filters?: MerchantFilter) => {
  let queryParams = `${filters?.searchTerm ? `&company_name=${filters.searchTerm}` : ''}`;
  if (filters?.active) {
    queryParams += `&active=${filters.active}`;
  }
  if (filters?.kyc_approved) {
    queryParams += `&kyc_approved=${filters.kyc_approved}`;
  }
  if (filters?.on_hold) {
    queryParams += `&on_hold=${filters.on_hold}`;
  }
  if (filters?.obsolete) {
    queryParams += `&obsolete=${filters.obsolete}`;
  }
  if (filters?.selectedDates?.from && filters?.selectedDates?.to) {
    queryParams += `&from=${filters?.selectedDates.from}&to=${filters?.selectedDates.to}`;
  }
  return API.get(`/admin/merchants?with=accounts&limit=${paginationData?.limit}&page=${paginationData?.current_page}${queryParams}`);
};

const getMerchantById = (uuid: string) => API.get(`/admin/merchants/${uuid}`);

const updateMerchant = (body: any) => API.post(`/admin/merchants/${body.uuid}`, body.form);

const addMerchant = (body: IMerchant) => API.post('/admin/merchants', body);

const deleteMerchant = (uuid: string) => API.delete(`/admin/merchants/${uuid}`);

const addMerchantPsp = (body: MerchantPsp) => API.post('/admin/merchant-providers', body);

const updateMerchantPsp = (body: MerchantPsp, id: number | undefined) => API.post(`/admin/merchant-providers/${id}`, body);

const deleteMerchantPsp = (id: number) => API.delete(`/admin/merchant-providers/${id}`);

const getMerchantPspList = () => API.get('/admin/merchant-providers');

const getMerchantPspById = (id: number) => API.get(`/admin/merchant-providers/${id}`);

const getPspByMerchantUUID = (uuid: string) => API.get(`/admin/merchant-providers?merchant_uuid=${uuid}`);

const merchantOptions = () => API.get('/admin/options/merchants');

export const merchantService = {
  getMerchantList,
  getMerchantById,
  addMerchant,
  updateMerchant,
  deleteMerchant,
  addMerchantPsp,
  updateMerchantPsp,
  deleteMerchantPsp,
  getMerchantPspList,
  getMerchantPspById,
  getPspByMerchantUUID,
  merchantOptions,
};
