import React from 'react';
import intl from 'react-intl-universal';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import {
  Box,
  Card,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  TableContainer,
  Paper,
} from '@material-ui/core';
import { PayoutTransaction } from '../../../types/merchant-billing.types';

interface IMerchantAccountTypes {
  payoutTransactions: PayoutTransaction[] | undefined;
}

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    marginTop: theme.spacing(3),
    borderRadius: '15px',
  },
  tableHeadCell: {
    fontSize: '12px',
    fontWeight: 700,
  },
  tableBodyCell: {
    fontSize: '12px',
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

export const PayoutTransactions = ({ payoutTransactions }: IMerchantAccountTypes) => {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <TableContainer component={Paper}>
        <Box m={2}>
          <h3 style={{ textAlign: 'left', margin: '20px' }}>{intl.get('PAYOUT_TRANSACTIONS')}</h3>
        </Box>
        <Table aria-label="account list">
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableHeadCell}>{intl.get('BANK_ACCOUNT')}</TableCell>
              <TableCell className={classes.tableHeadCell}>{intl.get('CURRENCY')}</TableCell>
              <TableCell className={classes.tableHeadCell}>{intl.get('AMOUNT')}</TableCell>
              <TableCell className={classes.tableHeadCell}>{intl.get('STATUS')}</TableCell>
              <TableCell className={classes.tableHeadCell}>{intl.get('COMPLETE_DATE')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {payoutTransactions && payoutTransactions.map((row: PayoutTransaction) => (
              <TableRow key={row.uuid}>
                <TableCell className={classes.tableBodyCell}>{row?.account?.bank_name}</TableCell>
                <TableCell className={classes.tableBodyCell}>{row.currency}</TableCell>
                <TableCell className={classes.tableBodyCell}>{row.amount}</TableCell>
                <TableCell className={classes.tableBodyCell}>{row.status}</TableCell>
                <TableCell className={classes.tableBodyCell}>
                  {row.completed_at && `${new Date(row.completed_at).toLocaleDateString()} ${new Date(row.completed_at).toLocaleTimeString()}`}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
};
