import React, { useEffect } from 'react';
import intl from 'react-intl-universal';
import Avatar from '@material-ui/core/Avatar';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { useDispatch, useSelector } from 'react-redux';
import { authActions } from '../../store/actions';
import { Copyright, DisplaySnackbar } from '../../components';
import { history } from '../../helpers';
import { RootState } from '../../store/reducers';
import UserIcon from '../../assets/icons/User_Icon.png';
import './style.css';

const useStyles = makeStyles((theme) => ({
  overrides: {
    MuiInput: {
      root: {
        background: 'black',
      },
    },
  },
  notchedOutline: {
    borderWidth: '1px',
    borderColor: '#282454 !important',
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(5),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  welcome: {
    margin: '0',
    fontFamily: 'PlayfairDisplay',
    fontSize: '42px',
    fontWeight: 'bold',
    color: '#1c4268',
  },
  normalLable: {
    color: '#3485f7',
    fontFamily: 'PlayfairDisplay',
    fontSize: '18px',
    fontWeight: 'bold',
  },
  formLabel: {
    color: '#1c4268',
    fontSize: '15px',
    fontWeight: 'bold',
  },
  blueLable: {
    color: '#3485f7',
    fontSize: '15px',
    fontWeight: 'bold',
    marginLeft: '10px',
  },
}));

export default () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [values, setValues] = React.useState({
    token: '',
    newPassword: '',
  });

  const onSubmit = () => {
    dispatch(authActions.setNewPassword(values.token, values.newPassword));
  };

  useEffect(() => {
    if (!values.token) {
      const resetPasswordToken = localStorage.getItem('resetPasswordToken') || null;
      if (resetPasswordToken) {
        setValues({ ...values, token: resetPasswordToken });
        setTimeout(() => {
          localStorage.setItem('visitedReset', 'true');
        }, 1000);
      }
    }
  }, [values]);

  const loginState = useSelector((state: RootState) => state.authentication);
  if (window.location.pathname.includes('reset-password')) {
    document.body.style.backgroundColor = '#131128';
  } else {
    document.body.style.backgroundColor = '#fafafa';
  }
  return (
    <Container component="main" maxWidth="xs" style={{ marginTop: '7%', marginBottom: '5%' }} className="login-container">
      <CssBaseline />
      <h1 className="welcomeHeading">
        {intl.get('PLEASE_LOGIN')}
      </h1>
      {
        loginState?.isError
          ? <DisplaySnackbar type="error" message={intl.get('SOMETHING_WENT_WRONG')} /> : null
      }
      {
        loginState?.newPasswordUpdated
          ? <DisplaySnackbar type="success" message={intl.get('PASSWORD_UPDATED')} /> : null
      }
      {loginState && loginState.isError
        ? loginState.errorMessages && Object.values(loginState.errorMessages).length
        && Object.values(loginState.errorMessages).map(
          (error: any) => error?.length && error.map(
            (msg: string) => <DisplaySnackbar type="error" message={msg} />,
          ),
        ) : null}
      <div className={classes.paper}>
        <Avatar className={classes.avatar} alt="icon" src={UserIcon} />
        <div className="smallHeading">{intl.get('SIGN_IN')}</div>
        <form className={classes.form} noValidate>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="flex-start"
            alignItems="center"
          >
            <div className="inputLabel">{intl.get('NEW_PASSWORD')}</div>
          </Box>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            type="password"
            id="password"
            autoComplete="current-password"
            className="inputStyle"
            InputProps={{
              classes: {
                notchedOutline: classes.notchedOutline,
              },
              style: { color: 'white' },
            }}
            onChange={(e) => setValues({ ...values, newPassword: e.target.value })}
            onKeyDown={(e) => { if (e.key === 'Enter') { onSubmit(); } }}
          />
          <button
            type="button"
            className="loginBtn"
            onClick={() => onSubmit()}
          >
            {intl.get('SET_NEW_PASSWORD')}
          </button>
          <Grid container>
            <Grid item xs>
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                style={{ marginTop: '40px' }}

              >
                <div className="smallHeading">
                  {intl.get('DONT_HAVE_ACCOUNT')}
                </div>
                <Link href="https://app.vanillafx.io/" variant="body2" className={classes.blueLable} style={{ cursor: 'pointer' }}>
                  {intl.get('SIGNUP')}
                </Link>
              </Box>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs>
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                style={{ marginTop: '40px' }}

              >
                <div className="smallHeading">
                  {intl.get('ALREADY_HAVE_ACCOUNT')}
                </div>
                <div
                  onClick={() => { localStorage.removeItem('resetPasswordToken'); history.push('/login'); }}
                  className={classes.blueLable}
                  aria-hidden="true"
                  style={{ cursor: 'pointer' }}
                >
                  {intl.get('LOGIN')}
                </div>
              </Box>
            </Grid>
          </Grid>
        </form>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
};
