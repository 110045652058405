/* eslint-disable no-shadow */
import React, { useEffect } from 'react';
import intl from 'react-intl-universal';
import {
  makeStyles,
  Box,
} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { useDispatch, useSelector } from 'react-redux';
import { DailyTransaction, PayoutData, WidgetTransactions } from '../../../types/dashboard.types';
import { ErrorSummary } from '../../../components/ErrorSummary';
import { RightSection } from '.';
import DashboardTableComponent from './DashboardTable';
import { SearchInput } from '../../../components';
import { Dates } from '../../../types/common.types';
import { RootState } from '../../../store/reducers';
import { dashboardActions } from '../../../store/actions';
import WidgetTransaction from '../../../components/WidgetTransaction';
import MerchantBalances from './MerchantBalances';

const useStyles = makeStyles(() => ({
  root: {
    marginTop: '10px',
    backgroundColor: '#EDEDED',
  },
  chip: {
    color: '#fff',
    paddingLeft: '5px',
    paddingRight: '5px',
    fontSize: '12px',
    fontWeight: 'bold',
  },
  tableHead: {
    fontSize: '12px',
    fontWeight: 'bold',
    color: '#0f0e3b',
    borderBottom: '1px solid #707070',
  },
  tableBody: {
    fontSize: '11px',
    color: '#0f0e3b',
    borderBottom: '1px solid #707070',
  },
  tableBodyBold: {
    fontSize: '11px',
    fontWeight: 'bold',
    color: '#0f0e3b',
    borderBottom: '1px solid #707070',
  },
  title: {
    color: '#959595',
    marginRight: '20px',
  },
  expandIcon: {
    backgroundColor: '#f4f4f4',
    color: '#880a15',
    borderRadius: '5px',
  },
  main: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  bottmSec: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    alignItems: 'center',
    // marginTop: '20px',
  },
  sectionRow: {
    margin: '10px',
    padding: '10px 20px',
    borderRadius: '10px',
    boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
    backgroundColor: 'rgba(0, 0, 0, 0.05)',
  },
  subSecNested: {
    padding: '10px',
    borderRadius: '10px',
    boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
    backgroundColor: '#f4f4f4',
    margin: '5px',
    width: '144px',
  },
  subSecTitle: {
    color: '#000',
    fontSize: '14px',
    fontWeight: 'bold',
    textAlign: 'left',
  },
  subSecLabel: {
    minWidth: '100px',
    margin: '10px 10px 0 10px',
  },
  sectionRowLight: {
    margin: '9px 10px',
    position: 'relative',
    backgroundColor: 'rgba(0, 0, 0, 0.05)',
  },
  currenyLabel: {
    margin: '10px',
    position: 'absolute',
    right: '30px',
    top: '-40px',
    color: '#959595',
  },
  formControl: {
    minWidth: '20%',
  },
}));

interface Props {
  payoutData: PayoutData;
  dailyTransactionData: DailyTransaction[];
  merchantPanel: boolean;
}

export const LeftSection: React.FC<Props> = ({ payoutData, dailyTransactionData, merchantPanel }: Props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  useEffect(() => {
    let currentDate: Dates;
    let lastmonthDate: Dates;
    const year = new Date().getFullYear();
    const monthIndex = new Date().getMonth();
    const lastmonthIndex = new Date().getMonth() - 1;
    const lastDay = (new Date(year, monthIndex + 1, 0)).getDate();
    const lastDayPrev = (new Date(year, lastmonthIndex + 1, 0)).getDate();
    currentDate = {
      from: (`${year}-${(monthIndex + 1).toString().length === 1 ? `0${monthIndex + 1}` : (monthIndex + 1)}-01`),
      to: (`${year}-${(monthIndex + 1).toString().length === 1 ? `0${monthIndex + 1}` : (monthIndex + 1)}-${lastDay}`),
    };
    lastmonthDate = {
      from: (`${year}-${(lastmonthIndex + 1).toString().length === 1 ? `0${lastmonthIndex + 1}` : (lastmonthIndex + 1)}-01`),
      to: (`${year}-${(lastmonthIndex + 1).toString().length === 1 ? `0${lastmonthIndex + 1}` : (lastmonthIndex + 1)}-${lastDayPrev}`),
    };
    dispatch(dashboardActions.getWidgetTransactionsCurrent(currentDate, merchantPanel));
    dispatch(dashboardActions.getWidgetTransactionsprevious(lastmonthDate, merchantPanel));
  }, [dispatch, merchantPanel]);
  const dashboardReducer = useSelector((state: RootState) => state.dashboardReducer);
  const widgetPrev: WidgetTransactions = dashboardReducer.previousMonth || {};
  const widget: WidgetTransactions = dashboardReducer.currentMonth || {};

  const currentMonthTotalAmount = widget?.amounts?.reduce((sum, amount) => {
    const updatedSum = sum + Number(amount.amount);
    return updatedSum;
  }, 0);
  const previousMonthTotalAmount = widgetPrev?.amounts?.reduce((sum, amount) => {
    const updatedSum = sum + Number(amount.amount);
    return updatedSum;
  }, 0);

  const currentMonthAvg = widget?.averages?.reduce((sum, amount) => {
    const updatedSum = sum + Number(amount.amount);
    return updatedSum;
  }, 0);
  const previousMonthAvg = widgetPrev?.averages?.reduce((sum, amount) => {
    const updatedSum = sum + Number(amount.amount);
    return updatedSum;
  }, 0);

  let count = Number(widget.transactions?.toFixed(2)) - Number(widgetPrev.transactions?.toFixed(2));
  count /= 100;

  let total = Number(currentMonthTotalAmount?.toFixed(2)) - Number(previousMonthTotalAmount?.toFixed(2));
  total /= 100;
  total = Number(total.toFixed(2));

  let average = Number(currentMonthAvg) - Number(previousMonthAvg);
  average /= 100;
  average = Number(average.toFixed(2));
  return (
    <>
      <WidgetTransaction
        count={count}
        total={total}
        average={average}
        currentMonthTotalAmount={currentMonthTotalAmount}
        previousMonthTotalAmount={previousMonthTotalAmount}
        currentMonthAvg={currentMonthAvg}
        previousMonthAvg={previousMonthAvg}
        widgetPrev={widgetPrev.transactions}
        widgetCurrent={widget.transactions}
      />
      <Box
        style={{ marginTop: '20px' }}
      >
        <SearchInput width="100%" />
      </Box>
      <div className={classes.bottmSec}>
        <Grid
          container
          spacing={2}
        >
          <Grid
            md={8}
            xs={12}
            style={{ padding: '10px 20px 40px 0px' }}
          >
            <DashboardTableComponent merchantPanel={merchantPanel} />
          </Grid>
          <Grid
            md={4}
            xs={12}
            style={{ padding: '10px 20px 40px 0px' }}
          >
            <MerchantBalances />
          </Grid>
        </Grid>
      </div>
      <div className={classes.bottmSec}>

        <Grid
          container
          spacing={2}
        >
          <Grid
            item
            md={6}
            xs={12}
          >
            <div style={{ height: '100%' }}>
              <RightSection dailyTransactionData={dailyTransactionData} />
            </div>
          </Grid>
          <Grid
            item
            md={6}
            xs={12}
          >
            <div>
              <Box
                style={{ borderBottom: '1px solid #959595' }}
                display="flex"
              >
                <h3 className={classes.title} style={{ width: '100%', display: 'flex' }}>{intl.get('ERROR_SUMMARY')}</h3>
              </Box>
              <div style={{ height: '100%' }}>
                <ErrorSummary />
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </>
  );
};
