import { API } from '../config';
import { Pagination } from '../types/common.types';
import { IMerchantAccountIds, IMerchantAccountUpdate, IMerchantAccountAdd } from '../types/merchant-account.types';

const getMerchantAccountList = (merchantUUID: string, paginationData: Pagination) => API.get(`/admin/merchants/${merchantUUID}/accounts?limit=${paginationData.limit}&page=${paginationData.current_page}`);

const getMerchantAccountById = (payload: IMerchantAccountIds) => API.get(
  `/admin/merchants/${payload.merchantUUID}/accounts/${payload.merchantAccountUUID}`,
);
const updateMerchantAccount = (body: IMerchantAccountUpdate) => API.post(
  `/admin/merchants/${body.Ids.merchantUUID}/accounts/${body.Ids.merchantAccountUUID}`, body.form,
);

const addMerchantAccount = (body: IMerchantAccountAdd) => API.post(`/admin/merchants/${body.merchantUUID}/accounts`, body.form);

const deleteMerchantAccount = (payload: IMerchantAccountIds) => API.delete(
  `/admin/merchants/${payload.merchantUUID}/accounts/${payload.merchantAccountUUID}`,
);

export const merchantAccountService = {
  getMerchantAccountList,
  getMerchantAccountById,
  addMerchantAccount,
  updateMerchantAccount,
  deleteMerchantAccount,
};
