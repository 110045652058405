import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/reducers';
import { SettlementReport } from '../../../types/settlement.types';
import Toolbar from './Toolbar';
import RefundList from './RefundList';
import PageHeader from '../../../components/StyledComponents/PageHeader';

interface Types {
  merchantPanel: boolean;
}

const Refund = ({ merchantPanel }: Types) => {
  const [paginationPar, setPaginationPar] = React.useState({ limit: 10, current_page: 1 });
  const settlementReducer = useSelector((state: RootState) => state.adminSettlementReducer);
  const refundSettlementList: SettlementReport[] = settlementReducer.refundSettlementList || [];

  return (
    <div>
      <PageHeader
        label="REFUNDS"
        componentToPassDown={<Toolbar merchantPanel={merchantPanel} pagination={paginationPar} />}
      />
      <RefundList merchantPanel={merchantPanel} settlement={refundSettlementList} changePaginationOptions={(e) => setPaginationPar(e)} />
    </div>
  );
};

export default Refund;
