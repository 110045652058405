import { PaymentProviderActionTypes, paymentProviderConstants } from '../constants';
import { IPaymentProvider, IPaymentProviderState, PaymentProviderCode } from '../../types/payment-provider.types';
import { Pagination } from '../../types/common.types';

export interface IState {
  loading: boolean;
  isError: boolean;
  allPaymentProviderList: IPaymentProvider[];
  paymentProviderDetails: IPaymentProvider;
  errorMessages: string[];
  updateError: boolean;
  isUpdated: boolean;
  isDeleted: boolean;
  pspCodeOptions: PaymentProviderCode[];
  pspConfigOptions: any;
  pagination: Pagination;
}

const initialState: IState = {
  loading: false,
  isError: false,
  allPaymentProviderList: [],
  updateError: false,
  isUpdated: false,
  isDeleted: false,
  paymentProviderDetails: IPaymentProviderState,
  errorMessages: [],
  pspCodeOptions: [],
  pspConfigOptions: {},
  pagination: { limit: 10, current_page: 1 },
};

export const paymentProviderReducer = (state = initialState, action: PaymentProviderActionTypes): IState => {
  switch (action.type) {
    case paymentProviderConstants.GET_PAYMENT_PROVIDER_LIST_REQUEST:
      return {
        ...state, loading: true, isError: false, isUpdated: false, isDeleted: false,
      };
    case paymentProviderConstants.GET_PAYMENT_PROVIDER_LIST_SUCCESS:
      return {
        ...state, loading: false, isError: false, allPaymentProviderList: action.allPaymentProviderList, pagination: action.pagination,
      };
    case paymentProviderConstants.GET_PAYMENT_PROVIDER_LIST_FAILURE:
      return {
        ...state, loading: false, isError: true,
      };

    case paymentProviderConstants.GET_PAYMENT_PROVIDER_BY_ID_REQUEST:
      return { ...state, loading: true, isError: false };
    case paymentProviderConstants.GET_PAYMENT_PROVIDER_BY_ID_SUCCESS:
      return {
        ...state, loading: false, isError: false, paymentProviderDetails: action.payload,
      };
    case paymentProviderConstants.GET_PAYMENT_PROVIDER_BY_ID_FAILURE:
      return {
        ...state, loading: false, isError: true,
      };

    case paymentProviderConstants.ADD_PAYMENT_PROVIDER_REQUEST:
      return {
        ...state, loading: true, updateError: false, isUpdated: false, errorMessages: [],
      };
    case paymentProviderConstants.ADD_PAYMENT_PROVIDER_SUCCESS:
      return {
        ...state,
        loading: false,
        updateError: false,
        isUpdated: true,
        errorMessages: [],
        allPaymentProviderList: [...state.allPaymentProviderList, action.payload],
      };
    case paymentProviderConstants.ADD_PAYMENT_PROVIDER_FAILURE:
      return {
        ...state, loading: false, updateError: true, errorMessages: action.errors,
      };

    case paymentProviderConstants.UPDATE_PAYMENT_PROVIDER_REQUEST:
      return {
        ...state, loading: true, updateError: false, isUpdated: false, errorMessages: [],
      };
    case paymentProviderConstants.UPDATE_PAYMENT_PROVIDER_SUCCESS: {
      const paymentProviderIndex = state.allPaymentProviderList.findIndex((paymentProvider) => paymentProvider.id === action.payload.id);
      const updatedList = [...state.allPaymentProviderList];
      updatedList[paymentProviderIndex] = action.payload;
      return {
        ...state,
        loading: false,
        updateError: false,
        isUpdated: true,
        errorMessages: [],
        allPaymentProviderList: updatedList,
      };
    }
    case paymentProviderConstants.UPDATE_PAYMENT_PROVIDER_FAILURE:
      return {
        ...state, loading: false, updateError: true, errorMessages: action.errors,
      };

    case paymentProviderConstants.DELETE_PAYMENT_PROVIDER_REQUEST:
      return {
        ...state, loading: true, isError: false, isDeleted: false,
      };
    case paymentProviderConstants.DELETE_PAYMENT_PROVIDER_SUCCESS:
      return {
        ...state,
        loading: false,
        isError: false,
        isDeleted: true,
        allPaymentProviderList:
          state.allPaymentProviderList.filter((item) => item.id !== action.id),
      };
    case paymentProviderConstants.DELETE_PAYMENT_PROVIDER_FAILURE:
      return {
        ...state, loading: false, isError: true,
      };

    case paymentProviderConstants.PAYMENT_PROVIDER_CODE_REQUEST:
      return {
        ...state, loading: true, isError: false,
      };
    case paymentProviderConstants.PAYMENT_PROVIDER_CODE_SUCCESS:
      return {
        ...state,
        loading: false,
        isError: false,
        pspCodeOptions: action.pspCodeOptions,
      };
    case paymentProviderConstants.PAYMENT_PROVIDER_CODE_FAILURE:
      return {
        ...state, loading: false, isError: true,
      };

    case paymentProviderConstants.PAYMENT_PROVIDER_OPTIONS_REQUEST:
      return {
        ...state, loading: true, isError: false,
      };
    case paymentProviderConstants.PAYMENT_PROVIDER_OPTIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        isError: false,
        pspConfigOptions: action.pspConfigOptions,
      };
    case paymentProviderConstants.PAYMENT_PROVIDER_OPTIONS_FAILURE:
      return {
        ...state, loading: false, isError: true,
      };

    default:
      return state;
  }
};
