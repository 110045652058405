import React from 'react';
import { useSelector } from 'react-redux';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  makeStyles,
} from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import intl from 'react-intl-universal';
import { RootState } from '../../../store/reducers';
import { WidgetTransactions } from '../../../types/dashboard.types';

interface Props {
  widget: WidgetTransactions;
}

const useStyles = makeStyles({
  root: {
    marginTop: '100px',
  },
  main: {
    marginTop: '15px',
  },
  table: {
    backgroundColor: '#EDEDED',
  },
  tableHead: {
    fontSize: '12px',
    fontWeight: 'bold',
    color: '#0f0e3b',
    borderBottom: '1px solid #707070',
  },
  tableBody: {
    fontSize: '11px',
    color: '#0f0e3b',
    borderBottom: '1px solid #707070',
  },
});

export default ({ widget }: Props) => {
  const classes = useStyles();
  const userState = useSelector((state: RootState) => state.userReducer);

  /**
   * This will run when user added/edited
   */
  React.useEffect(() => {
  }, [userState.isUpdated]);

  return (
    <div className={classes.main}>
      <TableContainer
        component={Paper}
        style={{ display: 'flex' }}
        className={classes.table}
      >
        <Table size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableHead}>{intl.get('MERCHANT')}</TableCell>
              <TableCell align="center" className={classes.tableHead}>{intl.get('CURRENCY')}</TableCell>
              <TableCell align="center" className={classes.tableHead}>{intl.get('AMOUNT')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {widget?.amounts?.map((row) => (
              <TableRow>
                <TableCell component="th" scope="row" className={classes.tableBody}>
                  {row?.merchantName}
                </TableCell>
                <TableCell align="center" className={classes.tableBody}>{row.currency}</TableCell>
                <TableCell align="center" className={classes.tableBody}>{row.amount}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};
