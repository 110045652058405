import React, { useEffect } from 'react';
import intl from 'react-intl-universal';
import {
  Box,
  Button,
  Grid,
  TextField,
} from '@material-ui/core';
import ArrowBackSharpIcon from '@material-ui/icons/ArrowBackSharp';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import CircularProgress from '@material-ui/core/CircularProgress';
import { StyledSectionHeading } from '../../StyledComponents/SectionHeading';
import { RootState } from '../../../store/reducers';
import { adminTransactionActions } from '../../../store/actions';
import { refundForm } from '../../../types/settlement.types';
import { DisplaySnackbar } from '../../Snackbar';
import { history } from '../../../helpers/index';
import { adminSettlementActions } from '../../../store/actions/admin-settlement.actions';
import { CurrencyDropDown } from '../../Currency';
import { RefundReasonDropDown } from '../RefundReason';
import { MerchantDropDown } from '../../MerchantDropDown';

interface Types {
  merchantPanel: boolean;
}
const useStyles = makeStyles(() => createStyles({
  formControl: {
    minWidth: 250,
    width: '100%',
  },
  buttonRight: {
    background: '#3f51b5',
    color: 'white',
    marginTop: '10px',
    padding: '10px',
    width: '20%',
  },
  circularProgress: {
    color: '#870a15',
  },
  back: {
    marginRight: '30px',
    '&:hover': {
      color: '#3f51b5',
      cursor: 'pointer',
    },
  },
}));

export const RefundForm = ({ merchantPanel }: Types) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [values, setValues] = React.useState({ ...refundForm });
  const settlementReducer = useSelector((state: RootState) => state.adminSettlementReducer);
  const { loading } = settlementReducer;
  useEffect(() => {
    dispatch(adminTransactionActions.getProviderOptions(merchantPanel));
  }, [dispatch, merchantPanel]);

  const onSubmit = () => {
    dispatch(adminSettlementActions.addRefund({ ...values }));
  };
  const backChange = () => {
    history.push('/dashboard/refunds');
  };
  useEffect(() => {
    if (settlementReducer.addForm) {
      setTimeout(() => {
        history.push('/dashboard/refunds');
      }, 3000);
    }
  }, [settlementReducer.addForm]);

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
        <Box className={classes.back}>
          <ArrowBackSharpIcon onClick={backChange} />
        </Box>
        <Box>
          <StyledSectionHeading>{intl.get('REFUNDS')}</StyledSectionHeading>
        </Box>
      </div>
      <div style={{ height: '100px' }}>
        {
          loading ? (
            <span style={{
              display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative',
            }}
            >
              <h3 style={{ marginRight: '30px' }}>Add Refund In Progress </h3>
              <CircularProgress className={classes.circularProgress} />
            </span>
          ) : null
        }
      </div>
      <form
        autoComplete="off"
        noValidate
      >
        <Grid
          container
          spacing={2}
        >
          <Grid
            item
            sm={6}
            md={6}
            xs={12}
          >
            <MerchantDropDown
              i18nTag="MERCHANT"
              variant="outlined"
              width="100%"
              defaultValue={values && values.merchant_uuid}
              onChange={(e: string) => setValues({ ...values, merchant_uuid: e })}
            />
          </Grid>
          <Grid
            item
            sm={6}
            md={6}
            xs={12}
          >
            <FormControl size="small" className={classes.formControl}>
              <InputLabel id="demo-customized-select-label" style={{ paddingLeft: '20px' }}>{intl.get('STATUS')}</InputLabel>
              <Select
                variant="outlined"
                labelId="demo-customized-select-label"
                fullWidth
                required
                style={{ textAlign: 'left', padding: '7px' }}
                value={values.status}
                onChange={(e) => setValues({ ...values, status: e.target.value })}
              >
                {
                  ['Pending', 'Rejected', 'Refunded', 'InProgress'].map((value: string) => <MenuItem value={value}>{value}</MenuItem>)
                }
              </Select>
            </FormControl>
          </Grid>
          <Grid
            item
            sm={6}
            md={6}
            xs={12}
          >
            <CurrencyDropDown
              i18nTag="CURRENCY"
              variant="outlined"
              width="100%"
              defaultValue={values && values.currency}
              onChange={(e: string) => setValues({ ...values, currency: e })}
            />
          </Grid>
          <Grid
            item
            sm={6}
            md={6}
            xs={12}
          >
            <TextField
              className={classes.formControl}
              placeholder={intl.get('AMOUNT')}
              fullWidth
              variant="outlined"
              label="Amount"
              value={values.amount}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => setValues(
                { ...values, amount: (event.target as HTMLInputElement).value },
              )}
            />
          </Grid>
          <Grid
            item
            sm={6}
            md={6}
            xs={12}
          >
            <RefundReasonDropDown
              i18nTag="REASON"
              variant="outlined"
              defaultValue={values && values.reason}
              onChange={(e: string) => setValues({ ...values, reason: String(e) })}
            />
          </Grid>
          <Grid
            item
            sm={6}
            md={6}
            xs={12}
          >
            <TextField
              className={classes.formControl}
              placeholder={intl.get('TRANSACTION_NUMBER')}
              variant="outlined"
              value={values.transactionNo}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => setValues(
                { ...values, transactionNo: (event.target as HTMLInputElement).value },
              )}
            />
          </Grid>
          <Grid
            item
            sm={12}
            md={12}
            xs={12}
          >
            <Button
              className={classes.buttonRight}
              variant="contained"
              onClick={onSubmit}
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </form>
      {
        settlementReducer && settlementReducer.isError
          ? settlementReducer.errorMessages && Object.values(settlementReducer.errorMessages).length
          && Object.values(settlementReducer.errorMessages).reverse().map(
            (error: any) => error?.length && error.map(
              (msg: string) => <DisplaySnackbar type="error" message={msg} />,
            ),
          ) : null
      }
      {
        settlementReducer && settlementReducer.addForm
          ? <DisplaySnackbar type="success" message={intl.get('ADDED_SUCCESSFULLY')} /> : null
      }
    </div>
  );
};
