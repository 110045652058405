import axios from 'axios';
import { history } from '../helpers';

/**
 * Configuration for all API calls
 */
export const API = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

/**
 * Default headers for POST calls
 */
API.defaults.headers.post['Content-Type'] = 'application/json';

/**
 * The below code runs for every endpoint request
 */
API.interceptors.request.use((req) => {
  const auth = JSON.parse(localStorage.getItem('auth') || '{}');
  const resetPasswordToken = localStorage.getItem('reset_password_token') || '{}';
  /**
   * If access_token available then send in endpoint request
   */
  if (auth && auth.access_token) {
    req.headers.Authorization = auth && auth.access_token ? `Bearer ${auth.access_token}` : null;
  } else if (resetPasswordToken) {
    req.headers.Authorization = `Bearer ${resetPasswordToken}` || null;
  }
  return req;
}, (error) => Promise.reject(error));

API.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      localStorage.removeItem('auth');
      history.push('/login');
    }
    return Promise.reject(error);
  },
);
