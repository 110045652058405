import { TwoFAData } from '../../types/common.types';

interface Types {
  LOGIN_REQUEST: string
  LOGIN_SUCCESS: string
  LOGIN_FAILURE: string

  RESET_PASSWORD_LINK_REQUEST: string;
  RESET_PASSWORD_LINK_SUCCESS: string;
  RESET_PASSWORD_LINK_FAILURE: string;

  SET_NEW_PASSWORD_REQUEST: string;
  SET_NEW_PASSWORD_SUCCESS: string;
  SET_NEW_PASSWORD_FAILURE: string;

  ACTIVATE_2FA_REQUEST: string;
  ACTIVATE_2FA_SUCCESS: string;
  ACTIVATE_2FA_FAILURE: string;

  REQUEST_2FA_REQUEST: string;
  REQUEST_2FA_SUCCESS: string;
  REQUEST_2FA_FAILURE: string;

  VALIDATE_2FA_REQUEST: string;
  VALIDATE_2FA_SUCCESS: string;
  VALIDATE_2FA_FAILURE: string;

  UPDATE_PASSWORD_REQUEST: string;
  UPDATE_PASSWORD_SUCCESS: string;
  UPDATE_PASSWORD_FAILURE: string;

  RESET_TWO_FA_STATE: string;

  LOGOUT: string
}

export const authConstants: Types = {

  LOGIN_REQUEST: 'USER_LOGIN_REQUEST',
  LOGIN_SUCCESS: 'USER_LOGIN_SUCCESS',
  LOGIN_FAILURE: 'USER_LOGIN_FAILURE',

  RESET_PASSWORD_LINK_REQUEST: 'RESET_PASSWORD_LINK_REQUEST',
  RESET_PASSWORD_LINK_SUCCESS: 'RESET_PASSWORD_LINK_SUCCESS',
  RESET_PASSWORD_LINK_FAILURE: 'RESET_PASSWORD_LINK_FAILURE',

  SET_NEW_PASSWORD_REQUEST: 'SET_NEW_PASSWORD_REQUEST',
  SET_NEW_PASSWORD_SUCCESS: 'SET_NEW_PASSWORD_SUCCESS',
  SET_NEW_PASSWORD_FAILURE: 'SET_NEW_PASSWORD_FAILURE',

  ACTIVATE_2FA_REQUEST: 'ACTIVATE_2FA_REQUEST',
  ACTIVATE_2FA_SUCCESS: 'ACTIVATE_2FA_SUCCESS',
  ACTIVATE_2FA_FAILURE: 'ACTIVATE_2FA_FAILURE',

  REQUEST_2FA_REQUEST: 'REQUEST_2FA_REQUEST',
  REQUEST_2FA_SUCCESS: 'REQUEST_2FA_SUCCESS',
  REQUEST_2FA_FAILURE: 'REQUEST_2FA_FAILURE',

  VALIDATE_2FA_REQUEST: 'VALIDATE_2FA_REQUEST',
  VALIDATE_2FA_SUCCESS: 'VALIDATE_2FA_SUCCESS',
  VALIDATE_2FA_FAILURE: 'VALIDATE_2FA_FAILURE',

  UPDATE_PASSWORD_REQUEST: 'UPDATE_PASSWORD_REQUEST',
  UPDATE_PASSWORD_SUCCESS: 'UPDATE_PASSWORD_SUCCESS',
  UPDATE_PASSWORD_FAILURE: 'UPDATE_PASSWORD_FAILURE',

  RESET_TWO_FA_STATE: 'RESET_TWO_FA_STATE',

  LOGOUT: 'USER_LOGOUT',

};

interface LoadLoginAction {
  type: typeof authConstants.LOGIN_REQUEST
  loggedInUser: { name: string, role: string, FAToken: string, loggedIn: boolean };
  twoFAData: TwoFAData;
  errors: any;
  resetPassword: boolean

}

interface LoadedLoginAction {
  type: typeof authConstants.LOGIN_SUCCESS
  loggedInUser: { name: string, role: string, FAToken: string, loggedIn: boolean };
  twoFAData: TwoFAData;
  errors: any;
  resetPassword: boolean

}

interface FailedLoginAction {
  type: typeof authConstants.LOGIN_FAILURE
  loggedInUser: { name: string, role: string, FAToken: string, loggedIn: boolean };
  twoFAData: TwoFAData;
  errors: any;
  resetPassword: boolean

}

interface LoadUpdatePasswordAction {
  type: typeof authConstants.UPDATE_PASSWORD_REQUEST
  loggedInUser: { name: string, role: string, FAToken: string, loggedIn: boolean };
  twoFAData: TwoFAData;
  errors: any;
  resetPassword: boolean

}

interface LoadedUpdatePasswordAction {
  type: typeof authConstants.UPDATE_PASSWORD_SUCCESS
  loggedInUser: { name: string, role: string, FAToken: string, loggedIn: boolean };
  twoFAData: TwoFAData;
  errors: any;
  resetPassword: boolean

}

interface FailedUpdatePasswordAction {
  type: typeof authConstants.UPDATE_PASSWORD_FAILURE
  loggedInUser: { name: string, role: string, FAToken: string, loggedIn: boolean };
  twoFAData: TwoFAData;
  errors: any;
  resetPassword: boolean

}

interface LoadResetPasswordLinkAction {
  type: typeof authConstants.RESET_PASSWORD_LINK_REQUEST
  twoFAData: TwoFAData;
  loggedInUser: { name: string, role: string, FAToken: string, loggedIn: boolean };
  errors: any;
  resetPassword: boolean

}

interface LoadedResetPasswordLinkAction {
  type: typeof authConstants.RESET_PASSWORD_LINK_SUCCESS
  loggedInUser: { name: string, role: string, FAToken: string, loggedIn: boolean };
  twoFAData: TwoFAData;
  resetPassword: boolean;
  errors: any;
}

interface FailedResetPasswordLinkAction {
  type: typeof authConstants.RESET_PASSWORD_LINK_FAILURE
  loggedInUser: { name: string, role: string, FAToken: string, loggedIn: boolean };
  twoFAData: TwoFAData;
  resetPassword: boolean;
  errors: any;
}

interface LogoutAction {
  type: typeof authConstants.LOGOUT
  loggedInUser: { name: string, role: string, FAToken: string, loggedIn: boolean };
  twoFAData: TwoFAData;
  resetPassword: boolean;
  errors: any;
}

interface LoadSetNewPasswordAction {
  type: typeof authConstants.RESET_PASSWORD_LINK_REQUEST
  loggedInUser: { name: string, role: string, FAToken: string, loggedIn: boolean };
  twoFAData: TwoFAData;
  resetPassword: boolean;
  errors: any;
}

interface LoadedSetNewPasswordAction {
  type: typeof authConstants.RESET_PASSWORD_LINK_SUCCESS
  loggedInUser: { name: string, role: string, FAToken: string, loggedIn: boolean };
  twoFAData: TwoFAData;
  resetPassword: boolean;
  errors: any;
}

interface FailedSetNewPasswordAction {
  type: typeof authConstants.RESET_PASSWORD_LINK_FAILURE
  loggedInUser: { name: string, role: string, FAToken: string, loggedIn: boolean };
  twoFAData: TwoFAData;
  resetPassword: boolean;
  errors: any;
}

interface LoadActivate2FAAction {
  type: typeof authConstants.ACTIVATE_2FA_REQUEST
  loggedInUser: { name: string, role: string, FAToken: string, loggedIn: boolean };
  twoFAData: TwoFAData;
  resetPassword: boolean;
  errors: any;
}

interface LoadedActivate2FAAction {
  type: typeof authConstants.ACTIVATE_2FA_SUCCESS
  loggedInUser: { name: string, role: string, FAToken: string, loggedIn: boolean };
  twoFAData: TwoFAData;
  resetPassword: boolean;
  errors: any;
}

interface FailedActivate2FAAction {
  type: typeof authConstants.ACTIVATE_2FA_FAILURE
  loggedInUser: { name: string, role: string, FAToken: string, loggedIn: boolean };
  twoFAData: TwoFAData;
  resetPassword: boolean;
  errors: any;
}

interface LoadRequest2FAAction {
  type: typeof authConstants.REQUEST_2FA_REQUEST
  loggedInUser: { name: string, role: string, FAToken: string, loggedIn: boolean };
  twoFAData: TwoFAData;
  resetPassword: boolean;
  errors: any;
}

interface LoadedRequest2FAAction {
  type: typeof authConstants.REQUEST_2FA_SUCCESS
  loggedInUser: { name: string, role: string, FAToken: string, loggedIn: boolean };
  twoFAData: TwoFAData;
  errors: any;
  resetPassword: boolean;

}

interface FailedRequest2FAAction {
  type: typeof authConstants.REQUEST_2FA_FAILURE
  loggedInUser: { name: string, role: string, FAToken: string, loggedIn: boolean };
  twoFAData: TwoFAData;
  errors: any;
  resetPassword: boolean;

}

interface LoadValidate2FAAction {
  type: typeof authConstants.VALIDATE_2FA_REQUEST
  loggedInUser: { name: string, role: string, FAToken: string, loggedIn: boolean };
  twoFAData: TwoFAData;
  errors: any;
  resetPassword: boolean;

}

interface LoadedValidate2FAAction {
  type: typeof authConstants.VALIDATE_2FA_SUCCESS
  twoFAData: TwoFAData;
  loggedInUser: { name: string, role: string, FAToken: string, loggedIn: boolean };
  errors: any;
  resetPassword: boolean;
}

interface FailedValidate2FAAction {
  type: typeof authConstants.VALIDATE_2FA_FAILURE
  twoFAData: TwoFAData;
  loggedInUser: { name: string, role: string, FAToken: string, loggedIn: boolean };
  errors: any;
  resetPassword: boolean

}

export type AuthActionTypes
  = LoadLoginAction
  | LoadedLoginAction
  | FailedLoginAction

  | LogoutAction

  | LoadResetPasswordLinkAction
  | LoadedResetPasswordLinkAction
  | FailedResetPasswordLinkAction

  | LoadSetNewPasswordAction
  | LoadedSetNewPasswordAction
  | FailedSetNewPasswordAction

  | LoadActivate2FAAction
  | LoadedActivate2FAAction
  | FailedActivate2FAAction

  | LoadRequest2FAAction
  | LoadedRequest2FAAction
  | FailedRequest2FAAction

  | LoadValidate2FAAction
  | LoadedValidate2FAAction
  | FailedValidate2FAAction

  | LoadUpdatePasswordAction
  | LoadedUpdatePasswordAction
  | FailedUpdatePasswordAction;
