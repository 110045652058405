import React, { useEffect } from 'react';
import intl from 'react-intl-universal';
import {
  Box,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Doughnut } from 'react-chartjs-2';
import { DailyTransaction } from '../../../types/dashboard.types';

const useStyles = makeStyles(() => ({
  title: {
    color: '#959595',
    margin: '13px',
  },
  sectionRow: {
    padding: '10px 20px 70px 20px',
    borderRadius: '10px',
    boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
    backgroundColor: '#040532',
  },
  sectionRowLight: {
    margin: '12px 10px',
    position: 'relative',
    backgroundColor: 'rgba(0, 0, 0, 0.05)',
  },
  currenyLabel: {
    margin: '10px',
    color: '#959595',
  },
}));

const optionsDonut = {
  cutoutPercentage: 70,
};

interface Props {
  dailyTransactionData: DailyTransaction[];
}

export const RightSection: React.FC<Props> = ({ dailyTransactionData }: Props) => {
  const classes = useStyles();
  const [donutData, setDonutData] = React.useState({});
  const [totalDailyTransactions, setTotalDailyTransactions] = React.useState(0);

  useEffect(() => {
    const totals: number[] = [];
    if (dailyTransactionData && dailyTransactionData.length) {
      const amountSuccess = (item: DailyTransaction) => Number(item.amount_success);
      const amountFailed = (item: DailyTransaction) => Number(item.amount_failed);
      const sum = (prev: number, next: number) => prev + next;
      totals.push(dailyTransactionData.map(amountSuccess).reduce(sum));
      totals.push(dailyTransactionData.map(amountFailed).reduce(sum));
      const totalSum = (amount: number) => amount;
      setTotalDailyTransactions(totals.map(totalSum).reduce(sum));
    }
    const data = {
      labels: [
        'Success',
        'Unsuccessful',
      ],
      datasets: [{
        data: totals,
        backgroundColor: [
          '#0d3e95',
          '#f7971d',
        ],
        hoverBackgroundColor: [
          '#0d3e95',
          '#f7971d',
        ],
      }],
    };
    setDonutData(data);
  }, [dailyTransactionData, totalDailyTransactions]);

  return (
    <>
      <div>
        <div style={{
          marginBottom: '10px',
        }}
        >
          <Box>
            <h2 className={classes.title}>{intl.get('TRANSACTIONS')}</h2>
          </Box>
          <div style={{ borderBottom: '1px solid #959595' }} />
        </div>
        <div className={[classes.sectionRow, classes.sectionRowLight].join(' ')}>
          <Box>
            <h3 style={{ color: '#880a15' }} className={classes.title}>{intl.get('TOTAL', { amount: totalDailyTransactions })}</h3>
          </Box>
          <Doughnut data={donutData} width={400} options={optionsDonut} />
        </div>
      </div>
    </>
  );
};
