import React, { useEffect } from 'react';
import intl from 'react-intl-universal';
import { useSelector, useDispatch } from 'react-redux';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core';
import { merchantActions } from '../store/actions';
import { AccountOptions } from '../types/merchant-billing.types';
import { RootState } from '../store/reducers';

export const MerchantDropDown = ({
  onChange, defaultValue, i18nTag, variant, label, clearFilter, width, minWidth, margin,
}: any) => {
  const [selectedValue, setSelectedValue] = React.useState(defaultValue);
  const dispatch = useDispatch();

  useEffect(() => {
    if (clearFilter === true) {
      setSelectedValue('');
    }
  }, [clearFilter]);
  useEffect(() => {
    dispatch(merchantActions.merchantOptions());
  }, [dispatch]);

  const merchant = useSelector((state: RootState) => state.merchantReducer);
  const merchantlist: AccountOptions[] = merchant.merchantOptions;
  return (
    <FormControl style={{ width, minWidth, margin }}>
      <InputLabel>{intl.get(i18nTag)}</InputLabel>
      <Select
        variant={variant}
        onChange={(e) => {
          onChange(e.target.value); setSelectedValue(e.target.value);
        }}
        label={label}
        value={selectedValue}
        style={{ textAlign: 'left' }}
        required
      >
        {
          merchantlist?.map((row: AccountOptions) => <MenuItem value={row.value}>{row.label}</MenuItem>)
        }
      </Select>
    </FormControl>
  );
};
