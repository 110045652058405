/* eslint camelcase:  off */
import { Dates } from './common.types';
import { IMerchant, IMerchantState } from './merchant.types';

export interface IAdminTransaction {
  id: number;
  uuid: string;
  merchant_id: number;
  provider: string;
  transaction_no: string;
  order_no: string;
  currency: string;
  base_currency: string;
  amount: number;
  base_amount: number;
  exchange_rate: number;
  response_code: string;
  success: number;
  error: string;
  response_time: number;
  complete_at: string;
  callback_endpoint: string;
  deleted_at: string;
  created_at: string;
  updated_at: string;
  merchant: IMerchant;
  payment_method?: string;
  psp_code: string;
  provider_code?: string;
}

export const IAdminTransactionState = {
  id: 0,
  uuid: '',
  merchant_id: 0,
  provider: '',
  transaction_no: '',
  order_no: '',
  currency: '',
  base_currency: '',
  amount: 0,
  base_amount: 0,
  exchange_rate: 0,
  response_code: '',
  success: 0,
  error: '',
  response_time: 0,
  complete_at: '',
  callback_endpoint: '',
  deleted_at: '',
  created_at: '',
  updated_at: '',
  merchant: IMerchantState,
  psp_code: '',
};

export interface TransactionFilter {
  merchantUUID?: string;
  selectedDates?: Dates;
  currency?: string;
  payment_method?: string;
  provider?: string;
  transaction_no?: string;
  order_no?: string;
  succesfull?: string;
  search?:string
}
