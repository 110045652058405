import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/reducers';
import Toolbar from './Toolbar';
import UserList from './UserList';
import { IUser } from './types';
import PageHeader from '../../../components/StyledComponents/PageHeader';

export const Users = () => {
  const [paginationPar, setPaginationPar] = React.useState({ limit: 10, current_page: 1 });
  const userReducer = useSelector((state: RootState) => state.userReducer);
  const userList: IUser[] = userReducer.allUserList || [];
  return (
    <>
      <PageHeader
        label="USERS"
        componentToPassDown={<Toolbar pagination={paginationPar} />}
      />
      <UserList users={userList} changePaginationOptions={(e) => setPaginationPar(e)} />
    </>
  );
};
