import {
  DailyTransaction, PaymentMethod, PayoutData, ProviderBalance, WidgetTransactions, WidgetError, MerchantBalances, Balances,
} from '../../types/dashboard.types';

interface Types {

  GET_WIDGET_PAYOUTS_REQUEST: string;
  GET_WIDGET_PAYOUTS_SUCCESS: string;
  GET_WIDGET_PAYOUTS_FAILURE: string;

  GET_WIDGET_PROVIDER_BALANCE_REQUEST: string;
  GET_WIDGET_PROVIDER_BALANCE_SUCCESS: string;
  GET_WIDGET_PROVIDER_BALANCE_FAILURE: string;

  GET_WIDGET_PAYMENT_METHODS_REQUEST: string;
  GET_WIDGET_PAYMENT_METHODS_SUCCESS: string;
  GET_WIDGET_PAYMENT_METHODS_FAILURE: string;

  GET_WIDGET_DAILY_TRANSACTIONS_REQUEST: string;
  GET_WIDGET_DAILY_TRANSACTIONS_SUCCESS: string;
  GET_WIDGET_DAILY_TRANSACTIONS_FAILURE: string;

  GET_WIDGET_TRANSACTIONS_REQUEST_CURRENT_MONTH: string;
  GET_WIDGET_TRANSACTIONS_SUCCESS_CURRENT_MONTH: string;
  GET_WIDGET_TRANSACTIONS_FAILURE_CURRENT_MONTH: string;

  GET_WIDGET_TRANSACTIONS_REQUEST_PREVIOUS_MONTH: string;
  GET_WIDGET_TRANSACTIONS_SUCCESS_PREVIOUS_MONTH: string;
  GET_WIDGET_TRANSACTIONS_FAILURE_PREVIOUS_MONTH: string;

  GET_WIDGET_ERROR_REQUEST: string;
  GET_WIDGET_ERROR_SUCCESS: string;
  GET_WIDGET_ERROR_FAILURE: string;

  GET_WIDGET_BALANCES_REQUEST: string;
  GET_WIDGET_BALANCES_SUCCESS: string;
  GET_WIDGET_BALANCES_FAILURE: string;

  GET_MERCHANT_BALANCES_REQUEST: string;
  GET_MERCHANT_BALANCES_SUCCESS: string;
  GET_MERCHANT_BALANCES_FAILURE: string;
}

export const dashboardConstants: Types = {

  GET_WIDGET_PAYOUTS_REQUEST: 'GET_WIDGET_PAYOUTS_REQUEST',
  GET_WIDGET_PAYOUTS_SUCCESS: 'GET_WIDGET_PAYOUTS_SUCCESS',
  GET_WIDGET_PAYOUTS_FAILURE: 'GET_WIDGET_PAYOUTS_FAILURE',

  GET_WIDGET_PROVIDER_BALANCE_REQUEST: 'GET_WIDGET_PROVIDER_BALANCE_REQUEST',
  GET_WIDGET_PROVIDER_BALANCE_SUCCESS: 'GET_WIDGET_PROVIDER_BALANCE_SUCCESS',
  GET_WIDGET_PROVIDER_BALANCE_FAILURE: 'GET_WIDGET_PROVIDER_BALANCE_FAILURE',

  GET_WIDGET_PAYMENT_METHODS_REQUEST: 'GET_WIDGET_PAYMENT_METHODS_REQUEST',
  GET_WIDGET_PAYMENT_METHODS_SUCCESS: 'GET_WIDGET_PAYMENT_METHODS_SUCCESS',
  GET_WIDGET_PAYMENT_METHODS_FAILURE: 'GET_WIDGET_PAYMENT_METHODS_FAILURE',

  GET_WIDGET_DAILY_TRANSACTIONS_REQUEST: 'GET_WIDGET_DAILY_TRANSACTIONS_REQUEST',
  GET_WIDGET_DAILY_TRANSACTIONS_SUCCESS: 'GET_WIDGET_DAILY_TRANSACTIONS_SUCCESS',
  GET_WIDGET_DAILY_TRANSACTIONS_FAILURE: 'GET_WIDGET_DAILY_TRANSACTIONS_FAILURE',

  GET_WIDGET_TRANSACTIONS_REQUEST_CURRENT_MONTH: 'GET_WIDGET_TRANSACTIONS_REQUEST_CURRENT_MONTH',
  GET_WIDGET_TRANSACTIONS_SUCCESS_CURRENT_MONTH: 'GET_WIDGET_TRANSACTIONS_SUCCESS_CURRENT_MONTH',
  GET_WIDGET_TRANSACTIONS_FAILURE_CURRENT_MONTH: 'GET_WIDGET_TRANSACTIONS_FAILURE_CURRENT_MONTH',

  GET_WIDGET_TRANSACTIONS_REQUEST_PREVIOUS_MONTH: 'GET_WIDGET_TRANSACTIONS_REQUEST_PREVIOUS_MONTH',
  GET_WIDGET_TRANSACTIONS_SUCCESS_PREVIOUS_MONTH: 'GET_WIDGET_TRANSACTIONS_SUCCESS_PREVIOUS_MONTH',
  GET_WIDGET_TRANSACTIONS_FAILURE_PREVIOUS_MONTH: 'GET_WIDGET_TRANSACTIONS_FAILURE_PREVIOUS_MONTH',

  GET_WIDGET_ERROR_REQUEST: 'GET_WIDGET_ERROR_REQUEST',
  GET_WIDGET_ERROR_SUCCESS: 'GET_WIDGET_ERROR_SUCCESS',
  GET_WIDGET_ERROR_FAILURE: 'GET_WIDGET_ERROR_FAILURE',

  GET_WIDGET_BALANCES_REQUEST: 'GET_WIDGET_BALANCES_REQUEST',
  GET_WIDGET_BALANCES_SUCCESS: 'GET_WIDGET_BALANCES_SUCCESS',
  GET_WIDGET_BALANCES_FAILURE: 'GET_WIDGET_BALANCES_FAILURE',

  GET_MERCHANT_BALANCES_REQUEST: 'GET_MERCHANT_BALANCES_REQUEST',
  GET_MERCHANT_BALANCES_SUCCESS: 'GET_MERCHANT_BALANCES_SUCCESS',
  GET_MERCHANT_BALANCES_FAILURE: 'GET_MERCHANT_BALANCES_FAILURE',
};

interface LoadGetWidgetPayoutsAction {
  type: typeof dashboardConstants.GET_WIDGET_PAYOUTS_REQUEST;
  payoutData: PayoutData;
  providerBalanceData: ProviderBalance[];
  paymentMethodData: PaymentMethod[];
  dailyTransactionData: DailyTransaction[];
  currentMonth: WidgetTransactions;
  previousMonth: WidgetTransactions;
  WidgetsError: WidgetError[];
  WidgetMerchantBalances: MerchantBalances[];
  MerchantBalance: Balances[]
}

interface LoadedGetWidgetPayoutsAction {
  type: typeof dashboardConstants.GET_WIDGET_PAYOUTS_SUCCESS;
  payoutData: PayoutData;
  providerBalanceData: ProviderBalance[];
  paymentMethodData: PaymentMethod[];
  dailyTransactionData: DailyTransaction[];
  currentMonth: WidgetTransactions;
  previousMonth: WidgetTransactions;
  WidgetsError: WidgetError[];
  WidgetMerchantBalances: MerchantBalances[];
  MerchantBalance: Balances[]
}

interface FailedGetWidgetPayoutsAction {
  type: typeof dashboardConstants.GET_WIDGET_PAYOUTS_FAILURE;
  payoutData: PayoutData;
  providerBalanceData: ProviderBalance[];
  paymentMethodData: PaymentMethod[];
  dailyTransactionData: DailyTransaction[];
  currentMonth: WidgetTransactions;
  previousMonth: WidgetTransactions;
  WidgetsError: WidgetError[];
  WidgetMerchantBalances: MerchantBalances[];
  MerchantBalance: Balances[]
}
interface LoadGetWidgetProviderBalanceAction {
  type: typeof dashboardConstants.GET_WIDGET_PROVIDER_BALANCE_REQUEST;
  payoutData: PayoutData;
  providerBalanceData: ProviderBalance[];
  paymentMethodData: PaymentMethod[];
  dailyTransactionData: DailyTransaction[];
  currentMonth: WidgetTransactions;
  previousMonth: WidgetTransactions;
  WidgetsError: WidgetError[];
  WidgetMerchantBalances: MerchantBalances[];
  MerchantBalance: Balances[]
}

interface LoadedGetWidgetProviderBalanceAction {
  type: typeof dashboardConstants.GET_WIDGET_PROVIDER_BALANCE_SUCCESS;
  payoutData: PayoutData;
  providerBalanceData: ProviderBalance[];
  paymentMethodData: PaymentMethod[];
  dailyTransactionData: DailyTransaction[];
  currentMonth: WidgetTransactions;
  previousMonth: WidgetTransactions;
  WidgetsError: WidgetError[];
  WidgetMerchantBalances: MerchantBalances[];
  MerchantBalance: Balances[]
}

interface FailedGetWidgetProviderBalanceAction {
  type: typeof dashboardConstants.GET_WIDGET_PROVIDER_BALANCE_FAILURE;
  payoutData: PayoutData;
  providerBalanceData: ProviderBalance[];
  paymentMethodData: PaymentMethod[];
  dailyTransactionData: DailyTransaction[];
  currentMonth: WidgetTransactions;
  previousMonth: WidgetTransactions;
  WidgetsError: WidgetError[];
  WidgetMerchantBalances: MerchantBalances[];
  MerchantBalance: Balances[]
}

interface LoadGetWidgetPaymentMethodsAction {
  type: typeof dashboardConstants.GET_WIDGET_PAYMENT_METHODS_REQUEST;
  payoutData: PayoutData;
  providerBalanceData: ProviderBalance[];
  paymentMethodData: PaymentMethod[];
  dailyTransactionData: DailyTransaction[];
  currentMonth: WidgetTransactions;
  previousMonth: WidgetTransactions;
  WidgetsError: WidgetError[];
  WidgetMerchantBalances: MerchantBalances[];
  MerchantBalance: Balances[]
}

interface LoadedGetWidgetPaymentMethodsAction {
  type: typeof dashboardConstants.GET_WIDGET_PAYMENT_METHODS_SUCCESS;
  payoutData: PayoutData;
  providerBalanceData: ProviderBalance[];
  paymentMethodData: PaymentMethod[];
  dailyTransactionData: DailyTransaction[];
  currentMonth: WidgetTransactions;
  previousMonth: WidgetTransactions;
  WidgetsError: WidgetError[];
  WidgetMerchantBalances: MerchantBalances[];
  MerchantBalance: Balances[]
}

interface FailedGetWidgetPaymentMethodsAction {
  type: typeof dashboardConstants.GET_WIDGET_PAYMENT_METHODS_FAILURE;
  payoutData: PayoutData;
  providerBalanceData: ProviderBalance[];
  paymentMethodData: PaymentMethod[];
  dailyTransactionData: DailyTransaction[];
  currentMonth: WidgetTransactions;
  previousMonth: WidgetTransactions;
  WidgetsError: WidgetError[];
  WidgetMerchantBalances: MerchantBalances[];
  MerchantBalance: Balances[]
}

interface LoadGetWidgetDailyTransactionsAction {
  type: typeof dashboardConstants.GET_WIDGET_DAILY_TRANSACTIONS_REQUEST;
  payoutData: PayoutData;
  providerBalanceData: ProviderBalance[];
  paymentMethodData: PaymentMethod[];
  dailyTransactionData: DailyTransaction[];
  currentMonth: WidgetTransactions;
  previousMonth: WidgetTransactions;
  WidgetsError: WidgetError[];
  WidgetMerchantBalances: MerchantBalances[];
  MerchantBalance: Balances[]
}

interface LoadedGetWidgetDailyTransactionsAction {
  type: typeof dashboardConstants.GET_WIDGET_DAILY_TRANSACTIONS_SUCCESS;
  payoutData: PayoutData;
  providerBalanceData: ProviderBalance[];
  paymentMethodData: PaymentMethod[];
  dailyTransactionData: DailyTransaction[];
  currentMonth: WidgetTransactions;
  previousMonth: WidgetTransactions;
  WidgetsError: WidgetError[];
  WidgetMerchantBalances: MerchantBalances[];
  MerchantBalance: Balances[]
}

interface FailedGetWidgetDailyTransactionsAction {
  type: typeof dashboardConstants.GET_WIDGET_DAILY_TRANSACTIONS_FAILURE;
  payoutData: PayoutData;
  providerBalanceData: ProviderBalance[];
  paymentMethodData: PaymentMethod[];
  dailyTransactionData: DailyTransaction[];
  currentMonth: WidgetTransactions;
  previousMonth: WidgetTransactions;
  WidgetsError: WidgetError[];
  WidgetMerchantBalances: MerchantBalances[];
  MerchantBalance: Balances[]
}

interface LoadGetWidgetCurrentMonthTransactionsAction {
  type: typeof dashboardConstants.GET_WIDGET_TRANSACTIONS_REQUEST_CURRENT_MONTH;
  payoutData: PayoutData;
  providerBalanceData: ProviderBalance[];
  paymentMethodData: PaymentMethod[];
  dailyTransactionData: DailyTransaction[];
  currentMonth: WidgetTransactions;
  previousMonth: WidgetTransactions;
  WidgetsError: WidgetError[];
  WidgetMerchantBalances: MerchantBalances[];
  MerchantBalance: Balances[]
}

interface LoadedGetWidgetCurrentMonthTransactionsAction {
  type: typeof dashboardConstants.GET_WIDGET_TRANSACTIONS_SUCCESS_CURRENT_MONTH;
  payoutData: PayoutData;
  providerBalanceData: ProviderBalance[];
  paymentMethodData: PaymentMethod[];
  dailyTransactionData: DailyTransaction[];
  currentMonth: WidgetTransactions;
  previousMonth: WidgetTransactions;
  WidgetsError: WidgetError[];
  WidgetMerchantBalances: MerchantBalances[];
  MerchantBalance: Balances[]
}
interface FailedGetWidgetCurrentMonthTransactionsAction {
  type: typeof dashboardConstants.GET_WIDGET_TRANSACTIONS_FAILURE_CURRENT_MONTH;
  payoutData: PayoutData;
  providerBalanceData: ProviderBalance[];
  paymentMethodData: PaymentMethod[];
  dailyTransactionData: DailyTransaction[];
  currentMonth: WidgetTransactions;
  previousMonth: WidgetTransactions;
  WidgetsError: WidgetError[];
  WidgetMerchantBalances: MerchantBalances[];
  MerchantBalance: Balances[]
}
interface LoadGetWidgetPreviousMonthTransactionsAction {
  type: typeof dashboardConstants.GET_WIDGET_TRANSACTIONS_REQUEST_PREVIOUS_MONTH;
  payoutData: PayoutData;
  providerBalanceData: ProviderBalance[];
  paymentMethodData: PaymentMethod[];
  dailyTransactionData: DailyTransaction[];
  currentMonth: WidgetTransactions;
  previousMonth: WidgetTransactions;
  WidgetsError: WidgetError[];
  WidgetMerchantBalances: MerchantBalances[];
  MerchantBalance: Balances[]
}

interface LoadedGetWidgetPreviousMonthTransactionsAction {
  type: typeof dashboardConstants.GET_WIDGET_TRANSACTIONS_SUCCESS_PREVIOUS_MONTH;
  payoutData: PayoutData;
  providerBalanceData: ProviderBalance[];
  paymentMethodData: PaymentMethod[];
  dailyTransactionData: DailyTransaction[];
  currentMonth: WidgetTransactions;
  previousMonth: WidgetTransactions;
  WidgetsError: WidgetError[];
  WidgetMerchantBalances: MerchantBalances[];
  MerchantBalance: Balances[]
}

interface FailedGetWidgetPreviousMonthTransactionsAction {
  type: typeof dashboardConstants.GET_WIDGET_TRANSACTIONS_FAILURE_PREVIOUS_MONTH;
  payoutData: PayoutData;
  providerBalanceData: ProviderBalance[];
  paymentMethodData: PaymentMethod[];
  dailyTransactionData: DailyTransaction[];
  currentMonth: WidgetTransactions;
  previousMonth: WidgetTransactions;
  WidgetsError: WidgetError[];
  WidgetMerchantBalances: MerchantBalances[];
  MerchantBalance: Balances[]
}

interface LoadGetWidgetErrorAction {
  type: typeof dashboardConstants.GET_WIDGET_ERROR_REQUEST;
  payoutData: PayoutData;
  providerBalanceData: ProviderBalance[];
  paymentMethodData: PaymentMethod[];
  dailyTransactionData: DailyTransaction[];
  currentMonth: WidgetTransactions;
  previousMonth: WidgetTransactions;
  WidgetsError: WidgetError[];
  WidgetMerchantBalances: MerchantBalances[];
  MerchantBalance: Balances[]
}

interface LoadedGetWidgetErrorAction {
  type: typeof dashboardConstants.GET_WIDGET_ERROR_SUCCESS;
  payoutData: PayoutData;
  providerBalanceData: ProviderBalance[];
  paymentMethodData: PaymentMethod[];
  dailyTransactionData: DailyTransaction[];
  currentMonth: WidgetTransactions;
  previousMonth: WidgetTransactions;
  WidgetsError: WidgetError[];
  WidgetMerchantBalances: MerchantBalances[];
  MerchantBalance: Balances[]
}

interface FailedGetWidgetErrorAction {
  type: typeof dashboardConstants.GET_WIDGET_ERROR_FAILURE;
  payoutData: PayoutData;
  providerBalanceData: ProviderBalance[];
  paymentMethodData: PaymentMethod[];
  dailyTransactionData: DailyTransaction[];
  currentMonth: WidgetTransactions;
  previousMonth: WidgetTransactions;
  WidgetsError: WidgetError[];
  WidgetMerchantBalances: MerchantBalances[];
  MerchantBalance: Balances[]
}

interface LoadGetWidgetMerchantBalancesAction {
  type: typeof dashboardConstants.GET_WIDGET_BALANCES_REQUEST;
  payoutData: PayoutData;
  providerBalanceData: ProviderBalance[];
  paymentMethodData: PaymentMethod[];
  dailyTransactionData: DailyTransaction[];
  currentMonth: WidgetTransactions;
  previousMonth: WidgetTransactions;
  WidgetsError: WidgetError[];
  WidgetMerchantBalances: MerchantBalances[];
  MerchantBalance: Balances[]
}

interface LoadedGetWidgetMerchantBalancesAction {
  type: typeof dashboardConstants.GET_WIDGET_BALANCES_SUCCESS;
  payoutData: PayoutData;
  providerBalanceData: ProviderBalance[];
  paymentMethodData: PaymentMethod[];
  dailyTransactionData: DailyTransaction[];
  currentMonth: WidgetTransactions;
  previousMonth: WidgetTransactions;
  WidgetsError: WidgetError[];
  WidgetMerchantBalances: MerchantBalances[];
  MerchantBalance: Balances[]
}

interface FailedGetWidgetMerchantBalancesAction {
  type: typeof dashboardConstants.GET_WIDGET_BALANCES_FAILURE;
  payoutData: PayoutData;
  providerBalanceData: ProviderBalance[];
  paymentMethodData: PaymentMethod[];
  dailyTransactionData: DailyTransaction[];
  currentMonth: WidgetTransactions;
  previousMonth: WidgetTransactions;
  WidgetsError: WidgetError[];
  WidgetMerchantBalances: MerchantBalances[];
  MerchantBalance: Balances[]
}

interface LoadGetMerchantBalancesAction {
  type: typeof dashboardConstants.GET_WIDGET_BALANCES_REQUEST;
  payoutData: PayoutData;
  providerBalanceData: ProviderBalance[];
  paymentMethodData: PaymentMethod[];
  dailyTransactionData: DailyTransaction[];
  currentMonth: WidgetTransactions;
  previousMonth: WidgetTransactions;
  WidgetsError: WidgetError[];
  WidgetMerchantBalances: MerchantBalances[];
  MerchantBalance: Balances[]
}

interface LoadedGetMerchantBalancesAction {
  type: typeof dashboardConstants.GET_WIDGET_BALANCES_SUCCESS;
  payoutData: PayoutData;
  providerBalanceData: ProviderBalance[];
  paymentMethodData: PaymentMethod[];
  dailyTransactionData: DailyTransaction[];
  currentMonth: WidgetTransactions;
  previousMonth: WidgetTransactions;
  WidgetsError: WidgetError[];
  WidgetMerchantBalances: MerchantBalances[];
  MerchantBalance: Balances[]
}

interface FailedGetMerchantBalancesAction {
  type: typeof dashboardConstants.GET_WIDGET_BALANCES_FAILURE;
  payoutData: PayoutData;
  providerBalanceData: ProviderBalance[];
  paymentMethodData: PaymentMethod[];
  dailyTransactionData: DailyTransaction[];
  currentMonth: WidgetTransactions;
  previousMonth: WidgetTransactions;
  WidgetsError: WidgetError[];
  WidgetMerchantBalances: MerchantBalances[]
  MerchantBalance: Balances[]
}
export type DashboardActionTypes
  = LoadGetWidgetPayoutsAction
  | LoadedGetWidgetPayoutsAction
  | FailedGetWidgetPayoutsAction

  | LoadGetWidgetProviderBalanceAction
  | LoadedGetWidgetProviderBalanceAction
  | FailedGetWidgetProviderBalanceAction

  | LoadGetWidgetPaymentMethodsAction
  | LoadedGetWidgetPaymentMethodsAction
  | FailedGetWidgetPaymentMethodsAction

  | LoadGetWidgetDailyTransactionsAction
  | LoadedGetWidgetDailyTransactionsAction
  | FailedGetWidgetDailyTransactionsAction

  | LoadGetWidgetCurrentMonthTransactionsAction
  | LoadedGetWidgetCurrentMonthTransactionsAction
  | FailedGetWidgetCurrentMonthTransactionsAction

  | LoadGetWidgetPreviousMonthTransactionsAction
  | LoadedGetWidgetPreviousMonthTransactionsAction
  | FailedGetWidgetPreviousMonthTransactionsAction

  | LoadGetWidgetErrorAction
  | LoadedGetWidgetErrorAction
  | FailedGetWidgetErrorAction

  | LoadGetWidgetMerchantBalancesAction
  | LoadedGetWidgetMerchantBalancesAction
  | FailedGetWidgetMerchantBalancesAction

  | LoadGetMerchantBalancesAction
  | LoadedGetMerchantBalancesAction
  | FailedGetMerchantBalancesAction;
