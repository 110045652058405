import { API } from '../config';
import { MerchantKycIds } from '../types/file.types';

const getFiles = (merchantUUID: string) => API.get(`/admin/merchants/${merchantUUID}/kyc`);

const deleteMerchantKyc = (payload: MerchantKycIds) => API.delete(`/admin/merchants/${payload.merchantUUID}/kyc/${payload.merchantKycUUID}`);

const approveMerchantKyc = (payload: MerchantKycIds) => API.post(`/admin/merchants/${payload.merchantUUID}/kyc/${payload.merchantKycUUID}/approve`);

const getKycById = (payload: MerchantKycIds) => API.get(`/admin/merchants/${payload.merchantUUID}/kyc/${payload.merchantKycUUID}`);

export const fileService = {
  getFiles,
  deleteMerchantKyc,
  approveMerchantKyc,
  getKycById,
};
