import React, { useState, useEffect } from 'react';
import intl from 'react-intl-universal';
import { useSelector } from 'react-redux';
import {
  Dialog,
  DialogContent,
} from '@material-ui/core';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import Fab from '@material-ui/core/Fab';
import { RootState } from '../../../store/reducers';
import { DisplaySnackbar } from '../../index';
import { IMerchantAccount } from '../../../types/merchant-account.types';
import { MerchantAccountEdit } from '../index';

interface IProps {
  openDialog: boolean, closeMerchantAccountForm: () => void;
  selectedMerchantAccount: IMerchantAccount | null;
  merchantUUID: string;
  merchantPanel: boolean;
}

const useStyles = makeStyles((theme: Theme) => createStyles({
  dialogContainer: {
    [theme.breakpoints.up('md')]: {
      marginLeft: '165px',
    },
    marginTop: '100px',
  },
  button: {
    margin: theme.spacing(1),
  },
  backBtn: {
    borderRadius: '5px',
    position: 'absolute',
    top: '-80px',
    left: 0,
    height: '20px',
  },
}));

export const MerchantAccountForm: React.FC<IProps> = ({
  openDialog,
  closeMerchantAccountForm,
  selectedMerchantAccount,
  merchantUUID,
  merchantPanel,
}: IProps): React.ReactElement => {
  const classes = useStyles();
  const [open, setOpen] = useState(openDialog);
  const merchantAccountState = useSelector((state: RootState) => state.merchantAccountReducer);

  /**
     * This will run when we open/close merchantAccount form dialog
     */
  useEffect(() => {
    setOpen(openDialog);
  }, [openDialog]);

  return (
    <div>
      {merchantAccountState && merchantAccountState.updateError
        ? merchantAccountState.errorMessages && Object.values(merchantAccountState.errorMessages).length
        && Object.values(merchantAccountState.errorMessages).map(
          (error: any) => error?.length && error.map(
            (msg: string) => <DisplaySnackbar type="error" message={msg} />,
          ),
        ) : null}
      {merchantAccountState && merchantAccountState.isUpdated
        ? <DisplaySnackbar type="success" message={intl.get('UPDATED_SUCCESSFULLY')} /> : null}
      <div>
        <Dialog
          fullWidth
          maxWidth="xl"
          open={open}
          onClose={closeMerchantAccountForm}
          aria-labelledby="form-dialog-title"
          className={classes.dialogContainer}
          PaperProps={{
            style: {
              backgroundColor: '#2c333d',
              boxShadow: 'none',
              overflow: 'inherit',
            },
          }}
        >
          <Fab onClick={closeMerchantAccountForm} size="small" className={classes.backBtn} aria-label="back">
            <ChevronLeftIcon />
          </Fab>
          <DialogContent>
            <MerchantAccountEdit
              closeMerchantAccountForm={closeMerchantAccountForm}
              selectedMerchantAccount={selectedMerchantAccount}
              merchantUUID={merchantUUID}
              merchantPanel={merchantPanel}
            />
          </DialogContent>
        </Dialog>
      </div>
    </div>
  );
};
