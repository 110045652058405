import { Dispatch } from 'redux';
import { dashboardConstants } from '../constants';
import { dashboardService } from '../../services';
import { merchantDashboardService } from '../../services/Merchant';
import { Dates } from '../../types/common.types';
import {
  DailyTransaction, PaymentMethod, PayoutData, ProviderBalance, WidgetTransactions, WidgetError, MerchantBalances, Balances,
} from '../../types/dashboard.types';

const getWidgetPayouts = (dates: Dates, merchantPanel: boolean) => {
  const request = () => ({ type: dashboardConstants.GET_WIDGET_PAYOUTS_REQUEST });
  const success = (payoutData: PayoutData) => (
    { type: dashboardConstants.GET_WIDGET_PAYOUTS_SUCCESS, payoutData }
  );
  const failure = () => ({ type: dashboardConstants.GET_WIDGET_PAYOUTS_FAILURE });
  return (dispatch: Dispatch) => {
    dispatch(request());
    if (!merchantPanel) {
      dashboardService.getWidgetPayouts(dates)
        .then(
          (res) => {
            dispatch(success(res.data));
          },
        ).catch(() => {
          dispatch(failure());
        });
    } else {
      merchantDashboardService.getWidgetPayouts(dates)
        .then(
          (res) => {
            dispatch(success(res.data));
          },
        ).catch(() => {
          dispatch(failure());
        });
    }
  };
};

const getWidgetProviderBalance = (dates: Dates) => {
  const request = () => ({ type: dashboardConstants.GET_WIDGET_PROVIDER_BALANCE_REQUEST });
  const success = (providerBalanceData: ProviderBalance) => (
    { type: dashboardConstants.GET_WIDGET_PROVIDER_BALANCE_SUCCESS, providerBalanceData }
  );
  const failure = () => ({ type: dashboardConstants.GET_WIDGET_PROVIDER_BALANCE_FAILURE });
  return (dispatch: Dispatch) => {
    dispatch(request());
    dashboardService.getWidgetProviderBalance(dates)
      .then(
        (res) => {
          dispatch(success(res.data));
        },
      ).catch(() => {
        dispatch(failure());
      });
  };
};
const getWidgetPaymentMethods = (dates: Dates) => {
  const request = () => ({ type: dashboardConstants.GET_WIDGET_PAYMENT_METHODS_REQUEST });
  const success = (paymentMethodData: PaymentMethod[]) => (
    { type: dashboardConstants.GET_WIDGET_PAYMENT_METHODS_SUCCESS, paymentMethodData }
  );
  const failure = () => ({ type: dashboardConstants.GET_WIDGET_PAYMENT_METHODS_FAILURE });
  return (dispatch: Dispatch) => {
    dispatch(request());
    merchantDashboardService.getWidgetPaymentMethods(dates)
      .then(
        (res) => {
          dispatch(success(res.data));
        },
      ).catch(() => {
        dispatch(failure());
      });
  };
};

const getWidgetDailyTransactions = (dates: Dates) => {
  const request = () => ({ type: dashboardConstants.GET_WIDGET_DAILY_TRANSACTIONS_REQUEST });
  const success = (dailyTransactionData: DailyTransaction[]) => (
    { type: dashboardConstants.GET_WIDGET_DAILY_TRANSACTIONS_SUCCESS, dailyTransactionData }
  );
  const failure = () => ({ type: dashboardConstants.GET_WIDGET_DAILY_TRANSACTIONS_FAILURE });
  return (dispatch: Dispatch) => {
    dispatch(request());
    merchantDashboardService.getWidgetDailyTransactions(dates)
      .then(
        (res) => {
          dispatch(success(res.data));
        },
      ).catch(() => {
        dispatch(failure());
      });
  };
};

const getWidgetTransactionsCurrent = (dates: Dates, merchantPanel: boolean) => {
  const request = () => ({ type: dashboardConstants.GET_WIDGET_TRANSACTIONS_REQUEST_CURRENT_MONTH });
  const success = (currentMonth: WidgetTransactions) => (
    { type: dashboardConstants.GET_WIDGET_TRANSACTIONS_SUCCESS_CURRENT_MONTH, currentMonth }
  );
  const failure = () => ({ type: dashboardConstants.GET_WIDGET_TRANSACTIONS_FAILURE_CURRENT_MONTH });
  return (dispatch: Dispatch) => {
    dispatch(request());
    if (!merchantPanel) {
      dashboardService.getWidgetTransactions(dates)
        .then(
          (res) => {
            dispatch(success(res.data));
          },
        ).catch(() => {
          dispatch(failure());
        });
    } else {
      merchantDashboardService.getWidgetTransactions(dates)
        .then(
          (res) => {
            dispatch(success(res.data));
          },
        ).catch(() => {
          dispatch(failure());
        });
    }
  };
};
const getWidgetTransactionsprevious = (dates: Dates, merchantPanel: boolean) => {
  const request = () => ({ type: dashboardConstants.GET_WIDGET_TRANSACTIONS_REQUEST_PREVIOUS_MONTH });
  const success = (previousMonth: WidgetTransactions) => (
    { type: dashboardConstants.GET_WIDGET_TRANSACTIONS_SUCCESS_PREVIOUS_MONTH, previousMonth }
  );
  const failure = () => ({ type: dashboardConstants.GET_WIDGET_TRANSACTIONS_FAILURE_PREVIOUS_MONTH });
  return (dispatch: Dispatch) => {
    dispatch(request());
    if (!merchantPanel) {
      dashboardService.getWidgetTransactions(dates)
        .then(
          (res) => {
            dispatch(success(res.data));
          },
        ).catch(() => {
          dispatch(failure());
        });
    } else {
      merchantDashboardService.getWidgetTransactions(dates)
        .then(
          (res) => {
            dispatch(success(res.data));
          },
        ).catch(() => {
          dispatch(failure());
        });
    }
  };
};

const getWidgetErrors = (merchantPanel: boolean) => {
  const request = () => ({ type: dashboardConstants.GET_WIDGET_ERROR_REQUEST });
  const success = (WidgetsError: WidgetError[]) => (
    { type: dashboardConstants.GET_WIDGET_ERROR_SUCCESS, WidgetsError }
  );
  const failure = () => ({ type: dashboardConstants.GET_WIDGET_ERROR_FAILURE });
  return (dispatch: Dispatch) => {
    dispatch(request());
    if (!merchantPanel) {
      dashboardService.getWidgetErrors()
        .then(
          (res) => {
            dispatch(success(res.data));
          },
        ).catch(() => {
          dispatch(failure());
        });
    } else {
      merchantDashboardService.getWidgetErrors()
        .then(
          (res) => {
            dispatch(success(res.data));
          },
        ).catch(() => {
          dispatch(failure());
        });
    }
  };
};
const getWidgetMerchantBalances = (dashboard: boolean, merchantUUID?: string) => {
  const request = () => ({ type: dashboardConstants.GET_WIDGET_BALANCES_REQUEST });
  const success = (WidgetMerchantBalances: MerchantBalances[]) => (
    { type: dashboardConstants.GET_WIDGET_BALANCES_SUCCESS, WidgetMerchantBalances }
  );
  const failure = () => ({ type: dashboardConstants.GET_WIDGET_BALANCES_FAILURE });
  return (dispatch: Dispatch) => {
    dispatch(request());
    if (!dashboard) {
      dashboardService.getMerchantBalances()
        .then(
          (res) => {
            dispatch(success(res.data));
          },
        ).catch(() => {
          dispatch(failure());
        });
    } else {
      dashboardService.getMerchantBalanceSpecific(merchantUUID)
        .then(
          (res) => {
            dispatch(success(res.data.data));
          },
        ).catch(() => {
          dispatch(failure());
        });
    }
  };
};

const getMerchantBalances = () => {
  const request = () => ({ type: dashboardConstants.GET_MERCHANT_BALANCES_REQUEST });
  const success = (MerchantBalance: Balances[]) => (
    { type: dashboardConstants.GET_MERCHANT_BALANCES_SUCCESS, MerchantBalance }
  );
  const failure = () => ({ type: dashboardConstants.GET_MERCHANT_BALANCES_FAILURE });
  return (dispatch: Dispatch) => {
    dispatch(request());
    merchantDashboardService.balances()
      .then(
        (res) => {
          dispatch(success(res.data.data));
        },
      ).catch(() => {
        dispatch(failure());
      });
  };
};

export const dashboardActions = {
  getWidgetPayouts,
  getWidgetProviderBalance,
  getWidgetPaymentMethods,
  getWidgetDailyTransactions,
  getWidgetTransactionsCurrent,
  getWidgetTransactionsprevious,
  getWidgetErrors,
  getWidgetMerchantBalances,
  getMerchantBalances,
};
