import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  makeStyles,
} from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import intl from 'react-intl-universal';
import { RootState } from '../../../store/reducers';
import { dashboardActions } from '../../../store/actions';
import { MerchantBalances } from '../../../types/dashboard.types';

const useStyles = makeStyles({
  root: {
    marginTop: '10px',
  },
  main: {
    marginTop: '15px',
  },
  table: {
    backgroundColor: '#EDEDED',
  },
  tableHead: {
    fontSize: '12px',
    fontWeight: 'bold',
    color: '#0f0e3b',
    borderBottom: '1px solid #707070',
  },
  tableBody: {
    fontSize: '11px',
    color: '#0f0e3b',
    borderBottom: '1px solid #707070',
  },

});

interface Props {
  merchantUUID: string,
  dashboard: boolean,
}

export default ({ merchantUUID, dashboard }: Props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  // const dashboard = false;
  useEffect(() => {
    dispatch(dashboardActions.getWidgetMerchantBalances(dashboard, merchantUUID));
  }, [dispatch, dashboard, merchantUUID]);

  const dashboardReducer = useSelector((state: RootState) => state.dashboardReducer);
  const balances: MerchantBalances[] = dashboardReducer.WidgetMerchantBalances || [];

  return (
    <div className={classes.main}>
      <TableContainer
        component={Paper}
        style={{ display: 'flex' }}
        className={classes.table}
      >
        <Table size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableHead}>{intl.get('MERCHANT')}</TableCell>
              <TableCell align="center" className={classes.tableHead}>{intl.get('CURRENCY')}</TableCell>
              <TableCell align="center" className={classes.tableHead}>{intl.get('AMOUNT')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {balances && balances.length && balances.map((balance) => (
              <TableRow>
                <TableCell component="th" scope="row" className={classes.tableBody}>
                  {balance?.merchant?.name}
                </TableCell>
                <TableCell align="center" className={classes.tableBody}>{balance?.currency}</TableCell>
                <TableCell align="center" className={classes.tableBody}>{balance?.amount}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};
