import { Dispatch } from 'redux';
import { AxiosError } from 'axios';
import { userConstants } from '../constants';
import { userService } from '../../services';
import { ChangePassword, IUser, UserFilter } from '../../modules/AdminPanel/Users/types';
import { Pagination } from '../../types/common.types';

const getUserList = (paginationData: Pagination, filters?: UserFilter) => {
  const request = () => ({ type: userConstants.GET_USER_LIST_REQUEST });
  const success = (allUserList: IUser[], pagination: Pagination) => (
    { type: userConstants.GET_USER_LIST_SUCCESS, allUserList, pagination }
  );
  const failure = (error: any) => ({ type: userConstants.GET_USER_LIST_FAILURE, error });
  return (dispatch: Dispatch) => {
    dispatch(request());
    userService.getUserList(paginationData, filters)
      .then(
        (res) => {
          dispatch(success(res.data.data, res.data));
        },
      ).catch((error) => {
        dispatch(failure(error.message));
      });
  };
};

const addUser = (body: IUser) => {
  const request = () => ({ type: userConstants.ADD_USER_REQUEST });
  const success = (payload: IUser[]) => ({ type: userConstants.ADD_USER_SUCCESS, payload });
  const failure = (errors: any) => ({ type: userConstants.ADD_USER_FAILURE, errors });
  return (dispatch: Dispatch) => {
    dispatch(request());
    userService.addUser(body)
      .then(
        (res) => {
          dispatch(success(res.data));
        },
      ).catch((error: AxiosError) => dispatch(failure(error.response?.data?.errors)));
  };
};

const updateUser = (body: any) => {
  const request = () => ({ type: userConstants.UPDATE_USER_REQUEST });
  const success = (payload: IUser[]) => ({ type: userConstants.UPDATE_USER_SUCCESS, payload });
  const failure = (errors: any) => ({ type: userConstants.UPDATE_USER_FAILURE, errors });
  return (dispatch: Dispatch) => {
    dispatch(request());
    userService.updateUser(body)
      .then(
        (res) => {
          dispatch(success(res.data));
        },
      ).catch((error: AxiosError) => dispatch(failure(error.response?.data?.errors)));
  };
};

const deleteUser = (selectedUserUUID: string) => {
  const request = () => ({ type: userConstants.DELETE_USER_REQUEST });
  const success = (uuid: string) => (
    {
      type: userConstants.DELETE_USER_SUCCESS, uuid,
    }
  );
  const failure = (error: any) => ({ type: userConstants.DELETE_USER_FAILURE, error });
  return (dispatch: Dispatch) => {
    dispatch(request());
    userService.deleteUser(selectedUserUUID)
      .then(
        () => {
          dispatch(success(selectedUserUUID));
        },
      ).catch((error: AxiosError) => dispatch(failure(error.response?.data?.errors || [])));
  };
};

const changePasswordUser = (body: ChangePassword, uuid: string) => {
  const request = () => ({ type: userConstants.CHANGE_PASSWORD_USER_REQUEST });
  const success = (changePassword: ChangePassword[]) => ({ type: userConstants.CHANGE_PASSWORD_USER_SUCCESS, changePassword });
  const failure = (errors: any) => ({ type: userConstants.CHANGE_PASSWORD_USER_FAILURE, errors });
  return (dispatch: Dispatch) => {
    dispatch(request());
    userService.changePasswordUser(body, uuid)
      .then(
        (res) => {
          dispatch(success(res.data));
        },
      ).catch((error: AxiosError) => dispatch(failure(error.response?.data?.errors)));
  };
};

export const userActions = {
  getUserList,
  addUser,
  updateUser,
  deleteUser,
  changePasswordUser,
};
