import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import intl from 'react-intl-universal';
import {
  Button,
  Card,
  CardContent,
  CardActions,
  Divider,
  Grid,
  Typography,
  Box,
} from '@material-ui/core';
import {
  useParams,
} from 'react-router-dom';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { merchantBillingActions } from '../../../store/actions';
import { RootState } from '../../../store/reducers';
import { IMerchantBilling, IMerchantBillingState } from '../../../types/merchant-billing.types';
import { DisplaySnackbar, StyledSectionHeading } from '../../../components';

type RouteParams = {
  billingId: string;
  merchantId: string;
}

const useStyles = makeStyles(() => createStyles({
  root: {
    margin: '10px auto',
  },
}));

export const PayBill: React.FC = (): React.ReactElement => {
  const params = useParams<RouteParams>();
  const [bill, setBill] = useState<IMerchantBilling>(IMerchantBillingState);
  const classes = useStyles();
  const dispatch = useDispatch();
  const merchantPanel = false;

  useEffect(() => {
    dispatch(merchantBillingActions.getMerchantBillingById(params.billingId, merchantPanel));
  }, [dispatch, params, merchantPanel]);

  const merchantBillingReducer = useSelector((state: RootState) => state.merchantBillingReducer);

  useEffect(() => {
    if (merchantBillingReducer.merchantBillingDetails) {
      setBill(merchantBillingReducer.merchantBillingDetails);
    }
  }, [merchantBillingReducer.merchantBillingDetails]);

  useEffect(() => {
    if (merchantBillingReducer.isUpdated) {
      dispatch(merchantBillingActions.getMerchantBillingById(params.billingId, merchantPanel));
    }
  }, [merchantBillingReducer.isUpdated, dispatch, params, merchantPanel]);

  const onSubmit = () => {
    const body = { id: params.billingId, form: { paid: true } };
    dispatch(merchantBillingActions.updateMerchantBillingStatus(body, merchantPanel));
  };

  return (
    <>
      <StyledSectionHeading>{intl.get('PAY_BILL')}</StyledSectionHeading>
      <Card className={classes.root}>
        <CardContent>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              md={6}
              sm={12}
            >
              <Typography
                color="primary"
                variant="h5"
                align="left"
              >
                <Box fontWeight="fontWeightBold">
                  {intl.get('MERCHANT')}
                </Box>
              </Typography>
              <Typography
                color="textSecondary"
                variant="body1"
                align="left"
              >
                {bill?.merchant?.company_name}
              </Typography>
              <Typography
                color="textSecondary"
                variant="body1"
                align="left"
              >
                {bill?.merchant?.vat_number}
              </Typography>
              <Typography
                color="textSecondary"
                variant="body1"
                align="left"
              >
                {bill?.merchant?.company_country}
              </Typography>
            </Grid>

            <Grid
              item
              md={6}
              sm={12}
            >
              <Typography
                color="primary"
                variant="h5"
                align="left"
              >
                <Box fontWeight="fontWeightBold">
                  {intl.get('BANK_ACCOUNT')}
                </Box>
              </Typography>
              <Typography
                color="textSecondary"
                variant="body1"
                align="left"
              >
                {`${intl.get('BANK_NAME')} : ${bill?.account?.bank_name}`}
              </Typography>
              <Typography
                color="textSecondary"
                variant="body1"
                align="left"
              >
                {`${intl.get('NAME_ON_ACCOUNT')} : ${bill?.account?.name_on_account}`}
              </Typography>
              <Typography
                color="textSecondary"
                variant="body1"
                align="left"
              >
                {`${intl.get('IBAN_CODE')} : ${bill?.account?.iban_code}`}
              </Typography>
              <Typography
                color="textSecondary"
                variant="body1"
                align="left"
              >
                {`${intl.get('ACCOUNT_NUMBER')} : ${bill?.account?.account_number}`}
              </Typography>
              <Typography
                color="textSecondary"
                variant="body1"
                align="left"
              >
                {`${intl.get('SWIFT_CODE')} : ${bill?.account?.swift_code}`}
              </Typography>
              <Typography
                color="textSecondary"
                variant="body1"
                align="left"
              >
                {`${intl.get('BIC_CODE')} : ${bill?.account?.bic_code}`}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              md={6}
              sm={12}
            >
              <Typography
                color="primary"
                variant="h5"
                align="left"
              >
                <Box fontWeight="fontWeightBold">
                  {`${intl.get('STATUS')}:${bill?.status}`}
                </Box>
              </Typography>
            </Grid>
            <Grid
              item
              md={6}
              sm={12}
            >
              <Typography
                color="primary"
                variant="h5"
                align="left"
              >
                <Box fontWeight="fontWeightBold">
                  {intl.get('AMOUNT')}
                </Box>
              </Typography>

              <Typography
                color="textSecondary"
                variant="body1"
                align="left"
              >
                {`${intl.get('TRANSACTIONS_TOTAL')} : ${bill?.total}`}
              </Typography>
              <Typography
                color="textSecondary"
                variant="body1"
                align="left"
              >
                {`${intl.get('SERVICE_FEE')} : ${bill?.service_fee}`}
              </Typography>
              <Divider />
              <Typography
                color="textSecondary"
                variant="body1"
                align="left"
              >
                <Box fontWeight="fontWeightBold">
                  {`${intl.get('GRAND_TOTAL')} : ${(bill?.total && (bill.total - bill.service_fee))?.toFixed(2)}`}
                </Box>
              </Typography>
              <Divider />
            </Grid>
          </Grid>
        </CardContent>
        <CardActions>
          <Button
            fullWidth
            variant="text"
            onClick={onSubmit}
            disabled={!!bill.paid_on}
          >
            {bill.paid ? intl.get('PAID') : intl.get('MARK_AS_PAID')}
          </Button>
        </CardActions>
      </Card>
      {merchantBillingReducer && merchantBillingReducer.isUpdated
        ? <DisplaySnackbar type="success" message={intl.get('UPDATED_SUCCESSFULLY')} /> : null}
      {merchantBillingReducer && merchantBillingReducer.updateError
        ? <DisplaySnackbar type="error" message={intl.get('SOMETHING_WENT_WRONG')} /> : null}
    </>
  );
};
