import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { Provider } from 'react-redux';
import App from './App';
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import * as serviceWorker from './serviceWorker';
import { store } from './store/store';


Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [new Integrations.BrowserTracing()],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

/**
 * If redirected from landing page then switch to the selected language of landing page
 */
const urlParams = new URLSearchParams(window.location.search);
const selectedLanguage = urlParams.get('language');
if (selectedLanguage) {
  localStorage.setItem('selectedLocale', selectedLanguage);
}

const resetPasswordToken = urlParams.get('token');
if (resetPasswordToken) {
  localStorage.removeItem('visitedReset');
  localStorage.setItem('resetPasswordToken', resetPasswordToken);
}

const resetPasswordTokenAvailable = localStorage.getItem('reset_password_token') || '{}';
if (resetPasswordTokenAvailable) {
  localStorage.removeItem('reset_password_token');
}

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
