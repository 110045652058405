import { Dispatch } from 'redux';
import { AxiosError } from 'axios';
import { merchantBillingConstants } from '../constants';
import { merchantBillingService } from '../../services';
import { billingService } from '../../services/Merchant';
import {
  AccountOptions, AddPayoutAccount, AddPayoutAccounts, ExportData, IMerchantBilling, PayoutAccountIds, PayoutTransaction,
} from '../../types/merchant-billing.types';
import { PayoutFilter } from '../../types/dashboard.types';

const getMerchantBillingList = (merchantPanel: boolean, filters?: PayoutFilter) => {
  const request = () => ({ type: merchantBillingConstants.GET_MERCHANT_BILLING_LIST_REQUEST });
  const success = (merchantBillingList: IMerchantBilling[]) => (
    { type: merchantBillingConstants.GET_MERCHANT_BILLING_LIST_SUCCESS, merchantBillingList }
  );
  const failure = (error: any) => ({ type: merchantBillingConstants.GET_MERCHANT_BILLING_LIST_FAILURE, error });
  return (dispatch: Dispatch) => {
    dispatch(request());

    if (!merchantPanel) {
      merchantBillingService.getMerchantBillingList(filters)
        .then(
          (res) => {
            dispatch(success(res.data.data));
          },
        ).catch((error: AxiosError) => dispatch(failure(error.response?.data?.errors || [])));
    } else {
      billingService.getMerchantBillingList(filters)
        .then(
          (res) => {
            dispatch(success(res.data.data));
          },
        ).catch((error: AxiosError) => dispatch(failure(error.response?.data?.errors || [])));
    }
  };
};

const getPayoutsExport = (merchantPanel: boolean, filters?: PayoutFilter) => {
  const request = () => ({ type: merchantBillingConstants.GET_PAYOUTS_EXPORT_REQUEST });
  const success = (exports: ExportData) => (
    { type: merchantBillingConstants.GET_PAYOUTS_EXPORT_SUCCESS, exports }
  );
  const failure = (error: any) => ({ type: merchantBillingConstants.GET_PAYOUTS_EXPORT_FAILURE, error });
  return (dispatch: Dispatch) => {
    dispatch(request());

    if (!merchantPanel) {
      merchantBillingService.getPayoutsExport(filters)
        .then(
          (res) => {
            dispatch(success({ filename: res.data.filename }));
          },
        ).catch((error: AxiosError) => dispatch(failure(error.response?.data?.errors || [])));
    } else {
      billingService.getPayoutsExport(filters)
        .then(
          (res) => {
            dispatch(success({ filename: res.data.filename }));
          },
        ).catch((error: AxiosError) => dispatch(failure(error.response?.data?.errors || [])));
    }
  };
};

const resetPayoutsExportState = () => {
  const success = () => (
    { type: merchantBillingConstants.RESET_EXPORT_DATA_STATE }
  );
  return (dispatch: Dispatch) => {
    dispatch(success());
  };
};

const getMerchantBillingById = (uuid: string, merchantPanel: boolean) => {
  const request = () => ({ type: merchantBillingConstants.GET_MERCHANT_BILLING_BY_ID_REQUEST });
  const success = (payload: IMerchantBilling) => (
    { type: merchantBillingConstants.GET_MERCHANT_BILLING_BY_ID_SUCCESS, payload }
  );
  const failure = (error: any) => ({ type: merchantBillingConstants.GET_MERCHANT_BILLING_BY_ID_FAILURE, error });
  return (dispatch: Dispatch) => {
    dispatch(request());
    if (!merchantPanel) {
      merchantBillingService.getMerchantBillingById(uuid)
        .then(
          (res) => {
            dispatch(success(res.data));
          },
        )
        .catch((error: AxiosError) => dispatch(failure(error.response?.data?.errors || [])));
    } else {
      billingService.getMerchantBillingById(uuid)
        .then(
          (res) => {
            dispatch(success(res.data));
          },
        )
        .catch((error: AxiosError) => dispatch(failure(error.response?.data?.errors || [])));
    }
  };
};

const addMerchantBilling = (body: any) => {
  const request = () => ({ type: merchantBillingConstants.ADD_MERCHANT_BILLING_REQUEST });
  const success = (payload: IMerchantBilling[]) => ({ type: merchantBillingConstants.ADD_MERCHANT_BILLING_SUCCESS, payload });
  const failure = (error: any) => ({ type: merchantBillingConstants.ADD_MERCHANT_BILLING_FAILURE, error });
  return (dispatch: Dispatch) => {
    dispatch(request());
    merchantBillingService.addMerchantBilling(body)
      .then(
        (res) => {
          dispatch(success(res.data));
        },
      ).catch((error: AxiosError) => dispatch(failure(error.response?.data?.errors || [])));
  };
};

const updateMerchantBillingStatus = (body: any, merchantPanel: boolean) => {
  const request = () => ({ type: merchantBillingConstants.UPDATE_MERCHANT_BILLING_REQUEST });
  const success = (payload: IMerchantBilling[]) => ({ type: merchantBillingConstants.UPDATE_MERCHANT_BILLING_SUCCESS, payload });
  const failure = (error: any) => ({ type: merchantBillingConstants.UPDATE_MERCHANT_BILLING_FAILURE, error });
  return (dispatch: Dispatch) => {
    dispatch(request());
    if (!merchantPanel) {
      merchantBillingService.updateMerchantBillingStatus(body)
        .then(
          (res) => {
            dispatch(success(res.data));
          },
        ).catch((error: AxiosError) => dispatch(failure(error.response?.data?.errors || [])));
    } else {
      billingService.updateMerchantBillingStatus(body)
        .then(
          (res) => {
            dispatch(success(res.data));
          },
        ).catch((error: AxiosError) => dispatch(failure(error.response?.data?.errors || [])));
    }
  };
};

const getMerchantAccountOptions = (uuid: string, merchantPanel: boolean) => {
  const request = () => ({ type: merchantBillingConstants.GET_MERCHANT_ACCOUNT_OPTIONS_REQUEST });
  const success = (accountOptions: AccountOptions[]) => (
    { type: merchantBillingConstants.GET_MERCHANT_ACCOUNT_OPTIONS_SUCCESS, accountOptions }
  );
  const failure = (error: any) => ({ type: merchantBillingConstants.GET_MERCHANT_ACCOUNT_OPTIONS_FAILURE, error });
  return (dispatch: Dispatch) => {
    dispatch(request());
    if (!merchantPanel) {
      merchantBillingService.getMerchantAccountOptions(uuid)
        .then(
          (res) => {
            dispatch(success(res.data));
          },
        ).catch((error: AxiosError) => dispatch(failure(error.response?.data?.errors || [])));
    } else {
      billingService.getMerchantAccountOptions()
        .then(
          (res) => {
            dispatch(success(res.data));
          },
        ).catch((error: AxiosError) => dispatch(failure(error.response?.data?.errors || [])));
    }
  };
};

const getPayoutTransactionList = (uuid: string, merchantPanel: boolean) => {
  const request = () => ({ type: merchantBillingConstants.GET_PAYOUT_TRANSACTION_LIST_REQUEST });
  const success = (payoutTransactions: PayoutTransaction[]) => (
    { type: merchantBillingConstants.GET_PAYOUT_TRANSACTION_LIST_SUCCESS, payoutTransactions }
  );
  const failure = (error: any) => ({ type: merchantBillingConstants.GET_PAYOUT_TRANSACTION_LIST_FAILURE, error });
  return (dispatch: Dispatch) => {
    dispatch(request());
    if (!merchantPanel) {
      merchantBillingService.getPayoutTransactionList(uuid)
        .then(
          (res) => {
            dispatch(success(res.data.data));
          },
        ).catch((error: AxiosError) => dispatch(failure(error.response?.data?.errors || [])));
    } else {
      billingService.getPayoutTransactionList(uuid)
        .then(
          (res) => {
            dispatch(success(res.data.data));
          },
        ).catch((error: AxiosError) => dispatch(failure(error.response?.data?.errors || [])));
    }
  };
};

const addPayoutAccount = (body: AddPayoutAccount, merchantPanel: boolean) => {
  const request = () => ({ type: merchantBillingConstants.ADD_PAYOUT_ACCOUNT_REQUEST });
  const success = () => (
    { type: merchantBillingConstants.ADD_PAYOUT_ACCOUNT_SUCCESS }
  );
  const failure = (error: any) => ({ type: merchantBillingConstants.ADD_PAYOUT_ACCOUNT_FAILURE, error });
  return (dispatch: Dispatch) => {
    dispatch(request());
    if (!merchantPanel) {
      merchantBillingService.addPayoutAccount(body)
        .then(
          () => {
            dispatch(success());
          },
        ).catch((error) => {
          dispatch(failure(error.message));
        });
    } else {
      billingService.addPayoutAccount(body)
        .then(
          () => {
            dispatch(success());
          },
        ).catch((error) => {
          dispatch(failure(error.message));
        });
    }
  };
};

const deletePayoutAccount = (payload: PayoutAccountIds, merchantPanel: boolean) => {
  const request = () => ({ type: merchantBillingConstants.DELETE_PAYOUT_ACCOUNT_REQUEST });
  const success = (uuid: string | undefined) => (
    { type: merchantBillingConstants.DELETE_PAYOUT_ACCOUNT_SUCCESS, uuid }
  );
  const failure = (error: any) => ({ type: merchantBillingConstants.DELETE_PAYOUT_ACCOUNT_FAILURE, error });
  return (dispatch: Dispatch) => {
    dispatch(request());
    if (!merchantPanel) {
      merchantBillingService.deletePayoutAccount(payload)
        .then(
          () => {
            dispatch(success(payload.payoutAccountUUID));
          },
        ).catch((error: AxiosError) => dispatch(failure(error.response?.data?.errors || [])));
    } else {
      billingService.deletePayoutAccount(payload)
        .then(
          () => {
            dispatch(success(payload.payoutAccountUUID));
          },
        ).catch((error: AxiosError) => dispatch(failure(error.response?.data?.errors || [])));
    }
  };
};
const addPayoutAccounts = (body: AddPayoutAccounts) => {
  const request = () => ({ type: merchantBillingConstants.ADD_PAYOUTS_ACCOUNT_REQUEST });
  const success = (payload: IMerchantBilling) => (
    { type: merchantBillingConstants.ADD_PAYOUTS_ACCOUNT_SUCCESS, payload }
  );
  const failure = (errors: any) => ({ type: merchantBillingConstants.ADD_PAYOUTS_ACCOUNT_FAILURE, errors });
  return (dispatch: Dispatch) => {
    dispatch(request());
    billingService.addPayoutAccounts(body)
      .then(
        (res) => {
          dispatch(success(res.data));
        },
      ).catch((error) => {
        dispatch(failure(error.response.data));
      });
  };
};

export const merchantBillingActions = {
  getMerchantBillingList,
  getMerchantBillingById,
  addMerchantBilling,
  updateMerchantBillingStatus,
  getMerchantAccountOptions,
  getPayoutTransactionList,
  addPayoutAccount,
  deletePayoutAccount,
  getPayoutsExport,
  resetPayoutsExportState,
  addPayoutAccounts,
};
