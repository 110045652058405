import { UserActionTypes, userConstants } from '../constants';
import { IUser } from '../../modules/AdminPanel/Users/types';
import { Pagination } from '../../types/common.types';

export interface IState {
  loading: boolean;
  isError: boolean;
  allUserList: IUser[];
  updateError: boolean;
  isUpdated: boolean;
  isDeleted: boolean;
  errorMessages: string[];
  pagination: Pagination;
  changePassword: boolean;
}

const initialState: IState = {
  loading: false,
  isError: false,
  allUserList: [],
  updateError: false,
  isUpdated: false,
  isDeleted: false,
  errorMessages: [],
  pagination: { limit: 10, current_page: 1 },
  changePassword: false,
};

export const userReducer = (state = initialState, action: UserActionTypes): IState => {
  switch (action.type) {
    case userConstants.GET_USER_LIST_REQUEST:
      return {
        ...state, loading: true, isError: false, errorMessages: [], changePassword: false,
      };
    case userConstants.GET_USER_LIST_SUCCESS:
      return {
        ...state, loading: false, isError: false, allUserList: action.allUserList, pagination: action.pagination, changePassword: false,
      };
    case userConstants.GET_USER_LIST_FAILURE:
      return {
        ...state, loading: false, isError: true, changePassword: false,
      };

    case userConstants.ADD_USER_REQUEST:
      return {
        ...state, loading: true, updateError: false, isUpdated: false, errorMessages: [], changePassword: false,
      };
    case userConstants.ADD_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        updateError: false,
        isUpdated: true,
        allUserList: [...state.allUserList, action.payload],
        changePassword: false,
      };
    case userConstants.ADD_USER_FAILURE:
      return {
        ...state, loading: false, updateError: true, errorMessages: action.errors, changePassword: false,
      };

    case userConstants.UPDATE_USER_REQUEST:
      return {
        ...state, loading: true, updateError: false, isUpdated: false, errorMessages: [], changePassword: false,
      };
    case userConstants.UPDATE_USER_SUCCESS: {
      const userIndex = state.allUserList.findIndex((user) => user.uuid === action.payload.uuid);
      const updatedList = [...state.allUserList];
      updatedList[userIndex] = action.payload;
      return {
        ...state,
        loading: false,
        updateError: false,
        isUpdated: true,
        allUserList: updatedList,
        changePassword: false,
      };
    }
    case userConstants.UPDATE_USER_FAILURE:
      return {
        ...state, loading: false, updateError: true, errorMessages: action.errors, changePassword: false,
      };

    case userConstants.DELETE_USER_REQUEST:
      return {
        ...state, loading: true, isError: false, isDeleted: false, errorMessages: [], changePassword: false,
      };
    case userConstants.DELETE_USER_SUCCESS:
      return {
        ...state, loading: false, isError: false, isDeleted: true, allUserList: state.allUserList.filter((item) => item.uuid !== action.uuid), changePassword: false,
      };
    case userConstants.DELETE_USER_FAILURE:
      return {
        ...state, loading: false, isError: true, changePassword: false,
      };

    case userConstants.CHANGE_PASSWORD_USER_REQUEST:
      return {
        ...state, loading: true, isError: false, changePassword: false,
      };
    case userConstants.CHANGE_PASSWORD_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        isError: false,
        changePassword: true,
      };
    case userConstants.CHANGE_PASSWORD_USER_FAILURE:
      return {
        ...state, loading: false, isError: true, changePassword: false, errorMessages: action.errors,
      };

    default:
      return state;
  }
};
