import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import { RootState } from '../../../store/reducers';
import {
  Toolbar, LeftSection, TopSection,
} from './index';
import { utils } from '../../../helpers';
import { PayoutData } from '../../../types/dashboard.types';
import { dashboardActions } from '../../../store/actions';
import { Dates } from '../../../types/common.types';

export const Dashboard = () => {
  const dispatch = useDispatch();
  const dashboardReducer = useSelector((state: RootState) => state.dashboardReducer);
  const payoutData: PayoutData = dashboardReducer.payoutData || {};
  // const providerBalanceData: ProviderBalance[] = dashboardReducer.providerBalanceData || [];
  const [selectedDates, setSelectedDates] = React.useState<Dates>({ from: '', to: '' });
  const [prevselectDate, setPrevSelectDate] = React.useState<Dates>({ from: '', to: '' });

  const updateSelectedDates = (value: Dates) => {
    setSelectedDates(value);
  };

  const merchantPanel = utils.userType() === 'merchant';
  useEffect(() => {
    let currentDate: Dates;
    if (!selectedDates.from) {
      const year = new Date().getFullYear();
      const monthIndex = new Date().getMonth();
      const lastDay = (new Date(year, monthIndex + 1, 0)).getDate();
      currentDate = {
        from: (`${year}-${(monthIndex + 1).toString().length === 1 ? `0${monthIndex + 1}` : (monthIndex + 1)}-01`),
        to: (`${year}-${(monthIndex + 1).toString().length === 1 ? `0${monthIndex + 1}` : (monthIndex + 1)}-${lastDay}`),
      };
      dispatch(dashboardActions.getWidgetPayouts(currentDate, merchantPanel));
      dispatch(dashboardActions.getWidgetProviderBalance(currentDate));
    } else {
      dispatch(dashboardActions.getWidgetPayouts(selectedDates, merchantPanel));
      dispatch(dashboardActions.getWidgetProviderBalance(selectedDates));
    }
  }, [dispatch, selectedDates, merchantPanel]);
  return (
    <>
      <Toolbar />
      <TopSection
        getSelectedDates={(event: Dates) => updateSelectedDates(event)}
        SelectedPrevDates={(event: Dates) => setPrevSelectDate(event)}
      />
      <Grid
        container
        spacing={4}
      >
        <Grid
          item
          md={12}
          xs={12}
        >
          <LeftSection payoutData={payoutData} selectedDates={selectedDates} prevselectDate={prevselectDate} />
        </Grid>
      </Grid>

    </>
  );
};
