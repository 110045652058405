import React from 'react';
import TransactionReports from '../../AdminPanel/SettlementReports/TransactionReports';
import { utils } from '../../../helpers/utils';

export const MerchantSettlementReport = () => {
  const isMerchantPanel = utils.userType() === 'merchant';
  return (
    <>
      <TransactionReports merchantPanel={isMerchantPanel} />
    </>
  );
};
