import { API } from '../config';
import { TransactionFilter } from '../types/admin-transaction.types';
import { Pagination } from '../types/common.types';

const getAdminTransactionList = (paginationData: Pagination, filters?: TransactionFilter) => {
  let queryParams = '';
  if (filters?.merchantUUID) {
    queryParams += `&merchant_uuid=${filters.merchantUUID}`;
  }
  if (filters?.selectedDates?.from && filters?.selectedDates?.to) {
    queryParams += `&from=${filters?.selectedDates.from}&to=${filters?.selectedDates.to}`;
  }
  if (filters?.provider) {
    queryParams += `&psp_id=${filters.provider}`;
  }
  if (filters?.payment_method) {
    queryParams += `&payment_method=${filters.payment_method}`;
  }
  if (filters?.currency) {
    queryParams += `&currency=${filters.currency}`;
  }
  if (filters?.order_no) {
    queryParams += `&order_no=${filters.order_no}`;
  }
  if (filters?.transaction_no) {
    queryParams += `&transaction_no=${filters.transaction_no}`;
  }
  if (filters?.succesfull) {
    queryParams += `&succesfull=${filters.succesfull}`;
  }
  if (filters?.search) {
    queryParams += `&search=${filters.search}`;
  }
  return API.get(`/admin/transactions?with=merchant&limit=${paginationData.limit}&page=${paginationData.current_page}${queryParams}`);
};

const getTransactionExport = (filters?: TransactionFilter) => {
  let queryParams = '?';
  if (filters?.selectedDates?.from && filters?.selectedDates?.to) {
    queryParams += `from=${filters?.selectedDates.from}&to=${filters?.selectedDates.to}`;
    if (filters?.merchantUUID) {
      queryParams += `&merchant_uuid=${filters.merchantUUID}`;
    }
    if (filters?.provider) {
      queryParams += `&psp_id=${filters.provider}`;
    }
    if (filters?.payment_method) {
      queryParams += `&payment_method=${filters.payment_method}`;
    }
    if (filters?.currency) {
      queryParams += `&currency=${filters.currency}`;
    }
  }
  return API
    .get(`/admin/exports/transactions${queryParams}`);
};

const getAdminTransactionById = (uuid: string) => API.get(`/admin/transactions/${uuid}`);

const getPaymentProviderOptions = () => API.get('/admin/options/payment-providers');

const getPaymentMethodOptions = () => API.get('/admin/options/payment-methods');

export const adminTransactionService = {
  getAdminTransactionList,
  getAdminTransactionById,
  getPaymentProviderOptions,
  getPaymentMethodOptions,
  getTransactionExport,
};
