import React from 'react';
import intl from 'react-intl-universal';
import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  makeStyles,
  TablePagination,
  ThemeProvider,
  createMuiTheme,
  LinearProgress,
} from '@material-ui/core';
import {
  Link,
  useRouteMatch,
} from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import { IMerchantBilling } from '../../../types/merchant-billing.types';
import { tableTheme } from '../../../assets/styles/table-style';
import { StyledTableHeadCell } from '../../../components';
import Badges from '../../../components/Badges';

interface IMerchantBillingTypes {
  merchantBillings: IMerchantBilling[],
  merchantPanel: boolean;
  loading: boolean;
}

const paginationTheme = createMuiTheme({
  overrides: {
    MuiTablePagination: {
      select: {
        paddingLeft: '8px !important',
        paddingRight: '24px !important',
      },
    },
  },
});

const useStyles = makeStyles({
  root: {
    marginTop: '10px',
  },
  table: {
    minWidth: 650,
  },
  tableBodyCell: {
    fontSize: '12px',
  },
  colorPrimary: {
    backgroundColor: '#3981f7',
  },
  barColorPrimary: {
    backgroundColor: '#3981f7',
  },
});

export default ({ merchantBillings, merchantPanel, loading }: IMerchantBillingTypes) => {
  const classes = useStyles();
  const { path } = useRouteMatch();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Card className={classes.root}>
      <TableContainer component={Paper}>
        <ThemeProvider theme={tableTheme}>
          {loading ? <LinearProgress classes={{ colorPrimary: classes.colorPrimary, barColorPrimary: classes.barColorPrimary }} /> : null}
          <Table className={classes.table} aria-label="billing list">
            <TableHead>
              <TableRow>
                <TableCell><StyledTableHeadCell>{intl.get('DATE')}</StyledTableHeadCell></TableCell>
                {!merchantPanel ? <TableCell><StyledTableHeadCell>{intl.get('MERCHANT')}</StyledTableHeadCell></TableCell> : null}
                <TableCell><StyledTableHeadCell>{intl.get('TRANSACTION_AMOUNT')}</StyledTableHeadCell></TableCell>
                <TableCell><StyledTableHeadCell>{intl.get('SERVICE_FEE')}</StyledTableHeadCell></TableCell>
                <TableCell><StyledTableHeadCell>{intl.get('FINAL_TOTAL')}</StyledTableHeadCell></TableCell>
                <TableCell><StyledTableHeadCell>{intl.get('CURRENCY')}</StyledTableHeadCell></TableCell>
                <TableCell><StyledTableHeadCell>{intl.get('STATUS')}</StyledTableHeadCell></TableCell>
                <TableCell><StyledTableHeadCell>{intl.get('PAID_ON')}</StyledTableHeadCell></TableCell>

                <TableCell><StyledTableHeadCell>{intl.get('ACTIONS') || 'Actions'}</StyledTableHeadCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {merchantBillings
                && merchantBillings.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row: IMerchantBilling) => (
                  <TableRow key={row.uuid}>
                    <TableCell className={classes.tableBodyCell}>
                      {row.billed_on ? new Date(row.billed_on).toLocaleDateString() : 'N/A'}
                    </TableCell>
                    {!merchantPanel
                      ? (
                        <>
                          <TableCell className={classes.tableBodyCell}>{row?.merchant?.company_name}</TableCell>
                          <TableCell className={classes.tableBodyCell}>{row.base_total}</TableCell>
                        </>
                      ) : <TableCell className={classes.tableBodyCell}>{row.transaction_total}</TableCell>}
                    <TableCell className={classes.tableBodyCell}>{row.base_service_fee}</TableCell>
                    <TableCell className={classes.tableBodyCell}>{row.base_final_total}</TableCell>
                    <TableCell className={classes.tableBodyCell}>{row.currency}</TableCell>
                    <TableCell className={classes.tableBodyCell}><Badges status={row?.status} /></TableCell>
                    <TableCell className={classes.tableBodyCell}>{row.paid_on ? new Date(row.paid_on).toLocaleDateString() : 'N/A'}</TableCell>

                    <TableCell className={classes.tableBodyCell} style={{ display: 'flex', justifyContent: 'space-around' }}>
                      {!merchantPanel
                        ? <Link to={`${path}/${row.merchant && row.merchant.uuid}/${row.uuid}`}>{intl.get('DETAILS')}</Link>
                        : <Link to={`${path}/${row.uuid}`}>{intl.get('DETAILS')}</Link>}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </ThemeProvider>
      </TableContainer>
      {merchantBillings && merchantBillings.length > 9
        ? (
          <ThemeProvider theme={paginationTheme}>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={merchantBillings.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </ThemeProvider>
        )
        : null}
    </Card>
  );
};
