import React from 'react';

const CommonDate = ({ month, year }: any) => {
  const date = new Date();
  const day = date.getDate();
  const todayDate = `${day} / ${month} / ${year}`;
  return (
    <span>
      {todayDate}
    </span>
  );
};

export default CommonDate;
