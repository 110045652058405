import {
  DailyTransaction,
  PaymentMethod, PayoutData, PayoutDataState, ProviderBalance, WidgetTransactions, TransactionDataState, WidgetError, MerchantBalances, Balances,
} from '../../types/dashboard.types';
import { DashboardActionTypes, dashboardConstants } from '../constants';

export interface IState {
  loading: boolean;
  payoutData: PayoutData;
  providerBalanceData: ProviderBalance[];
  paymentMethodData: PaymentMethod[];
  dailyTransactionData: DailyTransaction[];
  WidgetsError: WidgetError[];
  currentMonth: WidgetTransactions;
  previousMonth: WidgetTransactions;
  WidgetMerchantBalances: MerchantBalances[];
  MerchantBalance: Balances[];
}

const initialState: IState = {
  loading: false,
  payoutData: PayoutDataState,
  providerBalanceData: [],
  paymentMethodData: [],
  dailyTransactionData: [],
  currentMonth: TransactionDataState,
  previousMonth: TransactionDataState,
  WidgetsError: [],
  WidgetMerchantBalances: [],
  MerchantBalance: [],
};

export const dashboardReducer = (state = initialState, action: DashboardActionTypes): IState => {
  switch (action.type) {
    case dashboardConstants.GET_WIDGET_PAYOUTS_REQUEST:
      return {
        ...state, loading: true,
      };
    case dashboardConstants.GET_WIDGET_PAYOUTS_SUCCESS:
      return {
        ...state, loading: false, payoutData: action.payoutData,
      };
    case dashboardConstants.GET_WIDGET_PAYOUTS_FAILURE:
      return {
        ...state, loading: false,
      };

    case dashboardConstants.GET_WIDGET_PROVIDER_BALANCE_REQUEST:
      return {
        ...state, loading: true,
      };
    case dashboardConstants.GET_WIDGET_PROVIDER_BALANCE_SUCCESS:
      return {
        ...state, loading: false, providerBalanceData: action.providerBalanceData,
      };
    case dashboardConstants.GET_WIDGET_PROVIDER_BALANCE_FAILURE:
      return {
        ...state, loading: false,
      };

    case dashboardConstants.GET_WIDGET_PAYMENT_METHODS_REQUEST:
      return {
        ...state, loading: true,
      };
    case dashboardConstants.GET_WIDGET_PAYMENT_METHODS_SUCCESS:
      return {
        ...state, loading: false, paymentMethodData: action.paymentMethodData,
      };
    case dashboardConstants.GET_WIDGET_PAYMENT_METHODS_FAILURE:
      return {
        ...state, loading: false,
      };

    case dashboardConstants.GET_WIDGET_DAILY_TRANSACTIONS_REQUEST:
      return {
        ...state, loading: true,
      };
    case dashboardConstants.GET_WIDGET_DAILY_TRANSACTIONS_SUCCESS:
      return {
        ...state, loading: false, dailyTransactionData: action.dailyTransactionData,
      };
    case dashboardConstants.GET_WIDGET_DAILY_TRANSACTIONS_FAILURE:
      return {
        ...state, loading: false,
      };

    case dashboardConstants.GET_WIDGET_TRANSACTIONS_REQUEST_CURRENT_MONTH:
      return {
        ...state, loading: true,
      };
    case dashboardConstants.GET_WIDGET_TRANSACTIONS_SUCCESS_CURRENT_MONTH:
      return {
        ...state, loading: false, currentMonth: action.currentMonth,
      };
    case dashboardConstants.GET_WIDGET_TRANSACTIONS_FAILURE_CURRENT_MONTH:
      return {
        ...state, loading: false,
      };
    case dashboardConstants.GET_WIDGET_TRANSACTIONS_REQUEST_PREVIOUS_MONTH:
      return {
        ...state, loading: true,
      };
    case dashboardConstants.GET_WIDGET_TRANSACTIONS_SUCCESS_PREVIOUS_MONTH:
      return {
        ...state, loading: false, previousMonth: action.previousMonth,
      };
    case dashboardConstants.GET_WIDGET_TRANSACTIONS_FAILURE_PREVIOUS_MONTH:
      return {
        ...state, loading: false,
      };

    case dashboardConstants.GET_WIDGET_ERROR_REQUEST:
      return {
        ...state, loading: true,
      };
    case dashboardConstants.GET_WIDGET_ERROR_SUCCESS:
      return {
        ...state, loading: false, WidgetsError: action.WidgetsError,
      };
    case dashboardConstants.GET_WIDGET_ERROR_FAILURE:
      return {
        ...state, loading: false,
      };

    case dashboardConstants.GET_WIDGET_BALANCES_REQUEST:
      return {
        ...state, loading: true,
      };
    case dashboardConstants.GET_WIDGET_BALANCES_SUCCESS:
      return {
        ...state, loading: false, WidgetMerchantBalances: action.WidgetMerchantBalances,
      };
    case dashboardConstants.GET_WIDGET_BALANCES_FAILURE:
      return {
        ...state, loading: false,
      };

    case dashboardConstants.GET_MERCHANT_BALANCES_REQUEST:
      return {
        ...state, loading: true,
      };
    case dashboardConstants.GET_MERCHANT_BALANCES_SUCCESS:
      return {
        ...state, loading: false, MerchantBalance: action.MerchantBalance,
      };
    case dashboardConstants.GET_MERCHANT_BALANCES_FAILURE:
      return {
        ...state, loading: false,
      };

    default:
      return state;
  }
};
