import React, { useEffect } from 'react';
import intl from 'react-intl-universal';
import { useSelector, useDispatch } from 'react-redux';
import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  makeStyles,
  TablePagination,
  IconButton,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import Paper from '@material-ui/core/Paper';
import { KYC } from '../../../types/file.types';
import { ApproveKyc } from '../../AdminPanel/Merchants';
import { RootState } from '../../../store/reducers';
import { DisplaySnackbar } from '../../../components';
import { KYCActions } from '../../../store/actions/Merchant';

interface IFiles {
  files: KYC[],
  merchantPanel: boolean;
  merchantUUID: string;
}

const useStyles = makeStyles({
  root: {
    marginTop: '10px',
  },
  table: {
    minWidth: 650,
  },
});

export const Files = ({ files, merchantPanel, merchantUUID }: IFiles) => {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [selectedKyc, setSelectedKyc] = React.useState(null);
  const [showApproveKyc, setShowApproveKyc] = React.useState(false);
  const dispatch = useDispatch();

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const onDelete = (uuid: any) => {
    dispatch(KYCActions.deleteKyc({ merchantUUID, merchantKycUUID: uuid }));
  };

  const openApprove = (kyc: any) => {
    setSelectedKyc(kyc);
    setShowApproveKyc(true);
  };

  const closeApproveDialog = () => {
    setSelectedKyc(null);
    setShowApproveKyc(false);
  };

  const KYCReducer = useSelector((state: RootState) => state.KYCReducer);

  useEffect(() => {
    if (KYCReducer.isUpdated) {
      setShowApproveKyc(false);
    }
  }, [KYCReducer.isUpdated]);

  const viewKyc = (uuid: any) => {
    dispatch(KYCActions.getKycById(merchantPanel, { merchantUUID, merchantKycUUID: uuid }));
  };

  useEffect(() => {
    if (KYCReducer?.kyc?.url) {
      window.open(
        KYCReducer.kyc.url,
        '_blank',
      );
      dispatch(KYCActions.resetState());
    }
  }, [KYCReducer, dispatch]);

  return (
    <>
      <Card className={classes.root}>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="billing list">
            <TableHead>
              <TableRow>
                <TableCell align="left">{intl.get('TITLE')}</TableCell>
                <TableCell align="center">{intl.get('STATUS')}</TableCell>
                {/* <TableCell align="center">{intl.get('TYPE')}</TableCell> */}
                <TableCell align="center">{intl.get('CREATED_AT')}</TableCell>

                <TableCell align="center">{intl.get('ACTIONS')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {files
                && files.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row: KYC, i: number) => (
                  <TableRow key={row.uuid} style={i % 2 ? { background: '#ffffff' } : { background: '#e8ebed' }}>
                    <TableCell>
                      {i + 1}
                    </TableCell>
                    <TableCell align="left">{row.title}</TableCell>
                    <TableCell align="center">{row.status}</TableCell>
                    {/* <TableCell align="center">{row.extension}</TableCell> */}
                    <TableCell align="center">{row?.created_at ? new Date(row.created_at).toLocaleDateString() : 'N/A'}</TableCell>

                    <TableCell align="center" style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
                      <span onClick={() => viewKyc(row.uuid)} style={{ cursor: 'pointer' }}>View</span>
                      {!merchantPanel
                        ? (
                          <>
                            {!row?.approved
                              ? <span onClick={() => openApprove(row)} style={{ color: 'blue', cursor: 'pointer' }}>Approve</span>
                              : 'Approved'}
                            <IconButton onClick={() => onDelete(row.uuid)} aria-label="delete">
                              <DeleteIcon />
                            </IconButton>
                          </>
                        )
                        : null}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        {files && files.length > 9
          ? (
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={files.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          )
          : null}
      </Card>
      <ApproveKyc openDialog={showApproveKyc} kyc={selectedKyc} merchantUUID={merchantUUID} closeApproveDialog={closeApproveDialog} />
      <div>
        {KYCReducer && KYCReducer.isError
          ? <DisplaySnackbar type="error" message={intl.get('SOMETHING_WENT_WRONG')} /> : null}
        {KYCReducer && KYCReducer.isUpdated
          ? <DisplaySnackbar type="success" message={intl.get('UPDATED_SUCCESSFULLY')} /> : null}
        {KYCReducer && KYCReducer.isDeleted
          ? <DisplaySnackbar type="success" message={intl.get('DELETED_SUCCESSFULLY')} /> : null}
      </div>
    </>
  );
};
