import React, { useEffect } from 'react';
import intl from 'react-intl-universal';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import {
  Grid,
  Box,
  IconButton,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store/reducers';
import { KYCActions } from '../../../store/actions/Merchant';
import { Files } from './index';
import { StyledSectionHeading, StyledDividerLine } from '../../../components';

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    marginTop: theme.spacing(3),
  },
  inputStyle: {
    margin: theme.spacing(1),
    width: '30ch',
  },
  button: {
    margin: theme.spacing(1),
  },
  cardStyle: {
    marginTop: '20px',
  },
  iconStyle: {
    fill: '#3485f7',
  },
}));

// interface HTMLInputType extends Event {
//   target: HTMLInputElement & EventTarget;
// }

export const KYCMerchant = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  // const [selectedFile, setSelectedFile] = useState<string | Blob>('');
  const merchantPanel = true;
  const fileRef: React.RefObject<HTMLInputElement> = React.createRef();

  useEffect(() => {
    dispatch(KYCActions.getFiles(merchantPanel, ''));
  }, [dispatch, merchantPanel]);

  const KYCReducer = useSelector((state: RootState) => state.KYCReducer);

  const toBase64 = (file: any) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

  const onFileUpload = async (file: any, fileName: string) => {
    const fileBase64 = await toBase64(file);
    const data = {
      title: fileName,
      file: fileBase64,
    };
    dispatch(KYCActions.fileUpload(data));
  };

  const onFileChange = (event: any) => {
    // const target = event.target as HTMLInputElement;
    // const { files } = target;
    const file: File = event && event.target && event?.target?.files[0];
    // setSelectedFile(file);
    onFileUpload(file, file.name);
  };

  useEffect(() => {
    if (KYCReducer.isUpdated) {
      // setSelectedFile('');
      dispatch(KYCActions.getFiles(merchantPanel, ''));
    }
  }, [KYCReducer, dispatch, merchantPanel]);

  return (
    <div className={classes.root}>
      <input hidden type="file" onChange={onFileChange} accept=".jpg, .jpeg, .png, .pdf" ref={fileRef} />
      <Grid container wrap="wrap" justify="space-between">
        <StyledSectionHeading>KYC</StyledSectionHeading>
        <Box display="flex" flexDirection="row" alignItems="center" justifyContent="flex-end">
          <Box mr={1} display="flex" flexDirection="row" alignItems="center">
            <IconButton onClick={() => fileRef && fileRef.current && fileRef.current.click()}>
              <AddIcon className={classes.iconStyle} />
            </IconButton>
            <span>
              {intl.get('ADD')}
            </span>
          </Box>
          {/* <Box mr={1} display="flex" flexDirection="row" alignItems="center">
            <IconButton>
              <FilterListIcon className={classes.iconStyle} />
            </IconButton>
            <span>
              {intl.get('FILTER')}
            </span>
          </Box>
          <SearchInput width="130px"  /> */}
        </Box>
      </Grid>
      <StyledDividerLine />
      <Files files={KYCReducer?.files} merchantPanel={merchantPanel} merchantUUID="" />
    </div>
  );
};
