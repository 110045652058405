import React from 'react';
import intl from 'react-intl-universal';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  formControl: {
    minWidth: 250,
    width: '100%',
  },
}));

export const RefundReasonDropDown = ({
  onChange, defaultValue, i18nTag, variant,
}: any) => {
  const [selectedValue, setSelectedValue] = React.useState(defaultValue);
  const classes = useStyles();

  return (
    <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <FormControl className={classes.formControl}>
        <InputLabel style={{ paddingLeft: '20px' }}>{intl.get(i18nTag)}</InputLabel>
        <Select
          variant={variant}
          onChange={(e) => { onChange(e.target.value); setSelectedValue(e.target.value); }}
          value={selectedValue}
          required
        >
          <MenuItem value={1}>Out of stock</MenuItem>
          <MenuItem value={2}>Quality issue</MenuItem>
          <MenuItem value={3}>Goods returned</MenuItem>
          <MenuItem value={4}>Customer cancel order</MenuItem>
          <MenuItem value={5}>Discount</MenuItem>
          <MenuItem value={6}>Duplicated payment</MenuItem>
          <MenuItem value={7}>Suspicious order</MenuItem>
          <MenuItem value={10}>Other</MenuItem>

        </Select>
      </FormControl>
    </Box>
  );
};
