import React from 'react';
import Typography from '@material-ui/core/Typography';
// import Link from '@material-ui/core/Link';
import intl from 'react-intl-universal';

export const Copyright = () => (
  <Typography variant="body2" color="textSecondary" align="center">
    <span style={{ color: '#fff' }}>

      {intl.get('COPYRIGHT')}
      {' '}
      © VANILLA FX
      {/* <Link color="inherit" href="https://atlanticpartnersasia.com/">
        vanillafx
      </Link> */}
      {' '}
      {new Date().getFullYear()}
      .
    </span>
  </Typography>
);
