import { createMuiTheme } from '@material-ui/core/styles';

export const customDatePicker = createMuiTheme({
  overrides: {
    MuiInputBase: {
      input: {
        width: '84px',
        padding: '5px !important',
        color: 'rgba(44, 51, 61, 0.6)',
      },
    },
  },
});
