import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import { Toolbar, TopSection } from '../../AdminPanel/Dashboard';
import { LeftSection } from './index';
import { Dates } from '../../../types/common.types';
import { dashboardActions } from '../../../store/actions';
import { RootState } from '../../../store/reducers';
import {
  DailyTransaction, PayoutData,
} from '../../../types/dashboard.types';
import { utils } from '../../../helpers';

export const MerchantDashboard = () => {
  const [selectedDates, setSelectedDates] = React.useState<Dates>({ from: '', to: '' });
  const [prevselectDate, setPrevSelectDate] = React.useState<Dates>({ from: '', to: '' });
  console.log(prevselectDate);
  const updateSelectedDates = (value: Dates) => {
    setSelectedDates(value);
  };

  const merchantPanel = utils.userType() === 'merchant';

  const dispatch = useDispatch();
  useEffect(() => {
    let currentDate: Dates;
    if (!selectedDates.from) {
      const year = new Date().getFullYear();
      const monthIndex = new Date().getMonth();
      const lastDay = (new Date(year, monthIndex + 1, 0)).getDate();
      currentDate = {
        from: (`${year}-${(monthIndex + 1).toString().length === 1 ? `0${monthIndex + 1}` : (monthIndex + 1)}-01`),
        to: (`${year}-${(monthIndex + 1).toString().length === 1 ? `0${monthIndex + 1}` : (monthIndex + 1)}-${lastDay}`),
      };
      dispatch(dashboardActions.getWidgetPayouts(currentDate, merchantPanel));
      if (merchantPanel) {
        dispatch(dashboardActions.getWidgetPaymentMethods(currentDate));
        dispatch(dashboardActions.getWidgetDailyTransactions(currentDate));
      }
    } else {
      dispatch(dashboardActions.getWidgetPayouts(selectedDates, merchantPanel));
      if (merchantPanel) {
        dispatch(dashboardActions.getWidgetPaymentMethods(selectedDates));
        dispatch(dashboardActions.getWidgetDailyTransactions(selectedDates));
      }
    }
  }, [dispatch, selectedDates, merchantPanel]);

  useEffect(() => {
    dispatch(dashboardActions.getWidgetErrors(merchantPanel));
  }, [dispatch, merchantPanel]);

  const dashboardReducer = useSelector((state: RootState) => state.dashboardReducer);
  const payoutData: PayoutData = dashboardReducer.payoutData || {};
  const dailyTransactionData: DailyTransaction[] = dashboardReducer.dailyTransactionData || [];
  // const ErrorWidgetsData: WidgetError[] = dashboardReducer.WidgetsError || [];

  return (
    <>
      <Toolbar />
      <TopSection
        getSelectedDates={(event: Dates) => updateSelectedDates(event)}
        SelectedPrevDates={(event: Dates) => setPrevSelectDate(event)}
      />
      <Grid
        container
        spacing={3}
      >
        <Grid
          item
          md={12}
          xs={12}
        >
          <LeftSection payoutData={payoutData} dailyTransactionData={dailyTransactionData} merchantPanel={merchantPanel} />
        </Grid>
      </Grid>
    </>
  );
};
