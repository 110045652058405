import { ChangePassword, IUser } from '../../modules/AdminPanel/Users/types';
import { Pagination } from '../../types/common.types';

interface Types {
  GET_USER_LIST_REQUEST: string
  GET_USER_LIST_SUCCESS: string
  GET_USER_LIST_FAILURE: string

  ADD_USER_REQUEST: string
  ADD_USER_SUCCESS: string
  ADD_USER_FAILURE: string

  UPDATE_USER_REQUEST: string
  UPDATE_USER_SUCCESS: string
  UPDATE_USER_FAILURE: string

  DELETE_USER_REQUEST: string
  DELETE_USER_SUCCESS: string
  DELETE_USER_FAILURE: string

  CHANGE_PASSWORD_USER_REQUEST: string
  CHANGE_PASSWORD_USER_SUCCESS: string
  CHANGE_PASSWORD_USER_FAILURE: string

}

export const userConstants: Types = {

  GET_USER_LIST_REQUEST: 'GET_USER_LIST_REQUEST',
  GET_USER_LIST_SUCCESS: 'GET_USER_LIST_SUCCESS',
  GET_USER_LIST_FAILURE: 'GET_USER_LIST_FAILURE',

  ADD_USER_REQUEST: 'ADD_USER_REQUEST',
  ADD_USER_SUCCESS: 'ADD_USER_SUCCESS',
  ADD_USER_FAILURE: 'ADD_USER_FAILURE',

  UPDATE_USER_REQUEST: 'UPDATE_USER_REQUEST',
  UPDATE_USER_SUCCESS: 'UPDATE_USER_SUCCESS',
  UPDATE_USER_FAILURE: 'UPDATE_USER_FAILURE',

  DELETE_USER_REQUEST: 'DELETE_USER_REQUEST',
  DELETE_USER_SUCCESS: 'DELETE_USER_SUCCESS',
  DELETE_USER_FAILURE: 'DELETE_USER_FAILURE',

  CHANGE_PASSWORD_USER_REQUEST: 'CHANGE_PASSWORD_USER_REQUEST',
  CHANGE_PASSWORD_USER_SUCCESS: 'CHANGE_PASSWORD_USER_SUCCESS',
  CHANGE_PASSWORD_USER_FAILURE: 'CHANGE_PASSWORD_USER_FAILURE',

};

interface LoadGetUserListAction {
  type: typeof userConstants.GET_USER_LIST_REQUEST
  allUserList: IUser[]
  payload: IUser
  uuid: string
  errors?: any;
  pagination: Pagination;
  changePassword:ChangePassword
}

interface LoadedGetUserListAction {
  type: typeof userConstants.GET_USER_LIST_SUCCESS
  allUserList: IUser[]
  payload: IUser
  uuid: string
  errors?: any;
  pagination: Pagination;
  changePassword:ChangePassword
}

interface FailedGetUserListAction {
  type: typeof userConstants.GET_USER_LIST_FAILURE
  allUserList: IUser[]
  payload: IUser
  uuid: string
  errors?: any;
  pagination: Pagination;
  changePassword:ChangePassword
}

interface LoadAddUserAction {
  type: typeof userConstants.ADD_USER_REQUEST
  allUserList: IUser[]
  payload: IUser
  uuid: string
  errors?: any;
  pagination: Pagination;
  changePassword:ChangePassword
}

interface LoadedAddUserAction {
  type: typeof userConstants.ADD_USER_SUCCESS
  allUserList: IUser[]
  payload: IUser
  uuid: string
  errors?: any;
  pagination: Pagination;
  changePassword:ChangePassword
}

interface FailedAddUserAction {
  type: typeof userConstants.ADD_USER_FAILURE
  allUserList: IUser[]
  payload: IUser
  uuid: string
  errors?: any;
  pagination: Pagination;
  changePassword:ChangePassword
}

interface LoadUpdateUserAction {
  type: typeof userConstants.UPDATE_USER_REQUEST
  allUserList: IUser[]
  payload: IUser
  uuid: string
  errors?: any;
  pagination: Pagination;
  changePassword:ChangePassword
}

interface LoadedUpdateUserAction {
  type: typeof userConstants.UPDATE_USER_SUCCESS
  allUserList: IUser[]
  payload: IUser
  uuid: string
  errors?: any;
  pagination: Pagination;
  changePassword:ChangePassword
}

interface FailedUpdateUserAction {
  type: typeof userConstants.UPDATE_USER_FAILURE
  allUserList: IUser[]
  payload: IUser
  uuid: string
  errors?: any;
  pagination: Pagination;
  changePassword:ChangePassword
}

interface LoadDeleteUserAction {
  type: typeof userConstants.DELETE_USER_REQUEST
  allUserList: IUser[]
  payload: IUser
  uuid: string
  errors?: any;
  pagination: Pagination;
  changePassword:ChangePassword
}

interface LoadedDeleteUserAction {
  type: typeof userConstants.DELETE_USER_SUCCESS
  allUserList: IUser[]
  payload: IUser
  uuid: string
  errors?: any;
  pagination: Pagination;
  changePassword:ChangePassword
}

interface FailedDeleteUserAction {
  type: typeof userConstants.DELETE_USER_FAILURE
  allUserList: IUser[]
  payload: IUser
  uuid: string
  errors?: any;
  pagination: Pagination;
  changePassword:ChangePassword
}

interface LoadChangePasswordUserAction {
  type: typeof userConstants.CHANGE_PASSWORD_USER_REQUEST
  allUserList: IUser[]
  payload: IUser
  uuid: string
  errors?: any;
  pagination: Pagination;
  changePassword:ChangePassword
}

interface LoadedChangePasswordUserAction {
  type: typeof userConstants.CHANGE_PASSWORD_USER_SUCCESS
  allUserList: IUser[]
  payload: IUser
  uuid: string
  errors?: any;
  pagination: Pagination;
  changePassword:ChangePassword
}

interface FailedChangePasswordUserAction {
  type: typeof userConstants.CHANGE_PASSWORD_USER_FAILURE
  allUserList: IUser[]
  payload: IUser
  uuid: string
  errors?: any;
  pagination: Pagination;
  changePassword:ChangePassword
}

export type UserActionTypes
  = LoadGetUserListAction
  | LoadedGetUserListAction
  | FailedGetUserListAction
  | LoadAddUserAction
  | LoadedAddUserAction
  | FailedAddUserAction
  | LoadUpdateUserAction
  | LoadedUpdateUserAction
  | FailedUpdateUserAction
  | LoadDeleteUserAction
  | LoadedDeleteUserAction
  | FailedDeleteUserAction
  | LoadChangePasswordUserAction
  | LoadedChangePasswordUserAction
  | FailedChangePasswordUserAction;
