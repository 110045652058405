import React, { useState, useEffect } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import intl from 'react-intl-universal';
import {
  Typography,
  Box,
  IconButton,
  Button,
  Grid,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store/reducers';
import { IMerchantState } from '../../../types/merchant.types';
import { merchantActions } from '../../../store/actions/Merchant';
import {
  DisplaySnackbar, Input, StyledDividerLine, StyledSectionHeading,
} from '../../../components';
import { KYCMerchant } from './index';

const useStyles = makeStyles((theme: Theme) => createStyles({
  mainContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: '10px',
    justifyContent: 'space-between',
  },
  inputStyle: {
    margin: theme.spacing(1),
    width: '30ch',
  },
  button: {
    margin: theme.spacing(1),
  },
  cardStyle: {
    marginTop: '20px',
  },
  iconStyle: {
    fill: '#3485f7',
  },
}));

export const Merchant = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [form, setForm] = useState(IMerchantState);
  const [editing, setEditing] = useState(false);

  useEffect(() => {
    dispatch(merchantActions.getMerchant());
  }, [dispatch]);

  const merchantReducer = useSelector((state: RootState) => state.merchantPanelReducer);

  useEffect(() => {
    if (merchantReducer.merchantDetails) {
      setForm(merchantReducer.merchantDetails);
    }
    if (merchantReducer.isUpdated) {
      dispatch(merchantActions.getMerchant());
      setEditing(false);
    }
  }, [merchantReducer, dispatch]);

  const handleSubmit = () => {
    const forUpdate = {
      company_name: form.company_name,
      company_code: form.company_code,
      company_country: form.company_country,
    };
    dispatch(merchantActions.updateMerchant({ form: forUpdate }));
  };

  return (
    <>
      {merchantReducer && merchantReducer.updateError
        ? merchantReducer.errorMessages && Object.values(merchantReducer.errorMessages).length
        && Object.values(merchantReducer.errorMessages).map(
          (error: any) => error?.length && error.map(
            (msg: string) => <DisplaySnackbar type="error" message={msg} />,
          ),
        ) : null}
      <Grid container spacing={1} wrap="wrap" justify="space-between">
        <Box mr={1} display="flex" flexDirection="row" alignItems="baseline">
          <StyledSectionHeading>{merchantReducer?.merchantDetails?.company_name}</StyledSectionHeading>
          <Typography
            color="textSecondary"
            variant="body1"
            style={{ marginLeft: '20px' }}
          >
            {form.company_code}
          </Typography>
        </Box>
        <Box display="flex" flexDirection="row" alignItems="center" justifyContent="flex-end">
          <Box mr={1} display="flex" flexDirection="row" alignItems="center">
            <div style={{ marginRight: '20px' }}>
              <IconButton>
                {editing ? (
                  <CloseIcon
                    onClick={() => setEditing(false)}
                    aria-label="close"
                    className={classes.iconStyle}
                  />
                )
                  : (
                    <EditIcon
                      onClick={() => setEditing(true)}
                      aria-label="edit"
                      className={classes.iconStyle}
                    />
                  )}
              </IconButton>
              {editing ? (
                <span>
                  {intl.get('CLOSE')}
                </span>
              )
                : (
                  <span>
                    {intl.get('EDIT')}
                  </span>
                )}
            </div>
          </Box>
        </Box>
      </Grid>
      <StyledDividerLine />

      {/* <Card className={classes.cardStyle}>
        <CardContent> */}
      <div className={classes.mainContainer}>
        <div className={classes.inputStyle}>
          <Input
            i18nTag="COMPANY_NAME"
            isRequired
            onChange={(e: string) => setForm({ ...form, company_name: e })}
            defaultValue={form.company_name}
            isDisable={!editing}
          />
        </div>
        {/* <div className={classes.inputStyle}>
          <Input
            i18nTag="COMPANY_CODE"
            isRequired
            onChange={(e: string) => setForm({ ...form, company_code: e })}
            defaultValue={form.company_code}
            isDisable={!editing}
          />
        </div> */}
        <div className={classes.inputStyle}>
          <Input
            i18nTag="COMPANY_COUNTRY"
            isRequired
            onChange={(e: string) => setForm({ ...form, company_country: e })}
            defaultValue={form.company_country}
            isDisable={!editing}
          />
        </div>
        <div className={classes.inputStyle}>
          <Input
            i18nTag="VAT_NUMBER"
            isRequired
            onChange={(e: string) => setForm({ ...form, vat_number: e })}
            defaultValue={form.vat_number}
            isDisable={!editing}
          />
        </div>
        {/* <div className={classes.inputStyle}>
          <Input
            i18nTag="BILLING_PERIOD_IN_DAYS"
            isRequired
            onChange={(e: number) => setForm({ ...form, billing_period: e })}
            defaultValue={form.billing_period}
            isDisable={!editing}
          />
        </div> */}
        <div className={classes.inputStyle}>
          <Input
            i18nTag="PRIMARY_CONTACT_NAME"
            isRequired
            onChange={(e: string) => setForm({ ...form, primary_contact_name: e })}
            defaultValue={form.primary_contact_name}
            isDisable={!editing}
          />
        </div>
        <div className={classes.inputStyle}>
          <Input
            i18nTag="PRIMARY_CONTACT_EMAIL"
            isRequired
            onChange={(e: string) => setForm({ ...form, primary_contact_email: e })}
            defaultValue={form.primary_contact_email}
            isDisable={!editing}
          />
        </div>
        <div className={classes.inputStyle}>
          <Input
            i18nTag="PRIMARY_CONTACT_PHONE"
            isRequired
            onChange={(e: string) => setForm({ ...form, primary_contact_phone: e })}
            defaultValue={form.primary_contact_phone}
            isDisable={!editing}
          />
        </div>
        <div className={classes.inputStyle}>
          <Input
            i18nTag="TECHNICAL_CONTACT_NAME"
            isRequired
            onChange={(e: string) => setForm({ ...form, technical_contact_name: e })}
            defaultValue={form.technical_contact_name}
            isDisable={!editing}
          />
        </div>
        <div className={classes.inputStyle}>
          <Input
            i18nTag="TECHNICAL_CONTACT_EMAIL"
            isRequired
            onChange={(e: string) => setForm({ ...form, technical_contact_email: e })}
            defaultValue={form.technical_contact_email}
            isDisable={!editing}
          />
        </div>
        <div className={classes.inputStyle}>
          <Input
            i18nTag="TECHNICAL_CONTACT_PHONE"
            isRequired
            onChange={(e: string) => setForm({ ...form, technical_contact_phone: e })}
            defaultValue={form.technical_contact_phone}
            isDisable={!editing}
          />
        </div>
        {/* <div className={classes.inputStyle}>
          <Input
            i18nTag="KYC_APPROVED"
            isRequired
            defaultValue={form.kyc_approved}
            isDisable
          />
        </div> */}
      </div>
      {editing
        ? (
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
            className={classes.button}
          >
            <Button
              style={{
                marginLeft: '20px', background: '#f5f5f5', color: '#3485f7', border: '1px solid #3485f7',
              }}
              variant="contained"
              onClick={() => { setEditing(false); }}
            >
              {intl.get('CANCEL')}
            </Button>
            <Button
              style={{ background: '#3485f7', color: 'white' }}
              variant="contained"
              onClick={handleSubmit}
              className={classes.button}
            >
              {intl.get('UPDATE')}
            </Button>
          </Box>
        )
        : null}
      <div style={{ display: 'flex' }}>
        <div className={classes.inputStyle}>
          <Input
            i18nTag="API_KEY"
            isRequired
            defaultValue={form.api_key}
            isDisable
          />
        </div>
        <div className={classes.inputStyle}>
          <Input
            i18nTag="API_SECRET"
            isRequired
            defaultValue={form.api_secret}
            isDisable
          />
        </div>
      </div>
      {/* </CardContent>
      </Card> */}
      {/* <Card className={classes.cardStyle}>
        <CardContent>
          <Grid
            container
            spacing={1}
          >
            <Grid
              item
              md={8}
              xs={12}
            >
              <Typography
                color="primary"
                variant="body1"
                align="left"
              >
                <Box fontWeight="fontWeightBold">
                  {intl.get('API_KEY')}
                </Box>
              </Typography>
              <Typography
                color="textSecondary"
                variant="body1"
                align="left"
              >
                <Box fontWeight="fontWeightBold">
                  {merchantReducer?.merchantDetails?.api_key}
                </Box>
              </Typography>
            </Grid>
            <Grid
              item
              md={4}
              xs={12}
            >
              <Typography
                color="primary"
                variant="body1"
                align="left"
              >
                <Box fontWeight="fontWeightBold">
                  {intl.get('API_SECRET')}
                </Box>
              </Typography>
              <Typography
                color="textSecondary"
                variant="body1"
                align="left"
              >
                <Box fontWeight="fontWeightBold">
                  {merchantReducer?.merchantDetails?.api_secret}
                </Box>
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card> */}
      <KYCMerchant />
    </>
  );
};
